import Grid from "UI/App/Components/Grid/Grid";
import Block from "UI/App/Components/Block/Block";
import React from "react";
import { useLoaderData } from "react-router-dom";
import CompanyDetail from "UI/App/Partials/Content/CRM/Companies/Company/Blocks/CompanyDetails/CompanyDetail";
import Title from "UI/App/Components/Title/Title";
import { formatName } from "App/Util/format";

export default function CompanyBlock() {
    const { order } = useLoaderData();

    return (
        <Block name='customer' title='Klant'>
            <Grid columns='1' gap='0px'>
                <Title type='h3' className={"notranslate"} style={{ marginBottom: '10px' }}>{order.company?.name}</Title>
                <CompanyDetail title='Contactpersoon' content={[formatName(order.contactPerson?.firstName, order.contactPerson?.prefixToSurname, order.contactPerson?.surname)]} display={order.contactPerson}/>
                <CompanyDetail title='E-mail contactpersoon' content={[
                    <a href={'https://outlook.office.com/?path=/mail/action/compose&to=' + order.contactPerson?.email} target='_blank' rel='noreferrer'>
                        {order.contactPerson?.email}
                    </a>
                ]} display={order.contactPerson?.email}/>
            </Grid>
        </Block>
    )
}