import { useLoaderData, useNavigate } from "react-router-dom";
import Grid from "UI/App/Components/Grid/Grid";
import React, { useRef } from "react";
import Checklist from "UI/App/Partials/Content/CRM/Orders/ActualCosting/Checklist";
import Summary from "UI/App/Partials/Content/CRM/Orders/ActualCosting/Summary";
import PageHeader from "UI/App/Components/PageHeader/PageHeader";
import CompanyBlock from "UI/App/Partials/Content/CRM/Orders/ActualCosting/CompanyBlock";
import MachineCostTable from "UI/App/Partials/Content/CRM/Orders/ActualCosting/MachineCostTable";
import MaterialCostTable from "UI/App/Partials/Content/CRM/Orders/ActualCosting/MaterialCostTable";
import Button from "UI/App/Components/Button/Button";
import Icon from "UI/App/Components/Icon/Icon";
import { printActualCosting } from "UI/App/Partials/Content/CRM/Orders/ActualCosting/printActualCosting";
import ThirdPartyTable from "UI/App/Partials/Content/CRM/Orders/ActualCosting/ThirdPartyTable";
import ManualLaborTable from "UI/App/Partials/Content/CRM/Orders/ActualCosting/ManualLaborTable";
import MiscellaneousCostTable from "UI/App/Partials/Content/CRM/Orders/ActualCosting/MiscellaneousCostTable";

export default function ActualCosting() {
    const { order } = useLoaderData();
    const contentRef = useRef();
    const navigate = useNavigate();

    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }} ref={contentRef}>
            <PageHeader title={'[' + order?.number + '] ' + order.company?.name + (order?.description ? ' - ' + order?.description : '')}>
                <span
                    style={{ display: 'flex', alignItems: 'center', gap: '10px', alignSelf: 'end' }}>
                    {order?.status}

                    <Button data-title='Downloaden' className='btn btn--black btn--round' onClick={() => printActualCosting(order, contentRef)}><Icon name='download'/></Button>
                    <Button data-title='Herberekenen' className='btn btn--black btn--round' onClick={() => navigate(`/calculations/${order.calculation.id}/recalculate`)}><Icon name='calculator'/></Button>
                </span>
            </PageHeader>

            <Grid colums={2} customColTemplate='1fr 1fr'>
                <Checklist />

                <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                    <Summary />
                    <CompanyBlock />
                </div>
            </Grid>

            <MachineCostTable />
            <MaterialCostTable />
            <MiscellaneousCostTable />
            <ThirdPartyTable />
            <ManualLaborTable />
        </div>
    )
}