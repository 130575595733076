import React, {useContext, useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';
import {FetchContext} from 'App/Strapi/FetchContext';
import {formatDate} from 'App/Util/format';
import {usePagination} from 'UI/App/Components/Pagination/Pagination';
import Table from 'UI/App/Components/Table/Table';
import Icon from 'UI/App/Components/Icon/Icon';
import useWindowDimensions from "App/Util/useWindowDimensions";
import Container from "UI/App/Components/Container/Container";
import IF from "UI/App/Components/Conditional/IF";
import Button from "UI/App/Components/Button/Button";
import Popup, {closePopup, openPopup} from "UI/App/Components/Popup/Popup";
import dayjs from "dayjs";
import ShipmentCalculator from "UI/App/Partials/Content/Shipment/ShipmentCalculator";
import {Spinner} from "@microsoft/mgt-react";
import {SpinnerOverlay} from "UI/App/Components/Spinner";

export default function CalculationOverview() {
    const [selectedCalculation, setSelectedCalculation] = useState(null);
    const [tableData, setTableData] = useState([]);
    const {authAxios} = useContext(FetchContext);
    const {width, height} = useWindowDimensions();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);

    const [calculations, setCalculations] = useState([]);
    const [isInfoMessage, setIsInfoMessage] = useState(true);
    const [editCalcId, setEditCalcId] = useState(null);

    // Enable pagination
    const {
        paging,
        filtering,
        currentPage,
        resultsPerPage,
        setTotalPages,
        setTotalResults,
        filterQuery,
        paginationStateLoaded
    } = usePagination({
        storageKey: 'calculation',
        searchSettings: {
            enabled: true,
            strapiFields: [
                '[company][name]',
            ]
        },
        htmlElements: [
            <button onClick={() => navigate('/shipping/shipments/create')}><Icon name={'plus'} /></button>
        ],
        buttonCollapseBreakpoint: 700,
        resultsPerPageBreakpoint: 1600,
        paginationBreakpoint: 1350,
        filterPopupBreakpoint: 1000
    });

    // Run in dom update
    useEffect(() => {
        if (!paginationStateLoaded) return;

        loadTable();
    }, [authAxios, currentPage, resultsPerPage, setTotalPages, setTotalResults, filterQuery, paginationStateLoaded]);

    async function loadTable () {
        setIsLoading(true);

        await authAxios
            .get(`/shipments?populate=company&populate=sending_address&sort=id:desc&${filterQuery}`)
            .then(({ data }) => {
                setTotalPages(data.meta.pagination.pageCount);
                setTotalResults(data.meta.pagination.total);

                let options = [];

                // loop through all the records
                for (const [index, shipment] of Object.entries(data.data)) {
                    // add a row for the company
                    options.push({
                        attributes: {
                            onClick: () => {
                                navigate(`/shipping/shipments/${shipment.id}`)
                            }
                        },
                        data: [
                            shipment.shipmentId,
                            shipment?.order?.number === undefined ? '-' : shipment?.order?.number,
                            shipment?.company === null ? '-' : shipment?.company?.name,
                            shipment?.sending_address === null ? '-' : shipment?.sending_address?.companyName,
                            dayjs(shipment?.pickupDate).add(1, 'day').format("DD-MM-YYYY")
                        ]
                    });
                }

                setTableData(options)
            })
            .catch((exception) => {
                console.error(exception);
            });


        setIsLoading(false);
    }

    function shipmentCalculatorCallBack(){
        closePopup();
        loadTable();
    }


    return (
        <div>
            <Popup popupID='shippingCalculator' title={'Verzending aanmelden'}>
                <ShipmentCalculator callBack={shipmentCalculatorCallBack} />
            </Popup>
            {filtering}
            <Container>
                <SpinnerOverlay visible={isLoading} >
                    <Table
                        structure={['150px', '120px','100%', '100%', '150px']}
                        headers={[
                            'VerzendId',
                            'Order nummer',
                            'Klant',
                            'Verzender',
                            'Ophaal datum',
                        ]}
                        data={tableData}
                        padding='s'
                        paddingBreakpoint={1400}
                        buttonCollapseBreakpoint={1400}
                        columnOverflowExceptions={[6]}
                        columnTranslationExceptions={[1, 2]}
                    />
                </SpinnerOverlay>
            </Container>
            {paging}
        </div>
    );
}
