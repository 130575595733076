import { useLocation, useNavigate, useRouteError } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { publicFetch } from 'App/Util/fetch';
import Grid from 'UI/App/Components/Grid/Grid';
import { tryParse } from 'App/Util/format';

export default function RoutingErrorHandler(props) {
    const navigate = useNavigate();
    // get user info fom localstorage
    const user = tryParse(localStorage.getItem('userInfo'));

    const [details, setDetails] = useState(true);
    const [errorPacket, setErrorPacket] = useState(null);
    const [errorEllement, setErrorEllement] = useState({});

    const locationParams = useLocation();
    const error = useRouteError();

    useEffect(() => {
        // create empty object which we will use for setStates later
        let packet = {};
        let errEll = {};

        // split the stack on new lines
        let parts = error.stack?.split('\n') ?? [];
        // get the first part as 'header', and remove it
        let header = parts.shift();
        // remove whitespaces
        parts = parts.map(function (part) {
            return part.trim();
        });

        // prepare info to display to the user
        errEll.stack = parts.join('\n');
        errEll.header = header;
        errEll.splitStack = parts;

        // prepare all info we know about the error
        packet.error = {
            cause: error.cause,
            name: error.name,
            message: error.message,
            stack: error.stack,
            fileName: error.fileName,
            lineNumber: error.lineNumber,
            columnNumber: error.columnNumber
        };
        packet.routeInfo = locationParams;
        packet.routeInfo.lastPathName = sessionStorage.getItem('lastRoute');
        packet.user = user?.id ?? null;
        packet.occurredAt = new Date();

        // set needed states
        setErrorPacket(packet);
        setErrorEllement(errEll);

        // set details to be collapsed if were NOT on development
        process.env.NODE_ENV !== 'development' && setDetails(false);
    }, []);

    useEffect(() => {
        if (errorPacket === null) return;
        // log automatically if were NOT on development
        process.env.NODE_ENV !== 'development' && logError();
    }, [errorPacket]);

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100vw',
                height: '100vh'
            }}
        >
            {process.env.NODE_ENV !== 'development' && elements()}
            {process.env.NODE_ENV === 'development' && devElements()}
        </div>
    );
    function handleDetails() {
        setDetails(!details);
    }
    function logError(returnToPrevious = false) {
        if (!user || localStorage.getItem('token') === null) {
            return;
        }

        // create the data payload
        let data = {
            user: user?.id,
            payload: errorPacket,
            status: '500',
            action: 'Error',
            apiToken: null
        };

        // make a public post reqeust
        publicFetch
            .post(
                '/v1/admin/logs',
                {
                    data
                },
                {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token')
                    }
                }
            )
            .then((data) => {
                // send user back to home or if possible last visisted page
                if (returnToPrevious) {
                    backToLastUrl();
                }
            })
            .catch((exception) => {
                console.error(exception);
                // send user back to home or if possible last visisted page
                if (returnToPrevious) {
                    backToLastUrl();
                }
            });
    }

    function devElements() {
        return (
            <>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        whiteSpace: 'break-spaces',
                        width: '900px'
                    }}
                >
                    <h1>Er is een fout opgetreden</h1>
                    <br />
                    <b>{errorEllement.header}</b>
                    <br />
                    {details && (
                        <>
                            <code>{errorEllement.stack}</code>
                            <br />
                        </>
                    )}
                    <button style={{ cursor: 'pointer' }} onClick={handleDetails}>
                        {details ? 'Minder info' : 'Meer info'}
                    </button>
                    <br />
                    <Grid columns={process.env.NODE_ENV === 'development' ? 2 : 1}>
                        {process.env.NODE_ENV === 'development' && (
                            <button
                                className={'btn btn--black'}
                                onClick={() => {
                                    logError(true);
                                }}
                            >
                                Log error en ga terug
                            </button>
                        )}
                        <button className={'btn'} onClick={backToLastUrl}>
                            Ga terug naar de laatst bekeken pagina
                        </button>
                    </Grid>
                </div>
            </>
        );
    }
    function elements() {
        return devElements();
    }
    function backToLastUrl() {
        let route = sessionStorage.getItem('lastRoute') === undefined ? '/' : sessionStorage.getItem('lastRoute');
        navigate(route);
    }
}
