import {useCallback, useContext, useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';
import {stringify} from 'qs';
import {FetchContext} from 'App/Strapi/FetchContext';
import Icon from 'UI/App/Components/Icon/Icon';
import {usePagination} from 'UI/App/Components/Pagination/Pagination';
import Table from 'UI/App/Components/Table/Table';
import Button from 'UI/App/Components/Button/Button';
import capitalize from "lodash/capitalize";
import HasRole from "UI/App/Components/Auth/HasRole";
import useWindowDimensions from "App/Util/useWindowDimensions";

export const _PURCHASE_QUOTE_STATUS_OPTIONS = [
    'aangevraagd',
    'wachtend op offerte',
    'in bestelling',
    'afgerond',
    'klacht',
    'afgewezen'
];

const statusOptions = [];

for (const status of _PURCHASE_QUOTE_STATUS_OPTIONS) {
    statusOptions.push(<option key={status}>{capitalize(status)}</option>);
}

export default function PurchaseQuotesOverview() {
    const { authAxios } = useContext(FetchContext);
    const navigate = useNavigate();

    const [quotesTableData, setQuotesTableData] = useState([]);
    const [quotesData, setQuotesData] = useState([]);
    const { width, height } = useWindowDimensions();
    const {
        paging,
        filtering,
        currentPage,
        resultsPerPage,
        setTotalPages,
        setTotalResults,
        filterQuery,
        paginationStateLoaded
    } = usePagination({
        storageKey: 'purchaseQuotes',
        searchSettings: {
            enabled: true,
            strapiFields: ['status', 'number', '[calculation][number]', '[supplier][name]']
        }
        ,
        htmlElements: [
            height > 500 && width > 500 && <span className='pagination__filter addItem' key='link--add'>
                <Link to={`/purchase/quotes/create`} className='btn btn--add btn--icon-right'>
                    Toevoegen
                    <Icon name='plus' />
                </Link>
            </span>
        ]
    });

    const updateQuote = useCallback(
        (target, stockId, stockKey) => {


            const query = stringify({
                fields: ['id', 'number', 'status'],
                populate: {
                    calculation: {
                        fields: ['id', 'number']
                    },
                    stockPurchaseSlip: {
                        fields: ['id', 'number']
                    },
                    supplier: {
                        fields: ['id', 'name']
                    }
                },
                sort: ['number:desc'],
                pagination: {
                    pageSize: resultsPerPage,
                    page: currentPage
                }
            }, { encodeValuesOnly: true });

            const toastFieldName = target.getAttribute('data-toast-field-name');

            authAxios
                .put(`/calculations/purchases/quotes/${stockId}?${query}`, {
                    data: {
                        [target.name]: target.value.toLowerCase()
                    }
                })
                .then(({ data }) => {
                    setQuotesData((prev) => {
                        prev[stockKey][target.name] = target.value;
                        return [...prev];
                    });
                    toast.success(
                        <>
                            <b>{data.data.number}</b>: {toastFieldName} omgezet naar{' '}
                            <i>{target.value}</i>
                        </>
                    );
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(`Er ging iets mis met het bewerken van de inkoopofferte.`);
                });
        },
        [authAxios]
    );

    const getQuotes = useCallback(() => {


        const query = stringify({
            fields: ['id', 'number', 'status'],
            populate: {
                calculation: {
                    fields: ['id', 'number']
                },
                order:true,
                stockPurchaseSlip: {
                    fields: ['id', 'number']
                },
                supplier: {
                    fields: ['id', 'name']
                }
            },
            sort: ['number:desc'],
            pagination: {
                pageSize: resultsPerPage,
                page: currentPage
            }
        }, { encodeValuesOnly: true });

        authAxios
            .get(`/calculations/purchases/quotes?${query}&${filterQuery}`)
            .then(({ data }) => {
                setQuotesData(data.data);
                setTotalPages(data.meta.pagination.pageCount);
                setTotalResults(data.meta.pagination.total);
            })
            .catch((exception) => {
                console.error(exception);
                toast.error('Kon de inkoopoffertes niet ophalen.');
            });
    }, [authAxios, currentPage, resultsPerPage, setTotalPages, setTotalResults, filterQuery, paginationStateLoaded]);

    const setTableData = useCallback(() => {
        const tableData = [];

        // loop through all the records
        for (const [key, quote] of Object.entries(quotesData)) {
            tableData.push({
                attributes: {
                    id: `purchase-quote-${quote.id}`,
                    key: key,
                },
                data: [
                    quote?.number,
                    <>
                        {quote?.order?.number ? (
                            <Button
                                to={`/orders/${quote?.order?.id}`}
                                onClick={(e) => {
                                    e.stopPropagation();
                                }}
                            >
                                {quote?.order?.number}
                            </Button>
                        ) : (
                            '-'
                        )}
                    </>,
                    quote?.supplier?.name ? <Link to={`/crm/suppliers/${quote.supplier.id}`} >{quote.supplier.name}</Link> : '-',
                    <select
                        name='status'
                        defaultValue={capitalize(quote?.status) ?? undefined}
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                        onChange={({target}) => {
                            updateQuote(target, quote.id, key);
                        }}
                        data-toast-field-name='status'
                    >
                        {statusOptions}
                    </select>,
                    <div className={'table__action-buttons'}>
                        <Link data-title={'Bekijken'} className={'btn btn--transparent'} to={`/purchase/quotes/${quote?.id}`}><Icon name={'eye'} /></Link>

                        {width >= 900 && (
                            <>
                            <Link data-title={'Bewerken'} className={'btn btn--transparent'} to={`/purchase/quotes/${quote?.id}/edit`}><Icon name={'pencil'} /></Link>
                            <HasRole roles={['admin']}>
                                <Link data-title={'Bestellen'} className={'btn btn--transparent'} to={`/purchase/orders/create/${quote?.id}`}><Icon name={'flatbed-cart'} /></Link>
                            </HasRole>
                            </>
                        )}
                    </div>
                ]
            });
        }

        setQuotesTableData(tableData);
    }, [navigate, quotesData, updateQuote]);

    /* handle callback changes */
    useEffect(() => {
        // fetch stocks on page load & when the callback's dependencies change
        getQuotes();
    }, [getQuotes, currentPage, resultsPerPage, setTotalPages, setTotalResults, filterQuery, paginationStateLoaded]);

    useEffect(() => {
        // set the table data on change on the `setTableData` function, which changes on `stockData` state
        setTableData();
    }, [setTableData]);

    return (
        <>
            {filtering}
            <Table
                structure={{
                    350: ['100%', 0, 0, 0, '50px'],
                    550: ['120px', 0, '100%', 0, '50px'],
                    750: ['120px', '150px', '100%', 0, '50px'],
                    900: ['120px', '150px', '100%', '200px', '50px'],
                    default: ['150px', '150px', '100%', '200px', '120px'],
                }}
                headers={['Nummer', 'Order', 'Leverancier', 'Status', '']}
                data={quotesTableData}
                padding={'s'}
                columnOverflowExceptions={[4]}
            />
            {paging}
        </>
    );
}
