import Drop from "UI/App/Partials/Content/Planning/ViewOptions/Week/Drop";
import {finalizeOrderChanges, getDependents} from "../../Helpers";
import Button from "UI/App/Components/Button/Button";
import IF from "UI/App/Components/Conditional/IF";
import Icon from 'UI/App/Components/Icon/Icon';
import {useEffect, useState} from "react";
import isEmpty from 'lodash/isEmpty';
import {toast} from "react-toastify";
import {format} from 'date-fns';
import dayjs from "dayjs";

export default function Weekly({visualizeDependencies, type, id, data, monday, sunday, setData, allData, planningIndex, selectedType, removeAllDependency, setPopupCurrentMachine, setPopupFormData, setIsShowMode, isShowMode, blacklist, showAllDependencyOptions, createAllDependency, setShowModeAllActivator}) {

    const days = Array(7).fill('').map((_, i) => {
        const date = new Date(monday);
        return date.setUTCDate(date.getUTCDate() + i);
    });

    // list of all items this week
    const [itemList, setItemList] = useState([]);
    const [showModeActivator, setShowModeActivator] = useState(undefined);
    const [allPlannedItems, setAllPlannedItems] = useState([]);

    useEffect(() => {
        if (type === 'all') {
            setShowModeAllActivator(showModeActivator)
        }
    }, [showModeActivator])

    useEffect(() => {
        let warningString;
        if (type === 'machines') {
            warningString = 'Deze machine';
        } else if (type === 'orders') {
            warningString = 'Dit project';
        } else if (type === 'all') {
            warningString = 'Deze weergave'
        } else {
            warningString = type;
        }

        if (!data.machines && !data.orders && type !== 'all') {
            toast.warning(`${warningString} bevat geen planbare items`)
        }
    }, [data]);

    useEffect(() => {
        if (Array.isArray(data.machines) && data.machines) {
            setItemList(createItemList(data.machines));
        } else {
            setItemList(createItemList([]));
        }
    }, [monday, sunday, data]);

    useEffect(() => {
        if (!isShowMode && type === 'orders') {
            showDependencyOptions(null, true)
        }
    }, [isShowMode])

    // Get date without timezone issues
    function getDateFromUTC(date) {
        const returnValue = new Date(date);
        returnValue.setUTCHours(0, 0, 0, 0);
        return returnValue;
    }

    // Get the amount of days between two items,
    // returns a positive number if date2 is smaller than date1.
    // where returnValue 1 means date1 is one day before date2.
    function getDaysInBetween(date1, date2) {
        return (date1 - date2) / (1000 * 60 * 60 * 24);
    }

    // fill itemList
    function createItemList(items) {
        const allPlannedItems = [];
        let newItemList = [];
        if (!isEmpty(items)) {

            items.forEach((item, itemsIndex) => {
                // make base object with empty content
                let eligiblePlanningItems = {
                    ...item,
                    prevItem: items[itemsIndex - 1] ? items[itemsIndex - 1].machineId : undefined,
                    nextItem: items[itemsIndex + 1] ? items[itemsIndex + 1].machineId : undefined,
                    startDay: monday,
                    endDay: sunday,
                    planning: [],
                };

                item.planning.forEach((planningItem, planningItemIndex) => {

                    // check if the current planningItem has a planningItem directly next to it on the right; tomorrow.
                    function getConnection() {
                        const nextItem = item.planning[planningItemIndex + 1];
                        if (nextItem) {
                            const nextStart = getDateFromUTC(nextItem.date);
                            if (getDaysInBetween(nextStart, planningDate) === 1) {
                                return nextItem.uuid;
                            }
                        }

                        return null;
                    }

                    function getDependencies() {
                        let newDependencies = [];

                        planningItem.dependsOn.forEach((dependency) => {
                            let dependencyHeight;
                            let dependencyWidth;

                            items.forEach((arrayItem, arrayItemIndex) => {
                                arrayItem.planning.forEach((arrayPlanning) => {
                                    if (dependency === arrayPlanning.uuid) {
                                        const dependencyDate = getDateFromUTC(arrayPlanning.date);
                                        const planningDate = getDateFromUTC(planningItem.date);
                                        const daysBetween = getDaysInBetween(planningDate, dependencyDate);
                                        dependencyWidth = daysBetween;
                                        dependencyHeight = itemsIndex - arrayItemIndex;
                                    }
                                })
                            })

                            newDependencies.push({
                                id: dependency,
                                height: dependencyHeight,
                                width: dependencyWidth,
                            });
                        });
                        return newDependencies;
                    }

                    // set date variables
                    const planningDate = getDateFromUTC(planningItem.date);

                    eligiblePlanningItems.planning.push({
                        ...planningItem,
                        planningDate: planningDate,
                        dayPosition: Math.ceil(getDaysInBetween(planningDate, monday)),
                        itemNumber: planningItemIndex,
                        connectsTo: getConnection(),
                        dependsOn: getDependencies(),
                        isDependencyOption: planningItem.isDependencyOption || false,
                        totalHours: item.totalHours,
                    });
                })

                allPlannedItems.push({...eligiblePlanningItems});

                // filter out items that are not in the current week
                eligiblePlanningItems.planning = eligiblePlanningItems.planning.filter((planningItem) => {
                    return planningItem.planningDate <= sunday && planningItem.planningDate >= monday;
                });

                if (eligiblePlanningItems.planning.length > 0) {
                    newItemList.push(eligiblePlanningItems);
                }
            })
        }

        setAllPlannedItems(allPlannedItems);

        for (const item of newItemList) {
            if (item.planning.length > 0) {
                for (const planDay of item.planning) {
                    planDay.dependents = getDependents(planDay, allPlannedItems, monday, sunday);

                }
            }
        }

        return newItemList;
    }

    function finalizeChanges(dif, draggingItem) {
        if (dif !== 0) {
            let newData = {...data}
            setData(finalizeOrderChanges(newData, dif, draggingItem));
        }
    }

    function removeDependency(item, uuid) {
        let newData = {...data};
        newData.machines = newData.machines.map((machine) => {
            machine.planning = machine.planning.map((planningItem) => {
                if (planningItem.uuid === uuid) {
                    return {
                        ...planningItem,
                        dependsOn: planningItem.dependsOn.filter((dependencyId) => dependencyId !== item.uuid),
                    };
                }
                return planningItem;
            });
            return machine;
        });
        setData(newData)
    }

    function createDependency(item) {
        const newData = {...data};
        for (const machine of newData.machines) {
            for (const planningItem of machine.planning) {
                if (planningItem.uuid === item.uuid) {
                    planningItem.dependsOn.push(showModeActivator.uuid);
                }
            }
        }
        setData(newData)
        setIsShowMode(false);
    }

    function getPlanningButtonPath() {
        if (type !== 'all') {
            if (type === 'orders') {
                localStorage.setItem("selected-type", 'ORDERS');
                return '/planning/all';
            } else if (type === 'machines') {
                localStorage.setItem("selected-type", 'MACHINES');
                return '/planning/all';
            }
        } else if (selectedType === 'MACHINES') {
            return `/planning/machines/${data.id}`
        } else if ((type === 'all' && selectedType === 'ORDERS')) {
            return `/planning/orders/${data.id}`
        }
    }

    function getTitleDisplay() {
        if (type === 'orders' || (type === 'all' && selectedType === 'ORDERS')) {
            // return data.description + ' - ' + `${data.number ? data.number : ''}`;
            return `${data.number} - ${data.company}`;
        } else if ((type === 'machines' || type === 'all') && selectedType === 'MACHINES') {
            return data.name;
        }
        return 'Onbekende Titel';
    }

    function showDependencyOptions(item, isShowingOptions) {

        const newData = {...data};
        newData.machines.forEach((machine) => {
            machine.planning.forEach((planningItem) => {
                if (isShowingOptions) {
                    planningItem.isDependencyOption = false;
                } else {
                    if (item.uuid !== planningItem.uuid) {
                        if (!planningItem.dependsOn.some(dependency => dependency === item.uuid) && (!planningItem.number || planningItem.number === item.number)) {
                            planningItem.isDependencyOption = true;
                        }
                    }
                }
            })
        });

        setData(newData);
    }

    function expand(item) {
        const newData = {...data};
        let newUUID = crypto.randomUUID();
        newData.machines.forEach((newDataItem) => {
            if (newDataItem.planning.some((planningItem) => planningItem.uuid === item.uuid)) {

                const expandedItem = newDataItem.planning.find((planningItem) => planningItem.uuid === item.uuid);

                if (!expandedItem) {
                    return;
                }

                expandedItem.time /= 2;

                const {id, ...newItemData} = expandedItem;

                newDataItem.planning.push({
                    ...newItemData,
                    date: getNextDate(item.date),
                    uuid: newUUID,
                    dependsOn: [item.uuid],
                    persons: [],
                    time: item.time / 2,
                });
            }
        });

        // Move dependencies from the split item to the new item
        for (const machine of newData.machines) {
            for (const planningItem of machine.planning) {
                if (planningItem.dependsOn.includes(item.uuid) && planningItem.uuid !== newUUID) {
                    const dependencyIndex = planningItem.dependsOn.findIndex((dependency) => dependency === item.uuid);
                    delete planningItem.dependsOn[dependencyIndex];
                    planningItem.dependsOn.push(newUUID);
                    planningItem.dependsOn = planningItem.dependsOn.filter((dependency) => dependency !== undefined);
                }
            }
        }

        setData(newData);

        function getNextDate(date) {
            date = new Date(date);
            date.setUTCDate(date.getUTCDate() + 1);
            if (date.getUTCDay() === 0) {
                date.setUTCDate(date.getUTCDate() + 1);
            }
            return format(date, 'yyyy-MM-dd');
        }
    }
    function expandProject(item, expansionData) {

        const newData = { ...data };
        let newUUID = crypto.randomUUID();
        let itemEntry = item.planning[item.planning.length - 1]
        item.uuid = itemEntry.uuid
        item.date = itemEntry.date

        newData.machines.forEach((newDataItem) => {
            const expandedItem = newDataItem.planning.find((planningItem) => planningItem.uuid === item.uuid);

            if (!expandedItem) {
                return;
            }

            const { id, ...newItemData } = expandedItem;

            newDataItem.planning.push({
                ...newItemData,
                uuid: newUUID,
                dependsOn: [item.uuid],
                persons: [],
                time: expansionData.time,
                note: expansionData?.note ?? "",
                date: expansionData?.date,
                priority: expansionData?.priority?.value ?? 5
            });
        });

        // Move dependencies from the split item to the new item
        for (const machine of newData.machines) {
            for (const planningItem of machine.planning) {
                if (planningItem.dependsOn.includes(item.uuid) && planningItem.uuid !== newUUID) {
                    const dependencyIndex = planningItem.dependsOn.findIndex((dependency) => dependency === item.uuid);
                    delete planningItem.dependsOn[dependencyIndex];
                    planningItem.dependsOn.push(newUUID);
                    planningItem.dependsOn = planningItem.dependsOn.filter((dependency) => dependency !== undefined);
                }
            }
        }

        setData(newData);

        function getNextDate(date) {
            date = new Date(date);
            date.setUTCDate(date.getUTCDate() + 1);
            if (date.getUTCDay() === 0) {
                date.setUTCDate(date.getUTCDate() + 1);
            }
            return format(date, 'yyyy-MM-dd');
        }
    }

    function shrink(item) {
        const newData = { ...data };
        const shrunkMachine = newData.machines.find(machine => machine.planning.some(planning => planning.uuid === item.uuid));
        const shrunkItemIndex = shrunkMachine.planning.findIndex(planning => planning.uuid === item.uuid);
        newData.machines.forEach((newDataItem) => {
            newDataItem.planning.forEach((planningItem) => {
                if (planningItem.dependsOn.includes(item.uuid)) {
                    const dependencyIndex = planningItem.dependsOn.findIndex((dependency) => dependency === item.uuid);
                    planningItem.dependsOn[dependencyIndex] = shrunkMachine.planning[shrunkItemIndex - 1].uuid;
                }

                if (planningItem.uuid === item.uuid) {
                    newDataItem.planning[shrunkItemIndex - 1].time += item.time;
                    // Move dependencies from the split item to the new item
                    newDataItem.planning[shrunkItemIndex - 1].dependsOn = [
                        ...newDataItem.planning[shrunkItemIndex - 1].dependsOn,
                        ...item.dependsOn
                            .map(dependency => dependency?.id ? dependency.id : dependency)
                            .filter(dependency => dependency !== newDataItem.planning[shrunkItemIndex - 1].uuid)
                    ];
                    newDataItem.planning = newDataItem.planning.filter((planningItem) => planningItem.uuid !== item.uuid);
                }
            })
        });
        setData(newData);
    }

    function getNameContent(item, prevItem) {
        if (type !== 'all') {
            if (item) {
                return item.machineName;
            }
            return;
        } else {
            if (!prevItem || prevItem.name === item.name) {
                return data.name
            }
            return;
        }
    }

    return (
        <>
            {
                type !== 'all' && (
                    <>
                        <div className={`planning__content__header__week`}>
                            <div className="planning__content__header__info__container">
                                <span className="planning__content__header__info__item">
                                    {getTitleDisplay()}
                                </span>
                                <Button
                                    data-title={type === 'all' ? 'Detail planning bekijken' : 'Algemene planning bekijken'}
                                    className={'btn btn--transparent planning__button'}
                                    onClick={(e) => e.stopPropagation()}
                                    to={getPlanningButtonPath()}
                                >
                                    <Icon name={'calendar'} className="planning__icon"/>
                                </Button>
                            </div>
                            <div className="planning__content__header__date__container">
                                <div className="planning__content__header__date__filler"/>
                                {
                                    days.map((day, index) => {
                                        const currentDay = dayjs(day);

                                        const timeStamp = new Date(day);
                                        const orderDueDateTime =  new Date(Date.parse(data?.deliveryDate))

                                        let backgroundColor = '';
                                        let color = 'black';
                                        // If due date make orange if past due date make red
                                        if(dayjs(timeStamp).isSame(orderDueDateTime)){
                                            backgroundColor = '#f3ab27'
                                            color = 'white';
                                        }else if(dayjs(timeStamp).isAfter(orderDueDateTime)){
                                            backgroundColor = '#ea6267';
                                            color = 'white';
                                        }

                                        return (
                                            <span
                                                // key={date + '-' + month + '-' + index + '-' + planningIndex}
                                                className="planning__content__header__date__item"
                                                style={{
                                                    backgroundColor: backgroundColor
                                                }}
                                            >
                                                <span
                                                    className="planning__content__header__date__item__day"
                                                    style={{
                                                        color: color
                                                    }}
                                                >
                                                    {new Date(timeStamp).toLocaleDateString('nl-NL', {weekday: 'long'})}
                                                </span>
                                                <span
                                                    className="planning__content__header__date__item__date"
                                                    style={{
                                                        opacity: color ===  'black' ? '0.1' : '0.5'
                                                    }}
                                                >
                                                    <span className="date" style={{
                                                            color:color,
                                                        }}>
                                                        {currentDay.format('D')}
                                                    </span>
                                                </span>
                                            </span>
                                        )
                                    })
                                }
                                <div className="planning__content__header__date__filler"/>
                            </div>
                        </div>
                    </>
                )
            }
            <IF condition={Array.isArray(itemList)}>
                {itemList.filter(item => item !== null).map((item, index) => {
                    if (isEmpty(blacklist) || !blacklist.find((blacklisted) => blacklisted.id === item.id)) {
                        return (
                            <div
                                key={item.name + '-' + index + '-' + planningIndex}
                                className={`planning__content__item__weekly`}
                                onContextMenu={(e) => e.preventDefault()}
                            >
                                <div className="planning__content__item__filler">
                                    <div className="planning__content__name__container">
                                        <div className={`planning__content__color__indicator bg-${item.colorIndex}`}/>
                                        <span className="planning__content__name__content" style={{fontWeight: 600, fontSize: '15px'}}>
                                            {item.name}
                                        </span>
                                    </div>
                                </div>
                                <div className="planning__content__item__filler"/>
                                <Drop
                                    allPlannedItems={allPlannedItems}
                                    visualizeDependencies={visualizeDependencies}
                                    item={item}
                                    finalizeChanges={finalizeChanges}
                                    prevItem={itemList[index - 1]}
                                    removeDependency={removeDependency}
                                    createDependency={createDependency}
                                    setPopupCurrentMachine={setPopupCurrentMachine}
                                    setPopupFormData={setPopupFormData}
                                    type={type}
                                    id={id}
                                    isContent={true}
                                    selectedType={selectedType}
                                    showDependencyOptions={showDependencyOptions}
                                    setIsShowMode={setIsShowMode}
                                    isShowMode={isShowMode}
                                    setShowModeActivator={setShowModeActivator}
                                    expand={expand}
                                    expandProject={expandProject}
                                    shrink={shrink}
                                    createAllDependency={createDependency}
                                />
                                <div className="planning__content__item__filler"/>
                            </div>
                        )
                    }
                })}
            </IF>
            <IF condition={isEmpty(itemList)}>
                {
                    <div className={`planning__content__item__weekly`}>
                        <div className="planning__content__item__filler">
                            <div className="planning__content__name__container">
                                <div className={`planning__content__color__indicator`}/>
                                <span className="planning__content__name__content" style={{fontWeight: 600, fontSize: '15px'}}>
                                    {getNameContent(null, null)}
                                </span>
                            </div>
                        </div>
                        <div className="planning__content__item__filler"/>
                        <div className="planning__content__item__filler"/>
                        <div className="planning__content__item__filler"/>
                    </div>
                }
            </IF>
        </>
    )
}
