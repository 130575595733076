import {Fragment, useContext, useEffect, useRef, useState} from 'react';
import {useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import {stringify} from 'qs';
import {FetchContext} from 'App/Strapi/FetchContext';
import {formatDateWithoutTime, formatMinutes, formatName, formatNumberValue, formatTime, mmToPx, parseTimeToNumber} from 'App/Util/format';
import {getVisibleTextWidthInMM, limitLines} from 'App/Util/getVisibleTextWidth';
import IF from 'UI/App/Components/Conditional/IF';
import Grid from 'UI/App/Components/Grid/Grid';
import {SpinnerOverlay} from 'UI/App/Components/Spinner';
import PretoreBackground from 'UI/Assets/Images/PdfBackgrounds/Order_PRETORE.jpg';
import HJMGbackground from 'UI/Assets/Images/PdfBackgrounds/Order_HJMG.jpg';
import './order.scss';
import 'UI/App/Css/pdf-mockup.scss';
import Button from 'UI/App/Components/Button/Button';
import {jsPDF} from 'jspdf';
import html2canvas from 'html2canvas';
import PageHeader from 'UI/App/Components/PageHeader/PageHeader';
import Icon from 'UI/App/Components/Icon/Icon';
import {base64ToBlobURL} from "App/Util/fetch";
import {planningSuffix} from "UI/App/Partials/Content/Calculations/HJMG/steps/util";
import HasRole from 'UI/App/Components/Auth/HasRole';
import ExcludeRole from 'UI/App/Components/Auth/ExcludeRole';
import MaterialUnitMutator from "UI/App/Components/Material/MaterialUnitMutator";
import User from "App/Strapi/User";

export default function OrderPdf() {
    const { authAxios } = useContext(FetchContext);
    const params = useParams();

    const [company, setCompany] = useState(null);
    const [machinesPerPage] = useState(3);
    const [order, setOrder] = useState(null);
    const [contactPerson, setContactPerson] = useState(null);
    const [calculation, setCalculation] = useState(null);
    const [orderPDF, setOrderPDF] = useState(null);
    // Keeps track of whether the order data is out of sync with the database
    const [isOutOfSync, setIsOutOfSync] = useState(false);

    const [orderBackground, setOrderBackground] = useState(null);

    const [formattedDeliveryDate, setFormattedDeliveryDate] = useState('');

    const [HJMGOrderData, setHJMGOrderData] = useState(null);

    const [fatalError, setFatalError] = useState(null);
    const [downloading, setDownloading] = useState({
        downloading: false,
        totalPages: 0,
        currentPage: 0,
    });

    const [orderData, setOrderData] = useState({
        number: '',
        deliveryDate: '',
        quantity: 0,
        customer: '',
        contactPerson: '',
        customerReference: '',
        checklist: [],
        checklistRemarks: '',
        materials: [],
        materialsRemarks: '',
        machines: [],
        transport: ''
    });

    const orderRef = useRef(orderData)
    const [DivedMachines, setDivedMachines] = useState([]);

    useEffect(() => {
        // update ref
        orderRef.current = orderData;
    }, [orderData?.checklistRemarks, orderData?.machines, orderData?.materialsRemarks, orderData?.transport])

    useEffect(() => {
        if (order && company && contactPerson && calculation) {
            if (calculation.owner === 'pretore') {
                setOrderBackground(PretoreBackground);
                // If missing values
                if (order && company && contactPerson && calculation && !calculation?.data?.templateData?.data) {
                    setFatalError(`Er is geen template data gevonden voor deze order.`);
                    return;
                }

                // Load order data for Pretore
                if (order && company && contactPerson && calculation) gatherDataForOrderPDF();

            } else {
                // Set order background
                setOrderBackground(HJMGbackground);

                // Gather data for HJMG order
                gatherDataForHJMGOrderPDF();
            }
        }


    }, [order, company, contactPerson, calculation, DivedMachines]);

    // Effect to handle date changes
    useEffect(() => {

        if(orderData?.deliveryDate){
            setFormattedDeliveryDate(new Date(orderData?.deliveryDate).toLocaleDateString('nl-NL').toString())
        } else {
            (setFormattedDeliveryDate('__-__-____'));
        }

    },[orderData?.deliveryDate])

    useEffect(() => {
        const query = stringify({
            populate: {
                company: true,
                contactPerson: true,
                calculation: true,
                quote: true,
                stickers: {
                    sort: ['id:desc']
                },
                quoteConfirmation: {
                    populate: {
                        quote: {
                            populate: {
                                contactPerson: true,
                                company: {
                                    populate: {
                                        telephone: true,
                                    }
                                },
                                copies: {
                                    fields: ['id', 'number'],
                                },
                            }
                        },
                        calculation: {
                            populate: {
                                stockQuotes: {
                                    fields: ['id'],
                                    populate: {
                                        stockPurchaseSlip: {
                                            fields: ['id']
                                        },
                                    }
                                },
                                copies: {
                                    fields: ['id', 'number'],
                                },
                            }
                        }
                    }
                },
                packingSlips: {
                    fields: ['id', 'number'],
                    sort: ['id:desc']
                },
                jobTickets: {
                    fields: ['id', 'name', 'status'],
                }
            }
        });

        authAxios
            .get(`/orders/${params.orderId}?${query}`)
            .then(({ data }) => {

                const calculation = data.data.calculation;
                const quote = data.data.quote;

                const company = data.data?.company ?? quote?.company;
                const contactPerson = data.data?.contactPerson ?? quote?.contactPerson;
                setOrderPDF(data.data.pdfBase64);

                setOrder(data.data);
                setCompany(company);
                setContactPerson(contactPerson);

                // change quantity in calculation checklist to use amounts from calculation data
                const checklistQuantity = (calculation?.data?.checklist ?? []).find(({ name }) =>
                    ['aantal', 'quantity'].includes(name?.toLowerCase())
                );

                if (checklistQuantity !== undefined) {
                    if (Array.isArray(calculation?.data?.amounts)) {
                        // get the index of current amount
                        let indexOfQuantity = calculation.data.amounts.indexOf(Number(data?.data?.chosenAmount))
                        // get the value of misses that belongs to our amount
                        let misses = Number(calculation?.data?.misses[indexOfQuantity])

                        // display amount WITH misses
                        checklistQuantity.value = `${data?.data?.chosenAmount} ${misses > 0 && `(+${misses} inschiet)`}`
                    }
                } else if (Array.isArray(calculation?.data?.amounts)) {
                    // get the index of current amount
                    let indexOfQuantity = calculation.data.amounts.indexOf(Number(data?.data?.chosenAmount))
                    // get the value of misses that belongs to our amount
                    let misses = Number(calculation?.data?.misses[indexOfQuantity])

                    // if the quantity is not found in the checklist, add it to the checklist
                    calculation.data.checklist.push({
                        name: 'quantity',
                        dutchName: 'Aantal',
                        englishName: 'Quantity',
                        value: `${data?.data?.chosenAmount} ${misses > 0 && `(+${misses})`}`
                    });
                }

                setCalculation(calculation);

                setFatalError(false);
            })
            .catch((exception) => {
                setFatalError(
                    `Er is iets fout gegaan ${exception?.response?.status ? `(${exception.response?.status})` : ''
                    }`
                );
                console.error(exception);
            });
    }, [params.orderId]);

    function getDivedMachines(_machines) {
        let dividedArray = [];

        // return nulln if we have no data to work with
        if (_machines?.length === undefined) return null;

        // clone object without a reff to the orignal
        let machines = JSON.parse(JSON.stringify(_machines));

        // didived total machines in to segements
        // this way we can render x amount of machines per page
        while (machines.length) {

            dividedArray.push(machines.splice(0, machinesPerPage))
        }
        return dividedArray;
    }

    function gatherDataForHJMGOrderPDF() {

        let HJMGOrderData = [];

        // Loop calculation data
        Object.keys(calculation.data.calculationData).forEach(function (name, index) {
            // Get order data
            const orderData = calculation.data.calculationData[name];

            // Only insert data if part has entires
            if (orderData.entries !== undefined) {

                // Create object with calculation part name
                HJMGOrderData[name] = orderData;
            }
        });

        // Set data in usestate
        setHJMGOrderData(HJMGOrderData);
    }

    async function gatherDataForOrderPDF() {
        const orderMaterials = await getMaterialsForOrder();
        const maxMaterialLength =
            Math.max(
                ...orderMaterials.map((material) =>
                    getVisibleTextWidthInMM(material.name, 'bold 11pt Helvetica')
                )
            ) || 50;

        const machines = getMachinesForOrder(orderMaterials);

        // machines = setPdfData(machines);
        setOrderData((prev) => ({
            number: order.number,
            quantity: order.chosenAmount,
            customer: company.name,
            machines: machines,
            divedMachines: getDivedMachines(machines),
            undivedMachines: machines,
            transport: (order?.orderPdfData?.transport ?? ''),
            materials: orderMaterials,
            checklist: calculation.data?.checklist ?? [],
            deliveryDate: order.deliveryDate ?? '',
            contactPerson: formatName(contactPerson.firstName, contactPerson.prefixToSurname, contactPerson.surname),
            checklistRemarks: (order?.orderPdfData?.checklistRemarks ?? ''),
            materialsRemarks: (order?.orderPdfData?.materialsRemarks ?? ''),
            customerReference: order.customerReference,
            maxMaterialLength: isFinite(maxMaterialLength) ? maxMaterialLength : 50
        }));
    }

    async function getMaterialsForOrder() {
        const materialArray = [];

        const materialReturnWidgetTabs = Object.entries(calculation.data.templateData.data)
            // Get only the tabs
            .filter(([key, value]) => /\d+-\d+/.test(key))
            // Get only the material return widgets
            .map(([key, value]) => {
                return [
                    key,
                    value.widgets.filter(
                        (widget) =>
                            widget.data.type === 'ReturnWidget' &&
                            widget.data.title.startsWith('Materiaal.')
                    )
                ];
            })
            // Filter out tabs without material return widgets
            .filter(([key, widgets]) => widgets.length > 0);

        const calculationTabsValues = Object.fromEntries(
            Object.entries(calculation.data.values)
                // Get only the calculation tabs
                .filter(([key, value]) => /\d+-\d+/.test(key))
                // Get the calculation result for the chosen amount
                .map(([key, value]) => [key, value?.[order?.chosenAmount]])
        );

        /**
         * Object containing all material widgets per material
         * @type {{[tabIndex: string]: {[materialWidgetId: string]: {}}}}
         */
        let materialWidgetsPerMaterial = {};

        // Loop through the tabs
        for (const [tabIndex, materialReturnWidgets] of materialReturnWidgetTabs) {
            // Skip tabs if it doesn't have a calculation result
            // In this case the tab was optional and not enabled in the calculation
            if (typeof calculationTabsValues[tabIndex] === 'undefined') continue;

            // Create an empty object for this tab
            materialWidgetsPerMaterial[tabIndex] = {};

            // Loop through the return widgets
            for (const materialReturnWidget of materialReturnWidgets) {
                // Split the return widget title
                // The title is in the format: Materiaal.[materialWidgetId].[part]
                // Example: Materiaal.1-widget-1.aantal
                // The first part (group) is in this case always 'Materiaal' since we're only dealing with material returns
                // The second part (materialWidgetId) is either the string value of the material we're dealing with or the id of the material widget
                // The third part (part) is the type of return value we're dealing with
                // Example: aantal, prijs, totaalprijs, verkoopprijs, etc.
                const [group, materialWidgetId, part] = materialReturnWidget.data.title.split('.');

                // Skip the usercharge widget since it's not necessary for the operators to see
                if (['usercharge', 'inkt'].includes(materialWidgetId?.toLowerCase())) continue;
                // Skip the totaalprijs and verkoopprijs since it's not necessary for the operators to see
                if (['totaalprijs', 'verkoopprijs'].includes(part?.toLowerCase())) continue;

                // Get the material widget value
                const returnWidgetValue = calculationTabsValues[tabIndex]?.find(
                    (widget) => widget.id === materialReturnWidget.id
                );

                // The name of the material
                let materialName;
                let material = null;

                // If the materialWidgetId starts with 'widget', it points to another material widget
                // We should get the value of that widget and get the internalSKU from that for the material name
                // Otherwise the materialWidgetId is the name of the material
                // Normally, if the materialWidgetId is the name of the material, we're dealing with materials that are not in the database
                // These materials are always used in the machine, and are always the same type of material E.G. usercharge or ink for printers
                // In the case of usercharge, it's not even a material, but a cost that is added on by the manufacturer
                if (materialWidgetId.includes('widget')) {
                    const materialWidgetValue = JSON.parse(
                        calculationTabsValues[tabIndex]?.find(
                            (widget) => widget.id === materialWidgetId
                        )?.value ?? '{}'
                    );
                    material = materialWidgetValue.data?.value?.result;
                    materialName = material?.calculationDescription;
                } else {
                    materialName = materialWidgetId;
                }


                // Save the widget value amount and unit in the materialWidgetsPerMaterial object
                // Filter out undefined material names. Because if a material is not defined, how can we know what material to get?
                if (materialName !== undefined) {
                    materialWidgetsPerMaterial[tabIndex][materialName] = {
                        quantity: returnWidgetValue.value,
                        unit: returnWidgetValue.unit,
                        category: material?.category ?? materialName,
                        material: material ?? {}
                    };
                }
            }
        }

        // Now we have all the material widgets per material
        // We can now loop through the materials and create the content
        for (const [machineTabId, materials] of Object.entries(materialWidgetsPerMaterial)) {
            // First we need to get the machine name
            // The machine name is the title of the tab
            const machineName = order.calculation.data.templateData.data[machineTabId].name;

            // Add the material names to the content
            for (const [materialName, material] of Object.entries(materials).sort(
                (a, b) => a[0].length - b[0].length
            )) {
                let first = material?.material?.quotationNameNL
                let fallback = material?.material?.quotationNameEN
                let lastly = material?.material?.internalSKU

                let name = first !== undefined ? first : (fallback !== undefined ? fallback : lastly)

                // Get quantity and unit for humans to understand
                const correctQuantity = Math.round(await (new MaterialUnitMutator()).quantityMerchant(material.quantity, material?.material, true))
                const correctUnit = await (new MaterialUnitMutator()).obtainUnit(material?.material)

                materialArray.push({
                    name: name,
                    unit: material.unit,
                    machine: machineName,
                    machineTabId: machineTabId,
                    material: material.material,
                    category: material.category,
                    quantity: material.quantity,
                    // If unit is kilogram also render sheets/meters
                    formattedValue: `${formatNumberValue(material.quantity, material.unit)} ${material.unit.toLowerCase() === 'kilogram' ? `(${correctQuantity} ${correctUnit})` : ''}`
                });
            }
        }

        return materialArray;
    }

    function getMachinesForOrder(materials) {
        let machinesArray = [];

        const optionalMachinesList = calculation.data.optionalMachines;

        // Get the machine schedule from the calculation data
        const machineScheduleStorage = calculation?.data?.totalResultJson?.machineScheduleStorage;
        const amountIndex = calculation?.data?.amounts?.findIndex(
            (amount) => order.chosenAmount === amount
        );

        if (machineScheduleStorage === undefined || amountIndex === -1) {
            console.warn('Planning unavailable', {
                machineScheduleStorage,
                amountIndex,
                chosenAmount: order.chosenAmount
            });
            toast.warn('Planning niet beschikbaar');
            return [];
        }

        // Create a string for keeping track of the previous machine name
        let prevMachine = '';

        const missesKey = Object.keys(calculation.data.amounts).find(
            (key) => calculation.data.amounts[key] === order.chosenAmount
        );

        // Loop through the machine schedule
        for (const machinePlanningEntry of machineScheduleStorage) {
            // Get the machine name and planning value from the machine schedule entry
            const machineEntryNames = machinePlanningEntry[0]?.match(
                /(?<machineName>.*) ?\((?<planningEntry>.*)\)$/i
            );

            // Make sure both of the values exist
            if (machineEntryNames === null || machineEntryNames === undefined) continue;

            // Get the values from the regex match
            const machineName = machineEntryNames?.groups?.machineName.trim();
            const planningEntry = machineEntryNames?.groups?.planningEntry;

            // Skip the "in te plannen uren" entry. This value is purely for planning purposes and not for the operators
            if (planningEntry?.toLowerCase() !== 'in te plannen uren') continue;

            // Get the planning time from the machine planning entry
            const planningTime = machinePlanningEntry[amountIndex + 1].match(/\((?<time>.*?)\)/i)?.groups?.time;

            // Create an empty object to store machine settings in
            let machine = {};
            let machineKey = null;
            let nUp = null;
            let machineData = {
                name: null,
                key: null,
                effort: null,
                planning: null,
                misses: null,
                materials: [],
                justification: ''
            };

            // If the previous machine name is not the same as the current machine name, we need to get the machine from the calculation data
            if (prevMachine !== machineName) {
                const foundMachine = Object.entries(calculation.data.templateData.data)?.find(
                    ([key, machine]) => machine.name?.trim() === machineName?.trim()
                );
                machineKey = foundMachine?.[0];
                machine = foundMachine?.[1];
            }

            // Warn the user if the machine is not found in the calculation data
            if (!machine) {
                toast.error(`Machine ${machineName} not found in template data`);
                continue;
            }

            if (machine.constants !== undefined) {
                machineData.name = machine.name;
                machineData.key = machineKey;
                machineData.effort =
                    machine.constants.find((constant) => constant.id.includes('laborEffort'))
                        ?.value ?? 0;
                machineData.planning = getTimeFromString(planningTime);
                machineData.misses = calculation.data.misses[missesKey];
            }

            // Add nUp to color stream data
            if (machine?.name?.toLowerCase().includes('colorstream')) {
                for (const widget of machine.widgets) {
                    if (widget?.data?.title?.toLowerCase() === 'n-up') {
                        nUp = widget.data.value.result;
                    }
                }
            }

            machineData.nUp = nUp;

            machinesArray.push(machineData);
        }

        // Loop through the machines
        for (const [machineKey, machine] of Object.entries(calculation.data.templateData.data)) {
            // Skip non-widget flow tabs and tabs without constants, since they aren't machine flows
            if (!/\d+-\d+/.test(machineKey) || (machine.constants?.length ?? 0) === 0) continue;

            // Skip non-enabled optional machines
            if (machine.optional && optionalMachinesList?.[machineKey] === 'false') continue;

            // Get the materials for this machine
            const machineMaterials = materials.filter(
                (material) => material.machineTabId === machineKey
            );

            // Get the machine index from the array
            const machineIndex = machinesArray.findIndex((machine) => machine.key === machineKey);
            if (machineIndex !== -1) {
                machinesArray[machineIndex].materials = machineMaterials;
            }
        }

        for (const manualLabor of calculation.data.manualLabor) {
            machinesArray.push({
                name: manualLabor.description,
                effort: '100',
                justification: '',
                materials: [],
                misses: calculation.data.misses[missesKey],
                planning: getTimeFromString(formatMinutes(manualLabor.quantities[order.chosenAmount].minutes)),
                nUp: null
            })
        }

        setPdfData(machinesArray)
        return machinesArray;
    }

    function setPdfData(machines) {

        const PdfData = order.orderPdfData;
        const pdfMachines = PdfData?.machines;
        if (pdfMachines === undefined) {
            return;
        }
        // set machine justification
        for (const machine of machines) {
            machine.justification = pdfMachines.filter(el => el.name === machine.name && el.key === machine.key)[0]?.justification ?? '';
        }
    }

    function updateMultiDimensionalObjectValue(object, keys, value) {
        const [key, ...rest] = keys;

        if (rest.length === 0) {
            object[key] = value;
        } else {
            if (typeof object[key] === 'undefined') {
                object[key] = {};
            }

            updateMultiDimensionalObjectValue(object[key], rest, value);
        }
    }

    function updateOrderDataValue(key, value) {

        // If delivery date set date for PDF rendering
        // if(key === 'deliveryDate'){
            // setFormattedDeliveryDate(value)
        // }

        setIsOutOfSync(true);

        if (Array.isArray(key)) {
            setOrderData((prev) => {
                const newOrderData = { ...prev };
                updateMultiDimensionalObjectValue(newOrderData, key, value);
                return newOrderData;
            });
            return;
        }

        setOrderData((prev) => ({
            ...prev,
            [key]: value
        }));

    }

    async function updatePdfData() {
        const pdf = await printDocument(false).catch((err) => {
            setDownloading(prev => ({
                ...prev,
                downloading: false,
            }));
            console.error(err)
            return Promise.reject("Invalid Date");
        })

        setOrderPDF(pdf);
        setIsOutOfSync(false);

        if (User.hasRole(['productie medewerker'])) {
            return {
                data: {
                    pdfBase64: pdf
                }
            };
        }

        return authAxios
            .put(`/orders/${params.orderId}`, {
                data: {
                    pdfBase64: pdf,
                    orderPdfData: orderData,
                    deliveryDate: orderData.deliveryDate === "" ? undefined : orderData.deliveryDate
                }
            })
            .then(({ data }) => {
                toast.success('Order aangepast.');
                return data;
            })
            .catch((exception) => {
                toast.error('Er is iets mis gegaan bij het bewerken van de order.');
                console.error(exception);
                return Promise.reject(exception);
            })
    }

    function getTimeFromString(timestring) {
        const [hours, minutes] = timestring.split(':');
        const timeInMinutes = parseInt(hours) * 60 + parseInt(minutes);

        if (timeInMinutes <= 60) {
            // Return minutes rounded to 5
            return `${Math.ceil(timeInMinutes / 5) * 5} minuten`;
        } else {
            return `${hours}:${Math.ceil(minutes / 5) * 5} uur`;
        }
    }

    async function printDocument(openInWindow = false) {
        // get the date input, for formatting
        let dateInput = document.querySelector('input[name=deliveryDate]')
        let datePreview = document.getElementById('datePreview');

        // let date = dateInput.value ? new Date(dateInput.value) : new Date(order.deliveryDate);

        // if (date.toString() === 'Invalid Date') {
        //     toast.error('Vul eerst een geldige leverdatum in!');
        //     return Promise.reject("Invalid Date");
        // }

        //remove border while we are converting it to pdf
        for (let element of document.getElementsByTagName('input')) {
            element.style.border = "none"
        }
        // Hide misses inputs and render amount in parent span
        const missesInputs = document.getElementsByClassName('missesInputSpan');
        const orignalInputs = [];

        for (const missesInput of missesInputs) {
            // clone the input so we can re-create it later
            let clonendInput = missesInput.cloneNode(true)
            // get the input inside of the span
            const input = missesInput.children[0];

            // check for a non set value
            const inputValue = (input?.value === undefined || input?.value === "") ? '0' : input?.value
            // store the input and value
            orignalInputs.push({ html: clonendInput, value: inputValue });
            // set the HTML to the value alone WITHOUT the input
            missesInput.innerHTML = inputValue;

        }

        if (dateInput && datePreview) {
            dateInput.style.display = 'none';
            datePreview.style.display = 'block';
        }

        //get all the html elements
        const input = document.getElementsByClassName("pdf-mockup__page");//document.getElementById('pdf-mockup');
        setShadowStyle(input, "none")

        setDownloading({
            downloading: true,
            totalPages: input.length,
            currentPage: 1
        });

        // create the pdf
        const pdf = new jsPDF("p", "mm", "a4");
        pdf.setFillColor(245);
        let currentpage = 0;
        for (const page of input) {
            currentpage++;

            setDownloading({
                downloading: true,
                totalPages: input.length,
                currentPage: currentpage
            });

            const textAreas = page.querySelectorAll('textarea');

            // Replace the textAreas with divs
            for (const textarea of textAreas) {
                const fontWeight = textarea.style.fontWeight;

                // Hide the textarea
                textarea.style.display = 'none';
                // Create a div with the same content as the textarea
                const div = document.createElement('div');
                div.className = 'pdf-mock-textarea';
                div.innerHTML = textarea.value;
                div.setAttribute('style', `white-space: break-spaces; font-weight: ${fontWeight};`);
                // Place the div after the textarea
                textarea.parentNode.insertBefore(div, textarea.nextSibling);
            }

            await html2canvas(page, {
                scale: 2
            }).then((canvas) => {
                const imgData = canvas.toDataURL('image/jpeg', 1.0);
                pdf.addImage(imgData, 'JPEG', 0, 0, pdf.internal.pageSize.getWidth(), pdf.internal.pageSize.getHeight());
                if (currentpage < input.length) {
                    pdf.addPage();
                }

                const textAreas = page.querySelectorAll('textarea');

                for (const textarea of textAreas) {
                    // Show the textarea
                    textarea.style.display = 'inline-block';
                }

                // Get the mock divs
                const divs = page.querySelectorAll('.pdf-mock-textarea');
                divs.forEach(div => div.remove());
            });
        }

        const base64 = pdf.output('datauristring', { filename: `${order.number}.pdf` });

        // store the pdf
        // pdf.save(`${order.number}.pdf`);

        if (openInWindow) {
            pdf.output('dataurlnewwindow', { filename: `${order.number}.pdf` });     //opens the data uri in new window
        }

        if (dateInput && datePreview) {
            dateInput.style.display = 'block';
            datePreview.style.display = 'none';
        }

        // 'reset' date input
        // dateInput.type = "date";

        // 'reset' border for inputs
        for (let element of document.getElementsByTagName('input')) {
            element.style.borderBottom = "dashed lightgrey 1px";
        }

        let index = 0
        for (const missesInput of missesInputs) {
            // get the input from the orignal input
            let input = orignalInputs[index]?.html?.children[0];

            if (!input) {
                continue;
            }

            // set the ID so we can set the value
            input.id = `pdf_Input_${index}`;

            // get the outerHTML of the input so we can place it
            missesInput.innerHTML = input.outerHTML;

            // set the value of the 'created' input
            document.getElementById(`pdf_Input_${index}`).value = orignalInputs[index]?.value
            index++
        }

        setDownloading({
            downloading: false,
            totalPages: input.length,
            currentPage: currentpage
        });

        return base64;
    }

    function setShadowStyle(els, shadowStyle) {

        for (let el of els) {
            el.style.boxShadow = shadowStyle;
        }

    }

    async function openPDFWindow() {
        if (isOutOfSync) {
            const res = await updatePdfData();

            const blobUrl = await base64ToBlobURL(res.data.pdfBase64);
            // Open new window with base64 data
            window.open(blobUrl, '_blank');

            return;
        }


        if (orderPDF) {
            const res = await updatePdfData();

            const blobUrl = await base64ToBlobURL(res.data.pdfBase64);

            // Open new window with base64 data
            window.open(blobUrl, '_blank');
        } else {
            setOrderPDF(printDocument(true));
        }
    }

    if (fatalError) {
        return (
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%'
            }}>
                <span style={{
                    color: 'red',
                }}>{fatalError}</span>
            </div>
        )
    }

    function splitChecklistIntoPages() {

        const canonicalChecklistItems = orderData
            ?.checklist
            ?.filter((item) => item.value)
            ?.map((item) => ({
                name: (calculation?.language?.toLowerCase() === 'nl' ? item.dutchName : item.englishName) ?? item.name,
                value: item.name === 'customerReference' ? orderData.customerReference : item.value
            }));

        const headerRows = 9;
        const maxRowsPerPage = 70;
        const checklistRemarksRows = 6;
        const splitFromIndexes = [];

        let rowNum = headerRows;

        // Loop through the checklist items
        for (const [index, item] of canonicalChecklistItems.entries()) {

            // Skip items without a value
            if (!item.value) continue;

            // Calculate the number of rows this item will take up
            const requiredRows = 1 + item.value.trimEnd().split('\n').length + 1;

            // If the number of rows exceeds the maximum rows per page, we need to create a new page
            if (rowNum + requiredRows > maxRowsPerPage) {
                splitFromIndexes.push(index);
                rowNum = headerRows;
            } else {
                // If the number of rows does not exceed the maximum rows per page, we can add the item to the current page
                rowNum += requiredRows;
            }
        }

        const splitReturnData = [];

        // Add the last page if the footer does not fit on the last page
        if (rowNum + checklistRemarksRows > maxRowsPerPage) {
            splitReturnData.push(canonicalChecklistItems.length);
        }

        let lastIndex = undefined;
        for (const splitIndex of splitFromIndexes.reverse()) {
            splitReturnData.push(canonicalChecklistItems.slice(splitIndex, lastIndex));
            lastIndex = splitIndex;
        }

        splitReturnData.push(canonicalChecklistItems.slice(0, lastIndex));

        return splitReturnData.reverse();
    }

    const splitChecklistData = splitChecklistIntoPages();

    return (
        <>
            <PageHeader title={`Order formulier`}>
                <ExcludeRole roles={['productie medewerker']}>
                    <Button className={'btn btn--black btn--round'} data-title={'Opslaan'} onClick={updatePdfData}><Icon name={'save'} /></Button>
                </ExcludeRole>
                <Button className={'btn btn--black btn--round'} data-title={'PDF printen'} onClick={openPDFWindow} disabled={downloading.downloading}><Icon name={'print'} /></Button>
            </PageHeader>
            <Grid columns='1'>
                <SpinnerOverlay visible={downloading.downloading} message={`Order formulier genereren (pagina ${downloading.currentPage}/${downloading.totalPages})`}>
                    <div className='pdf-mockup__wrapper' style={{ fontSize: '13pt' }} id='pdf-mockup' data-pdf-for='order' data-template='1'>

                        {splitChecklistData.map((page, index) => (
                            <div
                                className='pdf-mockup__page'
                                style={{
                                    backgroundImage: `url('${orderBackground}')`,
                                    padding: '15mm 21mm'
                                }}
                            >
                                <IF condition={index === 0}>
                                    <div className='pdf-mockup__row space-between'>
                                        <h3>{orderData.number}</h3>
                                        <Grid columns={2} customColTemplate="36mm 31mm" gap='10px' style={{ width: 'unset' }} alignItems="center" >
                                            <h3>Leverdatum: </h3>
                                            <ExcludeRole roles={['productie medewerker']}>
                                                <input
                                                    name={'deliveryDate'}
                                                    min={new Date().toLocaleDateString('fr-ca')}
                                                    className='strong'
                                                    id='deliveryDateInput'
                                                    value={orderData?.deliveryDate ?? ''}
                                                    onChange={({ target }) => {
                                                        updateOrderDataValue('deliveryDate', target.value);
                                                        // updateOrder(target.value);
                                                    }}
                                                    type='date'
                                                    style={{
                                                        fontSize: '13pt',
                                                    }}
                                                />
                                                <h3 id='datePreview' style={{ display:'none' }}>
                                                    {formattedDeliveryDate ?? ''}
                                                </h3>
                                            </ExcludeRole>
                                            <HasRole roles={['productie medewerker']}>
                                                <h3>{formattedDeliveryDate ?? ''}</h3>
                                            </HasRole>
                                        </Grid>
                                    </div>
                                </IF>

                                <IF condition={index > 0}>
                                    <div className='pdf-mockup__row space-between'>
                                        <h3>{orderData.number}</h3>
                                        <h3>Leverdatum: {formattedDeliveryDate}</h3>
                                    </div>
                                    <div className='pdf-mockup__row space-between'>
                                        <h3>Checklist ({index + 1})</h3>
                                    </div>
                                </IF>

                                <hr/>

                                <div className='pdf-mockup__row gap'>
                                    <strong>Klant:</strong> {orderData.customer}
                                </div>
                                <div className='pdf-mockup__row gap'>
                                    <strong>Contactpersoon:</strong> {orderData.contactPerson}
                                </div>
                                <div className='pdf-mockup__row gap'>
                                    <strong>Klantreferentie:</strong> {orderData.customerReference}
                                </div>

                                <hr />

                                <div className='pdf-mockup__row gap'>
                                    <Grid columns='3' customColTemplate='45mm 2mm 1fr' gap='5px' style={{ whiteSpace: 'break-spaces', rowGap: '15px' }}>
                                        {order?.FSC ? <Fragment key={'FSC'}>
                                            <strong>FSC order</strong>
                                            <span>:</span>
                                            <span><b>JA</b></span>
                                        </Fragment>
                                            : <></>}
                                        {page.map((item, index) => (
                                            <Fragment key={index}>
                                                <strong>{item.name}</strong>
                                                <span>:</span>
                                                <span>{item.name === 'Aantal' ? <strong>{item.value}</strong> : item.value}</span>
                                            </Fragment>
                                        ))}
                                        {order?.information && (
                                            <Fragment key={"information"}>
                                                <strong>Informatie</strong>
                                                <span>:</span>
                                                {order?.information}
                                            </Fragment>
                                        )}
                                        {order?.extraWork && (
                                            <Fragment key={"extraWork"}>
                                                <strong>Meerwerk</strong>
                                                <span>:</span>
                                                {order?.extraWork}
                                            </Fragment>
                                        )}
                                    </Grid>
                                </div>

                                <IF condition={index === splitChecklistData.length - 1}>
                                    <br/>
                                    <div className='pdf-mockup__row gap'>
                                        <Grid columns='1' gap='10px'>
                                            <strong>Opmerkingen:</strong>
                                            <ExcludeRole roles={['productie medewerker']}>
                                                <textarea
                                                    style={
                                                        {
                                                            backgroundImage: "none",
                                                            backgroundColor: "white",
                                                            borderBottom: "1px dashed lightgrey",
                                                            fontWeight: 'bold',
                                                        }
                                                    }
                                                    value={orderData.checklistRemarks}
                                                    onChange={(e) => {

                                                        updateOrderDataValue(
                                                            'checklistRemarks',
                                                            e.target.value
                                                        );
                                                        e.target.style.height = '0px';
                                                        e.target.style.height = e.target.scrollHeight + 'px'
                                                    }}
                                                    onFocus={e => {
                                                        e.target.style.height = '0px';
                                                        e.target.style.height = e.target.scrollHeight + 'px'
                                                    }}
                                                ></textarea>
                                            </ExcludeRole>
                                            <HasRole roles={['productie medewerker']}>
                                                <strong style={{ whiteSpace: 'break-spaces' }}>
                                                    {orderData.checklistRemarks}
                                                </strong>
                                            </HasRole>
                                        </Grid>
                                    </div>

                                    <br/>

                                    <strong>Machines:</strong>
                                    {orderData.undivedMachines?.filter((machine) => machine.name).map((machine, index) => (
                                        <div className='pdf-mockup__row gap'>
                                            <span>{machine.name}</span>
                                        </div>
                                    ))}
                                </IF>

                            </div>
                        ))}

                        {calculation !== null ? calculation?.owner === 'hjmg' ? <>
                            {HJMGOrderData !== null ? <>
                                <div
                                    className='pdf-mockup__page'
                                    style={{
                                        backgroundImage: `url('${orderBackground}')`,
                                        padding: '15mm 21mm'
                                    }}
                                >
                                    {Object.keys(HJMGOrderData).map((group, key) => {

                                        return <>
                                            <div className='pdf-mockup__row space-between'>
                                                <h3>{group}</h3>{' '}
                                                <small style={{ color: 'gray' }}>{order?.number}</small>
                                            </div>
                                            <hr />
                                            {HJMGOrderData[group].entries.map((item, index) => {
                                                return <div className='gap'>
                                                    <strong>{item.description}:</strong> {item.work.label} {item.time !== 0 ? `${formatTime(parseTimeToNumber(item.time))} ${planningSuffix(item?.billingPeriod)}` : <></>}
                                                </div>
                                            })}
                                            <hr />
                                        </>
                                    })}
                                </div>
                            </> : <></>}
                        </> : <></> : <></>}

                        {calculation !== null ? calculation?.owner === 'pretore' && orderData.materials !== undefined ? <>
                            <div
                                className='pdf-mockup__page'
                                style={{
                                    backgroundImage: `url('${orderBackground}')`,
                                    padding: '15mm 21mm'
                                }}
                            >
                                <div className='pdf-mockup__row space-between'>
                                    <h3>{orderData.number}</h3>
                                    <h3>Leverdatum: {formattedDeliveryDate}</h3>
                                </div>
                                <div className='pdf-mockup__row space-between'>
                                    <h3>Materialen</h3>
                                </div>

                                <hr/>

                                <div className='pdf-mockup__row gap'>
                                    <strong>Klant:</strong> {orderData.customer}
                                </div>
                                <div className='pdf-mockup__row gap'>
                                    <strong>Contactpersoon:</strong> {orderData.contactPerson}
                                </div>
                                <div className='pdf-mockup__row gap'>
                                    <strong>Klantreferentie:</strong> {orderData.customerReference}
                                </div>

                                <hr/>


                                <div className='pdf-mockup__row gap'>
                                    <Grid
                                        columns='2'
                                        customColTemplate={`${orderData.maxMaterialLength + 21 + 'mm'
                                        } 1fr`}
                                        gap='5px'
                                        style={{whiteSpace: 'break-spaces'}}
                                    >
                                        {orderData?.materials?.map((item, index) => (
                                            <Fragment key={index}>
                                                <strong>{item.name}:</strong>
                                                {item.formattedValue}
                                            </Fragment>
                                        ))}
                                        {orderData?.materials?.length === 0 ? (
                                            <span style={{fontStyle: 'italic', color: 'gray'}}>
                                                Geen materialen
                                            </span>
                                        ) : (
                                            ''
                                        )}
                                    </Grid>
                                </div>

                                <br/>
                                <div className='pdf-mockup__row gap'>
                                    <Grid columns='1' gap='5px'>
                                        <strong>Opmerkingen:</strong>
                                        <textarea
                                            style={
                                                {
                                                    backgroundImage: "none",
                                                    backgroundColor: "white",
                                                    borderBottom: "1px dashed lightgrey",
                                                    fontWeight: 'bold',
                                                }
                                            }
                                            value={orderData.materialsRemarks}
                                            onChange={(e) => {
                                                updateOrderDataValue(
                                                    'materialsRemarks',
                                                    e.target.value
                                                );
                                                e.target.style.height = '0px';
                                                e.target.style.height = e.target.scrollHeight + 'px'
                                            }}
                                            onFocus={e => {
                                                e.target.style.height = '0px';
                                                e.target.style.height = e.target.scrollHeight + 'px'
                                            }}
                                        ></textarea>
                                    </Grid>
                                </div>
                            </div>
                        </> : <></> : <></>}

                        {orderData.divedMachines?.map((machines, page) => (
                            <div
                                className='pdf-mockup__page'
                                style={{
                                    backgroundImage: `url('${orderBackground}')`,
                                    padding: '15mm 21mm'
                                }}
                            >
                                {page === 0 ? <>
                                    <div className='pdf-mockup__row space-between'>
                                        <h3>{orderData.number}</h3>
                                        <h3>Leverdatum: {formattedDeliveryDate}</h3>
                                    </div>
                                    <div className='pdf-mockup__row space-between'>
                                        <h3>Bewerkingen / Machines</h3>
                                    </div>

                                    <hr/>

                                    <div className='pdf-mockup__row gap'>
                                        <strong>Klant:</strong> {orderData.customer}
                                    </div>
                                    <div className='pdf-mockup__row gap'>
                                        <strong>Contactpersoon:</strong> {orderData.contactPerson}
                                    </div>
                                    <div className='pdf-mockup__row gap'>
                                        <strong>Klantreferentie:</strong> {orderData.customerReference}
                                    </div>

                                    <hr/>
                                </> : <>
                                    <div className='pdf-mockup__row space-between'>
                                        <h3>{orderData.number}</h3>
                                        <h3>Leverdatum: {formattedDeliveryDate}</h3>
                                    </div>

                                    <hr/>
                                </>}

                                <div className='pdf-mockup__row gap'>
                                    <Grid columns='1' style={{whiteSpace: 'break-spaces'}} gap='5px'>
                                        {machines
                                            ?.filter((machine) => machine.name)
                                            ?.map((machine, index) => (
                                                <Fragment key={index}>
                                                    <h3>{machine.name}:</h3>
                                                    <hr />
                                                    <IF condition={machine.materials.length > 0}>
                                                        <strong>Materiaal:</strong>
                                                        <Grid
                                                            columns='2'
                                                            gap='5px'
                                                            customColTemplate={`${orderData.maxMaterialLength + 21 + 'mm'
                                                                } 1fr`}
                                                        >
                                                            {machine.materials.map(
                                                                (material, index) => (
                                                                    <Fragment key={index}>
                                                                        <span>{material.name}:</span>
                                                                        <span>
                                                                            {material.formattedValue}
                                                                        </span>
                                                                    </Fragment>
                                                                )
                                                            )}
                                                        </Grid>
                                                    </IF>

                                                    <Grid
                                                        columns='2'
                                                        gap='5px'
                                                        customColTemplate={`${orderData.maxMaterialLength + 21 + 'mm'
                                                            } 1fr`}
                                                    >
                                                        <strong>Planning:</strong>
                                                        <span>{machine.planning}</span>

                                                        <strong>Inspanning:</strong>
                                                        <span>{machine.effort}%</span>

                                                        <strong>Aantal:</strong>
                                                        <span>{order.chosenAmount}</span>

                                                        {machine.nUp !== null ? <>
                                                            <strong>n-Up:</strong>
                                                            <span>{machine.nUp}-Up</span>
                                                        </> : <></>}

                                                        <strong>Inschiet:</strong>
                                                        <span className='missesInputSpan' >
                                                            <input
                                                                value={orderData.machines[(page * machinesPerPage + index)].misses}
                                                                type='number'
                                                                className='missesInput'
                                                                onChange={({ target }) => {

                                                                    updateOrderDataValue(
                                                                        ['machines', (page * machinesPerPage + index), 'misses'],
                                                                        parseInt(target.value)
                                                                    );
                                                                }}
                                                            />
                                                        </span>
                                                    </Grid>

                                                    <strong>Verantwoording:</strong>
                                                    <textarea
                                                        style={
                                                            {
                                                                backgroundImage: "none",
                                                                backgroundColor: "white",
                                                                borderBottom: "1px dashed lightgrey",
                                                            }
                                                        }
                                                        value={orderData.machines[(page * machinesPerPage + index)].justification}
                                                        onChange={(e) => {
                                                            const lines = limitLines(
                                                                e.target.value,
                                                                '13pt helvetica',
                                                                mmToPx(210 - 21 - 21),
                                                                4
                                                            );

                                                            updateOrderDataValue(
                                                                ['machines', (page * machinesPerPage + index), 'justification'],
                                                                lines
                                                            );
                                                            e.target.style.height = '0px';
                                                            e.target.style.height = e.target.scrollHeight + 'px'
                                                        }}
                                                        onFocus={(e) => {
                                                            e.target.style.height = '0px';
                                                            e.target.style.height = e.target.scrollHeight + 'px'
                                                        }}
                                                    ></textarea>

                                                    <br />
                                                </Fragment>
                                            ))}
                                    </Grid>
                                </div>
                            </div>
                        ))}

                        {calculation !== null ? calculation?.owner === 'pretore' ? <>
                            <div
                                className='pdf-mockup__page'
                                style={{
                                    backgroundImage: `url('${orderBackground}')`
                                }}
                            >
                                <div className='pdf-mockup__row space-between'>
                                    <h3>{orderData.number}</h3>
                                    <h3>Leverdatum: {formattedDeliveryDate}</h3>
                                </div>
                                <div className='pdf-mockup__row space-between'>
                                    <h3>Transport</h3>
                                </div>

                                <hr/>

                                <div className='pdf-mockup__row gap'>
                                    <strong>Klant:</strong> {orderData.customer}
                                </div>
                                <div className='pdf-mockup__row gap'>
                                    <strong>Contactpersoon:</strong> {orderData.contactPerson}
                                </div>
                                <div className='pdf-mockup__row gap'>
                                    <strong>Klantreferentie:</strong> {orderData.customerReference}
                                </div>

                                <hr/>

                                <div
                                    className='pdf-mockup__row flex-start'
                                    style={{height: 'calc(100% - 30px)', marginTop: '10px'}}
                                >
                                    <textarea
                                        style={
                                            {
                                                minHeight: '16px',
                                                backgroundImage: "none",
                                                backgroundColor: "white",
                                                borderBottom: "1px dashed lightgrey",
                                                fontWeight: 'bold',
                                            }
                                        }
                                        className='transport'
                                        value={orderData.transport}
                                        onChange={(e) => {
                                            const lines = limitLines(
                                                e.target.value,
                                                '13pt helvetica bold',
                                                mmToPx(210 - 21 - 21),
                                                48
                                            );

                                            updateOrderDataValue('transport', lines);
                                            if (lines !== e.target.value) return;
                                            e.target.style.height = '0px';
                                            e.target.style.height = e.target.scrollHeight + 'px'
                                        }}
                                        onFocus={e => {
                                            e.target.style.height = '0px';
                                            e.target.style.height = e.target.scrollHeight + 'px'
                                        }}
                                    ></textarea>
                                </div>
                            </div>
                        </> : <></> : <></>}
                    </div>
                </SpinnerOverlay>
            </Grid>
        </>
    );
}
