import {Link, useParams} from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import {FetchContext} from "App/Strapi/FetchContext";
import {toast} from "react-toastify";
import Container from "UI/App/Components/Container/Container";
import Grid from "UI/App/Components/Grid/Grid";
import Block from "UI/App/Components/Block/Block";
import {SpinnerOverlay} from "UI/App/Components/Spinner";
import dayjs from "dayjs";
import {formatCurrency} from "App/Util/format";
import PageHeader from "UI/App/Components/PageHeader/PageHeader";
import CompanyDetail from "UI/App/Partials/Content/CRM/Companies/Company/Blocks/CompanyDetails/CompanyDetail";

export default function Shipment() {
    const fetchContext = useContext(FetchContext);
    const params = useParams();

    const [shipmentData, setShipmentData] = useState(null);

    const [trackingData, setTrackingData] = useState(null);
    const [trackingDetails, setTrackingDetails] = useState(null);

    const [loadingTrackingData, setLoadingTrackingData] = useState(true);
    const [loadingShipmentData, setLoadingShipmentData] = useState(true);
    const [loadingDetailData, setLoadingDetailData] = useState(true);

    const [shipment, setShipment] = useState(null);
    const [hasDocuments, setHasDocuments] = useState(false);

    const [pageTitle,setPageTitle] = useState("");

    // get the table data using the useEffect hook
    useEffect(() => {
        load();
    }, [params.shipmentId]);

    async function load() {
        console.log('load?');
        await fetchContext.authAxios.get(`/shipments/${params.shipmentId}?populate=*`).then(({data}) => {
            setPageTitle(`${data?.data?.order?.number} | ${data.data?.sending_address?.companyName} - ${data?.data?.company?.name}`)
            setShipment(data.data)
        }).catch((exception) => {
            console.error(exception);
            toast.error(`Er ging iets mis met het ophalen van de verzending`);
        });

        await fetchContext.authAxios.get(`/let-me-ship/shipment/${params.shipmentId}`).then(({data}) => {
            setShipmentData(data.trackingData);
            setLoadingShipmentData(false);
        }).catch((exception) => {
            console.error(exception);
            toast.error(`Er ging iets mis met het ophalen van de verzending`);
        });

        await fetchContext.authAxios.get(`/let-me-ship/shipment/${params.shipmentId}/details`).then(({data}) => {
            setTrackingDetails(data);
            setLoadingDetailData(false);
        }).catch((exception) => {
            console.error(exception);
            toast.error(`Er ging iets mis met het ophalen van de verzending`);
        });

        await fetchContext.authAxios.get(`/let-me-ship/shipment/${params.shipmentId}/tracking`).then(({data}) => {
            setTrackingData(data);
            setLoadingTrackingData(false);
        }).catch((exception) => {
            console.error(exception);
            toast.error(`Er ging iets mis met het ophalen van de verzending`);
        });
    }

    useEffect(() => {
        trackingDetails?.documents?.map((document, v) => {
            if (document.status === 'READY') {
                setHasDocuments(true);
            }
        })
    }, [trackingDetails]);

    return (
        <>
            <Container>
                <PageHeader title={pageTitle} style={{marginBottom: '1lh'}} />
                <Grid columns={2} customColTemplate={'1fr 1fr'}>
                    <Block name={'trackingInfo'} title={"Verzender"}>
                        <SpinnerOverlay visible={loadingShipmentData}>
                            {/* Address */}
                            <Grid columns={1} gap={'10px'}>
                                <span>
                                    <b>Bedrijf</b>: {shipmentData?.sender?.company}
                                </span>
                                <span>
                                    <b>Email</b>: {shipmentData?.sender?.email}
                                </span>
                                <span>
                                    <b>Tel</b>: <a href={`tel: ${shipmentData?.sender?.phone?.phoneNumberPrefix} ${shipmentData?.sender?.phone?.phoneNumber}`}>{`${shipmentData?.sender?.phone?.phoneNumberPrefix} ${shipmentData?.sender?.phone?.phoneNumber}`}</a>
                                </span>
                                <span>
                                    {shipmentData?.sender?.address?.street} {shipmentData?.sender?.address?.houseNo}
                                </span>
                                <span>
                                    {shipmentData?.sender?.address?.zip} {shipmentData?.sender?.address?.city}
                                </span>
                                {shipmentData?.sender?.address?.stateCode !== null ? <span>
                                    {shipmentData?.sender?.address?.stateCode}
                                </span>: <></>}
                                <span>
                                    {shipmentData?.sender?.address?.countryCode}
                                </span>
                            </Grid>
                        </SpinnerOverlay>
                    </Block>
                    <Block name={'reciver'} title={"Ontvanger"}>
                        <SpinnerOverlay visible={loadingShipmentData}>
                            <Grid columns={1} gap={'10px'}>
                                <span>
                                    <b>Bedrijf</b>:<Link to={`/crm/companies/${shipment?.company?.id}`}> {shipmentData?.consignee?.company}</Link>
                                </span>
                                <span>
                                    <b>Email</b>: {shipmentData?.consignee?.email}
                                </span>
                                <span>
                                    <b>Tel</b>: <a href={`tel: ${shipmentData?.consignee?.phone?.phoneNumberPrefix} ${shipmentData?.consignee?.phone?.phoneNumber}`}>{`${shipmentData?.consignee?.phone?.phoneNumberPrefix} ${shipmentData?.consignee?.phone?.phoneNumber}`}</a>
                                </span>
                                <span>
                                    {shipmentData?.consignee?.address?.street} {shipmentData?.consignee?.address?.houseNo}
                                </span>
                                <span>
                                    {shipmentData?.consignee?.address?.zip} {shipmentData?.consignee?.address?.city}
                                </span>
                                {shipmentData?.consignee?.address?.stateCode !== null ? <span>
                                    {shipmentData?.consignee?.address?.stateCode}
                                </span>: <></>}
                                <span>
                                    {shipmentData?.consignee?.address?.countryCode}
                                </span>
                            </Grid>
                        </SpinnerOverlay>
                    </Block>
                </Grid>

                <Grid
                    style={{
                        marginTop: 'var(--gap-l)'
                    }}
                    columns={3}
                    customColTemplate={'1fr 1fr 1fr'}
                >
                    <Grid
                        columns={1}
                    >
                        <Block name={'info'} title={"Gegevens provider"}>
                            <SpinnerOverlay visible={loadingShipmentData}>
                                <Grid columns={1} gap={'10px'}>
                                    <span>
                                        <b>Provider</b>: {shipmentData?.carrier}
                                    </span>
                                    <span>
                                        <b>Verwachte aflever datum</b>: {shipmentData?.expectedDeliveryTime}
                                    </span>
                                    <span>
                                        <b>Aantal pakketten</b>: {shipmentData?.parcelList.length}
                                    </span>
                                    <span>
                                        <b>Kosten</b>: {formatCurrency(shipment?.shippingTotal)}({formatCurrency(shipment?.shippingVAT)})
                                    </span>

                                </Grid>
                            </SpinnerOverlay>
                        </Block>


                    </Grid>
                    <Block
                        title={'Documenten'}
                        name={'quotePDF'}>
                        <SpinnerOverlay visible={loadingDetailData}>
                            <Grid columns={1}>
                                {hasDocuments ? <>
                                    {trackingDetails?.documents?.map((document, v) => {
                                        if (document.status === 'READY') {
                                            return <a
                                                className="styled-like-a-button"
                                                download={document.type}
                                                href={`data:${document.mimeType};base64,${document.dataEncoded}`}
                                            >{document.type}</a>
                                        }
                                    })}
                                </> : <>
                                    Nog geen documenten beschikbaar.
                                </>}
                            </Grid>
                        </SpinnerOverlay>
                    </Block>
                    <Block
                        title={"Track and trace"}
                        name={"Track and trace"}
                    >
                        <SpinnerOverlay visible={loadingTrackingData}>
                            <Grid columns={1}>
                                <span>
                                    Huidige locatie: {trackingData?.currentStatusLocation === '' ? 'Onbekend' : trackingData?.currentStatusLocation}
                                </span>

                                {trackingData?.trackingEventList?.map((event, v) => {
                                    return (
                                        <Grid columns={3} customColTemplate={'100px 1fr 100px'}>
                                            <span>
                                                {event.location}
                                            </span>
                                            <span>
                                                {event.description}
                                            </span>
                                            <span>
                                                {dayjs(event.dateTime).add(1, 'day').format("DD-MM-YYYY")}
                                            </span>
                                        </Grid>
                                    )
                                })}
                            </Grid>
                        </SpinnerOverlay>
                    </Block>
                </Grid>
            </Container>
        </>
    )
}
