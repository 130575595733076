import {toast} from "react-toastify";
import {FetchContext} from 'App/Strapi/FetchContext';
import React, {useContext, useEffect, useState} from "react";
import {SpinnerOverlay} from "UI/App/Components/Spinner";
import Grid from "UI/App/Components/Grid/Grid";
import dayjs from "dayjs";
import Select from "react-select";
import Boolean from "UI/App/Components/Form/Boolean";
import Button from "UI/App/Components/Button/Button";
import Table from "UI/App/Components/Table/Table";
import {useForm} from "react-hook-form";
import Icon from "UI/App/Components/Icon/Icon";
import ConfirmationPDFGenerator from "UI/App/Partials/Content/CRM/OrderConfirmations/ConfirmationPDFGenerator";
import Briefpapier_PRETORE from "UI/Assets/Images/PdfBackgrounds/Briefpapier_PRETORE.jpg";
import Briefpapier_PRETORE_page_2 from "UI/Assets/Images/PdfBackgrounds/Briefpapier_PRETORE_2.jpg";
import {countryToAlpha2} from "country-to-iso";
import {formatCurrency} from "App/Util/format";
import {useNavigate} from "react-router-dom";

export default function ShipmentCalculator({deliveryContactPersonId,deliveryAddressId,deliveryBranchId,deliveryCompanyId,orderId,callBack,deliveryInfo,pickupInfo,goodsValue}) {

    const {authAxios} = useContext(FetchContext);
    const [shippingOptions, setShippingOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [formData,setFormData] = useState({});
    const useableTitles = ['mr','mrs','ms']
    const navigate = useNavigate();
    const defaultListRow = {
        "additionalHandling": false,
        "height": 0, // IN CM
        "length": 0,  // IN CM
        "width": 0,   // IN CM
        "weight": 0, // IN KG
        "quantity": 0
    }
    const [parcelList, setParcelList] = useState([ // BOXES
        {
            "additionalHandling": false,
            "height": 25, // IN CM
            "length": 30.2,  // IN CM
            "width": 22,   // IN CM
            "weight": 10, // IN KG
            "quantity": 3
        }
    ]);
    const {setValue,register, handleSubmit, watch, formState: {errors},getValues} = useForm({
        // defaultValues: defaultValues
        defaultValues: {
            "deliveryInfo": { // Adress info delivery
                "address": {
                    "street": "",
                    "houseNo": "",
                        "zip": "",
                    "city": "",
                    "countryCode": ""
                },
                "company": "",
                "email": "",
                "person": {
                    "lastname": "",
                    "title": "mr"
                },
                "phone": {
                    "phoneNumber": "",
                    "phoneNumberPrefix": ""
                }
            },
            "includedCarriers": ["UPS", "FedEx", "TNT", "DPD", "DHL-Freight"],
            "pickupInfo": { // Adres Prretore
                "address": {
                    "street": "",
                    "houseNo": "",
                    "zip": "",
                    "city": "",
                    // "stateCode": "string", // Only available for USA
                    "countryCode": ""
                },
                "company": "",
                "person": {
                    "lastname": "",
                    "title": "mr"
                },
                "email": "",
                "phone": {
                    "phoneNumber": "",
                    "phoneNumberPrefix": ""
                }
            },
            "shipmentDetails": {
                "contentDescription": 'desc',
                "goodsValue": goodsValue !== undefined ? goodsValue : 0, // TOTAL VALUE
                // "parcelList": ctx.request.body.parcelList,
                "parcelList": parcelList,
                "pickupInterval": {
                    // "date": "2025-03-21" // Altijd vandaag + 1 dag
                    "date": dayjs().add(1, 'day').format("YYYY-MM-DD") // Altijd vandaag + 1 dag
                },
                "shipmentSettings": {
                    // VAST
                    "ddp": false,
                    "deliveryTailLift": false,
                    "holidayDelivery": false,
                    "insurance": true, // Moet aan en uit gezet kunnen worden
                    "pickupOrder": true,
                    "pickupTailLift": false, // Only true by palllet
                    "saturdayDelivery": false
                },
                "shipmentType": "PARCEL", // VAST
                "transportType": "STANDARD" // STANDARD of STANDARD PALLET
            }
        }
    });

    const [boxes,setBoxes] = useState([]);

    const [sendingAddresses, setSendingAddresses] = useState([]);
    const [chosenSendingAddress, setChosenSendingAddress] = useState(null);

    const [deliveryAddresses, setDeliveryAddresses] = useState([]);
    const [chosenDeliveryAddress, setChosenDeliveryAddress] = useState(null);

    const [deliveryBranches,setDeliveryBranches] = useState([]);
    const [chosenDeliveryBranch,setChosenDeliveryBranch] = useState(null);


    useEffect(() => {
        load();
    }, [deliveryCompanyId]);

    // Load defaults if contact persons id is set
    useEffect(() => {
        if(deliveryContactPersonId !== null && deliveryContactPersonId !== undefined){
            authAxios.get(`/crm/companies/persons/${deliveryContactPersonId}?populate=*`).then((data) => {

                setValue(`deliveryInfo.person.title`, data?.data?.data?.title === null ? 'mr' : data?.data?.data?.title);
                setValue(`deliveryInfo.person.lastname`, data?.data?.data?.surname);

                setValue(`deliveryInfo.email`, data.data.data.email);
                setValue(`deliveryInfo.phone.phoneNumber`, data.data.data.telephone?.telephoneNumber);
                setValue(`deliveryInfo.phone.phoneNumberPrefix`, data.data.data.telephone?.dialingCode);

            }).catch((exception) => {
                console.error(exception);
            });
        }
    },[deliveryContactPersonId])

    // Used to reset form
    useEffect(() => {
        setDeliveryBranches([]);
        setChosenDeliveryBranch(null)

        setValue(`deliveryInfo.address.street`, '');
        setValue(`deliveryInfo.address.zip`, '');
        setValue(`deliveryInfo.address.state`, '');
        setValue(`deliveryInfo.address.city`, '');
        setValue(`deliveryInfo.address.countryCode`, '');
        setValue(`deliveryInfo.company`, '');
    }, [chosenDeliveryAddress]);


    useEffect(() => {
        // Update sending address data
        if(chosenSendingAddress !== null){
            const addressMatch = chosenSendingAddress.data.address[0].addressLine1.match(/(\d+)(\w*)/gm);
            if(addressMatch !== null){
                setValue(`pickupInfo.address.houseNo`, addressMatch[0]);
                setValue(`pickupInfo.address.street`,chosenSendingAddress.data.address[0].addressLine1.replace(/(\d+)(\w*)/gm, ''));
            }else{
                setValue(`pickupInfo.address.street`,chosenSendingAddress.data.address[0].addressLine1);
            }

            setValue(`pickupInfo.address.zip`, chosenSendingAddress.data.address[0].postalCode);
            setValue(`pickupInfo.address.city`, chosenSendingAddress.data.address[0].city);
            setValue(`pickupInfo.address.countryCode`, chosenSendingAddress.data.address[0].country);
            setValue(`pickupInfo.company`, chosenSendingAddress.data.companyName);
            setValue(`pickupInfo.person.lastname`, chosenSendingAddress.data.contactPersonLastname);
            setValue(`pickupInfo.person.title`, chosenSendingAddress.data.contactPersonTitle);
            setValue(`pickupInfo.email`, chosenSendingAddress.data.contactEmail);
            setValue(`pickupInfo.phone.phoneNumber`, chosenSendingAddress.data.contactPhoneNumber);
            setValue(`pickupInfo.phone.phoneNumberPrefix`, chosenSendingAddress.data.contactPhonePrefix);
        }
    }, [chosenSendingAddress]);

    useEffect(() => {
        if(chosenDeliveryBranch !== null){
            setValue(`deliveryInfo.address.houseNo`, '');
            setValue(`deliveryInfo.address.street`,'');
            setValue(`deliveryInfo.address.zip`, '');
            setValue(`deliveryInfo.address.city`, '');
            setValue(`deliveryInfo.address.countryCode`, '');

            authAxios.get(`/crm/companies/branches/${chosenDeliveryBranch.value}`,{
                params: {
                    populate: {
                        address:true,
                        branches:{
                            populate:{
                                address:true,
                                telephone:true,
                            },
                            fields: [ 'email','locationManager']
                        }
                    }
                }
            }).then((data) => {

                const addressMatch = data.data.data.address.addressLine1.match(/(\d+)(\w*)/gm);
                if(addressMatch !== null){
                    setValue(`deliveryInfo.address.houseNo`, addressMatch[0]);
                    setValue(`deliveryInfo.address.street`,data.data.data.address.addressLine1.replace(/(\d+)(\w*)/gm, ''));
                }else{
                    setValue(`deliveryInfo.address.street`,data.data.data.address.addressLine1);
                }

                setValue(`deliveryInfo.address.zip`, data.data.data.address.postalCode);
                setValue(`deliveryInfo.address.city`, data.data.data.address.city);
                setValue(`deliveryInfo.address.countryCode`, countryToAlpha2(data.data.data.address.country));
            }).catch((exception) => {
                console.error(exception);
            });


        }
    }, [chosenDeliveryBranch]);

    useEffect(() => {
        // Update sending address data
        if(chosenDeliveryAddress !== null){
            authAxios.get(`/crm/companies/${chosenDeliveryAddress.value}`,{
                params: {
                    populate: {
                        address:true,
                        telephone:true,
                        branches:{
                            populate:{
                                address:true,
                                telephone:true,
                            },
                            fields: ['name', 'email','locationManager']
                        }
                    }
                }
            }).then((data) => {
                setValue(`deliveryInfo.company`, data.data.data.name);

                setValue(`deliveryInfo.address.zip`, data.data.data.address.postalCode);
                setValue(`deliveryInfo.address.city`, data.data.data.address.city);
                setValue(`deliveryInfo.address.countryCode`, data.data.data.address.country);


                const addressMatch = data.data.data.address.addressLine1.match(/(\d+)(\w*)/gm);
                if(addressMatch !== null){
                    setValue(`deliveryInfo.address.houseNo`, addressMatch[0]);
                    setValue(`deliveryInfo.address.street`,data.data.data.address.addressLine1.replace(/(\d+)(\w*)/gm, ''));
                }else{
                    setValue(`deliveryInfo.address.street`,data.data.data.address.addressLine1);
                }

                setValue(`deliveryInfo.address.zip`, data.data.data.address.postalCode);
                setValue(`deliveryInfo.address.city`, data.data.data.address.city);
                setValue(`deliveryInfo.address.countryCode`, countryToAlpha2(data.data.data.address.country));

                if(deliveryContactPersonId === null || deliveryContactPersonId === undefined){
                    setValue(`deliveryInfo.email`, data.data.data.generalEmail);
                    setValue(`deliveryInfo.phone.phoneNumber`, data.data.data.telephone?.telephoneNumber);
                    setValue(`deliveryInfo.phone.phoneNumberPrefix`, data.data.data.telephone?.dialingCode);
                }

                // Load branches
                if(data?.data?.data?.branches.length > 0) {
                    let options = [];

                    for (const branch of data?.data?.data?.branches) {
                        if(deliveryBranchId === branch.id){
                            setChosenDeliveryBranch({
                                label: branch.name,
                                value: branch.id
                            })
                        }

                        options.push({
                            label: branch.name,
                            value: branch.id
                        })
                    }

                    setDeliveryBranches(options)
                }
            }).catch((exception) => {
                console.error(exception);
            });
        }
    }, [chosenDeliveryAddress,deliveryBranchId]);

    useEffect(() => {
        if(deliveryInfo !== undefined){
            setValue(`deliveryInfo.address.street`, deliveryInfo.address.street);
            setValue(`deliveryInfo.address.houseNo`, deliveryInfo.address.houseNo);
            setValue(`deliveryInfo.address.zip`, deliveryInfo.address.zip);
            setValue(`deliveryInfo.address.city`, deliveryInfo.address.city);
            setValue(`deliveryInfo.email`, deliveryInfo.email);
            setValue(`deliveryInfo.company`, deliveryInfo.company);
            setValue(`deliveryInfo.address.countryCode`, countryToAlpha2(deliveryInfo.address.countryCode));
            setValue(`deliveryInfo.phone.phoneNumber`, deliveryInfo.phone.phoneNumber);
            setValue(`deliveryInfo.phone.phoneNumberPrefix`, deliveryInfo.phone.phoneNumberPrefix);
        }
    }, [deliveryInfo]);

    useEffect(() => {
        if(goodsValue !== undefined){
            setValue(`shipmentDetails.goodsValue`, Math.round(goodsValue));
        }
    }, [goodsValue]);

    async function load(){
        await loadSendingAddresses();
        await loadDeliveryAddresses();
        await getBoxes();
        setIsLoading(false)
    }

    async function getBoxes(){
        await authAxios.get(`/logistics/materials/all?filters[category][$eqi]=dozen&filters[active][$eq]=true`).then((data) => {
            let options = [];

            for(const material of data.data){
                options.push({
                    label:material.internalSKU,
                    value:material.id,
                    data:material
                })
            }

            setBoxes(options)
        }).catch((exception) => {
            console.error(exception);
        });
    }

    async function loadDeliveryAddresses(){
        await authAxios.get(`/crm/companies/all`).then((data) => {
            let options = [];

            for(const deliveryAddresses of data.data){
                // set delivery chosen
                if(deliveryAddresses.id === deliveryCompanyId){
                    setChosenDeliveryAddress({
                        label:deliveryAddresses.name,
                        value:deliveryAddresses.id
                    })
                }

                options.push({
                    label:deliveryAddresses.name,
                    value:deliveryAddresses.id
                })
            }

            setDeliveryAddresses(options);
        }).catch((exception) => {
            console.error(exception);
        });
    }

    async function loadSendingAddresses(){
        await authAxios.get(`/sending-addresses/all`,{
            params: {
                populate: "*"
            }
        }).then((data) => {
            let options = [];

            for(const sendingAddress of data.data){
                options.push({
                    label:sendingAddress.companyName,
                    value:sendingAddress.id,
                    data:sendingAddress
                })
            }

            setSendingAddresses(options);
        }).catch((exception) => {
            console.error(exception);
        });
    }

    async function loadShippingOptions(formData) {
        setIsLoading(true);

        // Set parcelList data
        formData.shipmentDetails.parcelList = parcelList;

        await authAxios.post(`/let-me-ship/shipment/available`, formData).then(({data}) => {
            if (data.status === 400 || data?.status?.code == 1000) {
                setShippingOptions([]);
                console.error(data?.status?.message);
                for(const error of data?.status?.message){
                    toast.error(error)
                }
                return
            }

            let tableData = [];

            for (const shippingOption of data) {
                let messageString = ''

                if (shippingOption?.messages !== undefined) {
                    for (const message of shippingOption?.messages) {
                        messageString += message + ',';
                    }
                }

                tableData.push([
                    <img src={`data:image/png;base64, ${shippingOption.baseServiceDetails.carrierImage}`} alt=""/>,
                    shippingOption.baseServiceDetails.carrier,
                    `${formatCurrency(shippingOption.baseServiceDetails.priceInfo.netPrice)} (${formatCurrency(shippingOption.baseServiceDetails.priceInfo.totalPrice)})`,
                    <span style={{color:'red'}}>{messageString}</span>,
                    <button type="button" disabled={messageString !== ''} onClick={() => createShipment(shippingOption)}>
                        <Icon name={"fa paper-plane"} style={{ marginRight: '5px' }} />
                    </button>
                ])
            }

            setShippingOptions(tableData)
        }).catch((exception) => {
            console.error(exception);
            toast.error(`Er ging iets mis met het opslaan van een order!`);
        });

        setIsLoading(false);
    }

    async function createShipment(shippingOption){
        setIsLoading(true);

        const shipmentData = {
            letMeShipData:  {
                "deliveryInfo":getValues().deliveryInfo,
                "enableETD": false,
                "excludedCarriers": ["Karin"],
                "includedCarriers": ["UPS","FedEx","TNT","DPD","DHL-Freight"],
                "pickupInfo": getValues().pickupInfo,
                "service": shippingOption,
                "shipmentDetails": getValues().shipmentDetails
            },
            company: chosenDeliveryAddress?.value,
            sendingAddress: chosenSendingAddress?.value,
            orderId:orderId,
            sendingAddressId:deliveryAddressId, // For linking order delivery address to shipment,
            shippingVAT: shippingOption.baseServiceDetails.priceInfo.totalVat,
            shippingCost: shippingOption.baseServiceDetails.priceInfo.netPrice,
            shippingTotal: shippingOption.baseServiceDetails.priceInfo.totalPrice
        }

        await authAxios.post(`/let-me-ship/shipment/create`, shipmentData).then(({data}) => {
            if (data.status === 400) {
                setShippingOptions([]);
                toast.error('Er ging iets mis met het aanmaken van verzending.')
                console.error(data)
                return
            }

            toast.success('Verzending met succes aangemeld.')

            // Call callback if is isset else navigate to detail page
            if(typeof callBack === 'function') {
                callBack();
                return
            }

            setIsLoading(false);
            navigate(`/shipping/shipments/${data.id}`);
        }).catch((exception) => {
            console.error(exception);
            toast.error(`Er ging iets mis met het opslaan van een order!`);
        });

        setIsLoading(false);
    }

    async function onSubmit(data) {
        setFormData(data);
        if( validateForm(data)){
            await loadShippingOptions(data);
        }
    }

    function validateForm(data){

        let errors = [];

        // Person Checks
        // Check useableTitles
        if(!useableTitles.includes(data.deliveryInfo.person.title.toLowerCase())) errors.push('Bezorg persoon aanhef moet MR/MRS/MS zijn.')
        if(!useableTitles.includes(data.pickupInfo.person.title.toLowerCase())) errors.push('Ophaal persoon aanhef moet MR/MRS/MS zijn.')

        // Check last names
        if(data.deliveryInfo.person.lastname === '') errors.push('Achternaan bezorg persoon mag niet leeg zijn.')
        if(data.pickupInfo.person.lastname === '') errors.push('Achternaan Ophaal persoon mag niet leeg zijn.')


        // Check emails
        if(data.deliveryInfo.email === '') errors.push('Email voor bezorg adres mag niet leeg zijn.')
        if(data.pickupInfo.email === '') errors.push('Email voor Ophaal adres mag niet leeg zijn.')

        // check phone
        if(data.deliveryInfo.phone.phoneNumber === '') errors.push('Telefoon nummer voor bezorg adres mag niet leeg zijn.')
        if(data.pickupInfo.phone.phoneNumber === '') errors.push('Telefoon nummer voor Ophaal adres mag niet leeg zijn.')

        // check phone
        if(data.deliveryInfo.phone.phoneNumberPrefix === '') errors.push('Landcode voor bezorg adres mag niet leeg zijn.')
        if(data.pickupInfo.phone.phoneNumberPrefix === '') errors.push('Landcode voor Ophaal adres mag niet leeg zijn.')

        // Check if date not weekend
        if(dayjs(data.shipmentDetails.pickupInterval.date).day() === 6 || dayjs(data.shipmentDetails.pickupInterval.date).day() === 0) errors.push('Ophaal datum mag niet in het weekend zijn.')

        // IF us and state empty
        if(data.deliveryInfo.address.countryCode.toLowerCase() === 'us'){
            if(data.deliveryInfo.address.state === ''){
                errors.push('Als land US/Amerika is moet staat ingevuld zijn.')
            }
        }

        if(errors.length === 0){
            return true
        }

        // Create toasts for errors
        for(const error of errors){
            toast.error(error,{
                closeButton: true,
                autoClose: 4000,
            })
        }

        return false;
    }

    // Helper for removing parcel for parcellist
    async function removeParcelFromList(listKey) {
        parcelList.splice(listKey, 1)

        setParcelList([...parcelList]);
    }

    // Helper for adding parcels to list
    async function addParcelToList() {
        parcelList.push(defaultListRow)

        setParcelList([...parcelList]);
    }

    // Helper for updating parcel list values
    function updateParcelListItem(value, type, key) {
        parcelList[key][type] = value;

        setParcelList([...parcelList]);
    }

    function fillBoxRow(e,key){
        parcelList[key].height = e.data.heightInMeters * 100
        parcelList[key].length = e.data.lengthInMeters * 100
        parcelList[key].width = e.data.widthInMeters * 100

        setParcelList([...parcelList]);
    }

    return (
        <>
            <SpinnerOverlay visible={isLoading}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid columns={1}>
                        <span>
                            Omschrijving
                            <input type="text" name="shippingData.description" {...register('shippingData.description')}/>
                        </span>
                    </Grid>

                    {/* Pickup/Delivery adres */}
                    <Grid columns={2}>
                        <span>
                            <b>Ophaal adres</b>
                        </span>
                        <span>
                            <b>Verzend adres</b>
                        </span>
                    </Grid>

                    {/* Pickup/Delivery adres */}
                    <Grid columns={2}>
                        <span>
                            <Select
                                options={sendingAddresses}
                                value={chosenSendingAddress}
                                onChange={(e) => setChosenSendingAddress(e)}
                            />
                        </span>
                        <span>
                            <Select
                                options={deliveryAddresses}
                                value={chosenDeliveryAddress}
                                onChange={(e) => setChosenDeliveryAddress(e)}

                            />
                        </span>
                    </Grid>

                    {/* Company name */}
                    <Grid columns={2}>
                        <span>
                        </span>
                        <span>
                            Vestiging
                            <Select
                                options={deliveryBranches}
                                value={chosenDeliveryBranch}
                                onChange={(e) => setChosenDeliveryBranch(e)}
                            />
                        </span>
                    </Grid>

                    {/* Company name */}
                    <Grid columns={2}>
                        <span>
                            Bedrijfsnaam
                            <input type="text" name="pickupInfo.company" {...register('pickupInfo.company')}/>
                        </span>
                        <span>
                            Bedrijfsnaam
                            <input type="text" name="deliveryInfo.company" {...register('deliveryInfo.company')}/>
                        </span>
                    </Grid>

                    {/* Street */}
                    <Grid columns={2}>
                        <span>
                            <Grid columns={2} customColTemplate={'1fr 200px'}>
                                <span>
                                    Straat
                                    <input type="text" name="description" {...register('pickupInfo.address.street')}/>
                                </span>
                                <span>
                                    Huisnummer
                                    <input type="text" name="description" {...register('pickupInfo.address.houseNo')}/>
                                </span>
                            </Grid>
                        </span>
                        <span>
                            <Grid columns={2} customColTemplate={'1fr 200px'}>
                                <span>
                                    Straat
                                    <input type="text" name="description" {...register('deliveryInfo.address.street')}/>
                                </span>
                                <span>
                                    Huisnummer
                                    <input type="text" name="description" {...register('deliveryInfo.address.houseNo')}/>
                                </span>
                            </Grid>
                        </span>
                    </Grid>

                    {/* Zip city */}
                    <Grid columns={2}>
                        <span>
                            <Grid columns={2} customColTemplate={'200px 1fr'}>
                                <span>
                                    Postcode
                                    <input type="text" name="description" {...register('pickupInfo.address.zip')}/>
                                </span>
                                <span>
                                    Plaats
                                    <input type="text" name="description" {...register('pickupInfo.address.city')}/>
                                </span>
                            </Grid>
                        </span>
                        <span>
                            <Grid columns={2} customColTemplate={'200px 1fr'}>
                                <span>
                                    Postcode
                                    <input type="text" name="description" {...register('deliveryInfo.address.zip')}/>
                                </span>
                                <span>
                                    Plaats
                                    <input type="text" name="description" {...register('deliveryInfo.address.city')}/>
                                </span>
                            </Grid>
                        </span>
                    </Grid>

                    {/* State/country */}
                    <Grid columns={2}>
                        <span>
                            <Grid columns={2} customColTemplate={'1fr 1fr'}>
                                <span>
                                    Staat
                                    <input type="text" name="description" {...register('pickupInfo.address.state')}/>
                                </span>
                                <span>
                                    Land
                                    <input type="text" name="description" {...register('pickupInfo.address.countryCode')}/>
                                </span>
                            </Grid>
                        </span>
                        <span>
                            <Grid columns={2} customColTemplate={'1fr 1fr'}>
                                <span>
                                    Staat
                                    <input type="text" name="description" {...register('deliveryInfo.address.state')}/>
                                </span>
                                <span>
                                    Land
                                    <input type="text" name="description" {...register('deliveryInfo.address.countryCode')}/>
                                </span>
                            </Grid>
                        </span>
                    </Grid>
                    <Grid columns={2}>
                        <b>Contactpersoon</b>
                        <b>Contactpersoon</b>
                    </Grid>
                    {/* Person details */}
                    <Grid columns={2}>
                        <span>
                            <Grid columns={2} customColTemplate={'100px 1fr'}>
                                <span>
                                    Aanhef
                                    <select {...register('pickupInfo.person.title')} >
                                        {useableTitles.map((title) => {
                                            return <option value={title}>{title}</option>
                                        })}
                                    </select>
                                </span>
                                <span>
                                    Achternaam
                                    <input type="text" name="pickupInfo.person.lastname" {...register('pickupInfo.person.lastname')}/>
                                </span>
                            </Grid>
                        </span>
                        <span>
                            <Grid columns={2} customColTemplate={'100px 1fr'}>
                                <span>
                                    Aanhef
                                    <select {...register('deliveryInfo.person.title')} >
                                        {useableTitles.map((title) => {
                                            return <option value={title}>{title}</option>
                                        })}
                                    </select>
                                </span>
                                <span>
                                    Achternaam
                                    <input type="text" name="deliveryInfo.person.lastname" {...register('deliveryInfo.person.lastname')}/>
                                </span>
                            </Grid>
                        </span>
                    </Grid>
                    <Grid columns={2}>
                        <span>
                            Email
                            <input type="text" name="description" {...register('pickupInfo.email')}/>
                        </span>
                        <span>
                            Email
                            <input type="text" name="description" {...register('deliveryInfo.email')}/>
                        </span>
                    </Grid>
                    <Grid columns={2}>
                        <span>
                            <Grid columns={2} customColTemplate={'100px 1fr'}>
                                <span>
                                    Landcode
                                    <input type="text" name="description" {...register('pickupInfo.phone.phoneNumberPrefix')}/>
                                </span>
                                <span>
                                    Telefoon nummer
                                    <input type="text" name="description" {...register('pickupInfo.phone.phoneNumber')}/>
                                </span>
                            </Grid>
                        </span>
                        <span>
                            <Grid columns={2} customColTemplate={'100px 1fr'}>
                                <span>
                                    Landcode
                                    <input type="text" name="description" {...register('deliveryInfo.phone.phoneNumberPrefix')}/>
                                </span>
                                <span>
                                    Telefoon nummer
                                    <input type="text" name="description" {...register('deliveryInfo.phone.phoneNumber')}/>
                                </span>
                            </Grid>
                        </span>
                    </Grid>
                    <span>
                        Ophaal datum
                        <input
                            type="date"
                            min={dayjs().add(1, 'day').format("YYYY-MM-DD")}
                            {...register('shipmentDetails.pickupInterval.date')}
                        />
                    </span>
                    <Grid columns={3}>
                        <span>
                            Dozen/Pallets
                            <select {...register("shippingData.transportType")}>
                                <option value="STANDAARD PALLET">
                                    STANDAARD PALLET
                                </option>
                                <option value="PALLET">
                                    PALLET
                                </option>
                            </select>
                        </span>
                        <span>
                            Verzekering
                            <select {...register("shipmentDetails.shipmentSettings.insurance")}>
                                <option value="true">
                                    Ja
                                </option>
                                <option value="false">
                                    Nee
                                </option>
                            </select>
                        </span>
                        <span>
                            Waarde
                            <input
                                type='number'
                                step={1}
                                {...register('shipmentDetails.goodsValue')}
                            />
                        </span>
                    </Grid>

                    <Grid columns={2} customColTemplate={'1fr 100px'}>
                        <span></span>
                        <span>
                            <button
                                type="button"
                                onClick={() => addParcelToList()}>
                                Toevoegen
                            </button>
                        </span>
                    </Grid>

                    <br/>
                    {parcelList.map((parcel, key) => {

                        // If undefined happens on delete
                        return <Grid columns={5} customColTemplate={'1fr 1fr 1fr 1fr 1fr 1fr 50px'}>
                            <span>
                                Doos
                                <Select
                                    options={boxes}
                                    onChange={(e) => fillBoxRow(e,key)}
                                />
                            </span>
                            <span>
                                Aantal
                                <input
                                    value={parcel.quantity}
                                    onChange={(e) => updateParcelListItem(e.target.value, 'quantity', key)}/>
                            </span>
                            <span>
                                Hoogte
                                <input
                                    value={parcel.height}
                                    onChange={(e) => updateParcelListItem(e.target.value, 'height', key)}/>
                            </span>
                            <span>
                                lengte
                                <input
                                    value={parcel.length}
                                    onChange={(e) => updateParcelListItem(e.target.value, 'length', key)}/>
                            </span>
                            <span>
                                Breedte
                                <input
                                    value={parcel.width}
                                    onChange={(e) => updateParcelListItem(e.target.value, 'width', key)}/>
                            </span>
                            <span>
                                gewicht
                                <input
                                    value={parcel.weight}
                                    onChange={(e) => updateParcelListItem(e.target.value, 'weight', key)}/>
                            </span>
                            <span>
                                <button
                                    type="button"
                                    onClick={() => removeParcelFromList(key)}>
                                    <Icon name='times'></Icon>
                                </button>
                            </span>
                        </Grid>
                    })}


                    <Grid columns={2} customColTemplate={'1fr 350px'}>
                        <span></span>
                        <button type="submit">Berekenen</button>
                    </Grid>
                    <br/>
                    <Table
                        headers={['Logo', 'Vervoerder', 'Prijs (BTW)', 'Melding(en)', '']}
                        data={shippingOptions}
                        structure={['100px', '200px', '200px', '100%', '90px']}
                        border='row'
                        hover
                        overflowText
                    />

                </form>
            </SpinnerOverlay>
        </>
    )
}
