import Button from "UI/App/Components/Button/Button";
import Popup, { closePopup } from "UI/App/Components/Popup/Popup";
import { useForm } from "react-hook-form";
import { useContext } from "react";
import { FetchContext } from "App/Strapi/FetchContext";
import { toast } from "react-toastify";

export default function OrderUploadFilePopup({ order, setOrder }) {
    const { authAxios } = useContext(FetchContext);
    const { register, handleSubmit, ...formProps } = useForm();

    const onSubmit = (data) => {

        // create new form data object for sending when uploading
        const formData = new FormData();
        formData.append('file', data.file[0]);
        formData.append('fileName', data.fileName);

        authAxios.put(`/orders/${order.id}/files/upload`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(({ data }) => {
                // Close the popup
                closePopup('OrderUploadFilePopup');
                // Rest the form
                formProps.reset();

                setOrder(order => ({
                    ...order,
                    files: [
                        ...(order.files ?? []),
                        {
                            displayName: data.displayName,
                            fileSize: data.fileSize
                        }
                    ]
                }))
            })
            .catch(e => {
                console.error(e);
                toast.error("Er is iets mis gegaan bij het uploaden van het bestand!")
            })
    }

    return (
        <Popup overflow={true} popupID='OrderUploadFilePopup' title='Upload bestand' contentStyle={{ minHeight: '0' }}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className='input-group'>
                    <label className='required'>Nieuwe bestandsnaam (zonder extensie)</label>
                    <input required placeholder="Bestandsnaam" {...register('fileName')} />
                </div>

                <div className='input-group'>
                    <label className='required'>Kies bestand (Max. 2GB)</label>
                    <input type='file' accept='.pdf,.docx,.doc,.zip,.xlsx,.ps' {...register('file')} />
                </div>

                <div className='input-group'>
                    <Button type='submit' className='btn' style={{ width: '100%' }}>
                        Toevoegen
                    </Button>
                </div>
            </form>
        </Popup>
    )
}