import {toast} from "react-toastify";
import {FetchContext} from 'App/Strapi/FetchContext';
import React, {useContext, useEffect, useState} from "react";
import {SpinnerOverlay} from "UI/App/Components/Spinner";
import Grid from "UI/App/Components/Grid/Grid";
import Block from "UI/App/Components/Block/Block";
import Container from "UI/App/Components/Container/Container";
import Table from "UI/App/Components/Table/Table";
import {usePagination} from "UI/App/Components/Pagination/Pagination";
import Button from "UI/App/Components/Button/Button";
import Icon from "UI/App/Components/Icon/Icon";
import {Link} from "react-router-dom";
import Popup, {openPopup} from "UI/App/Components/Popup/Popup";
import ShipmentCalculator from "UI/App/Partials/Content/Shipment/ShipmentCalculator";

export default function Shipping() {
    const {
        paging,
        filtering,
        currentPage,
        resultsPerPage,
        setTotalPages,
        setTotalResults,
        filterValues,
        filterQuery,
        paginationStateLoaded
    } = usePagination({
        storageKey: 'catalog',
        searchSettings: {
            enabled: true,
            strapiFields: [
                'internalSKU',
                'category',
                'quotationNameEN',
                'quotationNameNL',
                'calculationDescription'
            ]
        },
        filters: [
            {
                name: 'active',
                type: 'select',
                options: [
                    {name: 'Actief', value: 'true'},
                    {name: 'Inactief', value: 'false'},
                ],
                strapiFilter: "$eq",
                strapiFilterFields: [
                    'active'
                ]
            }
        ], htmlElements: [
            <Button className='btn btn--icon-right' onClick={() => openPopup('calculateShipmentPopup')}>
                Bereken verzending <Icon name={'truck'}/>
            </Button>,
        ],
        buttonCollapseBreakpoint: 2000,
        resultsPerPageBreakpoint: 1600,
    });

    const {authAxios} = useContext(FetchContext);
    const [shippingOptions, setShippingOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(false)
        // loadShippingOptions();
    }, []);

    async function loadShippingOptions() {
        await authAxios.post(`/let-me-ship/available`, {}).then(({data}) => {
            console.log(data);
            setShippingOptions(data);
            setIsLoading(false);
        }).catch((exception) => {
            console.error(exception);
            toast.error(`Er ging iets mis met het opslaan van een order!`);
        });
    }


    return (
        <>
            <SpinnerOverlay visible={isLoading}>
                {filtering}
                <Container>
                    <Table headers={["Categorieën", "Naam", "Verkoopprijs", '', ""]}
                           structure={['250px', '60%', '40%', '200px', '90px']}
                           data={[]}
                           columnOverflowExceptions={[5]}
                    />
                </Container>
                {paging}
            </SpinnerOverlay>
            <Popup
                popupID="calculateShipmentPopup"
                title="Verzendkosten berekenen"
                style={{
                    width: 80 + '%',
                    left: `calc(50vw - ${80 / 2}%)`
                }}
            >
                <ShipmentCalculator />
            </Popup>
        </>
    )
}
