import { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import qs from 'qs';
import { FetchContext } from 'App/Strapi/FetchContext';
import Button from 'UI/App/Components/Button/Button';
import Icon from 'UI/App/Components/Icon/Icon';
import { usePagination } from 'UI/App/Components/Pagination/Pagination';
import Table from 'UI/App/Components/Table/Table';
import isEmpty from 'lodash/isEmpty';
import useWindowDimensions from 'App/Util/useWindowDimensions';

export default function OrdersPackingSlips() {
    const { authAxios } = useContext(FetchContext);
    const navigate = useNavigate();
    const [tableData, setTableData] = useState([]);
    const { width } = useWindowDimensions();

    const {
        paging,
        filtering,
        currentPage,
        resultsPerPage,
        setTotalPages,
        setTotalResults,
        filterValues,
        filterQuery,
        paginationStateLoaded,
        setFilterValues
    } = usePagination({
        storageKey: 'order-packing-slips',
        searchSettings: {
            enabled: true,
            strapiFields: ['number', '[order][number]', '[order][company][name]', '[order][description]']
        },
        filters: [
            {
                name: 'archived',
                type: 'select',
                options: [{ value: '*', name: 'Alle' }],
                enabled: true
            }
        ]
    });

    useEffect(() => {
        if (!paginationStateLoaded) return;

        const params = qs.stringify({
            sort: {
                id: 'desc'
            },
            populate: {
                order: {
                    fields: ['number', 'description'],
                    populate: {
                        company: {
                            fields: ['name']
                        }
                    }
                }
            },
            fields: ['number']
        });

        authAxios
            .get(`/orders/packing-slips?${params}&${filterQuery}`)
            .then(({ data }) => {
                const tableData = [];

                // loop through all the records
                for (const slip of data.data ?? []) {
                    tableData.push({
                        attributes: {
                            onClick: () => {
                                navigate(`/orders/${slip?.order?.id}/packing-slips/${slip?.id}`);
                            },
                            id: slip.id,
                            key: slip.id // need unique key. using array index causes archive Boolean to not update on filter switch (Boolean now removed but still keep this)
                        },
                        data: [
                            slip?.number,
                            <Link to={`/orders/${slip?.order?.id}`} onClick={(e) => e.stopPropagation()}>
                                {slip?.order?.number}
                            </Link>,
                            <Link
                                to={`/crm/companies/${slip?.order?.company?.id}`}
                                onClick={(e) => e.stopPropagation()}
                            >
                                {slip?.order?.company?.name}
                            </Link>,
                            isEmpty(slip?.order?.description) ? (
                                <span style={{ color: 'gray', fontStyle: 'italic' }}>Leeg</span>
                            ) : (
                                slip?.order?.description
                            ),

                            <div className='table__action-buttons'>
                                {width >= 900 && <Button data-title='Bewerken' className='btn btn--transparent'>
                                    <Icon name='pencil' />
                                </Button>}
                            </div>
                        ]
                    });
                }
                setTableData(tableData);

                // Set pagination
                setTotalPages(data.meta.pagination.pageCount);
                setTotalResults(data.meta.pagination.total);
            })
            .catch((error) => {
                toast.error(`Er is iets fout gegaan ${error?.response?.status ? `(${error.response?.status})` : ''}`);
                console.error(error);
            });
    }, [authAxios, filterQuery, navigate, paginationStateLoaded, setTotalPages, setTotalResults]);

    return (
        <>
            {filtering}
            <Table
                headers={['Nummer', 'Order', 'Klant', 'Klantreferentie', '']}
                structure={{
                    400: ['50%', '50%', 0, 0, 0],
                    650: ['120px', '50%', '50%', 0, 0],
                    900: ['120px', '150px', '50%', '50%', 0],
                    default: ['150px', '150px', '350px', '100%', '60px']
                }}
                data={tableData}
                hover
                border='row'
                className='table--padding-m'
                columnOverflowExceptions={[4]}
                columnTranslationExceptions={[0, 1, 2]}
            />
            {paging}
        </>
    );
}
