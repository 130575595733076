import { useLoaderData } from "react-router-dom";
import IF from "UI/App/Components/Conditional/IF";
import Block from "UI/App/Components/Block/Block";
import useDisclosure from "App/Util/hooks/useDisclosure";
import Icon from "UI/App/Components/Icon/Icon";
import useSubmit from "App/Util/hooks/useSubmit";
import { useForm } from "react-hook-form";

export default function Checklist() {
    const { order } = useLoaderData();
    const submit = useSubmit();

    const { value: isEdit, toggle: toggleEdit } = useDisclosure(false);

    const onSubmit = async (data) => {

        if (!isEdit) {
            toggleEdit();
            return;
        }

        submit(data);

        toggleEdit();
    }

    const {
        register,
        handleSubmit,
    } = useForm({
        defaultValues: {
            extraWork: order.extraWork,
            information: order.information
        }
    });

    return (
        <Block
            name='Checklist'
            title='Checklist'
            headerRightSideChildren={
                <button form='checklistForm' type='submit' className='btn btn--round'>
                    <Icon name={isEdit ? 'save' : 'pen'} />
                </button>
            }
        >
            <ul style={{ listStyleType: 'none' }} className={'checklist'}>
                <li><span className={'name'}>FSC</span>:<strong className={'value'}>{order.FSC ? 'Ja' : 'Nee'}</strong></li>
                {order.calculation.data.checklist.filter(listItem => listItem.value).map(listItem => (
                    <li key={listItem?.name}><span className={'name'}>{listItem.dutchName ?? listItem.name}</span>: <span className={'value'}>{listItem.value}</span></li>
                ))}
                <form id='checklistForm' onSubmit={handleSubmit(onSubmit)}>
                    <IF condition={isEdit}>
                        <li>
                            <span className={'name'}>Meerwerk</span>:
                            <span style={{ width: '100%' }} className={'value'}>
                                <textarea {...register('extraWork')} />
                            </span>
                        </li>
                        <li>
                            <span className={'name'}>Informatie</span>:
                            <strong style={{ width: '100%' }} className={'value'}>
                                <textarea {...register('information')} />
                            </strong>
                        </li>
                    </IF>

                    <IF condition={!isEdit}>
                        <IF condition={order.extraWork}>
                            <li>
                                <span className={'name'}>Meerwerk</span>:
                                <span style={{ width: '100%' }} className={'value'}>{order.extraWork}</span>
                            </li>
                        </IF>
                        <IF condition={order.information}>
                            <li>
                                <span className={'name'}>Informatie</span>:
                                <strong style={{ width: '100%' }} className={'value'}>{order.information}</strong>
                            </li>
                        </IF>
                    </IF>
                </form>
            </ul>
        </Block>
    )
}