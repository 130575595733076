import './dailyPlanning.scss'
import './planning.scss'
import {Fragment, useContext, useEffect, useState} from "react";
import {Link} from 'react-router-dom';
import {FetchContext} from "App/Strapi/FetchContext";
import { useParams } from 'react-router-dom';
import Grid from "UI/App/Components/Grid/Grid";
import Block from "UI/App/Components/Block/Block";
import Select from "react-select";
import Icon from "UI/App/Components/Icon/Icon";
import IF from "UI/App/Components/Conditional/IF";
import {formatTime} from "App/Util/format";
import {getSelectedValue} from "App/Util/select2";
import {SpinnerOverlay} from "UI/App/Components/Spinner";
import useWindowDimensions from 'App/Util/useWindowDimensions';
import UpdateMachinePlanningPopup from "UI/App/Partials/Content/Planning/UpdateMachinePlanningPopup";
import Popup, { openPopup, closePopup } from "UI/App/Components/Popup/Popup";
import {addOperatorsToMachine} from "UI/App/Partials/Content/Planning/Planning";
import {toast} from "react-toastify";
import capitalize from "lodash/capitalize";
import User from "App/Strapi/User";
import Button from 'UI/App/Components/Button/Button';
import planningPlaceholder from "UI/Assets/Images/planning-placeholder.png";
import {_PLANNING_PROGRESS_FILTERS} from "UI/App/Partials/Content/Planning/PersonalPlanning";
import AddUsersToMachinesPopup from './AddUsersToMachinesPopup';
import HasRole from "UI/App/Components/Auth/HasRole";

export default function GeneralPlanning() {
    const {authAxios} = useContext(FetchContext);
    const [grid, setGrid] = useState([]);
    const [startDate, setStartDate] = useState(getFirstDayOfWeek(new Date()));
    const [endDate, setEndDate] = useState(getLastDayOfWeek(new Date()));
    const [gridWidth, setGridWidth] = useState(3);
    const [colTemplate, setColTemplate] = useState('1fr')
    const [allMachines, setAllMachines] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [selectedDepartments, setSelectedDepartments] = useState([]);
    const [statusFilter, setStatusFilter] = useState([])
    const [isLoading, setIsLoading] = useState(true);
    const [lastWidthValue, setLastWidthValue] = useState(null)
    const [scrollToPos, setScrollToPos] = useState(false);
    const [isFilterPopup, setIsFilterPopup] = useState(false);

    const [popupFormData, setPopupFormData] = useState({});
    const [currentMachine, setCurrentMachine] = useState({});
    const [bulkUsersContext, setBulkUsersContext] = useState({});

    const { width, height } = useWindowDimensions();
    const { type } = useParams();


    const executeScroll = () => {
        // get the scroll element
        let element = document.getElementById("scroll_location")

        // if DONT have one return
        if (!element) {
            return
        }
        // scorll to the element
        element?.scrollIntoView({ behavior: "smooth" })

    };

    function getLastDayOfWeek(d) {
        d = getFirstDayOfWeek(d);
        return (d.setUTCDate(d.getUTCDate() + 6));
    }

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === 'ArrowRight') {
                e.preventDefault();
                nextWeek();
            } else if (e.key === 'ArrowLeft') {
                e.preventDefault();
                previousWeek();
            }
        }

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        }
    }, [startDate, endDate]);


    useEffect(() => {
        //loadMachineData();
        generateGrid();
    }, [statusFilter])

    useEffect(() => {
        void load();
    }, [])

    useEffect(() => {
        // only scroll if we need to
        if (scrollToPos) {
            executeScroll();
            setScrollToPos(false);
        }
    }, [scrollToPos])
    // automatic filter for departments
    useEffect(() => {
        // guard against, unset data
        if (type === undefined && departments?.length === 0) return;

        // see if we have a department with that corresponds to the type
        let foundDeparts = departments.find((el) => el?.value?.toLowerCase() === type?.toLowerCase())

        // return if we have none
        if (foundDeparts === undefined) return;

        // set the department
        setSelectedDepartments(foundDeparts?.value);

    }, [departments])

    useEffect(() => {
        void generateGrid();
    }, [startDate, endDate, selectedDepartments])

    useEffect(() => {
        if (lastWidthValue !== width && width <= 550 && width > 400) {
            setColTemplate((prevColTemplate) => {
                prevColTemplate = prevColTemplate.replace('250px', '200px');
                return prevColTemplate.replace('150px', '200px');
            })
        } else if (lastWidthValue !== width && width <= 400) {
            setColTemplate((prevColTemplate) => {
                prevColTemplate = prevColTemplate.replace('250px', '150px');
                return prevColTemplate.replace('200px', '150px');
            })
        } else if (lastWidthValue !== width && width > 400) {
            setColTemplate((prevColTemplate) => {
                prevColTemplate = prevColTemplate.replace('150px', '250px');
                return prevColTemplate.replace('200px', '250px');
            })
        }
    }, [width, colTemplate])

    async function generateGrid() {
        setIsLoading(true);

        if (startDate === null || endDate === null) {
            return;
        }

        // Get machine data from API
        const machineData = await loadMachineData();

        let grid = [];

        let dates = [];
        for (let d = new Date(startDate); d <= new Date(endDate); d.setDate(d.getDate() + 1)) {
            dates.push(new Date(d));
        }

        // Loop machines
        let y = 0;
        let x = 0;
        // Used to choose colors
        let planProjects = [];

        for (const date of dates) {
            const formattedDate = new Date(date).toISOString().split('T')[0];
            x = 0;
            if (grid[y] === undefined) {
                grid[y] = {
                    date: formattedDate,
                }
            }

            // Add machines names
            let machines = [];
            for (const [machineKey, machineEntry] of Object.entries(machineData)) {

                let machine = {
                    checklist: machineEntry.checklist,
                    orderChecklist:machineEntry.orderChecklist,
                    machineId: machineEntry.planning[0].machine.machineId,
                    name: machineEntry.name,
                    templatePart: machineEntry.templatePart,
                    projectKey: machineEntry.projectKey,
                    date: '',
                    project: [],
                }

                for (const planningItem of machineEntry.planning) {
                    const itemName = planningItem.description;
                    for (const planning of planningItem.machine.planning) {
                        if (planning.date === formattedDate) {
                            machine.project.push({
                                id: planningItem.id,
                                uuid: planning.uuid,
                                name: itemName,
                                company: planningItem.company,
                                time: planning.time,
                                number: planningItem.number,
                                persons: planning.persons,
                                priority: planning.priority,
                                progress: planning.progress,
                                templatePart: planningItem.templatePart,
                                planningId: planning.id,
                                note: planning.note,
                                machineName: planningItem.machine.name,
                                projectName: planningItem?.projectName,
                                amountProduced: planning.amountProduced,
                                totalHours: planningItem.totalHours,
                                amount: planningItem.amount,
                                quota: getProducedAmountLabelAndClass(planning.amountProduced, planningItem.amount, planning.time, planningItem.totalHours),
                            });
                        }
                    }
                }

                machines.push(machine);
                x++;
            }

            if (grid[y]['machines'] === undefined) {
                grid[y]['machines'] = machines;
            }

            y++;
        }

        setGridWidth(x);
        setColTemplate('minmax(250px,250px)' + ` minmax(min-content,1fr)`.repeat(x));
        setGrid(grid);
        setIsLoading(false);
    }

    async function loadAllMachines() {

        return await authAxios.get(`/calculations/resources/machines/all?sort[1]=position:asc`, {
            params: {
                populate: "*"
            }
        }).then(({data}) => {
            let machines = {};

            for (const machine of data) {
                if (selectedDepartments?.length > 0) {
                    if (selectedDepartments.includes(machine.department)) {
                        machines[machine.id] = machine
                    }
                } else {
                    machines[machine.id] = machine
                }
            }

            // Order machines
            let orderedMachines = [];
            let orderlessMachines = [];
            Object.keys(machines).forEach(function (key, index) {
                if (machines[key]?.position !== null) {
                    orderedMachines[machines[key]?.position] = machines[key];
                } else {
                    orderlessMachines.push(machines[key]);
                }
            });

            const allMachines = orderedMachines.concat(orderlessMachines);

            const lastPosition = Math.max(...allMachines.sort((a, b) => a.position - b.position).map((machine) => machine.position).filter(pos => pos)) + 1;

            allMachines.push({
                id: 'handwerk',
                name: 'Handwerk',
                position: lastPosition
            })

            setAllMachines(allMachines);
            return allMachines;
        }).catch((exception) => {
            console.error(exception);
        });
    }

    async function loadMachineData() {

        // Get all machines to use for machine data
        const allMachines = await loadAllMachines();
        let machineData = {};

        const projectData = await loadProjects(startDate, endDate);

        for (const machine of allMachines) {
            // Skip undefined keys
            if (machine === undefined || machine.position === null) continue;

            let projectKey = 1;
            // Loop project data to add to planning overview
            for (const project of projectData) {

                // Loop machines in project
                for (const projectMachine of project.machines) {

                    // If project machine matches current machine
                    if (machine.id === projectMachine.machineId) {

                        // Build planning date object
                        const dateObject = {
                            machine: projectMachine,
                            company: project?.company ?? false,
                            description: <span><b>[{project.number}]</b> {(project?.company ?? <i>Geen klant</i>)}</span>,
                            number: project.number,
                            templatePart: project.description,
                            id: project.id,
                            projectName: projectMachine.name,
                            totalHours: projectMachine.totalHours,
                            amount: project.amount,
                        }


                        // filter out planning items if we have an active filter
                        if (statusFilter.length > 0) {
                            let filterd = []
                            statusFilter.some((filter) => {
                                let part = []
                                if (filter.toLowerCase() === "niet begonnen") {
                                    part = projectMachine?.planning.filter((el) => el.progress === 0)

                                }
                                if (filter.toLowerCase() === "mee bezig") {
                                    part = projectMachine?.planning.filter((el) => (el.progress > 0 && el.progress < 100))

                                }
                                if (filter.toLowerCase() === "afgerond") {
                                    part = projectMachine?.planning.filter((el) => el.progress === 100)

                                }
                                filterd.push(...part)

                            })
                            projectMachine.planning = filterd
                        }

                        // If key does not exist create else push planning
                        if (machineData[machine.position] === undefined) {
                            machineData[machine.position] = {
                                planning: [dateObject],
                                name: machine.name,
                                templatePart: project.description,
                                projectKey: projectKey,
                            };
                        } else {
                            machineData[machine.position].planning.push(dateObject)
                        }
                    }
                }
                projectKey++;
            }
        }

        return machineData;
    }

    function getFirstDayOfWeek(d) {
        d = new Date(d);
        let day = d.getUTCDay(),
            diff = d.getUTCDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
        return new Date(d.setUTCDate(diff));
    }

    async function load() {
        let start = new Date(getFirstDayOfWeek(new Date()));
        let end = new Date(getLastDayOfWeek(new Date()));

        start = start.toISOString().split('T')[0];
        end = end.toISOString().split('T')[0]

        setStartDate(start);
        setEndDate(end);

        await loadMachineData(start, end);
        await loadDepartments();

        setIsLoading(false);

        setScrollToPos(true)
    }

    async function loadDepartments() {
        await authAxios.get(`/calculations/resources/machines/departments`, {
            params: {
                populate: "*"
            }
        }).then(({data}) => {

            let options = [];

            for (const department of data) {
                options.push({
                    value: department,
                    label: department
                });
            }

            setDepartments(options);

        }).catch((exception) => {
            console.error(exception);
        });
    }

    async function loadProjects(start, end) {
        start = new Date(start).toISOString().split('T')[0];
        end = new Date(end).toISOString().split('T')[0];

        return await authAxios.get(`/orders/planning?startDate=${start}&endDate=${end}`)
            .then(({data}) => {
                return data
            }).catch((exception) => {
                console.error(exception);
            });
    }

    function nextWeek() {
        let start = new Date(startDate + 'T00:00:00.000Z');
        start = new Date(start.setUTCDate(start.getUTCDate() + 7));

        let end = new Date(start);
        end.setUTCDate(start.getUTCDate() + 6);

        start = start.toISOString().split('T')[0];
        end = end.toISOString().split('T')[0]

        setStartDate(start);
        setEndDate(end);
    }

    function previousWeek() {
        const previousStartDate = new Date(startDate + 'T00:00:00.000Z');

        let nextEndDate = new Date(previousStartDate.setUTCDate(previousStartDate.getUTCDate() - 1));
        let nextStartDate = new Date(getFirstDayOfWeek(nextEndDate));

        nextStartDate = nextStartDate.toISOString().split('T')[0];
        nextEndDate = nextEndDate.toISOString().split('T')[0]

        setStartDate(nextStartDate);
        setEndDate(nextEndDate);
    }

    function onUpdateMachine(e) {
        e.preventDefault();

        const pendingToast = toast.loading('Planning aan het bijwerken...');

        const url = `/orders/${currentMachine.orderId}/planning/${currentMachine.uuid}`;
        const body = {}

        // If not all selected persons can operate the machine, ask for confirmation on whether to assign the persons
        if (popupFormData.operators && popupFormData.operators.some(person => !person.canOperate) && currentMachine.machineId !== 'handwerk') {
            if (window.confirm('Niet alle geselecteerde personen kunnen deze machine bedienen. Weet u zeker dat u deze personen wilt toewijzen?')) {
                addOperatorsToMachine(authAxios, popupFormData?.operators.filter(({canOperate}) => !canOperate), currentMachine.machineId, (operators) => {
                    setCurrentMachine((machine) => {
                        // machine.operators = operators;
                        return machine;
                    })
                });
            }
        }

        if (popupFormData.operators) {
            body.persons = popupFormData.operators.map(person => person.value);
        }

        if (popupFormData.priority) {
            body.priority = popupFormData.priority.value;
        }

        if (popupFormData.progress) {
            body.progress = popupFormData.progress.value;
        }

        if (popupFormData.time) {
            body.time = popupFormData.time;
        }

        if (popupFormData.note !== undefined) {
            body.note = popupFormData.note;
        }

        if (popupFormData.amount) {
            body.amountProduced = Number(popupFormData.amount);
        }

        authAxios.put(url, body)
            .then((response) => {
                toast.update(pendingToast, {
                    render: 'Planning bijgewerkt!',
                    type: 'success',
                    isLoading: false,
                    autoClose: 3000,
                });

                // void load();
                void generateGrid();

                // close the popup
                closePopup();
            })
            .catch((exception) => {
                console.error(exception);

                toast.update(pendingToast, {
                    render: 'Planning bijwerken mislukt!',
                    type: 'error',
                    isLoading: false,
                    autoClose: 3000,
                });
            });
    }

    const getInfoMessageGridTemplateColumns = () => {
        if (width > 350) return '30px 1fr 30px';
        if (width > 300) return '1fr 30px';
        return '1fr';
    }

    return (
        (width <= 1100 || height <= 300) ?
            <>
                <div
                    className="info__message"
                    style={{
                        gridTemplateColumns: getInfoMessageGridTemplateColumns()
                    }}
                >
                    {width > 350 && <Icon style={{fill: 'var(--primary)', height: '20px'}} name={'information'}/>}
                    Deze planning kan alleen worden bekeken vanaf desktop
                </div>
            </> :
        <Block
            name={'dagPlanning'}
            key={'dagPlanning'}
            title={width > 1000 ? 'Dag planning' : ''}
            headerStyle={{
                overflow: "visible",
                zIndex: 12
            }}
            headerRightSideChildren={
                <>
                    <Button onClick={() => setIsFilterPopup((prev) => !prev)}>
                        <Icon name='filter' />
                    </Button>

                    {isFilterPopup ? (
                        width > 900 && (
                            <Select
                                id='day-planning__status'
                                options={_PLANNING_PROGRESS_FILTERS}
                                placeholder='Filteren op Status'
                                isMulti
                                value={_PLANNING_PROGRESS_FILTERS.filter((el) => statusFilter.includes(el.value))}
                                onChange={(values) => {
                                    setStatusFilter(values.map((department) => department.value));
                                }}
                                styles={{
                                    menu: (provided) => ({
                                        ...provided,
                                        zIndex: 9999
                                    })
                                }}
                            />
                        )
                    ) : (
                        <></>
                    )}

                    {width > 600 ? (
                        <Select
                            key={'department'}
                            name='department'
                            id='day-planning__department'
                            placeholder='Kies een afdeling'
                            isMulti
                            options={departments}
                            value={getSelectedValue(selectedDepartments, departments)}
                            onChange={(values) => {
                                setSelectedDepartments(values.map((department) => department.value));
                            }}
                            styles={{
                                menu: (provided) => ({
                                    ...provided,
                                    zIndex: 9999
                                })
                            }}
                        />
                    ) : (
                        <></>
                    )}

                    <div key={'dailyHeaderWeekSelector'} className='daily-planning__date-selector'>
                        <div className={'daily-planning__date-button'} onClick={() => previousWeek('PREV')}>
                            <Icon name='chevron-left' className='planning__header__date__content__icon__button' />
                        </div>

                        <span className='daily-planning__date-text'>
                            <span>{new Date(startDate).toLocaleDateString('nl-NL', { month: 'short', day: 'numeric' })}</span>
                            <span>-</span>
                            <span>{new Date(endDate).toLocaleDateString('nl-NL', { month: 'short', day: 'numeric' })}</span>
                            <span>{new Date(endDate).toLocaleDateString('nl-NL', { year: 'numeric' })}</span>
                        </span>

                        <div className={'daily-planning__date-button'} onClick={() => nextWeek('NEXT')}>
                            <Icon name='chevron-right' className='planning__header__date__content__icon__button' />
                        </div>
                    </div>
                </>
            }
        >
            <SpinnerOverlay key={'spinner'} visible={isLoading}>

                {isLoading && grid.length === 0 && <img src={planningPlaceholder} alt={'planning-loading-placeholder'} style={{ width: '100%' }} />}
                    <div className='daily-planning__container' style={{ scrollBehavior: "smooth" }}>
                    <Grid gap={'0px'} colums={gridWidth} customColTemplate={colTemplate}>
                        <div className={'daily-planning__machine'}>
                            <b>Datum</b>
                        </div>

                        {grid[0]?.machines?.slice(0, gridWidth)?.map(machine =>
                            <div key={machine.machineId} className={'daily-planning__machine'}>
                                {machine.name}
                            </div>
                        )}

                        {grid.map((yAxis, yKey) => {
                            const isSunday = new Date(yAxis.date).getDay() === 0 || new Date(yAxis.date).getDay() === 6;

                            let scrollToIndex = false;
                            let today = new Date();
                            let AxisDate = new Date(yAxis?.date);
                            if (today?.getDate() === AxisDate?.getDate() && today?.getMonth() === AxisDate?.getMonth() && today?.getFullYear() === AxisDate?.getFullYear()) {
                                scrollToIndex = true;
                            }
                            return (
                                <Fragment key={`${yAxis}-${yKey}`}>

                                    <div key={`sunday-${yKey}`} className={`daily-planning__date ${isSunday ? 'sunday' : ''}`}>
                                        {new Date(yAxis.date).toLocaleDateString("nl-NL", {
                                            weekday: 'long',
                                            month: 'long',
                                            day: 'numeric'
                                        })}
                                        {scrollToIndex && <div id="scroll_location" style={{ height: "27px", position: "absolute", top: "-27px", pointerEvents: "none" }}>
                                        </div>}
                                    </div>
                                    <IF condition={yAxis.machines !== undefined}>
                                        {yAxis.machines.map((machine, machineKey) => (
                                            <div key={`machineContainer-${machineKey}`} className={`daily-planning__day ${isSunday ? 'sunday' : ''}`}>
                                                <IF condition={machine.project?.length !== 0}>
                                                    {machine.project
                                                        .sort((a, b) => a.priority === b.priority ? a.id === b.id ? a.planningId - b.planningId : a.id - b.id : a.priority - b.priority)
                                                        .map((project, projectKey) => {
                                                        return (
                                                            <>
                                                                <Grid
                                                                    gap={'0px'}
                                                                    className={`bg-${(project.number.match(/[1-9][0-9]*$/)?.[0] ?? 0) % 19 + 1}-two-tone daily-planning__project`}
                                                                    onClick={() => {
                                                                        setCurrentMachine({
                                                                            machineId: machine.machineId,
                                                                            orderId: project.id,
                                                                            name: machine.name,
                                                                            uuid: project.uuid,
                                                                            time: project.time,
                                                                            totalHours: project.totalHours,
                                                                            priority: project.priority,
                                                                            progress: project.progress,
                                                                            persons: project.persons.map(person => person.id),
                                                                            planningId: project.planningId,
                                                                            note: project.note,
                                                                            amount: project.amount,
                                                                            amountProduced: project.amountProduced
                                                                        });

                                                                        openPopup('draggable-click-popup');
                                                                    }}
                                                                >
                                                                    <Link
                                                                        to={User.hasRole(['productie medewerker', 'sales']) ? `/orders/${project?.id}` : `/planning/orders/${project?.id}`}
                                                                        key={projectKey}
                                                                        onClick={(e) => {
                                                                            e.stopPropagation();
                                                                        }}
                                                                    >
                                                                        <b>{project.name}</b>
                                                                    </Link>
                                                                    <div className={'daily-planning__project__content'}>
                                                                        <div>
                                                                            <span>
                                                                                {(project.templatePart || <i>Geen order omschrijving</i>)}&nbsp;({formatTime(project.time)})
                                                                            </span>
                                                                        </div>
                                                                        <div className={'daily-planning__middle-content'}>
                                                                            <span style={{ textAlign: "left" }}>
                                                                                Actie: {project.projectName}
                                                                            </span>
                                                                        </div>
                                                                        <div className={'daily-planning__bottom-content'}>
                                                                            <div>

                                                                                {/* Priority */}
                                                                                <div
                                                                                    className={'btn daily-planning__priority ' + getPriorityName(project.priority).en}
                                                                                    data-title={'Prioriteit: ' + capitalize(getPriorityName(project.priority).nl)}
                                                                                >
                                                                                    {capitalize(getPriorityName(project.priority).nl)}
                                                                                </div>

                                                                                {/* Status */}
                                                                                <div
                                                                                    className={'btn daily-planning__progress ' + (project.progress === 0 ? 'not-started' : project.progress === 100 ? 'completed' : 'in-progress')}
                                                                                    data-title={'Voortgang: ' + (project.progress === 0 ? 'Niet begonnen' : project.progress === 100 ? 'Afgerond' : 'Mee bezig')}
                                                                                >
                                                                                    <span>{project.progress === 0 ? 'Niet begonnen' : project.progress === 100 ? 'Afgerond' : 'Mee bezig'}</span>
                                                                                </div>

                                                                                {/* Quota */}
                                                                                <div
                                                                                    className={`btn daily-planning__quantity ${(project.quota?.class || '')}`}
                                                                                >
                                                                                    {(project.quota?.label || 'Niet ingevuld')}
                                                                                </div>

                                                                                {/*  Note */}
                                                                                {
                                                                                    project.note && project.note.length > 0 && (
                                                                                        <div
                                                                                            className={'btn daily-planning__icon'}
                                                                                            data-title={project.note}
                                                                                        >
                                                                                            <Icon name={'fa-regular message-lines'} />
                                                                                        </div>
                                                                                    )
                                                                                }
                                                                            </div>
                                                                            <div className={'planning__person__avatar__container'}>
                                                                                {
                                                                                    project.persons
                                                                                        .filter(person => person.firstName && person.lastName)
                                                                                        .map((person) => {
                                                                                            const firstNameInitial = person.firstName.charAt(0).toUpperCase();
                                                                                            let lastNameInitial = "";

                                                                                            // get the lastname parts
                                                                                            let lastnameParts = person.lastName?.split(' ');

                                                                                            // set the lastNameInitial if we have something to work with
                                                                                            if (lastnameParts !== undefined && lastnameParts.length > 0) {
                                                                                                lastNameInitial = lastnameParts?.[lastnameParts.length - 1]?.charAt(0)?.toUpperCase();
                                                                                            }


                                                                                            return (
                                                                                                <span
                                                                                                    data-title={`${person.firstName} ${person.lastName}`}
                                                                                                    className={`btn planning__person__avatar person__avatar-bg-${(project.number.match(/[1-9][0-9]*$/)?.[0] ?? 0) % 19 + 1}`}
                                                                                                >
                                                                                                    {firstNameInitial}{lastNameInitial}
                                                                                                </span>
                                                                                            )
                                                                                        })
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Grid>
                                                            </>
                                                        )
                                                    })}

                                                </IF>
                                                <IF condition={machine.project?.length > 1}>
                                                    <HasRole roles={['admin', 'management', 'sales', 'productie manager']}>
                                                        <div style={{
                                                            display:'flex',
                                                            justifyContent:'right'
                                                        }}>
                                                            <Button
                                                                style={{
                                                                    width: '35px',
                                                                    padding:' 10px 10px 10px 5px',
                                                                    borderRadius: '20px'
                                                                }}
                                                                onClick={(e) => { openPopup("addUsersInBulk"); setBulkUsersContext(machine) }}
                                                            ><Icon name='users-medical' /></Button>
                                                        </div>
                                                    </HasRole>
                                                </IF>

                                            </div>
                                        ))}

                                    </IF>
                                </Fragment>
                            )

                        })}
                    </Grid>
                </div>
            </SpinnerOverlay>

            <AddUsersToMachinesPopup
                machine={bulkUsersContext}
                afterUpdate={generateGrid}
            />
            <UpdateMachinePlanningPopup
                onSubmit={onUpdateMachine}
                popupCurrentMachine={currentMachine}
                popupFormData={popupFormData}
                setPopupFormData={setPopupFormData}
            />
        </Block>
    )
}

export function getPriorityName(value) {
    switch(value) {
        case 1:
            return {en: 'highest', nl: 'hoogst'};
        case 2:
        case 3:
            return {en: 'high', nl: 'hoog'};
        case 4:
        case 5:
        case 6:
            return {en: 'medium', nl: 'normaal'};
        case 7:
        case 8:
            return {en: 'low', nl: 'laag'};
        case 9:
        default:
            return {en: 'lowest', nl: 'laagst'};
    }
}

export function getProducedAmountLabelAndClass(amountProduced, amount, time, totalTime) {
    const quota = Math.round(amount * (time / totalTime));

    if (!amountProduced) {
        return { label: 'Niet ingevuld', class: '' };
    }

    if (amountProduced < quota) {
        return { label: 'Aantal niet gehaald', class: 'not-completed' };
    }

    if (amountProduced >= quota) {
        return { label: 'Aantal gehaald', class: 'completed' };
    }
}
