import Table from "UI/App/Components/Table/Table";
import { useLoaderData } from "react-router-dom";
import ActualCostingCalculator from "UI/App/Partials/Content/CRM/Orders/ActualCosting/ActualCostingCalculator";
import { useEffect, useState } from "react";

export default function MaterialCostTable() {
    const { order } = useLoaderData();
    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        const calculator = new ActualCostingCalculator(order);

        calculator.getMaterialCostData()
            .then(({ tableData }) => setTableData(tableData));
    }, [order])

    return (
        <Table
            className='materialCostTable'
            headers={[
                <strong>Materiaal</strong>,
                "Berekend (calculatie)",
                "Daadwerkelijk (gerealiseerd)",
                ""
            ]}
            data={tableData}
        />
    )
}