import axios, {AxiosInstance} from 'axios';

const publicFetch = axios.create({
    baseURL: process.env.REACT_APP_API_URL
});

const getAuthAxios = () => {
    const axiosInstance = axios.create({
        baseURL: process.env.REACT_APP_API_URL + '/v1'
    });

    const token = localStorage.getItem('token');

    axiosInstance.interceptors.request.use(
        (config: any) => {
            const { origin } = new URL(config.baseURL);
            const allowedOrigins = [process.env.REACT_APP_BACKEND_URL];

            if (allowedOrigins.includes(origin)) {
                config.headers.Authorization = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    return axiosInstance;
}

/**
 * Download a file.
 *
 * @param {AxiosInstance} authAxios
 * @param {number} id
 * @param {string} mime deprecated: mime is set from response header. todo: change into `base64` or `dataURl`
 * @param {('large' | 'medium' | 'small' | 'thumbnail')} [format] `undefined` for full size.
 * @return {Promise<string>}
 */
const downloadFile = async (
    authAxios: AxiosInstance,
    id: number,
    mime?: string,
    format?: 'large' | 'medium' | 'small' | 'thumbnail'
): Promise<string> => {
    return await authAxios
        .get(`../download/files/${id}${format ? `?format=${format}` : ''}`, {
            responseType: 'arraybuffer'
        })
        .then(({data, headers}) => {
            if (mime === 'base64') return _arrayBufferToBase64(data);

            // if no mime as parameter: use mime returned as response header
            if (!mime) mime = headers['content-type'];
            
            // type needs to be set to properly open blobs in tabs
            let blobOptions = { type: mime };

            return window.URL.createObjectURL(new Blob([data], blobOptions));
        })
        .catch((error) => {
            console.error(error);
            return Promise.reject('Download mislukt!');
        });
};

function _arrayBufferToBase64( buffer: Uint8Array ) {
    let binary = '';
    let bytes = new Uint8Array( buffer );
    let len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode( bytes[ i ] );
    }
    return window.btoa( binary );
}

async function base64ToBlobURL(base64: string) {
    const blob = await fetch(base64)
        .then(res => res.blob())

    return window.URL.createObjectURL(blob);
}

export {
    base64ToBlobURL,
    downloadFile,
    getAuthAxios,
    publicFetch,
};
