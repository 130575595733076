import { toast } from "react-toastify";
import MaterialUnitMutator from "UI/App/Components/Material/MaterialUnitMutator";
import { formatNumberValue } from "App/Util/format";
import isObject from "lodash/isObject";
import { stringify } from "qs";
import React from "react";

export async function getMaterialsForOrder(order, calculation, authAxios) {
    const materialArray = [];

    // Guard against unset amounts
    if (!order?.chosenAmount && order?.chosenAmount !== 0) {

        let autoAmount = Object.keys(calculation?.data?.values[Object.keys(calculation?.data?.values)[0]])[0];
        // if we werent able to get an amount from know data tell the user that
        if (!autoAmount && autoAmount !== 0) {
            toast.error(<>
                Order heeft geen gekozen aantal!
            </>, {autoClose: 15000})
        }
        // if we ARE able to get an amount from know data, notify the user we did so
        else {
            order.chosenAmount = autoAmount
            toast.warning(<>
                <b>LET OP!</b><br/>
                Het ziet er naar uit dat de order geen gekozen aantal heeft.<br/>
                <small>Automatisch <b>{`${autoAmount} stuks`}</b> gekozen</small>
            </>, {autoClose: 15000})
        }

    }

    const materialReturnWidgetTabs = Object.entries((calculation?.data?.templateData?.data ?? {}))
        // Get only the tabs
        .filter(([key, value]) => /\d+-\d+/.test(key))
        // Get only the material return widgets
        .map(([key, value]) => {
            return [
                key,
                value.widgets.filter(
                    (widget) =>
                        widget.data.type === 'ReturnWidget' &&
                        (widget.data.title.startsWith('Materiaal.'))
                ),
                value.widgets.find(
                    (widget) =>
                        widget.data.type === 'ReturnWidget' &&
                        (widget.data.title.endsWith('Te verwerken eenheden'))
                )
            ];
        })
        // Filter out tabs without material return widgets
        .filter(([key, widgets]) => widgets.length > 0);

    let calculationTabsValues = null;
    if (calculation.data.values !== undefined) {
        calculationTabsValues = Object.fromEntries(
            Object.entries(calculation.data.values)
                // Get only the calculation tabs
                .filter(([key, value]) => /\d+-\d+/.test(key))
                // Get the calculation result for the chosen amount
                .map(([key, value]) => [key, value?.[order?.chosenAmount]])
        );
    }

    /**
     * Object containing all material widgets per material
     * @type {{[tabIndex: string]: {[materialWidgetId: string]: {}}}}
     */
    let materialWidgetsPerMaterial = {};

    // Loop through the tabs
    for (const [tabIndex, materialReturnWidgets, processedUnit] of materialReturnWidgetTabs) {
        // Skip tabs if it doesn't have a calculation result
        // In this case the tab was optional and not enabled in the calculation
        if (typeof calculationTabsValues[tabIndex] === 'undefined') continue;

        // Create an empty object for this tab
        materialWidgetsPerMaterial[tabIndex] = {};

        // Loop through the return widgets
        for (const materialReturnWidget of materialReturnWidgets) {

            // Split the return widget title
            // The title is in the format: Materiaal.[materialWidgetId].[part]
            // Example: Materiaal.1-widget-1.aantal
            // The first part (group) is in this case always 'Materiaal' since we're only dealing with material returns
            // The second part (materialWidgetId) is either the string value of the material we're dealing with or the id of the material widget
            // The third part (part) is the type of return value we're dealing with
            // Example: aantal, prijs, totaalprijs, verkoopprijs, etc.
            const [_, materialWidgetId, part] = materialReturnWidget.data.title.split('.');

            // Skip the usercharge widget since it's not necessary for the operators to see
            if (['usercharge', 'inkt'].includes(materialWidgetId.toLowerCase())) continue;
            // Skip the totaalprijs and verkoopprijs since it's not necessary for the operators to see
            if (['totaalprijs', 'verkoopprijs'].includes(part?.toLowerCase())) continue;

            // Get the material widget value
            const returnWidgetValue = calculationTabsValues[tabIndex]?.find(
                (widget) => widget.id === materialReturnWidget.id
            );

            // The name of the material
            let materialName;
            let material = null;

            // If the materialWidgetId starts with 'widget', it points to another material widget
            // We should get the value of that widget and get the internalSKU from that for the material name
            // Otherwise the materialWidgetId is the name of the material
            // Normally, if the materialWidgetId is the name of the material, we're dealing with materials that are not in the database
            // These materials are always used in the machine, and are always the same type of material E.G. usercharge or ink for printers
            // In the case of usercharge, it's not even a material, but a cost that is added on by the manufacturer
            if (materialWidgetId.includes('widget')) {
                const materialWidgetValue = JSON.parse(
                    calculationTabsValues[tabIndex]?.find(
                        (widget) => widget.id === materialWidgetId
                    )?.value ?? '{}'
                );
                material = materialWidgetValue.data?.value?.result;
                materialName = material?.calculationDescription;
            } else {
                materialName = materialWidgetId;
            }

            // Save the widget value amount and unit in the materialWidgetsPerMaterial object
            // Filter out undefined material names. Because if a material is not defined, how can we know what material to get?
            if (materialName !== undefined) {
                materialWidgetsPerMaterial[tabIndex][materialName] = {
                    quantity: returnWidgetValue.value ?? 0,
                    unit: returnWidgetValue.unit,
                    category: material?.category ?? materialName,
                    material: material ?? {},
                    machineProcessedQuantity: processedUnit?.data?.value?.result,
                    machineProcessedUnit: processedUnit?.data?.unit,
                };
            }
        }
    }


    // Now we have all the material widgets per material
    // We can now loop through the materials and create the content
    for (const [machineTabId, materials] of Object.entries(materialWidgetsPerMaterial)) {
        // First we need to get the machine name
        // The machine name is the title of the tab
        const machineName = order.calculation.data.templateData.data[machineTabId].name;

        // Add the material names to the content
        for (const [_, material] of Object.entries(materials).sort(
            (a, b) => a[0].length - b[0].length
        )) {
            let first = material?.material?.quotationNameNL
            let fallback = material?.material?.quotationNameEN
            let lastly = material?.material?.internalSKU

            const correctQuantity = await (new MaterialUnitMutator()).quantityMerchant(material.quantity, material?.material, true)
            const correctUnit = await (new MaterialUnitMutator()).obtainUnit(material?.material)

            let name = first !== undefined ? first : (fallback !== undefined ? fallback : lastly)

            materialArray.push({
                name: name,
                unit: correctUnit,
                unitForStorage: material.unit,
                machine: machineName,
                machineTabId: machineTabId,
                material: material.material,
                category: material.category,
                quantity: Math.round(correctQuantity),
                priceQuantity: material.quantity,
                salePrice: material.material.salePrice / material.material.countPerUnit,
                formattedValue: formatNumberValue(material.quantity, material.unit)
            });
        }
    }

    for (const additionalMaterial of order?.additionalMaterials ?? []) {
        const material = additionalMaterial.material;

        // Check if material is object before continueing
        if (!isObject(material)) continue;

        let first = material?.quotationNameNL
        let fallback = material?.quotationNameEN
        let lastly = material?.internalSKU

        let name = first !== undefined ? first : (fallback !== undefined ? fallback : lastly)
        const correctQuantity = await (new MaterialUnitMutator()).quantityMerchant(additionalMaterial.quantity, material, true)
        const correctUnit = await (new MaterialUnitMutator()).obtainUnit(material)

        materialArray.push({
            name: name,
            unit: correctUnit,
            unitForStorage: material.unit,
            machine: null,
            material: material,
            category: material?.category,
            quantity: Math.round(correctQuantity),
            priceQuantity: additionalMaterial.quantity,
            salePrice: material.salePrice,
            formattedValue: formatNumberValue(additionalMaterial.quantity, material.unit)
        });
    }

    // Check if materials are present else skip order
    if (materialArray.length === 0) {
        return;
    }

    const query = stringify({
        materials: materialArray.map((material) => ({
            id: material.material.id,
            unit: material.material.unit,
        }))
    }, {
        encodeValuesOnly: true
    });

    const materialStock = await authAxios.get(`/logistics/materials/stock/find?${query}`)
        .then(({data}) => {
            return data;
        })
        .catch((exception) => {
            console.error(exception);
            toast.error('Voorraad kon niet worden opgehaald');
            return [];
        });

    const filteredMaterialArray = materialArray.map((material) => ({
        material: material,
        stock: materialStock.find((stock) => stock.material.id === material.material?.id),
    }));

    // Add preview names to stock
    for (const material of filteredMaterialArray) {
        // If material has stock add preview name
        if (material.stock !== undefined) {
            material.stock.preview = {
                name: await (new MaterialUnitMutator()).obtainUnit(material.material),
                available: await (new MaterialUnitMutator()).quantityMerchant(material.stock.available, material.material.material, true, true),
                reserved: await (new MaterialUnitMutator()).quantityMerchant(order?.stockData?.[material.material.material.id]?.reserved, material.material.material, true, true),
                writtenOff: await (new MaterialUnitMutator()).quantityMerchant(order?.stockData?.[material.material.material.id]?.writtenOff, material.material.material, true, true)
            }

            material.stock.raw = order?.stockData?.[material.material.material.id];
        }
    }

    return filteredMaterialArray;
}