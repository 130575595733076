import {useContext, useEffect, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import {FetchContext} from "App/Strapi/FetchContext";
import Table from "UI/App/Components/Table/Table";
import {toast} from "react-toastify";
import Block from "../../../../Components/Block/Block";
import Grid from "../../../../Components/Grid/Grid";
import qs from "qs";
import HasRole from "../../../../Components/Auth/HasRole";
import IF from 'UI/App/Components/Conditional/IF';

export default function OrderConfirmation() {
    const [contactPerson, setContactPerson] = useState(null);
    const [company, setCompany] = useState(null);
    const [quote, setQuote] = useState(null);
    const [calculations, setCalculations] = useState(null);
    const [order, setOrder] = useState(null);
    const [project, setProject] = useState(null);
    const [orders, setOrders] = useState(null);
    const fetchContext = useContext(FetchContext)
    const params = useParams();

    const navigate = useNavigate();

    const [base64, setBase64] = useState(null);

    // get the table data using the useEffect hook
    useEffect(() => {
        const query = qs.stringify({
            populate: {
                quote: {
                    populate: {
                        company: {
                            fields: [
                                'name',
                            ],
                        },
                        contactPerson: {
                            fields: [
                                'firstName',
                                'prefixToSurname',
                                'surname',
                            ]
                        },
                        calculations: {
                            fields: [
                                'number',
                                'owner'
                            ]
                        }
                    }
                },
                project:true
            }
        });

        fetchContext.authAxios.get(`/quotes/confirmations/${params.confirmationId}?${query}`).then(({data}) => {
            setQuote(data.data.quote);
            setBase64(data.data.pdfBase64);
            setCompany(data.data.quote.company);
            setContactPerson(data.data.quote.contactPerson);
            setProject(data.data.project);


            let tableData = [];

            for (const calculation of data.data.quote.calculations) {
                tableData.push({
                    attributes: {
                        onClick: () => {
                            navigate(`/calculations/${calculation.id}/view`)
                        }
                    },
                    data: [
                        <div className='owner-indicator'>
                            <IF condition={typeof calculation?.owner === 'string'}>
                                <span className={`circle circle-${calculation?.owner?.toLowerCase()}`}></span>
                            </IF>
                        </div>,
                        calculation.number
                    ]
                })
            }

            setCalculations(tableData);
            getOrder();

        }).catch((exception) => {
            console.log(exception);
            toast.error(`Er ging iets mis met het ophalen van order bevestiging.`);
        });

        // Get orders
        fetchContext.authAxios.get(`/orders?populate=quoteConfirmation&filters[quoteConfirmation][id][$eq]=${params.confirmationId}`).then(({data}) => {
            setOrders(data.data)
        }).catch((exception) => {
            console.log(exception);
            toast.error(`Er ging iets mis met het ophalen van order bevestiging.`);
        });
    }, []);

    const getOrder = () => {
        fetchContext.authAxios.get(`/orders/?populate=quoteConfirmation&filters[quoteConfirmation][id][$eq]=${params.confirmationId}`).then(({data}) => {
            setOrder(data.data);
        }).catch((exception) => {
            console.log(exception);
            toast.error(`Er ging iets mis met het ophalen van order bevestiging.`);
        });
    }

    const updateStatus = (e, confirmationId) => {
        fetchContext.authAxios.put(`/quotes/confirmations/${confirmationId}`, {
            "data": {
                "status": e.target.value
            }
        }).then(({data}) => {
            toast.success(`Offerte: ${data.data.number} status omgezet naar ${e.target.value}`);
        }).catch((exception) => {
            console.log(exception);
            toast.error(`Er ging iets mis met het bewerken van offerte.`);
        });
    }


    return (
        <Grid 
            columns={{
                1000: 1,
                default: 2
            }}
            customColTemplate={{
                1000: '1fr',
                default: '2fr 5fr'
            }}
        >
            <Block name={'confirmationInfo'} title={"Order informatie"}>
                <Grid columns={1}>
                    <HasRole roles={['admin']}>
                        {project !== null ? <>
                            <span>Project: {project.number}</span>
                        </> : <></>}
                    </HasRole>
                    <span>
                        Bedrijf: {company !== null ? <Link target={'_blank'} to={`/crm/companies/${company.id}`}>{company?.name}</Link> : ''}
                    </span>
                    <span>
                        Contactpersoon: {contactPerson !== null ? <Link target={'_blank'} to={`/crm/companies/${company.id}/contactpersons/${contactPerson.id}`}>{contactPerson?.firstName} {contactPerson?.prefixToSurname} {contactPerson?.surname}</Link> : ''}
                    </span>
                    <span>
                        Offerte: {quote !== null ? <Link target={'_blank'} to={`/quotes/${quote?.id}`}>{quote.number}</Link> : <i style={{color: 'gray'}}>Geen</i>}
                    </span>
                    <span>
                        <dd>Order{orders !== null ? orders.length > 1 ? 's' : '': ''}:</dd>
                        {orders !== null ? Object.entries(orders).map(([key, order]) => {
                            return <dl key={key}>
                                <Link target={'_blank'} to={`/orders/${order.id}`}>{order.number}</Link>
                            </dl>
                        }) : <></>}
                    </span>
                    <span>
                        Calculatie{calculations !== null ?? calculations.length > 1 ? 's' : ''}:
                        <Table
                            headers={['', 'Nummer']}
                            structure={['40px', '100%']}
                            data={calculations}
                            hover
                            border='row'
                            overflowText={true}
                            style={{
                                marginTop: '5px'
                            }}
                        />
                    </span>
                </Grid>
            </Block>
            <Block
                title={'Bevestiging'}
                name={'confirmationPDF'}>
                <Grid columns={1}>
                    <embed
                        style={{height: '80vh', width: '100%'}}
                        src={base64 !== null ? `data:application/pdf;base64,${base64}` : ''}
                        type="application/pdf"
                    />
                </Grid>
            </Block>
        </Grid>
    )
}
