import {useSubmit as useRouterDomSubmit} from 'react-router-dom';
import objSet from "lodash/set";
import { objectWalk } from "App/Util/object";

export default function useSubmit(submitOptions = {
    trimEmptyStrings: true
}) {
    const submit = useRouterDomSubmit();

    function trimData(data) {
        const trimmedData = {};

        // Clear out any empty string values
        objectWalk(data, (value, keys) => {
            if (value !== '') {
                objSet(trimmedData, keys.join('.'), value);
            }
        });

        return trimmedData;
    }

    return (data, method = 'POST', options = {}) => {

        // Trim empty strings from the data if enabled in the options
        if (submitOptions.trimEmptyStrings) {
            data = trimData(data);
        }

        return submit(data, {
            method: method,
            encType: 'application/json',
            ...options
        });
    }
}