import { useCallback, useContext, useEffect, useRef } from "react";
import User from "App/Strapi/User";
import { toast } from "react-toastify";
import Icon from "UI/App/Components/Icon/Icon";
import TimerRepository from "UI/App/Partials/Content/Dashboard/Blocks/Timer/TimerRepository";
import { FetchContext } from "App/Strapi/FetchContext";

export default function ClockInNotificationPopup() {

    const { authAxios } = useContext(FetchContext);

    const user = useRef(null);
    if (user.current === null) {
        user.current = User.get();
    }

    /**
     * Clock in the user
     * @type {function(): Promise<any>}
     */
    const clockIn = useCallback(async () => {
        const tr = new TimerRepository(authAxios);
        return tr.clockIn();
    }, [authAxios]);

    /**
     * Fetch the current running timer if there is one
     * @type {function(): Promise<any>}
     */
    const fetchTimer = useCallback(async () => {
        const tr = new TimerRepository(authAxios);
        return await tr.current();
    }, [authAxios]);

    useEffect(() => {
        if (!User.hasRole(["Productie medewerker"])) return;

        const ToastMSG = ({ closeToast }) => (
            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '100%' }}>
                <span style={{ color: 'black' }}><strong>Let op:</strong> Je bent nog niet ingeklokt!</span>
                <button className='btn--error' style={{ backgroundColor: 'var(--toastify-color-error)' }} onClick={() => {
                    clockIn()
                        .then(closeToast)
                        .finally(() => {
                            const event = new Event('timer::clock-in');
                            document.dispatchEvent(event);
                        })
                }}>Inklokken <Icon name='play' /></button>
            </div>
        );

        const clockInToast = fetchTimer()
            .then(({ data }) => {
                if (!data.id) {
                    return toast(ToastMSG, {
                        autoClose: false,
                        closeOnClick: false,
                        type: 'error',
                        style: {
                            borderLeft: 'solid var(--toastify-color-error) 5px'
                        },
                        icon: false
                    });
                }

                return false;
            });

        return () => {
            if (clockInToast) {
                toast.dismiss(clockInToast);
            }
        }
    }, [fetchTimer])

    return null;
}