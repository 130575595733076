import { useState } from "react";

export default function useDisclosure(defaultValue = true) {
    const [value, setValue] = useState(defaultValue)

    const toggle = () => {
        setValue(prev => !prev);
    }

    const open = () => {
        setValue(true);
    }

    const close = () => {
        setValue(false);
    }

    const set = (value) => {
        setValue(Boolean(value));
    }

    return {
        value,
        toggle,
        set,
        open,
        close
    }
}