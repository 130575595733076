import { isRouteErrorResponse, useRouteError } from "react-router-dom";

export default function ErrorHandler() {
    const error = useRouteError();

    if (isRouteErrorResponse(error)) {
        return (
            <div style={{
                display: 'flex',
                width: '100%',
                height: '100%',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <div style={{
                    padding: '10px 20px',
                    color: 'var(--message-red)'
                }}>
                    <span>{error.data} ({error.status})</span>
                </div>
            </div>
        )
    }

    throw error;
}