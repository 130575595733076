import {toast} from "react-toastify";
import {FetchContext} from 'App/Strapi/FetchContext';
import {useContext, useEffect, useState} from "react";
import {SpinnerOverlay} from "UI/App/Components/Spinner";
import Grid from "UI/App/Components/Grid/Grid";
import ShipmentCalculator from "UI/App/Partials/Content/Shipment/ShipmentCalculator";
import {countryToAlpha2} from "country-to-iso";

export default function LetMeShip({companyId,deliveryContactPersonId,deliveryBranchId, orderValue,orderId,callBack,deliveryAddress}) {
    const {authAxios} = useContext(FetchContext);
    const [shippingOptions, setShippingOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [pickupInfo,setPickupInfo] = useState({ // Adres Prretore
        "address": {
            "street": "",
            "houseNo": "",
            "zip": "",
            "city": "",
            // "stateCode": "string", // Only available for USA
            "countryCode": ""
        },
        "company": "",
        "person": {
            "lastname": "",
            "title": ""
        },
        "email": "",
        "phone": {
            "phoneNumber": "",
            "phoneNumberPrefix": ""
        }
    });

    const [deliveryInfo,setDeliveryInfo] = useState({ // Adress info delivery
        "address": {
            "street": "",
            "houseNo": "",
            "zip": "",
            "city": "",
            "countryCode": ""
        },
        "company": "",
        "email": "",
        "person": {
            "lastname": "",
            "title": ""
        },
        "phone": {
            "phoneNumber": "",
            "phoneNumberPrefix": ""
        }
    });

    useEffect(() => {
        if (companyId !== undefined) {
            authAxios.get(`/crm/companies/${companyId}`, {
                params: {
                    populate: {
                        address: true,
                        telephone: true
                    }
                }
            }).then((data) => {
                let changeableDeliveryInfo = {...deliveryInfo};

                changeableDeliveryInfo.address.street = data.data.data.address.addressLine1;
                changeableDeliveryInfo.address.zip = data.data.data.address.postalCode;
                changeableDeliveryInfo.address.city = data.data.data.address.city;
                changeableDeliveryInfo.address.countryCode = countryToAlpha2(data.data.data.address.country);
                changeableDeliveryInfo.company = data.data.data.name;
                changeableDeliveryInfo.phone.phoneNumber = data.data.data?.telephone?.telephoneNumber
                changeableDeliveryInfo.phone.phoneNumberPrefix = data.data.data?.telephone?.dialingCode
                changeableDeliveryInfo.email = data.data.data.generalEmail

                setDeliveryInfo(changeableDeliveryInfo);

                setIsLoading(false);
            }).catch((exception) => {
                console.error(exception);
            });
        }
    }, [companyId]);

    return (
        <SpinnerOverlay visible={isLoading}>
            <ShipmentCalculator deliveryContactPersonId={deliveryContactPersonId} callBack={callBack} deliveryCompanyId={companyId} deliveryBranchId={deliveryBranchId} deliveryAddressId={deliveryAddress} orderId={orderId} deliveryInfo={deliveryInfo} pickupInfo={pickupInfo} goodsValue={orderValue?.price}/>
        </SpinnerOverlay>
    )
}
