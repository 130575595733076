import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import isEmpty from 'lodash/isEmpty';
import { closePopup } from 'UI/App/Components/Popup/Popup';
import Content from "./Partials/Content/Content";
import Header from "./Partials/Header/Header";
import Navigation from "./Partials/Navigation/Navigation";
import swDev from '../../swDev';
import { setCssVariableValue, setDevTheme } from "App/Util/styling";
import { useSearchParams } from 'react-router-dom';
import RunningOrderTimers from "UI/App/Partials/Content/CRM/Orders/OrderTimer/RunningOrderTimers";
import {GoogleTranslateWidget} from "App/Util/translate/translate";
import ClockInNotificationPopup from "UI/App/Partials/Content/Dashboard/Blocks/Timer/ClockInNotificationPopup";


export default function AppShell() {
    const locationParams = useLocation();

    // get the page title
    const title = GetPageTitleByPath(locationParams);
    const [searchParams, setSearchParams] = useSearchParams();
    const [zoom, setZoom] = useState(1);

    // function to check and set zoom
    const setContentZoomOnUrl = () => {
        if (isNaN(searchParams?.get("zoom")?.replace(',', '.'))) return;
        let zoom = Number(searchParams?.get("zoom")?.replace(',', '.')) ?? 1
        setZoom(zoom)
        setCssVariableValue("--zoom", zoom)
    };

    // Registers serviceworker
    useEffect(() => {
        setDevTheme();

        const eventPollingInterval = swDev();

        return () => {
            clearInterval(eventPollingInterval);
        };
    }, []);

    // check zoom only if params changed
    useEffect(() => {
        setContentZoomOnUrl();
    }, [searchParams]);

    return (
        <>
            <Navigation />
            <GoogleTranslateWidget />
            <main>
                <Header title={title} />
                <Content>
                    <div className={'zoom-container'} style={{ zoom: zoom, height: '100%' }}>
                        <Outlet />
                    </div>
                </Content>
            </main>

            {/* popup dark background overlay */}
            <div className='emily-overlay' id='emily-overlay' onClick={closePopup}></div>
            <div className='emily-overlay' id='emily-overlay__no-click'></div>

            {/* Toastify div */}
            <ToastContainer
                style={{
                    marginTop: 'var(--header-height)'
                }}
                position='top-right'
                theme='light'
                toastClassName='emily-toast'
                closeOnClick={true}
            />

            <RunningOrderTimers />
            <ClockInNotificationPopup />
        </>
    )
}
export function GetPageTitleByPath(location, ignoreRouteSpecificTitle = false) {
    // create a dictionary for translating the url to dutch
    const routeNameDict = {
        '': 'Home',
        crm: 'CRM',
        customers: 'Klanten',
        prospects: 'Prospects',
        leads: 'Leads',
        suppliers: 'Leveranciers',
        archive: 'Archief',
        companies: 'Bedrijf',
        contactpersons: 'Contactpersoon',
        'responsible-people': 'Verantwoordelijke(n)',
        'packing-slip': 'Pakbon ',
        'packing-slips': 'Pakbonnen',
        calculations: 'Calculaties',
        calculation: 'Calculatie',
        checklists: 'Checklists',
        checklist: 'Checklist',
        machines: 'Machine',
        widgets: 'Widgets',
        templates: 'Templates',
        template: 'Template',
        catalog: 'Catalogus',
        materials: 'Materiaal',
        prices: 'Prijzen',
        users: 'Gebruikers',
        user: 'Gebruiker',
        branch: 'Vestiging',
        purchase: {
            translation: 'Inkoop',
            quotes: 'Inkoopoffertes',
            orders: 'Inkoopbonnen'
        },
        stock: 'Voorraad',
        quotes: 'Offertes',
        quote: 'Offerte',
        orders: 'Orders',
        confirmations: 'Bevestigingen',
        confirmation: 'Bevestiging',
        order: 'Order',
        log: 'Log',
        config: 'Configuratie',
        roles: 'Rollen',
        account: 'Account',
        update: 'aanpassen',
        edit: 'aanpassen',
        create: 'toevoegen',
        delete: 'verwijderen',
        settings: 'instellingen',
        view: 'bekijken',
        copy: 'kopiëren',
        revision: 'Revisie',
        recalculate: 'Herberekenen',
        hjmg: 'Calculatie',
        'actual-costing': 'Nacalculatie'
    }

    // create a dictionary for exceptions
    const routeExceptions = {
        "Calculaties instellingen": "Calculatie instellingen",
    }

    // get the pathname and split it on the slash
    const routePaths = location.pathname.toLowerCase().split('/').filter((t) => t !== '');
    // create a new variable for setting the slice length
    let sliceLength;

    if (!ignoreRouteSpecificTitle) {
        let routeNameObject;
        for (const routePath of routePaths) {
            if (routeNameObject && routeNameObject?.[routePath]) {
                if (typeof routeNameObject?.[routePath] === 'string') {
                    return routeNameObject?.[routePath];
                } else if (typeof routeNameObject?.[routePath] === 'object') {
                    routeNameObject = routeNameObject?.[routePath];
                }
            }

            if (routeNameDict?.[routePath] && typeof routeNameDict?.[routePath] === 'object') {
                routeNameObject = routeNameDict?.[routePath];
            }
        }
    }

    // check if the path contains an id and CRUD action after it at the end.
    // if so, get the last 3 values (companies/1/update) for instance.
    // if not, check if the path contains a route and an id at the end.
    // if so, get the last 2 values (companies/1) for instance.
    // if neither of these apply, get the last value (/crm or /admin or /account) for instance
    if (location.pathname.match(/[0-9]+\/(?:create|update|delete|settings|edit|view|copy)\/?$/) || location.pathname.match(/[\w+-]+\/(?:create|update|delete|settings|edit|view|copy)\/[0-9]+\/?$/)) {
        sliceLength = -3;
    } else if (location.pathname.match(/[\w+-]+\/(?:[0-9]+|(?:create|update|delete|settings|edit|view|copy))\/?$/)) {
        sliceLength = -2;
    } else {
        sliceLength = -1;
    }


    const slicedRoutePaths = routePaths.slice(sliceLength);
    // whether the path contains a create, read, update or delete action
    const isCRUDPath = (slicedRoutePaths.includes('update') || slicedRoutePaths.includes('edit') || slicedRoutePaths.includes('create') || slicedRoutePaths.includes('view') || slicedRoutePaths.includes('delete') || slicedRoutePaths.includes('settings') || slicedRoutePaths.includes('copy') || /\w+\/\d+$/.test(location.pathname));
    // create an empty array for the route name
    let routeName = [];
    // loop through each part of the path
    for (let path of slicedRoutePaths) {
        // skip any part that is either empty, is a number (for id's) or doesn't exist in the dictionary
        if (isEmpty(path) || !isNaN(parseInt(path)) || !routeNameDict?.[path])
            continue;

        path = isCRUDPath && path.endsWith('s') ? path.slice(0, -1) : path;

        const translation = routeNameDict?.[path] ?? routeNameDict?.[path + 's'] ?? '';

        // append the translation of the path to the route name
        routeName.push(typeof translation === 'object' ? translation.translation : translation);
    }

    routeName = routeName.join(' ').replace(Object.keys(routeExceptions), Object.values(routeExceptions));

    // Get the application name
    const applicationName = process.env.REACT_APP_APPLICATION_NAME ?? 'Emily';

    // join the route name together with spaces and return 'Emily' if this value is empty
    return routeName === '' ? applicationName : routeName;
}
