import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { stringify } from 'qs';
import { FetchContext } from 'App/Strapi/FetchContext';
import { formatDateWithoutTime, formatName, formatNumberValue, formatSeconds, formatTime, parseTimeToNumber } from 'App/Util/format';
import { getVisibleTextWidthInMM } from 'App/Util/getVisibleTextWidth';
import Block from 'UI/App/Components/Block/Block';
import IF from 'UI/App/Components/Conditional/IF';
import Grid from 'UI/App/Components/Grid/Grid';
import Table from 'UI/App/Components/Table/Table';
import Icon from 'UI/App/Components/Icon/Icon';
import { Spinner, SpinnerCenter } from 'UI/App/Components/Spinner';
import './order.scss';
import 'UI/App/Css/pdf-mockup.scss';
import { isEmpty } from 'lodash';
import Popup, { closePopup, openPopup } from "../../../../Components/Popup/Popup";
import CreateSticker from "../../Sticker/CreateSticker";
import Button from "../../../../Components/Button/Button";
import CompanyBlock from "UI/App/Partials/Content/CRM/Partials/CompanyBlock";
import PageHeader from 'UI/App/Components/PageHeader/PageHeader';
import HasRole from "UI/App/Components/Auth/HasRole";
import { downloadFile } from "App/Util/fetch";
import useWindowDimensions from 'App/Util/useWindowDimensions';
import { planningSuffix } from "UI/App/Partials/Content/Calculations/HJMG/steps/util";
import ReserveMaterialPopup from "UI/App/Components/Popup/Popups/MaterialPopup/ReserveMaterialPopup";
import WriteOffMaterialPopup from "UI/App/Components/Popup/Popups/MaterialPopup/WriteOffMaterialPopup";
import MaterialUnitMutator from "UI/App/Components/Material/MaterialUnitMutator";
import OrderAddAdditionalMaterial from "UI/App/Partials/Content/CRM/Orders/OrderAddAdditionalMaterial";
import isObject from "lodash/isObject";
import ConfirmationPopup from 'UI/App/Components/Popup/Popups/Util/ConfirmationPopup';
import UserdataMachinePopup from 'UI/App/Components/Popup/Popups/UserdataMachinePopup';
import Select from "react-select";
import User from "App/Strapi/User";
import PlanningHistory from './PlanningHistory';
import CreateCalculationPopup from "UI/App/Partials/Content/Calculations/Calculations/CreateCalculationPopup";
import TimerPopup from "UI/App/Partials/Content/CRM/Orders/OrderTimer/TimerPopup";
import capitalize from "lodash/capitalize";
import OrderAddDeliveryAddressPopup from './OrderAddDeliveryAddressPopup';
import Boolean from "UI/App/Components/Form/Boolean";
import { getMaterialsForOrder } from "UI/App/Partials/Content/CRM/Orders/orderUtil";
import LetMeShip from "UI/App/Components/Shipping/LetMeShip/LetMeShip";
import OrderUploadFilePopup from "UI/App/Partials/Content/CRM/Orders/OrderUploadFilePopup";
import prettyBytes from "pretty-bytes";

const _JOB_TICKET_IN_PROGRESS_STATUS = [
    'ready for production',
    'in progress'
];

export const _ORDER_QUERY = stringify({
    populate: {
        additionalMaterials: {
            populate: {
                material: true
            }
        },
        company: {
            populate: ['managingCompany']
        },
        sendingAddresses: {
            populate: {
                branch: {
                    populate: {
                        address: true
                    }
                }
            }
        },
        purchaseQuotes: true,
        contactPerson: true,
        calculation: {
            populate: {
                purchaseQuotes: {
                    fields: ['id', 'number'],
                }
            }
        },
        quote: {
            populate: {
                priceData: true,
                contactPerson: true,
                company: {
                    populate: ['telephone', 'managingCompany']
                },
                copies: {
                    fields: ['id', 'number'],
                },
            }
        },
        stickers: {
            sort: ['id:desc'],
            populate: {
                file: {
                    fields: ['id', 'name', 'mime']
                }
            }
        },
        quoteConfirmation: {
            populate: {
                quote: {
                    populate: {
                        contactPerson: true,
                        company: {
                            populate: ['telephone', 'managingCompany']
                        },
                        copies: {
                            fields: ['id', 'number'],
                        },
                    }
                },
                calculation: {
                    populate: {
                        stockQuotes: {
                            fields: ['id'],
                            populate: {
                                stockPurchaseSlip: {
                                    fields: ['id']
                                },
                            }
                        },
                        copies: {
                            fields: ['id', 'number'],
                        },
                        purchaseQuotes: {
                            fields: ['id', 'number'],
                        }
                    }
                }
            }
        },
        packingSlips: {
            fields: ['id', 'number'],
            sort: ['id:desc']
        },
        jobTickets: {
            fields: ['id', 'name', 'status', 'xml'],
        },
        project: {
            fields: ['id']
        },
        planning: {
            fields: ['id', 'machinePlanItems'],
            populate: {
                machinePlanItems: {
                    populate: {
                        dayPlanning: {
                            populate: {
                                noteUpdatedBy: true
                            }

                        },
                    }
                },
            }
        },
        files: {
            fields: ['id', 'displayName', 'fileSize']
        }
    }
});

export default function ViewOrder() {
    const {authAxios} = useContext(FetchContext);
    const params = useParams();
    const navigate = useNavigate();
    const {width} = useWindowDimensions();
    const [FSCStatus,setFSCStatus] = useState(false);

    const user = User.get();

    const [company, setCompany] = useState(null);
    const [order, setOrder] = useState(null);
    const [contactPerson, setContactPerson] = useState(null);
    const [calculation, setCalculation] = useState(null);
    const [popupData, setPopupData] = useState(null);
    const [deliveries, setDeliveries] = useState([]);
    const [knownDeliveries, setKnownDeliveries] = useState([]);
    const [deletionInfoDelivery, setDeletionInfoDelivery] = useState(undefined);
    const [deliveryAddress, setDeliveryAddress] = useState(undefined);
    const [deliveryBranchId, setDeliveryBranchId] = useState(undefined);

    const [HJMGOrderData, setHJMGOrderData] = useState(null);

    const [materialData, setMaterialData] = useState([]);

    const [isLoading, setIsLoading] = useState(true);

    const [timerFor, setTimerFor] = useState(null);

    const [fatalError, setFatalError] = useState(null);
    const [selectedSticker, setSelectedSticker] = useState({
        id: null,
        blob: null
    });
    const [selectedMaterial, setSelectedMaterial] = useState({
        materialId: null,
        quantity: null,
        unit: null
    });
    const [extraWork, setExtraWork] = useState({
        visible: false,
        data: ""
    });
    const [orderInformation,setOrderInformation] = useState('');

    const [planningEntries, setPlanningEntries] = useState([]);
    const [totalCost, setTotalCost] = useState(null);


    const orderStatuses = [
        'Binnengekomen',
        'In proef',
        'Bestand afgekeurd (door klant)',
        'Bestand afgekeurd (door Pretore)',
        'Prepress',
        'Factureren',
        'Productie',
        'Derden',
        'Verzending',
        'Klacht',
        'Afgerond',
        'Afgeboekt',
        'Reservering',
        'Niet in behandeling genomen'
    ]
    const statusOptions = [];
    for (const status of orderStatuses) {
        statusOptions.push(<option key={status}>{capitalize(status)}</option>)
    }

    const [edit, setEdit] = useState(false)
    const [orderData, setOrderData] = useState({
        number: '',
        deliveryDate: '',
        quantity: 0,
        customer: '',
        contactPerson: '',
        customerReference: '',
        checklist: [],
        checklistRemarks: '',
        materials: [],
        materialsRemarks: '',
        machines: [],
        transport: '',
        labor: [],
        planning: {}
    });

    const [conformationProps, setConfirmationProps] = useState({})

    const [reference, setReference] = useState(null)
    useEffect(() => {
        if (order && company && contactPerson && calculation) {
            if (calculation.owner === 'hjmg') {
                gatherHJMGDataForOrderPDF();
            } else {
                void gatherDataForOrderPDF()
            }
            // if (order && company && contactPerson && calculation) gatherDataForOrderPDF();
        }
    }, [order, company, contactPerson, calculation]);

    const getOrder = useCallback(() => {

        setIsLoading(true);
        setEdit(false);
        setExtraWork(extraWork => ({...extraWork, visible: false}))

        // get the companies using the built params
        authAxios
            .get(`/orders/${params.orderId}?${_ORDER_QUERY}`)
            .then(({data}) => {

                setFSCStatus(data.data.FSC)
                const quoteConfirmation = data?.data?.quoteConfirmation;
                setOrderInformation(data?.data?.information)
                let calculation = null

                if (data.data.calculation !== null && data.data.calculation !== undefined) {
                    calculation = data.data.calculation;
                } else if (quoteConfirmation !== null) {
                    calculation = quoteConfirmation.calculation;
                }

                let company = null;
                if (calculation?.company !== null && calculation?.company !== undefined) {
                    company = calculation?.company;
                } else if (data?.data?.company !== null && data?.data?.company !== undefined) {
                    company = data?.data?.company
                } else {
                    company = quoteConfirmation?.quote?.company
                }

                if (company !== null && company !== undefined) {
                    company.websiteURL = company?.website ? (company?.website?.startsWith("http:") ? 'http://' : 'https://') + company?.website?.replace(/https?:\/\//, '') : null;
                    company.telephoneNumber = company?.telephone ? `+${company?.telephone?.dialingCode} ${company?.telephone?.telephoneNumber}` : null;
                }

                const contactPerson = calculation?.contactPerson ?? data?.data?.contactPerson;

                setOrder(data.data);

                //set extra work if we have any
                if (data?.data?.extraWork?.trim() !== undefined && data?.data?.extraWork?.trim() !== "") {
                    setExtraWork({visible: true, value: data.data.extraWork})
                }
                setCompany(company);
                setContactPerson(contactPerson);

                setCalculation(calculation);

                setFatalError(false);

                setKnownDeliveries(data.data.sendingAddresses);
                void loadDeliveryAddresses();

                if (data.data.customerReference) {
                    setReference(data.data.customerReference)
                } else {
                    setReference(calculation?.data?.checklist?.find((item) =>
                        item?.name?.toLowerCase()?.includes('kenmerk') ||
                        item?.name?.toLowerCase()?.includes('referentie') ||
                        item?.name?.toLowerCase()?.includes('reference')
                    )?.value ?? '')
                }

                setPlanningEntries(data?.data?.planning?.machinePlanItems)
                let totalCost = null;

                // Then check from confirmation
                if (data?.data?.quoteConfirmation !== null) {
                    let currency = 'EUR'
                    // Try to get currency from quote else default to eur
                    if (data?.data?.chosenAmount !== null) {
                        if (data?.data?.quote !== null) {
                            for (const priceData of data?.data?.quote?.priceData) {
                                if (priceData.amount === data?.data?.chosenAmount) {
                                    currency = priceData.currency;
                                    break;
                                }
                            }
                        }
                    }

                    // Create price data object
                    totalCost = {
                        price: data?.data?.quoteConfirmation.price,
                        currency: currency
                    }
                }

                // First check from quote
                if (data?.data?.quote !== null && totalCost === null) {
                    if (data?.data?.chosenAmount !== null) {
                        for (const priceData of data?.data?.quote?.priceData) {
                            if (priceData.amount === data?.data?.chosenAmount) {
                                totalCost = priceData
                                break;
                            }
                        }
                    }
                }

                // If still no price can be found get from calculation
                if (totalCost === null && data?.data?.calculation !== null) {
                    // Check if chosen amount in order is not null
                    if (data?.data?.chosenAmount !== null) {
                        if (data?.data?.calculation.data?.summary[data?.data?.chosenAmount] !== undefined) {

                            // Get data from calculation and default to euro
                            totalCost = {
                                price: (data?.data?.calculation.data?.summary[data?.data?.chosenAmount].quote).toFixed(2),
                                currency: 'EUR'
                            }
                        }
                    }

                }

                setTotalCost(totalCost);
            })
            .catch((exception) => {
                setFatalError(
                    `Er is iets fout gegaan ${exception?.response?.status ? `(${exception.response?.status})` : ''
                    }`
                );
                console.error(exception);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [params.orderId]);

    useEffect(() => {
        const updateOrderTimers = async () => {
            const timers = await authAxios.get(`/orders/${params.orderId}/timers`)
                .then(({data}) => data)
                .catch((e) => {
                    console.error(e)
                })

            setOrder((order) => ({
                ...order,
                timers
            }));
        }

        window.addEventListener('timer::update', updateOrderTimers);

        return () => {
            window.removeEventListener('timer::update', updateOrderTimers);
        }
    }, []);

    useEffect(getOrder, [params.orderId, getOrder]);

    useEffect(() => {
        if (!orderData?.materials) return;

        setMaterialData(getMaterialTableData());
    }, [orderData]);

    // Avoid rendering hidden orders
    if (JSON.parse(process.env.REACT_APP_HIDDEN_ORDER_IDS).includes(params.orderId)) {
        return <span>Redirecting...<Navigate to='/orders' /></span>;
    }

    // Gather data for HJMG order
    function gatherHJMGDataForOrderPDF() {
        let HJMGOrderData = [];

        // Loop calculation data
        Object.keys(calculation.data.calculationData).forEach(function (name, index) {
            // Get order data
            const orderData = calculation.data.calculationData[name];

            // Only insert data if part has entries
            if (orderData.entries !== undefined) {

                // Create object with calculation part name
                HJMGOrderData[name] = orderData;
            }
        });

        // Set data in useState
        setHJMGOrderData(HJMGOrderData);
    }

    async function gatherDataForOrderPDF() {
        const orderMaterials = await getMaterialsForOrder(order, calculation, authAxios);

        // If no order materials stop to stop errors from occurring
        if (orderMaterials === undefined) return;

        const maxMaterialLength =
            Math.max(
                ...orderMaterials.map((material) =>
                    getVisibleTextWidthInMM(material.name, 'bold 11pt Helvetica')
                )
            ) || 50;


        let _reference = reference;
        if (_reference === undefined) {
            _reference = calculation?.customerReference ??
                calculation.data?.checklist?.find(
                    (item) =>
                        item?.name?.toLowerCase()?.includes('kenmerk') ||
                        item?.name?.toLowerCase()?.includes('referentie') ||
                        item?.name?.toLowerCase()?.includes('reference')
                )?.value ??
                ''
        }
        setOrderData((prev) => ({
            number: order.number,
            quantity: order.chosenAmount,
            customer: company.name,
            machines: getMachinesForOrder(orderMaterials),
            transport: prev?.transport ?? '',
            materials: orderMaterials,
            checklist: calculation.data?.checklist ?? [],
            deliveryDate: order.deliveryDate ?? '',
            contactPerson: formatName(contactPerson.firstName, contactPerson.prefixToSurname, contactPerson.surname),
            checklistRemarks: prev?.checklistRemarks ?? '',
            materialsRemarks: prev?.materialsRemarks ?? '',
            customerReference: _reference,
            maxMaterialLength: isFinite(maxMaterialLength) ? maxMaterialLength : 50,
            labor: getLaborData(calculation, order.calculation.data.amounts.findIndex(amount => amount === order.chosenAmount)),
            planning: order?.planning
        }));
    }

    async function getMaterialsForOrder() {
        const materialArray = [];

        // Guard against unset amounts
        if (!order?.chosenAmount && order?.chosenAmount !== 0) {

            let autoAmount = Object.keys(calculation?.data?.values[Object.keys(calculation?.data?.values)[0]])[0];
            // if we werent able to get an amount from know data tell the user that
            if (!autoAmount && autoAmount !== 0) {
                toast.error(<>
                    Order heeft geen gekozen aantal!
                </>, {autoClose: 15000})
            }
            // if we ARE able to get an amount from know data, notify the user we did so
            else {
                order.chosenAmount = autoAmount
                toast.warning(<>
                    <b>LET OP!</b><br/>
                    Het ziet er naar uit dat de order geen gekozen aantal heeft.<br/>
                    <small>Automatisch <b>{`${autoAmount} stuks`}</b> gekozen</small>
                </>, {autoClose: 15000})
            }

        }

        const materialReturnWidgetTabs = Object.entries((calculation?.data?.templateData?.data ?? {}))
            // Get only the tabs
            .filter(([key, value]) => /\d+-\d+/.test(key))
            // Get only the material return widgets
            .map(([key, value]) => {
                return [
                    key,
                    value.widgets.filter(
                        (widget) =>
                            widget.data.type === 'ReturnWidget' &&
                            (widget.data.title.startsWith('Materiaal.'))
                    ),
                    value.widgets.find(
                        (widget) =>
                            widget.data.type === 'ReturnWidget' &&
                            (widget.data.title.endsWith('Te verwerken eenheden'))
                    )
                ];
            })
            // Filter out tabs without material return widgets
            .filter(([key, widgets]) => widgets.length > 0);

        let calculationTabsValues = null;
        if (calculation.data.values !== undefined) {
            calculationTabsValues = Object.fromEntries(
                Object.entries(calculation.data.values)
                    // Get only the calculation tabs
                    .filter(([key, value]) => /\d+-\d+/.test(key))
                    // Get the calculation result for the chosen amount
                    .map(([key, value]) => [key, value?.[order?.chosenAmount]])
            );
        }

        /**
         * Object containing all material widgets per material
         * @type {{[tabIndex: string]: {[materialWidgetId: string]: {}}}}
         */
        let materialWidgetsPerMaterial = {};

        // Loop through the tabs
        for (const [tabIndex, materialReturnWidgets, processedUnit] of materialReturnWidgetTabs) {
            // Skip tabs if it doesn't have a calculation result
            // In this case the tab was optional and not enabled in the calculation
            if (typeof calculationTabsValues[tabIndex] === 'undefined') continue;

            // Create an empty object for this tab
            materialWidgetsPerMaterial[tabIndex] = {};

            // Loop through the return widgets
            for (const materialReturnWidget of materialReturnWidgets) {

                // Split the return widget title
                // The title is in the format: Materiaal.[materialWidgetId].[part]
                // Example: Materiaal.1-widget-1.aantal
                // The first part (group) is in this case always 'Materiaal' since we're only dealing with material returns
                // The second part (materialWidgetId) is either the string value of the material we're dealing with or the id of the material widget
                // The third part (part) is the type of return value we're dealing with
                // Example: aantal, prijs, totaalprijs, verkoopprijs, etc.
                const [group, materialWidgetId, part] = materialReturnWidget.data.title.split('.');

                // Skip the usercharge widget since it's not necessary for the operators to see
                if (['usercharge', 'inkt'].includes(materialWidgetId.toLowerCase())) continue;
                // Skip the totaalprijs and verkoopprijs since it's not necessary for the operators to see
                if (['totaalprijs', 'verkoopprijs'].includes(part?.toLowerCase())) continue;

                // Get the material widget value
                const returnWidgetValue = calculationTabsValues[tabIndex]?.find(
                    (widget) => widget.id === materialReturnWidget.id
                );


                // The name of the material
                let materialName;
                let material = null;

                // If the materialWidgetId starts with 'widget', it points to another material widget
                // We should get the value of that widget and get the internalSKU from that for the material name
                // Otherwise the materialWidgetId is the name of the material
                // Normally, if the materialWidgetId is the name of the material, we're dealing with materials that are not in the database
                // These materials are always used in the machine, and are always the same type of material E.G. usercharge or ink for printers
                // In the case of usercharge, it's not even a material, but a cost that is added on by the manufacturer
                if (materialWidgetId.includes('widget')) {
                    const materialWidgetValue = JSON.parse(
                        calculationTabsValues[tabIndex]?.find(
                            (widget) => widget.id === materialWidgetId
                        )?.value ?? '{}'
                    );
                    material = materialWidgetValue.data?.value?.result;
                    materialName = material?.calculationDescription;
                } else {
                    materialName = materialWidgetId;
                }

                // Save the widget value amount and unit in the materialWidgetsPerMaterial object
                // Filter out undefined material names. Because if a material is not defined, how can we know what material to get?
                if (materialName !== undefined) {
                    materialWidgetsPerMaterial[tabIndex][materialName] = {
                        quantity: returnWidgetValue.value,
                        unit: returnWidgetValue.unit,
                        category: material?.category ?? materialName,
                        material: material ?? {},
                        machineProcessedQuantity: processedUnit?.data?.value?.result,
                        machineProcessedUnit: processedUnit?.data?.unit,
                    };
                }
            }
        }


        // Now we have all the material widgets per material
        // We can now loop through the materials and create the content
        for (const [machineTabId, materials] of Object.entries(materialWidgetsPerMaterial)) {
            // First we need to get the machine name
            // The machine name is the title of the tab
            const machineName = order.calculation.data.templateData.data[machineTabId].name;

            // Add the material names to the content
            for (const [materialName, material] of Object.entries(materials).sort(
                (a, b) => a[0].length - b[0].length
            )) {
                let first = material?.material?.quotationNameNL
                let fallback = material?.material?.quotationNameEN
                let lastly = material?.material?.internalSKU

                const correctQuantity = await (new MaterialUnitMutator()).quantityMerchant(material.quantity, material?.material, true)
                const correctUnit = await (new MaterialUnitMutator()).obtainUnit(material?.material)

                let name = first !== undefined ? first : (fallback !== undefined ? fallback : lastly)

                materialArray.push({
                    name: name,
                    unit: correctUnit,
                    unitForStorage: material.unit,
                    machine: machineName,
                    machineTabId: machineTabId,
                    material: material.material,
                    category: material.category,
                    quantity: Math.round(correctQuantity),
                    formattedValue: formatNumberValue(material.quantity, material.unit)
                });
            }
        }

        for (const additionalMaterial of order.additionalMaterials) {
            const material = additionalMaterial.material;

            // Check if material is object before continueing
            if (!isObject(material)) continue;

            let first = material?.quotationNameNL
            let fallback = material?.quotationNameEN
            let lastly = material?.internalSKU

            let name = first !== undefined ? first : (fallback !== undefined ? fallback : lastly)
            const correctQuantity = await (new MaterialUnitMutator()).quantityMerchant(additionalMaterial.quantity, material, true)
            const correctUnit = await (new MaterialUnitMutator()).obtainUnit(material)

            materialArray.push({
                name: name,
                unit: correctUnit,
                unitForStorage: material.unit,
                machine: null,
                material: material,
                category: material?.category,
                quantity: Math.round(correctQuantity),
                formattedValue: additionalMaterial.quantity
            });
        }

        // Check if materials are present else skip order
        if (materialArray.length === 0) {
            return;
        }

        const query = stringify({
            materials: materialArray.map((material) => ({
                id: material.material.id,
                unit: material.material.unit,
            }))
        }, {
            encodeValuesOnly: true
        });

        const materialStock = await authAxios.get(`/logistics/materials/stock/find?${query}`)
            .then(({data}) => {
                return data;
            })
            .catch((exception) => {
                console.error(exception);
                toast.error('Voorraad kon niet worden opgehaald');
                return [];
            });

        const filteredMaterialArray = materialArray.map((material) => ({
            material: material,
            stock: materialStock.find((stock) => stock.material.id === material.material?.id),
        }));

        // Add preview names to stock
        for (const material of filteredMaterialArray) {
            // If material has stock add preview name
            if (material.stock !== undefined) {
                material.stock.preview = {
                    name: await (new MaterialUnitMutator()).obtainUnit(material.material),
                    available: await (new MaterialUnitMutator()).quantityMerchant(material.stock.available, material.material.material, true, true),
                    reserved: await (new MaterialUnitMutator()).quantityMerchant(order?.stockData?.[material.material.material.id]?.reserved, material.material.material, true, true),
                    writtenOff: await (new MaterialUnitMutator()).quantityMerchant(order?.stockData?.[material.material.material.id]?.writtenOff, material.material.material, true, true)
                }
            }
        }

        return filteredMaterialArray;
    }

    function getMachinesForOrder(materials) {
        let machinesArray = [];

        const optionalMachinesList = calculation.data.optionalMachines;

        // Get the machine schedule from the calculation data
        const machineScheduleStorage = calculation?.data?.totalResultJson?.machineScheduleStorage;
        const amountIndex = calculation?.data?.amounts?.findIndex(
            (amount) => order.chosenAmount === amount
        );

        if (machineScheduleStorage === undefined || amountIndex === -1) {
            console.warn('Planning unavailable', {
                machineScheduleStorage,
                amountIndex,
                chosenAmount: order.chosenAmount
            });
            toast.warn('Planning niet beschikbaar');
            return [];
        }

        // Create a string for keeping track of the previous machine name
        let prevMachine = '';

        if (calculation && calculation.data.templateData.data) {
            if (Object.values(calculation.data.templateData.data).some(tab => tab.name?.trim() === '')) {
                if (User.hasRole(['productie medewerker'])) {
                    setFatalError("Er is een fout opgetreden bij het ophalen van de order. Probeer het later opnieuw.");
                } else {
                    setFatalError({
                        title: "Er is iets fout gegaan.",
                        error: "Een of meerdere machines in de calculatie hebben geen naam. Pas het template aan, bereken de calculatie en probeer het opnieuw."
                    });
                }
            }
        }

        // Loop through the machine schedule
        for (const machinePlanningEntry of machineScheduleStorage) {

            if (!machinePlanningEntry[0]) continue;

            // Get the machine name and planning value from the machine schedule entry
            const machineEntryNames = machinePlanningEntry[0]?.match(
                /(?<machineName>.*) ?\((?<planningEntry>.*)\)$/i
            );

            // Make sure both of the values exist
            if (machineEntryNames === null || machineEntryNames === undefined) continue;

            // Get the values from the regex match
            const machineName = machineEntryNames?.groups?.machineName.trim();
            const planningEntry = machineEntryNames?.groups?.planningEntry;

            // Skip the "in te plannen uren" entry. This value is purely for planning purposes and not for the operators
            if (planningEntry.toLowerCase() !== 'in te plannen uren') continue;

            // Get the planning time from the machine planning entry
            const planningTime =
                machinePlanningEntry[amountIndex + 1].match(/\((?<time>.*?)\)/i)?.groups?.time;

            // Create an empty object to store machine settings in
            let machine = {};
            let machineKey = null;
            let machineData = {
                name: null,
                key: null,
                effort: null,
                planning: null,
                misses: null,
                materials: [],
                justification: ''
            };

            // If the previous machine name is not the same as the current machine name, we need to get the machine from the calculation data
            if (prevMachine !== machineName) {
                const foundMachine = Object.entries(calculation.data.templateData.data)
                    .find(([key, machine]) => {
                        if (machine.name !== '') {
                            return machine.name?.trim() === machineName?.trim()
                        } else {
                            const tabKey = key.split('-')[0];
                            const machineNameConst = machine.constants.find(constant => constant.id.includes('const-name'));
                            const altName = `${machineNameConst.value} (${tabKey})`;

                            if (machineName && altName === machineName) {
                                machine.name = altName;
                                return true;
                            } else {
                                return false;
                            }
                        }
                    });
                machineKey = foundMachine?.[0];
                machine = foundMachine?.[1];
            }

            // Warn the user if the machine is not found in the calculation data
            if (!machine) {
                toast.error(`Machine ${machineName} not found in template data`);
                continue;
            }

            if (machine.constants !== undefined) {
                machineData.name = machine.name;
                machineData.key = machineKey;
                machineData.effort =
                    machine.constants.find((constant) => constant.id.includes('laborEffort'))
                        ?.value ?? 0;
                machineData.planning = getTimeFromString(planningTime);

                let missesKey = Object.keys(calculation.data.amounts).find(
                    (key) => calculation.data.amounts[key] === order.chosenAmount
                );
                machineData.misses = calculation.data.misses[missesKey];
            }

            machinesArray.push(machineData);
        }

        // Loop through the machines
        for (const [machineKey, machine] of Object.entries(calculation.data.templateData.data)) {
            // Skip non-widget flow tabs and tabs without constants, since they aren't machine flows
            if (!/\d+-\d+/.test(machineKey) || (machine.constants?.length ?? 0) === 0) continue;

            // Skip non-enabled optional machines
            if (machine.optional && optionalMachinesList?.[machineKey] === 'false') continue;

            // Get the materials for this machine
            const machineMaterials = materials.filter(
                (material) => material.machineTabId === machineKey
            );

            // Get the machine index from the array
            const machineIndex = machinesArray.findIndex((machine) => machine.key === machineKey);
            if (machineIndex !== -1) {
                machinesArray[machineIndex].materials = machineMaterials;
            }
        }

        return machinesArray;
    }

    function getLaborData(calculation, index) {
        const labor = calculation?.data?.totalResultJson?.labor;

        // get labor items filter empty ones and put them into an object
        const laborItems = labor
            .filter((laborItem) => (
                !laborItem || laborItem[0] !== ''
            ))
            .map((laborItem) => {
                const [name, type] = laborItem[0].split(/\(([^)]+)\)$/)
                return {
                    name: name?.trim(),
                    type: type?.trim(),
                    calculation: laborItem[index + 1],
                }
            });

        // object containing lists of machines
        const categorizedLaborItems = {};
        // loop through laborItems, set the name as the Object.key and push the other values as object
        laborItems.forEach(item => {
            const {name, type, calculation} = item;

            if (!categorizedLaborItems[name]) {
                categorizedLaborItems[name] = [];
            }

            categorizedLaborItems[name].push({type, calculation});
        });

        // loop through categories and its items
        for (const labor of Object.values(categorizedLaborItems)) {
            labor.forEach((laborItem) => {
                // get minutes by input
                function getTimeInMinutes(timeIndication, timeValue) {
                    timeValue = timeValue?.replace('.', '')?.replace(',', '.');
                    if (timeIndication?.toUpperCase() === 'MINUTEN' || timeIndication?.toUpperCase() === 'MINUUT') {
                        return parseFloat(timeValue).toFixed(2);
                    } else if (timeIndication?.toUpperCase() === 'UUR' || timeIndication?.toUpperCase() === 'UREN') {
                        return parseFloat(timeValue * 60).toFixed(2);
                    } else {
                        console.error('Unrecognized time indication: ' + timeIndication);
                        // throw new Error('Unrecognized time indication: ' + timeIndication);
                    }
                }

                // apply correct formatting based on type
                switch (laborItem?.type?.toUpperCase()) {
                    case 'OPSTART':
                    case 'PRODUCTIE':
                        const result = laborItem?.calculation?.split(/=(.*)$/)[1];
                        const splitResult = result?.trim().split(' ');
                        if (splitResult !== undefined) {
                            laborItem.value = getTimeInMinutes(splitResult[splitResult?.length - 1], splitResult[0]);
                        }
                        break;
                    case 'UURTARIEF':
                        // currently not used
                        laborItem.value = laborItem?.calculation;
                        break;

                    default:
                        console.warn('Unrecognized labor type: ' + laborItem?.type);
                }
            });
        }

        // format the time value
        function getFormattedValue(totalValue) {
            const limitedValue = parseFloat(totalValue?.toFixed(2));
            // if time is more than 60 minutes
            if (limitedValue > 60) {
                const hours = String(Math.floor(limitedValue / 60)).padStart(2, '0');
                const minutes = Math.floor(limitedValue % 60);

                // if minutes are more than 0
                if (minutes > 0) {
                    return `${hours}:${String(minutes).padStart(2, '0')} uur`;
                } else {
                    return `${hours} uur`;
                }
            }
            // format whole number
            return `${Math.round(limitedValue)} minuten`;
        }

        // object that will be returned
        const returnItems = [];

        // loop through all items
        for (const machineName in categorizedLaborItems) {
            // if the type is 'opstart' or 'productie' add all numbers together
            const totalValue = categorizedLaborItems[machineName]
                .filter((laborItem) => laborItem?.type?.toUpperCase() === 'OPSTART' || laborItem?.type?.toUpperCase() === 'PRODUCTIE')
                .reduce((sum, laborItem) => parseFloat(sum) + parseFloat(laborItem?.value), 0);

            // If no time is found continue
            if (isNaN(totalValue)) continue;

            returnItems.push({
                name: machineName,
                total: getFormattedValue(totalValue),
                rawTotalMinutes: totalValue
            })
        }

        for (const manualLabor of calculation?.data?.manualLabor) {
            returnItems.push({
                name: manualLabor.description,
                total: getFormattedValue(manualLabor.quantities?.[order.chosenAmount].minutes),
                rawTotalMinutes: manualLabor.quantities?.[order.chosenAmount].minutes
            });
        }

        return returnItems;
    }

    function getTimeFromString(timestring) {
        const [hours, minutes] = timestring.split(':');
        const timeInMinutes = parseInt(hours) * 60 + parseInt(minutes);

        if (timeInMinutes <= 60) {
            // Return minutes rounded to 5
            return `${Math.ceil(timeInMinutes / 5) * 5} minuten`;
        } else {
            // if the number is bigger then 55, it will get rounded up to 60.
            // this will result in times like '1:60'
            if (Number(minutes) > 55) {
                return `${Number(hours) + 1}:00 uur`;
            }
            return `${hours}:${String(Math.ceil(minutes / 5) * 5).padStart(2, '0')} uur`;
        }
    }

    function getMachineTableData() {
        let today = new Date()

        return orderData?.machines?.map((machine) => {
            // put down a placeholder if we have dont have the required data
            if (orderData?.planning === null || orderData?.planning === undefined || orderData?.planning?.machinePlanItems?.length === 0) {
                return ({
                    data: [
                        machine.name,
                        "-",
                        "-",
                        "-",
                        "-",
                        "-"
                    ],
                });
            } else {
                const machinePlanningItems = orderData?.planning?.machinePlanItems?.find((el) => el?.name === machine?.name)


                const totalProgress = machinePlanningItems?.dayPlanning.map(el => el.progress).reduce((a, b) => a + b) / (machinePlanningItems?.dayPlanning?.length ?? 0)
                let statusText = <>geen status</>


                if ((width <= 950 || width > 1200) && width <= 1830) { // when we dont have enough space show a color to represent status
                    if (totalProgress === 0) {
                        statusText = { attributes: { title: "Niet begonnen." }, value: <div value="test" name={"StatusColor"} style={{ width: "15px", height: "15px", borderRadius: "10px", backgroundColor: "var(--text-error)" }}></div> }
                    } else if (totalProgress > 0 && totalProgress < 100) {
                        statusText = { attributes: { title: "Mee bezig." }, value: <div name={"StatusColor"} style={{ width: "15px", height: "15px", borderRadius: "10px", backgroundColor: "var(--text-warning)" }}></div> }
                    } else if (totalProgress >= 100) {
                        statusText = { attributes: { title: "Afgerond." }, value: <div name={"StatusColor"} style={{ width: "15px", height: "15px", borderRadius: "10px", backgroundColor: "var(--text-good)" }}></div> }
                    }

                } else {// show 'full' status
                    if (totalProgress === 0) {
                        statusText = "Niet begonnen."
                    } else if (totalProgress > 0 && totalProgress < 100) {
                        statusText = "Mee bezig."
                    } else if (totalProgress >= 100) {
                        statusText = "Afgerond."
                    }

                }

                let realized = [0];
                let planned = [0];
                // 'split' dayplanning items on future or past, planned | realized
                // Check for undefined for adding new materials to order
                if (machinePlanningItems?.dayPlanning !== undefined) {
                    for (const planning of machinePlanningItems?.dayPlanning) {
                        let planningDate = new Date(planning.date)

                        if (planningDate.getTime() >= today.getTime()) {
                            planned.push(planning)
                        } else {
                            realized.push(planning)
                        }
                    }
                }


                // get planned time, (things now or in the future)
                let totalPlannedTime = (Math.ceil(60 * planned?.map(el => el?.time ?? 0)?.reduce((a, b) => a + b)))
                let plannedHours = (totalPlannedTime / 60) | 0

                // get realized time, (things in the past)
                let totalRealizedTime = (Math.ceil(60 * realized?.map(el => el?.time ?? 0)?.reduce((a, b) => a + b)))
                if (totalRealizedTime !== 0) {
                    totalRealizedTime += 1
                }
                let realizedHours = (totalRealizedTime / 60) | 0

                // get total time
                let totalTimeInMinutes = (Math.ceil(60 * machinePlanningItems?.totalRequiredTime)) - totalRealizedTime;
                if (totalTimeInMinutes !== 0) {
                    totalTimeInMinutes += 1
                }
                let fullHours = (totalTimeInMinutes / 60) | 0

                // make strings of the time
                let timeRequiredAsString = `${fullHours}:${totalTimeInMinutes - fullHours * 60}`

                let timePlannedAsString = `${plannedHours}:${totalPlannedTime - plannedHours * 60}`

                let timeRealizedAsString = `${realizedHours}:${totalRealizedTime - realizedHours * 60}`
                return ({
                    data: [
                        machine.name,
                        machine.planning,
                        getTimeFromString(timeRequiredAsString),
                        getTimeFromString(timePlannedAsString),
                        getTimeFromString(timeRealizedAsString),
                        statusText
                    ],
                });
            }
        }).flat();
    }

    function getStickerTableData() {
        return (order?.stickers ?? [])?.map((sticker) => ({
            data: [
                sticker.Name,
                <Grid columns={2} gap='1px'>
                    <button
                        style={{
                            padding: 0
                        }}
                        className='btn btn--transparent'
                        onClick={() => {
                            openPopup('viewSticker');

                            if (sticker.file?.id) {
                                downloadFile(authAxios, sticker.file.id, sticker.file.mime).then(async (blobURL) => {
                                    setSelectedSticker({
                                        id: sticker.id,
                                        type: sticker.file.mime,
                                        url: blobURL
                                    });
                                });
                            } else {
                                setSelectedSticker({
                                    id: sticker.id,
                                    type: 'application/pdf',
                                    url: 'data:application/pdf;base64,' + sticker.base64
                                });
                            }
                        }}
                    >
                        <Icon name='eye' style={{width: '20px', height: '20px'}}/>
                    </button>
                    <button className='btn btn--transparent' style={{padding: 0, paddingRight: 0}} onClick={() => deleteSticker(sticker?.id)}>
                        <Icon name={"trash-can"} style={{width: '20px', height: '20px'}}/>
                    </button>
                </Grid>
            ],
        })).flat();
    }

    function getFileTableData() {
        return (order.files ?? []).map(file => ({
            data: [
                <strong>{file.displayName}</strong>,
                prettyBytes(file.fileSize),
            ]
        }))
    }

    function getMaterialTableData() {
        return orderData.materials.map(({material, stock}) => {
            // Get the reserved quantity by subtracting the written off value from the reserved value.
            // If this causes the reserved quantity to be negative, set it to 0.
            let reservedQuantity = 0;
            if (!isNaN(stock?.preview?.writtenOff)) {
                reservedQuantity = !isNaN(stock?.preview?.writtenOff) && (stock?.preview?.writtenOff > stock?.preview?.reserved) ? 0 : stock?.preview?.reserved - (stock?.preview?.writtenOff ?? 0);
            } else {
                reservedQuantity = stock?.preview?.reserved;
            }

            return ({
                data: [
                    `${material.name} ${(material?.material?.internalSKU) ? ` (${material?.material?.internalSKU})` : ""}`,
                    `${material.quantity} ${material.unit}`,
                    !isNaN(stock?.preview?.available) ? `${stock?.preview?.available} ${stock?.preview?.name}` : '-',
                    !isNaN(reservedQuantity) ? `${reservedQuantity} ${stock?.preview?.name}` : '-',
                    !isNaN(stock?.preview?.writtenOff) ? `${stock?.preview?.writtenOff} ${stock?.preview.name}` : '-',
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <button data-title='Reserveren' className='btn--transparent btn--padding-0' onClick={() => reserveMaterial(material.material.id, material.quantity - (stock?.preview?.reserved ?? 0), stock?.unit ?? material.unit, material.quantity)}><Icon name='clipboard-check'/></button>
                        <button data-title='Afboeken' className='btn--transparent btn--padding-0' onClick={() => writeOffMaterial(material.material.id, material.quantity - (stock?.preview?.writtenOff ?? 0), stock?.unit ?? material.unit)}><Icon name='flatbed-cart'/></button>
                    </div>,
                ],
            });
        }).flat();
    }

    function reserveMaterial(materialId, quantity, unit, maximumQuantity) {
        // If quantity is 0, don't open the popup, but show a toast
        if (quantity === 0) {
            toast.info('Het maximale aantal voor dit order is al gereserveerd.');
            return;
        }

        setSelectedMaterial({
            materialId,
            quantity,
            unit,
            maximumQuantity
        });

        openPopup('reserveMaterial');
    }

    function writeOffMaterial(materialId, quantity, unit) {
        setSelectedMaterial({
            materialId,
            quantity,
            unit
        });

        openPopup('writeOffMaterial');
    }

    function getEmployeeTableData() {
        return orderData?.labor.map((laborItem) => {
            // the 'base' icon to use
            let icon = <Icon name='angle-right' />;

            const totalTimerSeconds = (order.timers ?? [])
                .filter(timer => timer.timerFor === laborItem.name)
                .reduce((acc, timer) => {

                    // if we HAVE a start and DONT HAVE a end, we are ongoing
                    if (timer.start !== null && timer.end === null && timer.user?.id === user.id) {
                        icon = <Icon style={{ color: "var(--black)" }} name='fa-solid pause' />;
                    }

                    const start = new Date(timer.start);
                    const end = new Date(timer.end ?? new Date());

                    const totalDiff = end - start;

                    const breaks = timer.breaks.reduce((acc, current) => {
                        const start = new Date(current.start);
                        const end = new Date(current.end ?? new Date());

                        // if the current break has a start but NOT an end, we have an ongiong pause
                        if (current.start !== null && current.end === null) {
                            icon = <Icon style={{ color: "var(--black)" }} name='fa-solid mug-saucer' />;
                        }

                        return acc + (end - start);
                    }, 0);

                    return acc + (totalDiff - breaks) / 1000;
                }, 0)

            return ({
                attributes: {
                    onClick: () => {
                        setPopupData(laborItem);
                        openPopup('UserdataMachinePopup')
                    }
                },
                data: [
                    laborItem.name,
                    '-',
                    '-',
                    laborItem.total,
                    <span>{formatSeconds(totalTimerSeconds, false)}</span>,
                    {
                        attributes: {
                            onClick: (e) => {
                                e.stopPropagation();
                                setTimerFor({
                                    labor: laborItem.name,
                                    timeInMinutes: laborItem.rawTotalMinutes
                                });
                                openPopup('order-timer');
                            },
                            title: 'Uren registreren'
                        },
                        value: <button className={'btn btn--transparent btn--padding-0'}>{icon}</button>
                    }
                ]
            })
        })
    }

    function getDocumentData(itemsToShow) {
        const constructDataArray = (name, number, id, urlName, isView, isFullUrl, openInSameTab) => ({
            data: [
                <b>{name}</b>,
                number,
                <Link
                    target={openInSameTab ? undefined : '_blank'}
                    to={isFullUrl ? urlName : `/${urlName}/${id}/${isView ? 'view' : ''}`}>
                    <Icon name='eye' style={{width: '20px', height: '20px'}}/>
                </Link>,
            ],
        });

        if (!isEmpty(itemsToShow)) {
            const returnValue = [];

            itemsToShow.filter(item => item).forEach(item => {

                // If no return object return empty
                if (item.object === null || item.object === undefined) return;

                returnValue.push(constructDataArray(item.name, item.object?.number, item.object?.id, item.urlName, item.isView, item.isFullUrl, true));

                if (!isEmpty(item.object?.copies)) {
                    item.object.copies.filter(copy => copy).forEach(copy => {
                        returnValue.push(constructDataArray(item.name, copy.number, copy.id, item.urlName, item.isView, item.isFullUrl, true));
                    });
                }
                if (!isEmpty(item.object?.purchaseQuotes)) {
                    item.object.purchaseQuotes.filter(quote => quote).forEach(quote => {
                        returnValue.push(constructDataArray('Inkoopbon', quote.number, null, `/purchase/quotes/${quote.id}`, null, true, true));
                    });
                }
            });
            return returnValue.flat();
        }
        return [];
    }

    async function queueJobTicket(jobTicketId) {
        authAxios.put(`/workflow/job-tickets/${jobTicketId}`, {
            data: {
                status: 'ready for production'
            }
        })
            .then(() => {
                toast.success('Job ticket klaar gezet voor de workflow.');

                setOrder(order => {
                    order.jobTickets = order.jobTickets.map(ticket => {
                        if (ticket.id === jobTicketId) {
                            ticket.status = 'ready for production';
                        }

                        return ticket;
                    });

                    return order;
                })

                navigate(`/orders/${params.orderId}`);
            })
            .catch((error) => {
                console.error(error);
                toast.error('Er is iets misgegaan bij het aftrappen van de job ticket.')
            });
    }

    async function downloadXml(ticket, xml) {
        if (xml === undefined) {
            toast.error("Deze ticket heeft geen XML.")
            return;
        }
        const blob = new Blob([xml], {type: "text/xml"});

        // create elem to download
        const elem = window.document.createElement('a');
        elem.href = window.URL.createObjectURL(blob);
        elem.download = `${ticket.name}_${new Date().getTime()}`;

        // append elem to the doc body, remove after downloading
        document.body.appendChild(elem);
        elem.click();
        document.body.removeChild(elem);

    }

    function saveDetails() {
        if (extraWork?.value?.trim() === "" || extraWork?.value?.trim() === undefined) {
            setExtraWork((prev) => ({
                ...prev,
                visible: false
            }))
        }

        authAxios.put(`/orders/${order.id}`, {
            data: {
                description: order.description,
                extraWork: extraWork.value,
                customerReference: reference,
                information: orderInformation,
                FSC:FSCStatus
            }
        }).then((data) => {
            toast.success('Omschrijving aangepast');
        }).catch((exception) => {
            toast.error('Omschrijving kon niet aangepast worden!');
            console.error(exception);
        });
    }

    function deleteSticker(givenStickerId = undefined) {
        const query = stringify({
            populate: {
                order: {
                    populate: {
                        company: true,
                        contactPerson: true,
                        calculation: true,
                        quote: true,
                        stickers: {
                            sort: ['id:desc']
                        },
                        packingSlips: {
                            fields: ['id', 'number'],
                            sort: ['id:desc']
                        }
                    }
                }
            }
        });

        authAxios.delete(`stickers/${givenStickerId ?? selectedSticker.id}?${query}`).then((data) => {
            data.data.data.order.stickers = data.data.data.order.stickers.filter((sticker) => sticker.id !== givenStickerId ?? selectedSticker.id)
            setOrder(data.data.data.order)
            toast.info('Sticker verwijderd')
            closePopup('viewSticker')
        }).catch((exception) => {
            toast.error('Bijlage kon niet verwijderd worden!');
            console.error(exception);
        });
    }

    async function deleteDeliveries() {
        if (deletionInfoDelivery === undefined) return;

        let entriesToKeep = knownDeliveries.filter((el) => {
            return el.id !== deletionInfoDelivery
        });

        // Update the order
        await authAxios.put(`/orders/${order.id}`, {
            data: {
                sendingAddresses: entriesToKeep
            }
        }).then(() => {

            setDeliveries(entriesToKeep.map((el) => {
                let address = el?.branch?.address.addressLine1 ?? (el?.branch?.address.addressLine2 ?? (el?.branch?.address.addressLine3))
                return [
                    el?.branch?.name ?? "--",
                    address ?? "--",
                    el?.locationManager ?? "--",
                    el?.amountSend ?? "--",
                    <>
                        <button className='btn btn--transparent' data-title='Pakbon aanmaken' style={{padding: 0, marginRight: '20px' }} onClick={() => {
                            navigate(`./packing-slips/create?delivery-address=${el.id}`)
                        }}>
                            <Icon name={"fas boxes-packing"} style={{ width: '20px', height: '20px', fontSize: '20px', color: 'black' }}/>
                        </button>
                        <button className='btn btn--transparent' style={{padding: 0, paddingRight: 0}} onClick={() => {
                            setDeletionInfoDelivery(el.id);
                            openPopup("confirm-deletion-delivery-addres");
                        }}>
                            <Icon name={"trash-can"} style={{ width: '20px', height: '20px' }}/>
                        </button>
                    </>
                ]
            }))
            setKnownDeliveries(entriesToKeep)
            toast.success("Aflever adres Verwijderd!")
            closePopup();
        }).catch((e) => {
            toast.error("Er is een fout opgetreden met het verwijderen van een aflever adres")
            console.error(e)
        })
        await loadDeliveryAddresses();

    }

    function getValueByWidth(obj) {
        const orderedObj = Object.keys(obj)
            .sort((a, b) => {
                if (a === 'default') {
                    return 1
                } else if (b === 'default') {
                    return -1;
                }
                return parseInt(b) - parseInt(a)
            })
            .concat('default');

        for (const widthValue of orderedObj) {
            if (widthValue === 'default' || width > parseInt(widthValue)) {
                return obj[widthValue];
            }
        }
    }

    async function deleteJobTicket(e) {
        let jobTicketId = e.target.value

        authAxios.delete(`/workflow/job-tickets/${jobTicketId}`).then(() => {
            toast.success('Job ticket verwijderd.');

            // 'visually' remove ticket from know tickets
            let prunedJobTickets = order.jobTickets.filter((el) => Number(el.id) !== Number(jobTicketId))

            // set the prunned tickets as the new tickets
            setOrder((prev) => ({
                ...prev,
                jobTickets: prunedJobTickets
            }))
        }).catch((error) => {
            console.error(error);
            toast.error('Er is iets misgegaan bij het verwijderen van de job ticket.')
        });
    }

    if (!order || isLoading) {
        return <SpinnerCenter width='30' height='30' message="Order data ophalen..."/>

    }

    let reSetHjmgAmount = false;
    if (order.calculation.owner === "hjmg") {
        let noAmount = order?.calculation?.data?.printData?.amounts?.includes(order?.chosenAmount)
        let noCalcs = (order?.calculation?.data?.printData?.length > 0)

        // if we have NO amount set AND we DO have calculations
        reSetHjmgAmount = (!noAmount && noCalcs)
    }

    // if owner company is hjmg only warn the user if we have a calculation
    if (
        order.calculation.owner === "hjmg" ?
            reSetHjmgAmount
            :
            (order && !order?.calculation?.data?.amounts?.includes(order?.chosenAmount))
    ) {
        return (
            <div style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 10
            }}>
                <Block
                    name={'order-chosen-amount-error'}
                    title={<Grid
                        columns={2}
                        customColTemplate={"20px 1fr"}
                        gap={"10px"}
                        style={{alignItems: 'center'}}
                    >
                        <Icon
                            name={'circle-exclamation'}
                            width={'20px'}
                            fill={'var(--message-red)'}
                            style={{
                                fill: 'var(--message-red)'
                            }}
                        />
                        Error
                    </Grid>}
                    headerStyle={{
                        backgroundColor: 'var(--message-red-light)',
                        color: 'var(--message-red)',
                    }}
                    headerRightSideChildren={
                        <span>{order.number}</span>
                    }
                >
                    <strong style={{color: 'var(--message-red)'}}>Het aantal waarvoor deze order is gemaakt bestaat niet (meer) in de calculatie!</strong>
                    <br/>
                    <small style={{color: 'grey'}}>Dit kan voorkomen als de calculatie bewerkt is, nadat deze order is aangemaakt.</small>
                    <br/>
                    <br/>
                    <HasRole roles={['admin', 'management', 'productie manager', 'sales']}>
                        <p>Kies hieronder het juiste aantal:</p>
                        <ChosenAmountEditor
                            orderId={order.id}
                            amounts={order.calculation.owner === "hjmg" ? order.calculation.data.printData.amounts : order.calculation.data.amounts}
                            chosenAmount={order.chosenAmount}
                            getOrder={getOrder}
                        />
                    </HasRole>
                    <IF condition={!User.hasRole(['admin', 'management', 'productie manager', 'sales'])}>
                        <p>Neem contact op met een productie manager om dit op te lossen.</p>
                    </IF>
                </Block>
            </div>
        )
    }

    function downloadPurchaseQuote(purchaseQuote) {
        // Open pdf in newtab
        let pdfWindow = window.open("")
        pdfWindow.document.write(
            `<embed width='100%' height='100%' src='data:application/pdf;base64,${purchaseQuote.base64}'></embed>`
        )

    }

    const updateStatus = (e, orderId) => {

        authAxios.put(`/orders/${orderId}?${_ORDER_QUERY}`, {
            "data": {
                "status": e.target.value.toLowerCase()
            }
        }).then(({data}) => {
            setOrder(data.data);

            toast.success(`Order: ${data.data.number} status omgezet naar ${e.target.value}`);
        }).catch((exception) => {
            console.error(exception);
            toast.error(`Er ging iets mis met het bewerken van order.`);
        });
    }

    async function loadDeliveryAddresses(){
        let query = stringify({
            populate: {
                sendingAddresses: {
                    populate: {
                        branch: {
                            populate: {
                                address: true,
                            },
                            fields: ["id", "name"]
                        },
                        shpmnt: true
                    }
                }
            }
        });

        // Get delivery address
        await authAxios.get(`/orders/${params.orderId}?${query}`)
            .then(({data}) => {

                setDeliveries(data.data.sendingAddresses?.map((el) => {

                    let address = el?.branch?.address.addressLine1 ?? (el?.branch?.address.addressLine2 ?? (el?.branch?.address.addressLine3))
                    return [
                        el?.branch?.name ?? "--",
                        address ?? "--",
                        el?.locationManager ?? "--",
                        el?.amountSend ?? "--",
                        <>
                        {width >= 900 && (
                            <>
                            <button className='btn btn--transparent' data-title='Pakbon aanmaken' style={{padding: 0, marginRight: '20px' }} onClick={() => {
                                navigate(`./packing-slips/create?delivery-address=${el.id}`)
                            }}>
                                <Icon name={"fas boxes-packing"} style={{ width: '20px', height: '20px', fontSize: '20px', color: 'black' }}/>
                            </button>
                            {el?.shpmnt !== undefined && el?.shpmnt !== null ? <>
                                <Link data-title='Verzending bekijken.' to={`/shipping/shipments/${el?.shpmnt?.id}`} className='btn btn--transparent' style={{padding: 0, marginRight: '10px' }}>
                                    <Icon name={"fa list-check"} style={{ width: '20px', height: '20px', fontSize: '20px', color: 'black' }}/>
                                </Link>
                            </>: <>
                                <button
                                    data-title='Verzending aanmaken'
                                    className='btn btn--transparent'
                                    style={{padding: 0, marginRight: '13px' }}
                                    onClick={() => {
                                        navigate('/shipping/shipments/create',{
                                            state:{
                                                brancheId:el?.branch?.id,
                                                deliveryAddressId:el.id,
                                                companyId: order?.company?.id,
                                                contactPersonId: order?.contactPerson?.id,
                                                orderId: order?.id,
                                                orderValue:totalCost?.price
                                            }
                                        })
                                }}>
                                    <Icon
                                        name={"fa truck"}
                                        style={{ width: '20px', height: '20px', fontSize: '20px', color: 'black' }}/>
                                </button>
                            </>}
                            <button data-title='Verzendadres verwijderen' className='btn btn--transparent' style={{padding: 0, paddingRight: 0}} onClick={() => {
                                setDeletionInfoDelivery(el.id);
                                openPopup("confirm-deletion-delivery-addres");
                            }}>
                                <Icon name={"trash-can"} style={{ width: '20px', height: '20px' }}/>
                            </button>
                            </>
                        )}
                        </>
                    ]
                }))

            }).catch((e) => {
                toast.error("Er is een fout opgetreden met het ophalen van aflever adressen")
                console.error(e)
            })
    }

    function letMeShipCallback(){
        closePopup("let-me-ship-popup")
        getOrder();
    }


    return (
        <>
            <Popup
                style={{
                    width: 80 + '%',
                    left: `calc(50vw - ${80 / 2}%)`
                }}
                title="Verzending"
                popupID="let-me-ship-popup">
                <LetMeShip
                    deliveryContactPersonId={order?.contactPerson?.id}
                    deliveryCompanyId={order?.company?.id}
                    deliveryBranchId={deliveryBranchId}
                    deliveryAddress={deliveryAddress}
                    // callBack={letMeShipCallback}
                    orderId={order?.id}
                    companyId={company?.id}
                    orderValue={totalCost}/>
            </Popup>
            <Popup style={{width: 'auto'}} popupID='viewSticker' title={'Sticker'}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 10
                }}>
                    <IF condition={/image\/\w+/.test(selectedSticker?.type)}>
                        <img
                            style={{
                                objectFit: 'contain',
                                height: '100%',
                                width: '100%',
                                maxHeight: '550px',
                                maxWidth: '600px'
                            }}
                            src={selectedSticker?.url}
                            alt={selectedSticker?.name}
                        />
                    </IF>

                    <IF condition={!/image\/\w+/.test(selectedSticker?.type) && selectedSticker?.url}>
                        <embed
                            style={{
                                height: '550px',
                                width: '600px',
                            }}
                            src={selectedSticker?.url}
                        />
                    </IF>

                    <Button onClick={() => {
                        deleteSticker()
                    }}>
                        Verwijderen
                    </Button>
                </div>
            </Popup>

            <Popup style={{width: 'auto'}} popupID='addSticker' title={'Sticker toevoegen'}>
                <CreateSticker order={order} setOrder={setOrder}/>
            </Popup>

            <ConfirmationPopup
                title="Verwijderen"
                subject="Job ticket verwijderen"
                description="Weet u zeker dat u een job ticket wil verwijderen?"
                functionOnConfirm={(e) => deleteJobTicket(e)}
                confirmButtonProps={conformationProps}
            />
            <IF condition={fatalError === null}>
                <SpinnerCenter width='30' height='30'/>
            </IF>

            <IF condition={fatalError}>
                <div className='order-fatal-error'>
                    <Icon name='circle-exclamation'/>
                    <span className={'title'}>{fatalError?.title ?? fatalError}</span>
                    {fatalError?.error && <span>{fatalError?.error}</span>}
                    <Link className='btn btn--icon-left' to='/orders'>
                        <Icon name='arrow-left'/> Terug naar overzicht
                    </Link>
                </div>
            </IF>

            <HasRole roles={['admin']}>
                <Popup style={{width: 'auto'}} popupID='uploadFile' title={'Document'}>

                </Popup>
            </HasRole>

            <IF condition={fatalError === false}>
                <PageHeader
                    beingEdited={edit}
                    title={edit ?
                        <span>{`[${order?.number}] ${company?.name} - `} <input className={'margin-0'} id={'orderDescription'} value={order?.description} onChange={(e) => {

                            setOrder((prev) => ({
                                ...prev,
                                description: e.target.value
                            }))
                        }} type="text"/></span>
                        :
                        '[' + order?.number + '] ' + company?.name + (order?.description ? ' - ' + order?.description : '')}
                >
                    {edit ? <span>
                        <select
                            className={'margin-0'}
                            defaultValue={capitalize(order?.status) ?? undefined}
                            onClick={(e) => {
                            e.stopPropagation()
                            }} onChange={(e) => {
                            updateStatus(e, order.id)
                        }}>
                            {statusOptions}
                        </select>
                    </span> : <>
                        <span
                            style={{alignSelf: 'center'}}>
                            {order?.status}
                        </span>
                    </>}
                    {width >= 900 && (
                        <>
                        <HasRole roles={['admin', 'management', 'productie manager', 'sales']}>
                            <Button onClick={() => {
                                setEdit(!edit);
                                if (!extraWork.visible) {
                                    setExtraWork((prev) => ({
                                        ...prev,
                                        visible: true
                                    }))
                                }

                                (edit === true && saveDetails())
                            }} className='btn btn--round btn--black'><Icon name={edit === false ? 'pencil' : 'save'}/></Button>
                        </HasRole>
                        <IF condition={order?.calculation?.owner !== null && order?.calculation?.owner.toLowerCase() === 'pretore'}>
                            <HasRole roles={['admin', 'management', 'productie manager', 'sales']}>
                                <Button data-title={'Planning'} className={'btn btn--black btn--round'} to={order?.planning?.id ? `/planning/orders/${order?.id}` : `/planning/orders/${order?.id}/create`}><Icon name={'calendar'}/></Button>
                            </HasRole>
                            <HasRole roles={['admin', 'management', 'productie manager', 'sales']}>
                                <Button data-title={'Nacalculatie'} className={'btn btn--black btn--round'} to={`/orders/${order?.id}/actual-costing`}><Icon name={'book'}/></Button>
                            </HasRole>
                        </IF>
                        </>
                    )}
                </PageHeader>
                <Grid
                    row='4'
                    columns='8'
                >
                    <div style={{

                        gridRow: '1',
                        gridColumn: getValueByWidth({ 1300: '1 / 4', 750: '1 / 5', default: '1 / 9' })
                    }}>
                    <CompanyBlock
                        editable={true}
                        stateToEdit={setOrder}
                        saveFunction={saveDetails}
                        company={company}
                        contactPerson={contactPerson}
                        calculation={calculation}
                            style={{
                                gridRow: '1',
                                gridColumn: getValueByWidth({ 1300: '1 / 4', 750: '1 / 5', default: '1 / 9' })
                            }}
                    />
                        <Block
                            style={{ paddingTop: "var(--gap-l)" }}
                            name='table'
                            title='Afleveradressen'
                            headerRightSideChildren={
                                <button onClick={() => openPopup("OrderAddDeliveryAddres")} className={'btn btn--round'}><Icon name={'plus'} /></button>
                            }
                        >
                            <Table
                                structure={{
                                    450: ['50%', '50%', 0, "100px", 0],
                                    900: ['33%', '33%', '33%', "100px", 0],
                                    default: ['33%', '33%', '33%', "100px", "110px"]
                                }}
                                columnOverflowExceptions={[4]}
                                headers={[
                                    {
                                        value: 'Locatie',
                                        attributes: {
                                            style: {
                                                background: "white",
                                                color: "black",
                                                margin: '0px',
                                            }
                                        }
                                    }
                                    , {
                                        value: 'Plaats',
                                        attributes: {
                                            style: {
                                                background: "white",
                                                color: "black",
                                                margin: '0px',
                                            }
                                        }
                                    }, {
                                        value: 'Manager',
                                        attributes: {
                                            style: {
                                                background: "white",
                                                color: "black",
                                                margin: '0px',
                                            }
                                        }
                                    }, {
                                        value: 'Aantal',
                                        attributes: {
                                            style: {
                                                background: "white",
                                                color: "black",
                                                margin: '0px',
                                            }
                                        }
                                    }, {
                                        value: '',
                                        attributes: {
                                            style: {
                                                background: "white",
                                                color: "black",
                                                margin: '0px',
                                            }
                                        }
                                    }
                                ]}
                                data={deliveries}
                            />

                            <ConfirmationPopup
                                popupID="confirm-deletion-delivery-addres"
                                title="Aflever adres verwijderen?"
                                description={
                                    <>
                                        <span>Weet je zeker dat je dit adres wilt verwijderen? Dit kan niet ongedaan gemaakt worden.</span>
                                    </>
                                }
                                functionOnConfirm={deleteDeliveries}
                                confirmText={{
                                    value: "Verwijderen",
                                    style: {
                                        backgroundColor: 'white',
                                        color: 'var(--primary)',
                                        border: 'solid 2px var(--primary)',
                                        boxSizing: 'border-box'
                                    },
                                }}
                                declineText={{
                                    value: "Annuleren",
                                    style: {
                                        border: 'solid 2px var(--primary)',
                                    }
                                }}
                            />
                        </Block>
                    </div>
                    <Block
                        name='Checklist'
                        title='Checklist'
                        style={{
                            gridRow: getValueByWidth({750: '1', default: '2'}),
                            gridColumn: getValueByWidth({1300: '4 / 7', 750: '5 / 9', default: '1 / 9'})
                        }}
                    >

                        <ul style={{listStyleType: 'none'}} className={'checklist'}>

                            {!edit ?
                                FSCStatus ?
                                        <li>
                                <span className={'name'}>
                                    FSC
                                </span>:
                                            <span className={'value'}>
                                    <b>JA</b>
                                </span>
                                        </li>
                                        : <></>

                            : <>
                                <li>
                                    <span className={'name'}>
                                    FSC
                                    </span>:
                                    <span className={'value'}>
                                    <Boolean
                                        style={{
                                            marginBottom: '5px',
                                            marginTop: '5px',
                                            height: '38px'
                                        }}
                                        valueStyle={{
                                            margin: '3px',
                                            padding: '3px 10px',
                                            height: '32px',
                                            lineHeight: '24px'
                                        }}
                                        value={FSCStatus}
                                        displayFalse={"NEE"}
                                        displayTrue={"JA"}
                                        field={{}}
                                        setValue={(name, value) => {setFSCStatus(value)}}
                                    />
                                </span>
                                </li>
                            </>}
                            <IF condition={reference || edit}>
                                <li key={'customerReference'}>
                                    <span className={'name'}>
                                        {(calculation?.language?.toLowerCase() === 'nl' ? "Kenmerk klant" : "Customer reference")}
                                    </span>:
                                    <span className={'value'} style={{width: '100%'}}>
                                        <IF condition={edit}>
                                            <input
                                                name='reference'
                                                type='text'
                                                value={reference}
                                                style={{
                                                    margin: 0
                                                }}
                                                onChange={(reference) => {
                                                    setReference(reference.target.value)
                                                }}
                                            />
                                        </IF>
                                        <IF condition={!edit}>
                                            {reference}
                                        </IF>
                                    </span>
                                </li>
                            </IF>

                            {order?.deliveryDate !== undefined ? <>
                                <li key={'deliver-date'} style={{display: width > 500 ? 'flex' : 'block'}}>
                                <span className={'name'}>
                                    {(calculation?.language?.toLowerCase() === 'nl' ? "Leverdatum" : "Delivery date")}
                                </span>:
                                    <span className={'value'}>
                                    {formatDateWithoutTime(order.deliveryDate)}
                                </span>
                                </li>
                            </> : <></>}
                            {orderData?.checklist?.filter((check) => check.value !== '')?.filter(item => !['quantity', 'aantal', 'customerreference', 'kenmerk klant'].includes(item.name?.toLowerCase()))?.map((listItem) =>
                                <li key={listItem.name} style={{display: width > 500 ? 'flex' : 'block'}}>
                                    <IF condition={!["customerreference", "kenmerk klant"].includes(listItem.name?.toLowerCase())}>
                                        <span className={'name'}>
                                            {(calculation?.language?.toLowerCase() === 'nl' ? listItem.dutchName : listItem.englishName) ?? listItem.name}
                                        </span>:
                                        <span className={'value'}>
                                            {listItem.value}
                                        </span>
                                    </IF>
                                </li>
                            )}
                            <li key={"Informatie"} style={{display: (orderInformation !== '' && orderInformation !== null || edit) ? width > 500 ? 'flex' : 'block' : "none"}}>
                                <span className={'name'}>
                                    {(calculation?.language?.toLowerCase() === 'nl' ? "Informatie" : "Information") ?? "Informatie"}
                                </span>:
                                <span className={'value'} style={{width: '100%'}}>
                                    {edit === true ?
                                        <input value={orderInformation} style={{margin: 0, marginBottom: '-5px'}} onChange={(e) => {
                                            setOrderInformation(e.target.value)
                                        }}>
                                        </input>
                                        :
                                        <span>
                                            <b>
                                                {orderInformation}
                                            </b>
                                        </span>
                                    }
                                </span>
                            </li>

                            <li key={'quantity'} style={{display: width > 500 ? 'flex' : 'block'}}>
                                <span className={'name'}>
                                    {(calculation?.language?.toLowerCase() === 'nl' ? "Aantal" : "Quantity")}
                                </span>:
                                <span className={'value'} style={{width: '100%'}}>
                                    <IF condition={!edit}>
                                        {order.chosenAmount} {order.calculation.language === 'nl' ? 'stuks' : 'pieces'}
                                    </IF>
                                    <IF condition={edit}>
                                        <ChosenAmountEditor
                                            orderId={order.id}
                                            amounts={order.calculation.data.amounts}
                                            chosenAmount={order.chosenAmount}
                                            getOrder={getOrder}
                                        />
                                    </IF>
                                </span>
                            </li>

                            {(extraWork.visible) && <li>&nbsp;</li>}
                            <HasRole roles={['admin', 'management', 'sales', 'productie manager']}>
                                {(!extraWork.visible) && <li>&nbsp;</li>}
                                <li key={'extra-work'} style={{display: width > 500 ? 'flex' : 'block'}}>
                                    <span className={'name'}>
                                        Totale orderwaarde
                                    </span>:
                                    <span className={'value'}>

                                        {totalCost !== null ? <>
                                            {totalCost.currency === 'EUR' ? '€' : '$'} {totalCost.price}
                                        </> : "-"}
                                    </span>
                                </li>
                            </HasRole>

                            <li key={"meerwerk"} style={{display: extraWork.visible === true ? width > 500 ? 'flex' : 'block' : "none"}}>
                                <span className={'name'}>
                                    {(calculation?.language?.toLowerCase() === 'nl' ? "Meerwerk" : "Additional work") ?? "Meerwerk"}
                                </span>:
                                <span className={'value'} style={{width: '100%'}}>
                                    {edit === true ?
                                        <textarea value={extraWork.value} style={{margin: 0, marginBottom: '-5px'}} onChange={(e) => {
                                            setExtraWork((prev) => ({...prev, value: e.target.value}))
                                        }}>
                                        </textarea>
                                        :
                                        <span className={'value'}>
                                            {extraWork.value}
                                        </span>
                                    }
                                </span>
                            </li>
                        </ul>
                    </Block>

                    <Grid
                        columns={getValueByWidth({1300: '1', 1000: '3', 650: '2', default: '1'})}
                        style={{
                            gridRow: getValueByWidth({1300: '1 / 5', 750: '2 / 4', default: '3'}),
                            gridColumn: getValueByWidth({1300: '7 / 9', default: '1 / 9'})
                        }}
                    >

                        <HasRole roles={['admin', 'management', 'productie manager', 'sales']}>
                            <Block
                                name='table'
                                title='Documenten'
                            >
                                <Table
                                    structure={{
                                        350: [0, '100%', '50px'],
                                        default: ['100%', '100%', '50px'],
                                    }}
                                    data={getDocumentData([
                                        {object: calculation, name: 'Calculatie', urlName: 'calculations', isView: true, openInSameTab: true},
                                        {object: calculation?.stockQuotes?.stockPurchaseSlip, name: 'Calculatie', urlName: 'calculations', isView: true},
                                        {object: order?.quote, name: 'Offerte', urlName: 'quotes'},
                                        {object: order?.quoteConfirmation, name: 'Bevestiging', urlName: 'orders/confirmation'},
                                    ])}
                                />
                            </Block>
                        </HasRole>

                        <Block
                            name="table"
                            title='Formulieren'
                        >
                            <Table
                                structure={{
                                    350: [0, '100%', '50px'],
                                    default: ['100%', '100%', '50px'],
                                }}
                                data={getDocumentData([
                                    {object: order, name: 'PDF', urlName: `pdf`, isFullUrl: true, openInSameTab: true},
                                ])}
                            />
                        </Block>

                        <IF condition={calculation !== null ? calculation.owner === 'pretore' : false}>
                            <HasRole roles={['admin', 'management', 'productie manager', 'sales']}>
                                <Block
                                    name='table'
                                    title='Werkderden'
                                    headerRightSideChildren={
                                        <button onClick={() => navigate(`/purchase/quotes/create/${params.orderId}`)} className={'btn btn--round'}><Icon name={'plus'}/></button>
                                    }
                                >
                                    <Table
                                        structure={{
                                            default: ['100%', '50px'],
                                        }}
                                        columnOverflowExceptions={[2]}
                                        data={(order?.purchaseQuotes ?? [])?.map((purchaseQuote) => (
                                            {
                                                data: [
                                                    <span
                                                        style={{cursor: 'pointer'}}
                                                        onClick={() => {
                                                            navigate(`/purchase/quotes/${purchaseQuote.id}`)
                                                        }}>
                                                        <b>{purchaseQuote.number}</b>
                                                    </span>,
                                                    <span
                                                        style={{cursor: 'pointer'}}
                                                        onClick={(e) => {
                                                            e.preventDefault()
                                                            downloadPurchaseQuote(purchaseQuote);
                                                        }}>
                                                        <Icon name='eye' style={{width: '20px', height: '20px'}}/>
                                                    </span>
                                                ]
                                            }
                                        ))}
                                    />
                                </Block>

                                <HasRole roles={['admin']}>
                                    <Block
                                        name='table'
                                        title='Bestanden'
                                        headerRightSideChildren={
                                            <button onClick={() => { openPopup('OrderUploadFilePopup') }} className={'btn btn--round'}><Icon name={'upload'}/></button>
                                        }
                                    >
                                        <Table
                                            structure={{
                                                default: ['100%', '100%'],
                                            }}
                                            data={getFileTableData()}
                                        />
                                    </Block>
                                </HasRole>

                                <Block
                                    name='table'
                                    title='Job tickets'
                                    headerRightSideChildren={
                                        <button onClick={() => navigate(`/orders/${params.orderId}/job-ticket/create`)} className={'btn btn--round'}><Icon name={'plus'}/></button>
                                    }
                                >
                                    <Table
                                        structure={{
                                            350: [0, '100%', '100px'],
                                            default: ['100%', '100%', '100px'],
                                        }}
                                        columnOverflowExceptions={[2]}
                                        data={(order?.jobTickets ?? [])?.map((ticket) => (
                                            {
                                                attributes: {
                                                    onClick: (e) => {
                                                        navigate(`/orders/${params.orderId}/job-ticket/${ticket?.id}/logs`)
                                                    }
                                                },
                                                data: [
                                                    <b data-title-left={ticket.name}>{ticket.name}</b>,
                                                    ticket.status,
                                                    <Grid columns={4} gap='1px'>
                                                        {/* condition/status */}
                                                        <IF condition={!_JOB_TICKET_IN_PROGRESS_STATUS.includes(ticket.status)}>
                                                            <button onClick={(e) => {
                                                                e.stopPropagation();
                                                                queueJobTicket(ticket.id);
                                                            }} className='btn btn--transparent' style={{padding: 0, paddingRight: 0}} data-title={'Aftrappen'}>
                                                                <Icon style={{width: '20px', height: '20px'}} name={'play'}/>
                                                            </button>
                                                        </IF>
                                                        <IF condition={_JOB_TICKET_IN_PROGRESS_STATUS.includes(ticket.status)}>
                                                            <Spinner width={20} height={20}/>
                                                        </IF>

                                                        {/* edit XML */}
                                                        <button onClick={(e) => {
                                                            e.stopPropagation();
                                                            navigate(`job-ticket/${ticket.id}`)
                                                        }} className='btn btn--transparent' style={{ padding: 0, paddingRight: 0 }} data-title={'Aanpassen'}>
                                                            <Icon style={{ width: '20px', height: '20px' }} name={'pencil'} />
                                                        </button>

                                                        {/* download XML */}
                                                        <button onClick={(e) => {
                                                            e.stopPropagation();
                                                            downloadXml(ticket, ticket.xml)
                                                        }} className='btn btn--transparent' style={{padding: 0, paddingRight: 0}} data-title={'Downloaden'}>
                                                            <Icon style={{width: '20px', height: '20px'}} name={'download'}/>
                                                        </button>
                                                        {/* logs */}
                                                        {/*<button onClick={(e) => { e.stopPropagation(); navigate(`/orders/${params.orderId}/job-ticket/${ticket?.id}/logs`); }} className='btn btn--transparent' style={{ padding: 0, paddingRight: 0 }} data-title={'Log'}>*/}
                                                        {/*    <Icon style={{ width: '20px', height: '20px' }} name={'clipboard-list'} />*/}
                                                        {/*</button>*/}
                                                        <button value={ticket.id} className='btn btn--transparent' style={{padding: 0, paddingRight: 0}} data-title={'Verwijderen'} onClick={
                                                            (e) => {
                                                                e.stopPropagation();
                                                                // set the modal props
                                                                setConfirmationProps({value: ticket.id});
                                                                openPopup("confirmationPopup")
                                                            }
                                                        }>
                                                            <Icon style={{width: '20px', height: '20px'}} name={"trash-can"}/>
                                                        </button>
                                                    </Grid>
                                                ]
                                            }
                                        ))}
                                    />
                                </Block>

                                <Block
                                    name='table'
                                    title='Pakbonnen'
                                    headerRightSideChildren={
                                        <Link to='packing-slips/create' className='btn btn--round'>
                                            <Icon name='plus'/>
                                        </Link>
                                    }
                                >
                                    <Table
                                        structure={{
                                            350: [0, '100%', '50px'],
                                            default: ['100%', '100%', '50px'],
                                        }}
                                        data={getDocumentData(
                                            order?.packingSlips.map((slip) => {
                                                return {
                                                    object: slip,
                                                    name: 'Pakbon',
                                                    urlName: `packing-slips/${slip?.id}`,
                                                    isFullUrl: true
                                                };
                                            })
                                        )}
                                    />
                                </Block>

                                <Block
                                    name='table'
                                    title='Stickers'
                                    headerRightSideChildren={
                                        <Button className='btn btn--round' onClick={() => openPopup("addSticker")}>
                                            <Icon name='plus'/>
                                        </Button>
                                    }
                                >
                                    <Table
                                        structure={{
                                            // 350: [0, '100%', '50px'],
                                            default: ['100%', '100px'],
                                        }}
                                        data={getStickerTableData()}
                                    />
                                </Block>

                                <PlanningHistory planningRecords={planningEntries}/>
                            </HasRole>
                        </IF>
                    </Grid>
                    {/*<IF condition={HJMGOrderData !== null}>*/}

                    {HJMGOrderData !== null ? <>
                        {Object.keys(HJMGOrderData).map((group, key) => {

                            // If recurring else simple view
                            if (HJMGOrderData[group].isRecurring !== undefined ? HJMGOrderData[group].isRecurring : false) {

                                // Gather table data
                                let tableData = [];
                                for (const entryData of HJMGOrderData[group].entries) {
                                    tableData.push([
                                        entryData.work.label,
                                        entryData.description,
                                        entryData.time !== 0 ? `${formatTime(parseTimeToNumber(entryData.time))} ${planningSuffix(entryData?.billingPeriod)}` : <i style={{color: 'gray'}}>N.v.t.</i>,
                                    ])
                                }

                                return <Block
                                    title={group}
                                    name={group}
                                    style={{
                                        gridColumn: '1/10'
                                    }}
                                >
                                    <Table
                                        headers={['type', 'Omschrijving', 'tijd']}
                                        data={tableData}
                                    />
                                </Block>
                            } else {

                                // Gather table data
                                let tableData = [];
                                for (const entryData of HJMGOrderData[group].entries) {
                                    tableData.push([
                                        entryData.description,
                                        entryData.time ? `${formatTime(parseTimeToNumber(entryData.time))} ${planningSuffix(entryData?.billingPeriod)}` : <i style={{color: 'gray'}}>N.v.t.</i>
                                    ])
                                }

                                return <Block
                                    title={group}
                                    name={group}
                                    style={{
                                        gridColumn: '1/10'
                                    }}
                                >
                                    <Table
                                        headers={['Omschrijving', 'tijd']}
                                        data={tableData}
                                    />
                                </Block>
                            }
                        })}
                    </> : <></>}


                    {/*</IF>*/}
                    <IF condition={calculation !== null ? calculation.owner === 'pretore' : false}>
                        <div
                            className='table'
                            style={{
                                gridRow: getValueByWidth({1300: '2', 750: '4', default: 5}),
                                gridColumn: getValueByWidth({1300: '1 / 7', default: '1 / 9'})
                            }}
                        >
                            <Table
                                headers={['Machines', 'Totaal', 'Uren', 'Ingepland', 'Gerealiseerd', (width <= 950 || width > 1200 && width <= 1830) ? '' : 'status']}
                                structure={{
                                    450: ['100%', 0, 0, 0, 0, '30px'],
                                    600: ['100%', 0, , 0, 0, '110px', '30px'],
                                    800: ['100%', '110px', 0, , 0, '110px', '30px'],
                                    950: ['100%', '110px', '110px', '110px', '110px', '30px'],
                                    1200: ['100%', '110px', '110px', '110px', '110px', '150px'],
                                    1300: ['100%', '110px', '110px', 0, '110px', '30px'],
                                    1500: ['100%', '110px', '110px', '110px', '110px', '30px'],
                                    1830: ['100%', '150px', '150px', '150px', '150px', '30px'],
                                    default: ['100%', '150px', '150px', '150px', '150px', '150px'],
                                }}
                                data={getMachineTableData()}
                            />
                        </div>
                        <div
                            className='table'
                            style={{
                                gridRow: getValueByWidth({1300: '3', 750: '5', default: 6}),
                                gridColumn: getValueByWidth({1300: '1 / 7', default: '1 / 9'})
                            }}
                        >
                            <Table
                                headers={['Materialen', 'Totaal', 'Beschikbaar', 'Gereserveerd', 'Gebruikt',
                                    <button className="btn btn--black" onClick={() => openPopup('OrderAddAdditionalMaterial')}>
                                        <Icon name="plus"/>
                                    </button>
                                ]}
                                structure={{
                                    350: ['100%', 0, 0, 0, 0, '60px'],
                                    450: ['50%', '50%', 0, 0, 0, '60px'],
                                    600: ['100% / 3', '100% / 3', '100% / 3', 0, 0, '60px'],
                                    900: ['25%', '25%', '25%', '25%', 0, '50px'],
                                    default: ['20%', '20%', '20%', '20%', '20%', '70px'],
                                }}
                                data={materialData}
                                columnOverflowExceptions={[5]}
                            />
                        </div>
                        <div
                            className='table'
                            style={{
                                gridRow: getValueByWidth({1300: '4', 750: '6', default: 7}),
                                gridColumn: getValueByWidth({1300: '1 / 7', default: '1 / 9'})
                            }}
                        >
                            <Table
                                headers={['Uren', 'Instel tijd', 'Productie tijd', 'Beschikbare tijd', 'Verbruikt', '']}
                                structure={{
                                    450: ['100%', 0, 0, 0, '100px', '45px'],
                                    550: ['100%', 0, 0, '100px', '100px', '45px'],
                                    650: ['100%', 0, '100px', '100px', '100px', '45px'],
                                    750: ['100%', '100px', '100px', '100px', '100px', '45px'],
                                    800: ['100%', '120px', '120px', '120px', '120px', '45px'],
                                    1500: ['100%', '150px', '150px', '150px', '100px', '45px'],
                                    default: ['100%', '200px', '200px', '200px', '200px', '45px'],
                                }}
                                data={getEmployeeTableData()}
                            />
                        </div>

                    </IF>
                </Grid>

                <ReserveMaterialPopup
                    defaultMaterialId={selectedMaterial.materialId}
                    defaultQuantity={selectedMaterial.quantity}
                    defaultUnit={selectedMaterial.unit}
                    defaultWarehouse={selectedMaterial.warehouseId}
                    onClose={gatherDataForOrderPDF}
                    order={order}
                    setOrder={setOrder}
                    orderMaterials={orderData.materials}
                    maximumQuantity={selectedMaterial.maximumQuantity}
                />

                <WriteOffMaterialPopup
                    defaultMaterialId={selectedMaterial.materialId}
                    defaultQuantity={selectedMaterial.quantity}
                    defaultUnit={selectedMaterial.unit}
                    defaultWarehouse={selectedMaterial.warehouseId}
                    onClose={gatherDataForOrderPDF}
                    order={order}
                    setOrder={setOrder}
                    orderMaterials={orderData.materials}
                />

                <OrderAddAdditionalMaterial
                    order={order} setOrder={setOrder}
                />

                <OrderAddDeliveryAddressPopup loadDeliveryAddresses={loadDeliveryAddresses} setDeletionInfoDelivery={setDeletionInfoDelivery} setKnownDeliveries={setKnownDeliveries} knownSendingAddresses={knownDeliveries} setDeliveries={setDeliveries} orderId={order.id} companyId={company.id} knowContactPerson={formatName(contactPerson?.firstName, contactPerson?.prefixToSurname, contactPerson?.surname)} />

                <OrderUploadFilePopup order={order} setOrder={setOrder} />

                <TimerPopup
                    timerFor={timerFor}
                    orderId={order.id}
                />

                <UserdataMachinePopup
                    machine={popupData?.name}
                    orderId={order.id}
                />
            </IF>
        </>
    );
}

function ChosenAmountEditor({orderId, amounts, chosenAmount, getOrder}) {

    const {authAxios} = useContext(FetchContext);

    const [selectedAmount, setSelectedAmount] = useState(null);
    const [isUpdating, setIsUpdating] = useState(false);

    const [allowCalculationToLoad, setAllowCalculationToLoad] = useState(false);
    const [updatedAmount, setUpdatedAmount] = useState(null);

    useEffect(() => {
        if (chosenAmount && amounts.includes(chosenAmount)) {
            setSelectedAmount(chosenAmount);
        }
    }, [chosenAmount, amounts]);

    const updateChosenAmount = async () => {

        // Skip if unchanged
        if (updatedAmount === chosenAmount) {
            return;
        }

        setIsUpdating(true);

        // Update the order
        await authAxios.put(`/orders/${orderId}`, {
            data: {
                chosenAmount: updatedAmount,
            }
        });

        await getOrder();

        setSelectedAmount(amounts.find(amount => amount === updatedAmount));

        setIsUpdating(false);
    }

    return (
        <div>
            <Popup popupID='newCalculationAmount'>
                <CreateCalculationPopup
                    orderId={orderId}
                    allowLoading={allowCalculationToLoad}
                />
            </Popup>
            <ConfirmationPopup
                popupID="confirm-change-chosen-amount"
                title="Gekozen aantal aanpassen"
                description={
                    <>
                        <span>Weet je zeker dat je dit aantal wilt aanpassen? Dit kan niet ongedaan gemaakt worden.</span>
                        <br/><br/>
                        <small style={{color: 'gray'}}>Hoewel dit niet teruggedraaid kan worden, is dit achteraf nog wel aan te passen op de order pagina.</small>
                    </>
                }
                functionOnConfirm={updateChosenAmount}
                confirmText={{
                    value: "Aanpassen",
                    style: {
                        backgroundColor: 'white',
                        color: 'var(--primary)',
                        border: 'solid 2px var(--primary)',
                        boxSizing: 'border-box'
                    },
                }}
                declineText={{
                    value: "Annuleren",
                    style: {
                        border: 'solid 2px var(--primary)',
                    }
                }}
                buttonProps={{
                    value: updatedAmount,
                }}
            />

            <Grid columns={2} customColTemplate={`${User.hasRole(['admin','management']) ? '1fr 70px' : '1fr'}`}>
                <span>
                    <Select
                        value={{label: selectedAmount, value: selectedAmount}}
                        options={amounts?.map(amount => ({label: amount, value: amount}))}
                        onChange={option => {
                            setUpdatedAmount(option.value);
                            openPopup("confirm-change-chosen-amount");
                        }}
                        isLoading={isUpdating}
                        isDisabled={isUpdating}
                        styles={{
                            container: (provided) => ({
                                ...provided,
                                margin: '0 !important',
                            })
                        }}
                    />
                </span>
                <HasRole roles={['admin']}>
                    <span>
                        <Button onClick={() => openPopup('newCalculationAmount',true,true,null,setAllowCalculationToLoad(true))}>
                            Anders
                        </Button>
                    </span>
                </HasRole>
            </Grid>
        </div>
    );
}
