import { getAuthAxios } from "App/Util/fetch";

export async function actualOrderCostingLoader({ params }) {
    const { orderId } = params;

    const authAxios = getAuthAxios();

    const order = await authAxios.get(`orders/${orderId}`, {
        params: {
            fields: ['FSC', 'chosenAmount', 'customerReference', 'description', 'information', 'number', 'status', 'stockData', 'information', 'extraWork'],
            populate: {
                calculation: {
                    fields: ['language', 'data']
                },
                quote: {
                    fields: ['priceData']
                },
                company: {
                    fields: ['name']
                },
                contactPerson: {
                    fields: ['firstName', 'surname', 'prefixToSurname', 'email']
                },
                timers: {
                    populate: ['breaks']
                },
                additionalMaterials: {
                    populate: ['material']
                }
            }
        }
    }).catch(e => {
        return null;
    });

    if (!order) {
        throw new Response(`Order ${orderId} kon niet worden gevonden`, { status: 404 });
    }

    return {
        order: order.data.data
    }
}