import Block from "UI/App/Components/Block/Block";
import React, { useEffect, useState } from "react";
import { useLoaderData } from "react-router-dom";
import styles from './summary.module.scss';
import ActualCostingCalculator from "UI/App/Partials/Content/CRM/Orders/ActualCosting/ActualCostingCalculator";
import { formatCurrency, formatNumberValue } from "App/Util/format";

export default function Summary() {
    const { order } = useLoaderData();

    const calculator = new ActualCostingCalculator(order);

    const calculationCost = calculator.getCalculationCost();
    const quoteCost = calculator.getQuoteCost();
    const costPrice = calculator.getCostPrice();

    // Seperate component to avoid rerendering
    const RealisedCostWithDiff = () => {
        const [realisedCost, setRealisedCost] = useState(0);

        useEffect(() => {
            calculator.getRealisedCost()
                .then(cost => setRealisedCost(cost));
        }, []);

        const quoteDiff = quoteCost - realisedCost;
        const quoteDiffPercentage = (quoteDiff) / realisedCost * 100;

        const costPriceDiff = calculationCost - realisedCost;
        const costPriceDiffPercentage = (costPriceDiff) / realisedCost * 100;

        return (
            <>
                <li><span className={'name'}>Daadwerkelijke kostprijs</span>:<span className={'value'}>{formatCurrency(realisedCost)}</span></li>
                <li><span className={'name'}>Gerealiseerde marge</span>:<span className={'value'}>{formatCurrency(quoteDiff)} (<span style={{ color: quoteDiff < 0 ? 'var(--message-red)' : 'var(--message-green)' }}>{quoteDiffPercentage >= 0 ? '+' : ''}{formatNumberValue(quoteDiffPercentage, 'procent')}</span>)</span></li>
                <li><span className={'name'}>Verschil kostprijs</span>:<span className={'value'}>{formatCurrency(costPriceDiff)} (<span style={{ color: costPriceDiff < 0 ? 'var(--message-red)' : 'var(--message-green)' }}>{costPriceDiffPercentage > 0 ? '+' : ''}{formatNumberValue(costPriceDiffPercentage, 'procent')}</span>)</span></li>
            </>
        )
    }

    return (
        <Block title='Samenvatting' name='summary'>
            <strong>Prijs</strong>
            <ul className={styles.summary} style={{ listStyleType: 'none' }}>
                <li><span className={'name'}>Calculatie bedrag</span>:<span className={'value'}>{formatCurrency(calculationCost)}</span></li>
                <li><span className={'name'}>Kostprijs</span>:<span className={'value'}>{formatCurrency(costPrice)}</span></li>
                <li><span className={'name'}>Offerte bedrag</span>:<span className={'value'}>{quoteCost ? formatCurrency(quoteCost) : 'N.v.T.'}</span></li>
                <br/>
                <RealisedCostWithDiff/>
            </ul>
        </Block>
    )
}