import React, {useContext, useEffect, useState} from "react";
import {FetchContext} from "App/Strapi/FetchContext";
import {formatCurrency} from "App/Util/format";
import {toast} from "react-toastify";
import {Link, useNavigate} from "react-router-dom";
import Icon from "UI/App/Components/Icon/Icon";
import Table from "UI/App/Components/Table/Table";
import {usePagination} from "UI/App/Components/Pagination/Pagination";
import qs from "qs";
import { BooleanControlled } from "UI/App/Components/Form/Boolean";
import DataExporter from "UI/App/Components/Export/DataExporter";
import HasRole from "UI/App/Components/Auth/HasRole";
import { AuthContext } from 'App/Strapi/AuthProvider';
import useWindowDimensions from "App/Util/useWindowDimensions";

export default function Machines() {
    const {authAxios} = useContext(FetchContext);
    const authData = useContext(AuthContext);
    const navigate = useNavigate();
    const [machines, setMachines] = useState([]);
    const {width} = useWindowDimensions();

    // Enable pagination
    const {
        paging,
        filtering,
        currentPage,
        resultsPerPage,
        setTotalPages,
        setTotalResults,
        filterQuery,
        paginationStateLoaded
    } = usePagination({
        storageKey: 'machines',
        searchSettings: {
            enabled: true,
            strapiFields: [
                'name',
            ]
        },
        filters: [
            {
                name: 'active',
                type: 'select',
                options: [
                    {name: 'Alle', value: '*'},
                    {name: 'Actief', value: 'false'},
                    {name: 'Inactief', value: 'true'},
                ],
                strapiFilterFields: [
                    'archived'
                ]
            },
        ], htmlElements: [
            <HasRole roles={['admin']}>
                <button className={'btn btn--icon'} onClick={() => exportData()}>Export <Icon name='download' /></button>
            </HasRole>,
            <Link className={'btn btn--icon'} to={'./machines/create'}>Toevoegen <Icon name={'plus'}/></Link>
        ],
        buttonCollapseBreakpoint: 1300,
        resultsPerPageBreakpoint: 1430,
        resultCountBreakpoint: 800
    });

    async function updateMachineArchiveState(machineId, boolean) {
        const updateToast = toast.loading('Waarde aanpassen.')

        // For machines where archived boolean is not set
        if (boolean === null) {
            boolean = false;
        }

        // update template
        authAxios.put(`/calculations/resources/machines/${machineId}`, {
            data: {
                archived: boolean
            }
        }).then(({data}) => {
            loadMachines(updateToast);
        }).catch((exception) => {
            console.log(exception);
            toast.error(`Er ging iets mis met het opslaan van widget-templates!`);
        });

        console.log(machineId, boolean);
    }

    useEffect(() => {
        if (!paginationStateLoaded) return;

        loadMachines();
    }, [currentPage, resultsPerPage, filterQuery, paginationStateLoaded]);

    async function loadMachines(updateToast) {
        const query = qs.stringify({
            sort: 'name',
            pagination: {
                pageSize: resultsPerPage,
                page: currentPage
            }
        });

        // get the machines from the api
        await authAxios
            .get(`/calculations/resources/machines?${query}&${filterQuery}&sort[1]=position:desc`)
            .then(({data}) => {
                // check for the data
                if (data.data) {
                    setMachines(data.data.map(/** @param {Record<string, any>} machine **/(machine) => {
                        return {
                            attributes: {
                                id: machine.id,
                                onClick: () => {
                                    navigate(`/calculations/settings/machines/${machine.id}/view`)
                                }
                            },
                            data: [
                                machine.name,
                                machine?.netSpeedPerHour ? ((machine.netSpeedPerHour?.toFixed(0)) + " " + (machine?.speedUnit ?? '')) : '-',
                                machine?.productionCostPerHour ? (formatCurrency(machine.productionCostPerHour)) : '-',
                                <BooleanControlled
                                    leftLabel='Inactief'
                                    rightLabel='Actief'
                                    leftIsRed={true}
                                    value={!machine?.archived} // inversed to stay consistent
                                    setValue={(value) => {
                                        if (machine.archived !== !value || machine.archived === null) {
                                            updateMachineArchiveState(machine.id, !value);
                                        }
                                    }}
                                />,
                                , <div className={'table__action-buttons'}>
                                    {width >= 900 && (
                                        <>
                                        <Link data-title={'Bekijk machine'} className={'btn btn--transparent'} to={`/calculations/settings/machines/${machine.id}/view`}><Icon name={'eye'}/></Link>
                                        <Link data-title={'Widget bewerken'} onClick={(e) => e.stopPropagation()} className={'btn btn--transparent'} to={`../widgets/create/${machine.id}`}><Icon name={'widgets'}/></Link>
                                        <Link data-title={'Planning bekijken'} className={'btn btn--transparent'} to={`/planning/machines/${machine.id}`} onClick={(e) => e.stopPropagation()}><Icon name={'calendar'}/></Link>
                                        </>
                                    )}
                                </div>
                            ]
                        }
                    }))

                    // Set pagination
                    const paginationData = data.meta.pagination;
                    setTotalPages(paginationData.pageCount);
                    setTotalResults(paginationData.total);
                }
            }).catch((e) => {
                if (process.env.NODE_ENV === 'development') console.error(e);
                toast.error(`Er is iets fout gegaan bij het ophalen van de machines! ${e?.response?.status && `(${e.response.status})`}`);
            });

        if (updateToast !== undefined) {
            toast.update(updateToast, {
                render: `Machine status geupdate.`,
                type: "success",
                isLoading: false,
                autoClose: 1000
            });
        }
    }

    async function exportData() {
        const settings = [
            {
                header:'Naam',
                data: 'name'
            },
            {
                header:'Aanschafwaarde',
                data: 'initialCost'
            },
            {
                header:'Rest waarde',
                data: 'residualValue'
            },
            {
                header:'Afschrijving jaren',
                data: 'depreciationYears'
            },
            {
                header:'Jaarlijkse afschrijving',
                data: 'yearlyDepreciation'
            },
            {
                header:'Service kosten',
                data: 'serviceCost'
            },
            {
                header:'Bezettingsuren per jaar',
                data: 'occupationalHoursPerYear'
            },
            {
                header:'Uurtarief',
                data: 'hourlyRate'
            },
            {
                header:'Arbeidsinspanning',
                data: 'laborEffort'
            },
            {
                header:'Arbeidsuren',
                data: 'laborHours'
            },
            {
                header:'Uurloon personeel',
                data: 'employeeWage'
            },
            {
                header:'Productie uurloon',
                data: 'productionCostPerHour'
            },
            {
                header:'Bruto snelheid (per uur)',
                data: 'grossSpeed'
            },
            {
                header:'Snelheid eenheid',
                data: 'speedUnit'
            },
            {
                header:'Uptime',
                data: 'uptime'
            },
            {
                header:'Netto snelheid (Per uur)',
                data: 'netSpeedPerHour'
            },
            {
                header:'Opstart tijd (minuten)',
                data: 'startupTime'
            },
            {
                header:'Opstart kosten',
                data: 'startupCost'
            }
        ]

        await new DataExporter(authAxios, authData, settings, `/calculations/resources/machines/all`, 'export').exportData()
    }

    return (
        <>
            {filtering}
            <Table
                headers={["Machine", "Netto snelheid", "Uurtarief", '', '']}
                structure={{
                    450: ['100%', 0, 0, '160px', 0],
                    650: ['100%', 0, '80px', '160px', 0],
                    900: ['100%', '200px', '100px', '160px', 0],
                    950: ['100%', '200px', '100px', '200px', '140px'],
                    1300: ['100%', '250px', '100px', '200px', '140px'],
                    1600: ['100%', '300px', '150px', '200px', '140px'],
                    default: ['100%', '400px', '150px', '200px', '140px']
                }}
                data={machines}
                columnOverflowExceptions={[5]}
            />
            {paging}
        </>
    )
}
