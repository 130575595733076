import { download, generateCsv, mkConfig } from "export-to-csv";
import { addLog } from "./addLog";
import { stringify } from "qs";
import { formatCurrency } from "App/Util/format";

export function exportObject(csvData, csvFileOptions = {
    filename: `Unknown_export-${new Date().toLocaleDateString("nl-NL", { year: "numeric", month: "2-digit", day: "2-digit" })}`, // date in DD-MM-YYYY
    fieldSeparator: ';',
    decimalSeparator: 'locale',
    useKeysAsHeaders: true,
    showColumnHeaders: true,
    useBom: true,
},
    authAxios,
    authData,
    action = undefined,
) {
    // available options at: https://github.com/alexcaza/export-to-csv#api
    const csv = generateCsv(csvFileOptions)(csvData);

    // download csv
    download(csvFileOptions)(csv);

    if (authAxios === undefined || authData === undefined) {
        console.warn("Not all context provided to add a log for export: ", csvFileOptions.filename)
    } else {
        addLog({
            authAxios: authAxios,
            authData: authData,
            payload: {
                occurredAt: new Date(),
                csvOptions: csvFileOptions
            },
            status: "200",
            user: authData.authState.user.id,
            apiToken: null,
            action: action ?? "export"
        })
    }
}

export function getExportMaterials(authAxios, authData) {
    const query = stringify({
        populate: {
            stock: true
        }
    }, { encodeValuesOnly: true });
    authAxios
        .get(`/logistics/materials/all?${query}`)
        .then(({ data }) => {
            // create list of all materials to export
            let exportList = []

            for (let material of data) {
                let totalAmount = 0;
                // calculate pcs.
                let pricePerPiece = material.purchasePrice / material.countPerUnit;

                // check all know stocks for total material count
                for (let stock of material.stock) {
                    totalAmount += stock.total
                }


                // push formated findings to an array
                exportList.push({
                    "Categorie": material?.category ?? "",
                    "Artikelnummer klant": material?.supplierSKU ?? "",
                    "Intern artikelnummer": material?.internalSKU ?? "",
                    "Offertenaam": material?.quotationNameNL ?? "",
                    "Aantal": totalAmount,
                    "Eenheid": material.unit ?? "onbekend",
                    "Stuk prijs": `${formatCurrency(pricePerPiece)}`,
                    "Totale waarde voorraad": ` ${formatCurrency(pricePerPiece * totalAmount)}`
                })

            }

            // export the formated list
            exportObject(exportList, {
                filename: `actuele_voorraad-${new Date().toLocaleDateString("nl-NL", { year: "numeric", month: "2-digit", day: "2-digit" })}`, // date in DD-MM-YYYY
                fieldSeparator: ';',
                decimalSeparator: 'locale',
                useKeysAsHeaders: true,
                showColumnHeaders: true,
                useBom: true,
            }, authAxios, authData, "export.warehouses");
        })
}
