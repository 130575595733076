import React, {useEffect, useState} from "react";
import Select from "react-select";
import Block from "../../../../Components/Block/Block";
import FromCalculation from "./Create/FromCalculation";
import FromConfirmation from "./Create/FromConfirmation";
import FromQuote from "./Create/FromQuote";
import Grid from "UI/App/Components/Grid/Grid";
import Boolean from "UI/App/Components/Form/Boolean";

export default function CreateOrder({companyId = undefined}) {
    const [types, setTypes] = useState([]);
    const [type, setType] = useState(null);
    const [FSCStatus,setFSCStatus] = useState(false);

    useEffect(() => {
        setTypes([
            {
                value: 'Calculation',
                label: 'Calculatie'
            },
            {
                value: 'Quote',
                label: 'Offerte'
            },
            {
                value: 'Confirmation',
                label: 'Bevestiging'
            }
        ])
    }, []);


    return (
        <>
            <Block name={'Order toevoegen'}>
                <Grid columns={2} customColTemplate={'1fr 100px'}>
                    <span>
                    Waar van wilt u een order maken?
                    <Select
                        name='type'
                        id='type'
                        isClearable={false}
                        options={types}
                        value={type}
                        onChange={(type) => {
                            setType(type);
                        }}
                        required
                    />
                    </span>
                    <span>
                        FSC
                        <Boolean
                            style={{
                                marginBottom: '5px',
                                marginTop: '5px',
                                height: '38px'
                            }}
                            valueStyle={{
                                margin: '3px',
                                padding: '3px 10px',
                                height: '32px',
                                lineHeight: '24px'
                            }}
                            value={FSCStatus}
                            displayFalse={"NEE"}
                            displayTrue={"JA"}
                            field={{}}
                            setValue={(name, value) => {setFSCStatus(value)}}
                        />
                    </span>
                </Grid>
                {type?.value === 'Calculation' ? <FromCalculation FSCStatus={FSCStatus} companyId={companyId}/> : ''}
                {type?.value === 'Confirmation' ? <FromConfirmation FSCStatus={FSCStatus}/> : ''}
                {type?.value === 'Quote' ? <FromQuote FSCStatus={FSCStatus}/> : ''}
            </Block>
        </>
    )
}
