import Select from "react-select";
import {formatTime, stripDiacritics} from "App/Util/format";
import Popup, {closePopup, openPopup} from "UI/App/Components/Popup/Popup";
import isEmpty from "lodash/isEmpty";
import {getSelectedValue} from "App/Util/select2";
import {useContext, useEffect, useState} from "react";
import {FetchContext} from "App/Strapi/FetchContext";
import HasRole from "UI/App/Components/Auth/HasRole";
import { toast } from "react-toastify";
import User from "App/Strapi/User";
import Grid from "UI/App/Components/Grid/Grid";
import Icon from "UI/App/Components/Icon/Icon";
import './updateMachinePlanningPopup.scss';
import TimerPopup from "UI/App/Partials/Content/CRM/Orders/OrderTimer/TimerPopup";

export const _PLANNING_PRIORITIES = [
    {
        label: '1 (Hoog)',
        value: 1,
    },
    {
        label: '2',
        value: 2,
    },
    {
        label: '3',
        value: 3,
    },
    {
        label: '4',
        value: 4,
    },
    {
        label: '5 (Normaal)',
        value: 5,
    },
    {
        label: '6',
        value: 6,
    },
    {
        label: '7',
        value: 7,
    },
    {
        label: '8',
        value: 8,
    },
    {
        label: '9 (Laag)',
        value: 9,
    }
]

export const _PLANNING_PROGRESS = [
    {label: 'Niet begonnen', value: 0},
    {label: 'Mee bezig', value: 50},
    {label: 'Afgerond', value: 100}
]

export default function UpdateMachinePlanningPopup({
    onSubmit,
    popupCurrentMachine,
    popupFormData,
    setPopupFormData,
    setParentTimer = undefined,
}) {

    const {authAxios} = useContext(FetchContext);
    const [assignToMachineOptions, setAssignToMachineOptions] = useState([]);
    const [chosenAmount, setChosenAmount] = useState(0)
    const [runningTimer, setRunningTimer] = useState(undefined)

    useEffect(() => {
        if ((popupCurrentMachine?.machineId !== null && !isNaN(popupCurrentMachine?.machineId)) || popupCurrentMachine?.machineId === 'handwerk') {
            // Clear popup form data
            setPopupFormData({});
            const machineId = popupCurrentMachine?.machineId;

            authAxios.get(`/calculations/resources/machines/${machineId}/operators`)
                .then(({data}) => {
                    setAssignToMachineOptions(data.sort((a, b) => a.username > b.username ? 1 : -1).map(({id, username, email, canOperate}) => ({
                        label: `${username} (${email})`,
                        value: id,
                        canOperate: canOperate
                    })));
                })
                .catch((error) => {
                    setAssignToMachineOptions([]);
                    console.error(error);
                });
        }

        if (popupCurrentMachine?.time !== undefined && popupCurrentMachine?.totalHours !== undefined && popupCurrentMachine?.amount !== undefined) {
            setPopupFormData({});
            const timeScale = popupCurrentMachine.amount / popupCurrentMachine.totalHours;
            setChosenAmount(Math.round(timeScale * popupCurrentMachine.time))
        } else if (popupCurrentMachine?.projectId !== null && !isNaN(popupCurrentMachine?.projectId)) {
            setPopupFormData({});
            authAxios.get(`/orders/${popupCurrentMachine.projectId}`)
                .then(({ data }) => {
                    const timeScale = data.data.chosenAmount / popupCurrentMachine.totalHours;
                    setChosenAmount(Math.ceil(timeScale * popupCurrentMachine.time))
                })
                .catch((error) => {
                    toast.error("Er is een fout opgetreden met het ophalen van informatie")
                    console.error(error);
                });

        }
    }, [authAxios, popupCurrentMachine?.machineId, popupCurrentMachine?.time, popupCurrentMachine?.planningId, setAssignToMachineOptions, setPopupFormData]);

    useEffect(() => {
        // if we have a passed active timer no need to go looking for one
        if (popupCurrentMachine?.runningTimer !== null && popupCurrentMachine?.runningTimer !== undefined) {
            setRunningTimer(popupCurrentMachine?.runningTimer);
        } else if (popupCurrentMachine?.orderId !== undefined && popupCurrentMachine?.machineName !== undefined) {
            // default to undefined so we can 'reset' old timer
            let runningTimer = undefined;
            authAxios.get(`/orders/${popupCurrentMachine?.orderId}/timers/mine`).then(({ data }) => {
                for (const timer of data) {
                    if (timer?.timerFor === popupCurrentMachine?.machineName && timer?.end === null) {
                        runningTimer = timer;
                    }
                }
            }).catch((error) => {
                toast.error("Er is een fout opgetreden met het ophalen van informatie")
                console.error(error);
            });

            setRunningTimer(runningTimer)
        } else {
            // if we have NO valid data just set it to undefined
            setRunningTimer(undefined)
        }

    }, [authAxios, popupCurrentMachine?.orderId, popupCurrentMachine?.runningTimer, setParentTimer]);

    function getSelectedPopupFormFieldValue(fromOptions, formDataKey, planningDataKey, defaultValue = undefined) {
        if (!Array.isArray(fromOptions) || typeof formDataKey !== 'string' || typeof planningDataKey !== 'string') return;

        if (!isEmpty(popupFormData?.[formDataKey]) || (Array.isArray(popupFormData?.[formDataKey]) && popupFormData?.[formDataKey]?.length === 0)) return popupFormData?.[formDataKey];

        return getSelectedValue(
            popupCurrentMachine?.[planningDataKey] ?? defaultValue,
            fromOptions,
            'value'
        );
    }

    function openTimerPopup() {
        if (Object.values(popupFormData).length !== 0) {
            onSubmit();
        }

        closePopup('draggable-click-popup');
        openPopup('order-timer');
    }

    return (
        <>
            <Popup title={popupCurrentMachine?.name} popupID='draggable-click-popup'>
                <form
                    onKeyDown={e => e.stopPropagation()}
                    onSubmit={onSubmit}
                >
                    <h4><Icon name={'far calendar'} forceAwesome={true} /> Planning</h4>
                    <div className='input-group'>
                        <label htmlFor='draggable-popup-assign'>
                            <HasRole roles={['admin', 'management', 'sales', 'productie manager']} doesNotHaveRole={<>
                                Toegewezen werknemers <br/>
                            </>}>
                                Werknemers toewijzen
                            </HasRole>
                        </label>
                        <Select
                            id='draggable-popup-assign'
                            options={assignToMachineOptions}
                            filterOption={(option, input) => {
                                if (input?.length < 3) {
                                    return option.data?.['canOperate'] ?? false;
                                }
                                return stripDiacritics(option.label.toLowerCase()).includes(stripDiacritics(input.toLowerCase()));
                            }}
                            value={getSelectedPopupFormFieldValue(assignToMachineOptions, 'operators', 'persons', [])}
                            onChange={User.hasRole(['admin', 'management', 'sales', 'productie manager']) ? (operators) => {
                                setPopupFormData((formData) => ({
                                    ...formData,
                                    operators
                                }));
                            } : () => {
                            }}
                            isDisabled={!User.hasRole(['admin', 'management', 'sales', 'productie manager'])}
                            isMulti
                            placeholder='Selecteer werknemers'
                        />
                    </div>

                    <div className="input-multiple" style={{flexDirection: 'row', gap: '10px'}}>
                        <div className='input-group' style={{width: 'calc(50% - 5px)'}}>
                            <label htmlFor='draggable-popup-priority' className='required'>
                                Prioriteit
                            </label>

                            <Select
                                id='draggable-popup-priority'
                                options={_PLANNING_PRIORITIES}
                                value={getSelectedPopupFormFieldValue(_PLANNING_PRIORITIES, 'priority', 'priority', (popupCurrentMachine?.priority ?? 5))}
                                onChange={(priority) => {
                                    setPopupFormData((formData) => ({
                                        ...formData,
                                        priority
                                    }));
                                }}
                                placeholder='Selecteer de prioriteit'
                                required
                            />
                        </div>
                        <div className='input-group' style={{width: 'calc(50% - 5px)'}}>
                            <label htmlFor='draggable-popup-priority' className='required'>
                                Voortgang
                            </label>
                            <Select
                                id='draggable-popup-priority'
                                options={_PLANNING_PROGRESS}
                                value={getSelectedPopupFormFieldValue(_PLANNING_PROGRESS, 'progress', 'progress', (popupCurrentMachine?.progress ?? 0))}
                                onChange={(progress) => {
                                    setPopupFormData((formData) => ({
                                        ...formData,
                                        progress
                                    }));
                                }}
                                placeholder='Selecteer de voortang'
                                required
                            />
                        </div>
                    </div>

                    <div className='input-group time'>
                        <label htmlFor='draggable-popup-time' className='required'>
                            Ingeplande uren:
                        </label>
                        <label className='time-input-with-preview'>
                            <HasRole roles={['admin', 'management', 'sales','productie manager']} doesNotHaveRole={<>
                                {popupFormData?.time ?? popupCurrentMachine?.time ?? ''}
                            </>}>
                                <input
                                    id='draggable-popup-time'
                                    type='number'
                                    name='time'
                                    value={popupFormData?.time ?? popupCurrentMachine?.time ?? ''}
                                    step={0.01}
                                    min={0}
                                    required={true}
                                    onChange={(event) => {
                                        setPopupFormData((formData) => ({
                                            ...formData,
                                            time: event.target.value === '' ? '' : Math.abs(Number(event.target.value))
                                        }));
                                    }}
                                />

                                <span className='time-preview'>{formatTime((popupFormData?.time ?? popupCurrentMachine?.time ?? 0))}</span>
                            </HasRole>
                        </label>
                    </div>

                    <div className='input-group amount'>
                        <label htmlFor='draggable-popup-amount'>
                            Aantal gemaakt
                        </label>
                        <label className={'input-group input-group--text'}>
                            <input
                                id={'draggable-popup-amount'}
                                name={'amount'}
                                type={'number'}
                                step={1}
                                value={popupFormData?.amount ?? popupCurrentMachine?.amountProduced ?? ''}
                                onChange={(event) => {
                                    setPopupFormData((formData) => ({
                                        ...formData,
                                        amount: event.target.value
                                    }));
                                }}
                                placeholder={`Niet ingevuld`}
                            />
                            <span className={'input-group-text'}>van de {chosenAmount}</span>
                        </label>
                    </div>

                    {Array.isArray(popupCurrentMachine?.orderChecklist) && popupCurrentMachine?.orderChecklist.filter(item => item.value !== '')?.length > 0 ? <div className='order-checklist'>
                        <h4><Icon name={'list-check'} /> Order checklist</h4>
                        <div style={{
                            display:'flex',
                            flexDirection:'column',
                            gap:'5px',
                        }}>
                            {popupCurrentMachine?.orderChecklist.map((checklistItem) => {
                                // Skip if value is empty
                                if (checklistItem.value !== '') {
                                    return <Grid 
                                            style={{borderBottom:'1px solid lightgray',paddingBottom:'2px'}} 
                                            columns={2} 
                                            customColTemplate={{
                                                900: '100px 2px 1fr',
                                                default: '150px 2px 1fr'
                                            }} 
                                            gap={'10'}
                                            >
                                        <strong>
                                            {checklistItem.dutchName !== null ? checklistItem.dutchName : checklistItem.name}
                                        </strong>
                                        <span>:</span>
                                        <span>
                                            {checklistItem.value}
                                        </span>
                                    </Grid>
                                }

                                return null;
                            })}
                        </div>
                    </div> : <></>}

                    {popupCurrentMachine?.machineChecklist?.length !== 0 && popupCurrentMachine?.machineChecklist !== undefined ? <div className='checklist'>
                        <h4><Icon name={'list-check'} /> Machine checklist</h4>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '5px',
                            marginTop: '5px'
                        }}>
                            {popupCurrentMachine?.machineChecklist.map((checklistItem) => <Grid columns={2} customColTemplate='12px 1fr' gap='10px' style={{ alignItems: 'center' }}>
                                <Icon name={'check'} forceAwesome={true} style={{ fontSize: '12px' }} />
                                {checklistItem}
                            </Grid>
                            )}
                        </div>
                    </div> : <></>}

                    <div className='input-group note'>
                        <h4><Icon name={'pen-line'} /> Opmerkingen</h4>
                        <textarea
                            id={'draggable-popup-note'}
                            name={'note'}
                            value={popupFormData?.note ?? popupCurrentMachine?.note ?? ''}
                            onChange={(event) => {
                                setPopupFormData((formData) => ({
                                    ...formData,
                                    note: event.target.value
                                }));
                            }}
                            placeholder={'Voeg eventuele opmerkingen toe'}
                            rows={5}
                        />
                    </div>

                    <Grid columns={popupCurrentMachine?.orderId && popupCurrentMachine?.machineName && popupCurrentMachine?.totalHours ? 2 : 1} className='planning-popup__buttons'>
                        {popupCurrentMachine?.orderId && popupCurrentMachine?.machineName && popupCurrentMachine?.totalHours &&
                            <button type='button' onClick={openTimerPopup}><Icon name={'clock'} /> {!runningTimer ? `Uren registreren` : `Timer pauzeren`}</button>
                        }
                        <button type='submit'>Toepassen <Icon name={'arrow-right'} /></button>
                    </Grid>
                </form>
            </Popup>

            {popupCurrentMachine?.orderId && popupCurrentMachine?.machineName && popupCurrentMachine?.totalHours &&
                <TimerPopup
                    timerFor={{
                        labor: popupCurrentMachine.machineName,
                        timeInMinutes: popupCurrentMachine.totalHours * 60,
                    }}
                    orderId={popupCurrentMachine.orderId}
                    setParentTimer={setParentTimer}
                />
            }
        </>
    )
}
