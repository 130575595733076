import PDF from "App/Util/print";
import { formatDate } from "App/Util/format";
import autoTable from "jspdf-autotable";

export function printActualCosting(order, pageRef) {
    // create pdf document
    const pdf = new PDF(undefined, undefined, undefined, undefined, {
        orientation: 'landscape'
    });

    // Set the date for the PDF
    const dateStr = formatDate(null, {year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit'});
    pdf.setFontSize(10);
    pdf.text(dateStr, (order.number === '' ? pdf.getPageWidth() - 15 : pdf.getPageWidth() - 45), 15, {
        align: 'right',
    });

    // Add calculation number
    pdf.setFontSize(10);
    if (order.number) {
        pdf.text(order.number ?? '', pdf.getPageWidth() - 15, 15, {
            align: 'right',
        });
    }

    pdf.setFontSize(20);
    pdf.text('Nacalculatie', 14, 15);
    pdf.setFontSize(10);

    let checklistTableData = [];
    for (const checklistItem of order.calculation.data.checklist) {
        // check if we have something to display, if not continue with next entry
        if (checklistItem?.value === undefined || checklistItem.value === '') continue;
        checklistTableData.push([checklistItem.dutchName ?? checklistItem.name, checklistItem.value])
    }

    autoTable(pdf, {
        head: [['Naam', 'Waarde']],
        body: checklistTableData,
        startY: 20,
        rowPageBreak: 'avoid',
        bodyStyles: {valign: 'top'},
    })

    const summaryBlock = pageRef.current.querySelector('.block.summary');
    const summaryItems = summaryBlock.querySelectorAll('li, br');

    const tables = {
        Machines: pageRef.current.querySelector('.machineCostTable'),
        Materialen: pageRef.current.querySelector('.materialCostTable'),
        Diversen: pageRef.current.querySelector('.miscellaneousCostTable'),
        Werkderden: pageRef.current.querySelector('.thirdPartyTable'),
        Handwerk: pageRef.current.querySelector('.manualLaborTable'),
    }

    autoTable(pdf, {
        head: [['Samenvatting', '']],
        body: [...summaryItems].map(li => [li?.children?.[0]?.textContent, li?.children?.[1]?.textContent]),
        startY: pdf.autoTable.previous.finalY + 10,
        rowPageBreak: 'avoid',
        pageBreak: 'avoid',
        bodyStyles: {valign: 'top'},
    })

    for (const tableTitle in tables) {
        const tableElement = tables[tableTitle];

        if (!tableElement) continue;

        const headers = [...tableElement.querySelectorAll('th')]
            .map(header => header.textContent);

        const content = [...tableElement.children[1].querySelectorAll('tr')]
            .map(row =>
                [...row.querySelectorAll('td')]
                    .map(cell => {
                        const textColor = cell.children?.[0]?.style?.color;
                        const isBold = Boolean(row?.style?.fontWeight);

                        if (textColor || isBold) {
                            return {
                                content: cell.textContent,
                                styles: {
                                    textColor: textColor ?? 'black',
                                    fontStyle: isBold ? 'bold' : 'normal',
                                }
                            }
                        }

                        return cell.textContent
                    })
            )
            .filter(row => row.length > 0);

        autoTable(pdf, {
            head: [headers],
            body: content,
            startY: pdf.autoTable.previous.finalY + 10,
            rowPageBreak: 'avoid',
            pageBreak: 'avoid',
            bodyStyles: {valign: 'top'},
        })
    }

    pdf.openPDF();
}