import Table from "UI/App/Components/Table/Table";
import { useLoaderData } from "react-router-dom";
import ActualCostingCalculator from "UI/App/Partials/Content/CRM/Orders/ActualCosting/ActualCostingCalculator";

export default function MachineCostTable() {
    const { order } = useLoaderData();

    const calculator = new ActualCostingCalculator(order);

    return (
        <Table
            className='machineCostTable'
            headers={[
                <strong>Machines</strong>,
                "Berekend (calculatie)",
                "Daadwerkelijk (gerealiseerd)",
                ""
            ]}
            data={calculator.getMachineCostData().tableData}
        />
    )
}