import React, {useContext, useEffect, useState} from "react";
import {FetchContext} from "../../../../../../../App/Strapi/FetchContext";
import Select from "react-select";
import WidgetFormRenderer from "../../../Calculations/Widgets/Widgets/WidgetFormRenderer";
import WidgetCollection from "../../../Calculations/Widgets/Widgets/WidgetCollection";
import Block from "../../../../../Components/Block/Block";
import {toast} from "react-toastify";
import CalculationDataProvider from "../../../Calculations/Data/CalculationDataProvider";
import HandwerkTab from "../../../Calculations/Template/Tabs/HandwerkTab";
import IF from "UI/App/Components/Conditional/IF";
import Grid from "UI/App/Components/Grid/Grid";
import {stringify} from "qs";
import WerkderdenPurchaseTab from "../../../Calculations/Template/Tabs/WerkderdenPurchaseTab";

/**
 *
 * @param chosenCalculationDataId
 * @param setChosenCalculationData
 * @param setJson
 * @param quoteData
 * @param setQuoteData
 * @param activeStep
 * @param quoteId
 * @param {number} chosenAmount
 * @param setChosenAmount
 * @returns {JSX.Element}
 * @constructor
 */

export default function Recalculate({calculationNumber,chosenAmounts, setChosenAmounts, setExternalChosenAmount, calculation, revision, copy, chosenCalculationDataId, setChosenCalculationData, setJson, quoteData, setQuoteData, activeStep, quoteId, confirmationId, setSpinnerState}) {
    const [calculationTabButtons, setCalculationTabButtons] = useState([]);
    const [calculationTabs, setCalculationTabs] = useState([]);
    const [rawTemplateData, setRawTemplateData] = useState({});
    const [calculationData, setCalculationData] = useState([]);
    const [amountSelect, setAmountSelect] = useState(<></>);
    const {authAxios} = useContext(FetchContext)
    const [chosenCalculation, setChosenCalculation] = useState(null);
    const [calculationResult, setCalculationResult] = useState([]);
    const [calculations, setCalculations] = useState([]);
    const [amounts, setAmounts] = useState([]);
    const [chosenAmount, setChosenAmount] = useState(null)
    const [chosenAmountArray, setChosenAmountArray] = useState([]);

    const [thirdParty, setThirdParty] = useState([]);
    const [manualLabor, setManualLabor] = useState(false);
    const [werkderden, setWerkderden] = useState([]);

    useEffect(() => {
        if (calculation !== null) {
            setChosenCalculation(calculation);

            for (const amount of calculation.data.amounts) {
                amounts.push(amount);
                if (chosenAmount === null) {
                    setChosenAmount(amount)
                    setChosenAmountArray([[amount, 0]])
                    setExternalChosenAmount(amount)
                }
            }

            setAmounts(amounts)
        }
    }, [calculation])

    useEffect(() => {
        // Get quote data for create or copy
        if (copy || revision) {
            authAxios.get(`/quotes/confirmations/${confirmationId}?${stringify({
                populate: {
                    orders: {
                        populate: {
                            calculation: {
                                populate: {
                                    company: true,
                                    contactPerson: true
                                }
                            }
                        }
                    },
                    quote: {
                        populate: {
                            company: {
                                populate: {
                                    address: true
                                }
                            },
                            confirmation: true,
                            contactPerson: true,
                            calculations: true
                        }
                    }
                }
            })}`).then((data) => {
                // Get calculations from orders
                let calculations = [];
                for (const order of data.data.data.orders) {
                    calculations.push(order.calculation)
                }

                setCalculations(calculations);
                setQuoteData(data.data.data.quote)

                // Load amounts
                for (const calculation of calculations) {
                    for (const amount of calculation.data.amounts) {
                        let amounts = {...chosenAmounts}
                        // Update chosen amounts
                        amounts[calculation.id] = amount;
                        setChosenAmounts(amounts);
                    }
                }

                setCalculationData(calculations);
                setChosenCalculation(data.data.data.calculation);

            }).catch((exception) => {
                console.error(exception);
            });
        } else {
            const queryParams = stringify({
                populate: {
                    company: {
                        populate: ['address', 'contactPersons']
                    },
                    calculations: {
                        populate: ['purchaseQuotes']
                    },
                    contactPerson: true,
                    data: true,
                    priceData: true,
                    projects: true,
                }
            });

            authAxios.get(`/quotes/${quoteId}?${queryParams}`).then(({data}) => {
                setCalculationData(data.data.calculations)
                setQuoteData(data.data);
            }).catch((exception) => {
                console.error(exception);
            });
        }

    }, [])

    useEffect(() => {
        // If calculation data is provided fill data
        if (chosenCalculation) {
            const thirdPartyData = [];

            if (Array.isArray(chosenCalculation.data?.thirdParty)) {
                thirdPartyData.push(...chosenCalculation.data?.thirdParty);
            }

            if (chosenCalculation.purchaseQuotes) {
                thirdPartyData.push(...chosenCalculation.purchaseQuotes);
            }

            setThirdParty(thirdPartyData);

            // FOR BACKWARDS COMPATIBILITY
            if (Array.isArray(chosenCalculation.data?.manualLabor)) {
                setManualLabor(chosenCalculation.data.manualLabor);
            }
        }
    }, [setThirdParty, chosenCalculation])

    useEffect(() => {
        if (calculationData.length !== 0) {
            let tableData = [];
            let selectOptions = [];
            for (const calculation of calculationData) {
                selectOptions.push({
                    label: `${calculation.number}`,
                    value: calculation.id
                })
            }

            // if we have only one option we can fill this for user
            if (calculationData.length === 1) {
                setChosenCalculationData(calculationData[0].id);
            }

            setCalculations(selectOptions);
        }
    }, [calculationData])

    useEffect(() => {
        calculateForm()
    }, [activeStep])


    useEffect(() => {
        setAmountSelect(null);
        // setCalculationTabButtons([]);
        setRawTemplateData(null);
        // setCalculationTabs([]);


        let chosenCalculation = null;
        let amounts = [];
        for (const calculation of calculationData) {
            if (calculation.id === chosenCalculationDataId) {
                chosenCalculation = calculation;
                break;
            }
        }

        if (chosenCalculation !== null) {
            let selectOptions = [];
            for (const amount of chosenCalculation.data.amounts) {
                amounts.push(amount);
                if (chosenAmount === null) {
                    setChosenAmount(amount)
                    setExternalChosenAmount(amount)
                    setChosenAmountArray([[amount, 0]])
                }
                selectOptions.push({
                    label: amount,
                    value: amount
                })
            }

            setAmounts(amounts)

            // if we have only one option we can fill this for user
            let knowAmount = undefined
            setAmountSelect(<Select required={true} id="amountSelect" name={"amountSelect"} value={knowAmount} options={selectOptions} onChange={(e) => {
                setChosenAmount(e.value);
                setChosenAmountArray([[e.value, 0]]);
                setExternalChosenAmount(e.value);
            }}/>)
        }
    }, [chosenCalculationDataId])

    useEffect(() => {
        if (chosenAmount !== null) {
            loadCalculation();

            setTimeout(() => {
                calculateForm()
            }, 1000)
        }

        let amounts = {...chosenAmounts}
        // Update chosen amounts
        amounts[calculation.id] = chosenAmount;
        setChosenAmounts(amounts);

    }, [chosenAmount])

    /**
     * Load from id
     */
    async function loadCalculation() {
        setCalculationTabButtons([]);
        setCalculationTabs([]);
        // Timeout to give states time
        setTimeout(() => {
            /**
             * Load template
             */
            if (calculation !== null) {
                const optionalMachines = calculation.data.optionalMachines;
                /**
                 * Set defaults
                 */
                setCalculationTabButtons([]);
                setRawTemplateData(calculation.data.templateData);
                // setCalculationTabs([]);

                if (Array.isArray(calculation.data.thirdParty)) {
                    // setThirdParty(calculation.data.thirdParty);
                }

                // FOR BACKWARDS COMPATIBILITY
                if (Array.isArray(calculation.data.manualLabor)) {
                    setManualLabor(calculation.data.manualLabor);
                }

                /**
                 * Loop machines
                 */
                const tabButtons = [];
                for (const [machineKey, machine] of Object.entries(calculation.data.templateData.data).sort((a, b) => a[1].position - b[1].position)) {
                    if (machineKey !== 'isWebshopTemplate' && machineKey !== 'result' && machineKey !== 'amounts' && machineKey !== 'calculation_result' && machineKey !== 'template_result') {
                        if(machine === false) continue;

                        let prefix = machineKey.split('-')[0];
                        const machineId = machineKey.split('-')[1];
                        prefix += `-${calculationNumber}`

                        /**
                         * Get name
                         */
                        let name = null;
                        if (machine.name !== undefined && machine.name !== '') {
                            name = machine.name;
                        } else {
                            for (const constant of machine.constants) {
                                if (constant.id.includes("const-name")) {
                                    name = constant.value
                                }
                            }
                        }

                        /**
                         * If no name isset and no name in constants set machine id with prefix as name
                         */
                        if (name === null) {
                            name = `${prefix}-${machineId}`
                        }

                        /**
                         * Set tab buttons
                         */
                        tabButtons.push({
                            prefix: prefix,
                            machineId: machineId,
                            name: name,
                            optional: machine.optional
                        })

                        let tabOptions = <></>;
                        if (machine?.optional) {
                            if (optionalMachines[machineKey] !== undefined) {
                                if (optionalMachines[machineKey] == 'true') {
                                    tabOptions = <span>
                                        Actief: <input key={machineKey} name={"machineOptional"} className={"machineOptional"} type={"checkbox"} defaultChecked={true}/>
                                    </span>;
                                } else {
                                    tabOptions = <span>
                                        Actief: <input key={machineKey} name={"machineOptional"} className={"machineOptional"} type={"checkbox"} defaultChecked={false}/>
                                    </span>;
                                }
                            } else {
                                tabOptions = <span>
                                    Actief: <input key={machineKey} name={"machineOptional"} className={"machineOptional"} type={"checkbox"} defaultChecked={false}/>
                                </span>;
                            }
                        }

                        /**
                         * Add tabs
                         */
                        const constants = formatConstants(machine.constants);
                        setCalculationTabs(prevState => [...prevState, <div key={`${prefix}-${machineId}`} onChange={calculateForm} className={"calculationTab hidden"} machineid={machineId} id={`${prefix}-${machineId}`}>{tabOptions}{constants}</div>])
                    }
                }

                setCalculationTabButtons(tabButtons);

                for (const [machineKey, machine] of Object.entries(calculation.data.templateData.data)) {
                    if (machineKey !== 'result' && machineKey !== 'amounts' && machineKey !== 'calculation_result' && machineKey !== 'template_result') {
                        let prefix = machineKey.split('-')[0];
                        prefix += `-${calculationNumber}`
                        const machineId = machineKey.split('-')[1];

                        setTimeout(() => {
                            /**
                             * Add widgets
                             */
                            new WidgetFormRenderer().renderForm(machine.widgets, false, prefix, `${prefix}-${machineId}`);
                        }, 500)
                    }
                }

                fillData(calculation.data.templateData.data, calculation.data.values, calculation.data.templateData, calculation);
            }
            openCalculationTab()
        }, 50)
    }

    let i = 0;
    const fillData = async (data, values, rawData, chosenCalc) => {

        // Timeout to make sure all widgets are loaded
        setTimeout(() => {
            fillIndividualWidgets(data, values, rawData, chosenCalc);
        }, 500)
    }

    async function fillIndividualWidgets(data, values, rawData, chosenCalc) {
        /**
         * wait until all elements are loaded
         */
        const checkLoop = function () {
            i++;
            if (i < 30) {
                fillData(data, values, rawData, chosenCalc);
            }
        }

        /**
         * If data is undefined stop execution
         */
        if (data === undefined) {
            console.warn('Data is undefined abort filling data')
            return false;
        }

        /**
         * Calc total widgets
         */
        let totalWidgets = 0;
        for (const [key, machine] of Object.entries(data)) {
            if (machine.widgets !== undefined) {
                totalWidgets += +machine.widgets.length
            }
        }

        if (document.getElementsByClassName('calculationWidget').length < totalWidgets) {
            setTimeout(checkLoop, 200);
            return false;
        } else {
            clearTimeout(checkLoop)
        }

        const calcDataProvider = new CalculationDataProvider({});

        for (const widget of document.getElementById(`calculation-${chosenCalc.id}`).getElementsByClassName('calculationWidget')) {
            await calcDataProvider.fillIndividualWidget(widget.getAttribute('widgetData'), widget.getAttribute('id'), data, values)
        }


        setTimeout(() => {
            calculateForm(rawData, chosenCalc);

            setTimeout(() => {
                hideMachinesWithoutInteraction();
                setSpinnerState(false);
            }, 500)
        }, 500)

        return true;
    }

    const hideMachinesWithoutInteraction = () => {
        for (const tab of document.getElementsByClassName('calculationTab')) {
            if (tab.querySelector('.machineOptional') === null) {
                let hiddenWidgets = 0;
                for (const widget of tab.querySelectorAll('.calculationWidget')) {
                    if (widget.classList.contains('hidden')) {
                        hiddenWidgets++;
                    }
                }


                // If all widgets hidden and machine is not optional hide button
                // Only run if there are widgets
                if (tab.querySelectorAll('.calculationWidget').length !== 0) {
                    if (hiddenWidgets === tab.querySelectorAll('.calculationWidget').length) {
                        if (tab.id !== 'handwerk' && tab.id !== 'werk-derden') {
                            document.querySelector(`#calculation__tab-link--${tab.id}`)?.classList.add('hidden');
                        }
                    }
                }
            }
        }
    }

    /**
     * For handling caclulation template tabs
     * @param id
     */
    const openCalculationTab = (id = null) => {
        /**
         * Reset tabs
         */
        for (const tablink of document.getElementsByClassName('calculation__tab-button')) {
            tablink.classList.remove("currentTab")
        }

        if (id !== null) {
            document.getElementById(`calculation__tab-link--${id}`).classList.add('currentTab');
            document.getElementById(`calculation__tab-link--${id}`).classList.add('visitedTab');
        }

        // Hide werkderde and handwerk
        document.getElementById('handwerk').classList.add('hidden');
        document.getElementById('werk-derden').classList.add('hidden');

        /**
         * Reset tabs
         */
        for (const tab of document.getElementsByClassName('calculationTab')) {
            tab.classList.add("hidden")
        }

        /**
         * Display chosen tab
         */
        if (id !== null) {
            document.getElementById(id).classList.remove('hidden');
        }
    }

    /**
     * Helper function for formattingn constants
     */
    function formatConstants(constants, prefix = null) {
        if (constants === undefined) {
            toast.warn('Geen machine waardes gevonden bij calculatie')
            return;
        }
        let consts = [];

        for (const constant of constants) {
            consts.push(<input key={constant.id} className={"machine-constant"} type={"hidden"} value={constant?.data ?? constant?.value ?? ''} id={prefix !== null ? `${prefix}-${constant.id}` : constant.id}/>)
        }

        return consts;
    }

    /**
     * Run calculation logic
     */
    const calculateForm = (rawData = null, chosenCalc = null) => {
        console.log('calculateForm()');
        // loadAmounts();
        if (rawData === null) {
            rawData = rawTemplateData;
        }

        // if (chosenCalc === null) {
        //     chosenCalc = chosenCalculation
        // }

        chosenCalc = calculation;


        if (rawData === undefined || chosenCalc === null) {
            console.warn(rawData, chosenCalc);
            console.warn('rawData or chosenCalc is null')
            return;
        }

        let result = {'Inschiet': {}};
        let rawResult = {};
        let units = {};

        let amountKey = chosenCalc.data.amounts.findIndex(amount => Number(amount) === Number(chosenAmount));

        /**
         * Loop amounts
         */
        let templateValues = {};
        // for (const amount of amounts) {
        const totalAmount = chosenAmount + +chosenCalc.data.misses[amountKey];

        /**
         * Loop tabs and set value
         */
        for (const tab of document.getElementsByClassName('calculationTab')) {
            /**
             * Check if optional and if so handle optional
             */
            if (tab.querySelector('.machineOptional') !== null) {
                if (tab.querySelector('.machineOptional').checked === false) {
                    tab.querySelectorAll('.calculationWidget').forEach((element) => {
                        element.classList.add('hidden');
                    });

                    continue;
                } else {
                    tab.querySelectorAll('.calculationWidget').forEach((element) => {
                        element.classList.remove('hidden');
                    });
                }
            }

            for (const widget of tab.querySelectorAll('.calculationWidget')) {
                const widgetData = JSON.parse(widget.getAttribute('widgetdata'))
                /**
                 * If input
                 */
                if (widgetData.data.type === "Input") {
                    /**
                     * If amount set current amount
                     */
                    if (widgetData.data.title.toLowerCase() === "aantal") {
                        document.getElementById(`${widgetData.prefix}${widgetData.id}`).querySelector('.inputWidgetInput').value = totalAmount
                    }

                    if (widgetData.data.title.toLowerCase() === "lengte product") {
                        document.getElementById(`${widgetData.prefix}${widgetData.id}`).querySelector('.inputWidgetInput').value = chosenCalc.data.length
                    }
                    if (widgetData.data.title.toLowerCase() === "breedte product") {
                        document.getElementById(`${widgetData.prefix}${widgetData.id}`).querySelector('.inputWidgetInput').value = chosenCalc.data.width
                    }
                }
            }

            /**
             * Run calculation with given amount
             */
            for (const widget of tab.querySelectorAll('.calculationWidget')) {
                const widgetData = JSON.parse(widget.getAttribute('widgetdata'))
                const res = new WidgetCollection(null, null, null, widgetData, false).runCalculation(widgetData.data.type);
                if (res !== undefined) {

                    let title = '';
                    if (res.titel.includes('|')) {
                        /**
                         * Get prefix
                         */
                        const prefix = widget.id.split("-widget")[0];
                        title = res.titel.replace('internalSKU', prefix);
                    } else {
                        title = res.titel;
                    }

                    if (result[title] === undefined) {
                        result[title] = {};
                    }

                    if (units[title] === undefined) {
                        units[title] = res.unit;
                    }

                    if (result[title][chosenAmount] === undefined) {
                        result[title][chosenAmount] = res.result
                    } else {
                        result[title][chosenAmount] += res.result
                    }

                    if (!rawResult[widgetData.parentId])
                        rawResult[widgetData.parentId] = {}

                    if (!rawResult[widgetData.parentId][title])
                        rawResult[widgetData.parentId][title] = {}

                    if (!rawResult[widgetData.parentId][title][chosenAmount])
                        rawResult[widgetData.parentId][title][chosenAmount] = {}

                    rawResult[widgetData.parentId][title][chosenAmount] = res.result;
                }
            }
        }

        if (chosenCalc.data.misses) {
            result['Inschiet'][chosenAmount] = chosenCalc.data.misses
        }

        /**
         * Gather data for storage
         * @type {{data: {data: {summary: any, amounts: number[], rawResult: ({}|*), misses: number, templateData: {}, calculationData: *[], length: string, width: string, checklist: *[], totals: number}, company: unknown, Name: string}}}
         */
        for (const [machineKey, machine] of Object.entries(rawData?.data?.data ?? {})) {
            if (document.getElementById(machineKey) !== null) {
                if (document.getElementById(machineKey).querySelector('.machineOptional') !== null) {
                    if (document.getElementById(machineKey).querySelector('.machineOptional').value === 'false') {
                        continue;
                    }
                }
            }

            if (templateValues[machineKey] === undefined) {
                templateValues[machineKey] = {};
            }

            if (templateValues[machineKey][chosenAmount] === undefined) {
                templateValues[machineKey][chosenAmount] = [];
            }

            if (machine.widgets !== undefined) {
                for (const [widgetKey, widget] of Object.entries(machine.widgets)) {
                    let value = null;
                    if (widget.data.type === 'MaterialChoice') {
                        value = document.getElementById(`${widget.prefix}${widget.id}`).getAttribute('widgetdata');
                    } else if (widget.data.type === 'ReturnWidget') {
                        value = JSON.parse(document.getElementById(`${widget.prefix}${widget.id}`).getAttribute('widgetdata')).data.value.result;
                    } else {
                        if (document.getElementById(`${widget.prefix}${widget.id}`).querySelector('inputWidgetInput') !== null) {
                            value = document.getElementById(`${widget.prefix}${widget.id}`).querySelector('inputWidgetInput').value
                        } else if (document.getElementById(`${widget.prefix}${widget.id}`).querySelector('select') !== null) {
                            value = document.getElementById(`${widget.prefix}${widget.id}`).querySelector('select').value
                        }
                    }

                    templateValues[machineKey][chosenAmount].push({
                        id: widget.id,
                        prefix: widget.prefix,
                        value: value,
                        unit: widget.data.unit
                    })
                }
            }
        }

        /**
         * Add manual labor
         */
        for (const priceResult of document.querySelectorAll('.handwerk-price-result')) {
            if (priceResult.dataset?.quantity === undefined || priceResult.dataset?.price === undefined || priceResult.dataset?.time === undefined) continue;
            if (result['Handwerk kosten'] === undefined) result['Handwerk kosten'] = {};
            if (result['Handwerk tijd'] === undefined) result['Handwerk tijd'] = {};

            if (result['Handwerk kosten'][parseInt(priceResult.dataset.quantity)] === undefined) result['Handwerk kosten'][parseInt(priceResult.dataset.quantity)] = 0;
            if (result['Handwerk tijd'][parseInt(priceResult.dataset.quantity)] === undefined) result['Handwerk tijd'][parseInt(priceResult.dataset.quantity)] = 0;

            result['Handwerk kosten'][parseInt(priceResult.dataset.quantity)] += parseFloat(priceResult.dataset?.price);
            result['Totaal.Subtotaal'][parseInt(priceResult.dataset.quantity)] += parseFloat(priceResult.dataset?.price);
            result['Handwerk tijd'][parseInt(priceResult.dataset.quantity)] += parseFloat(priceResult.dataset?.time);
        }

        /**
         * Add third party work
         */
        for (const priceResult of document.querySelectorAll('.werk-derden--prijs')) {
            if (priceResult.dataset?.quantity === undefined || priceResult.dataset?.price === undefined || priceResult.dataset?.total === undefined) continue;
            if (result['Werk derden'] === undefined) result['Werk derden'] = {};

            if (result['Werk derden'][parseInt(priceResult.dataset.quantity)] === undefined) result['Werk derden'][parseInt(priceResult.dataset.quantity)] = 0;

            result['Werk derden'][parseInt(priceResult.dataset.quantity)] += parseFloat(priceResult.dataset?.total);
            result['Totaal.Subtotaal'][parseInt(priceResult.dataset.quantity)] += parseFloat(priceResult.dataset?.total);
        }

        setCalculationResult(result)
        gatherData(templateValues, result);

        const currentTabLink = document.querySelector('.calculation__tab-buttons .currentTab');

        if (currentTabLink === null) {
            return;
        }

        const currentTabId = currentTabLink.id.split('_')?.[1];

        if (currentTabId.match(/\d+-\d+/) !== null) {
            openCalculationTab(currentTabId);
        }
    }

    const gatherData = (optionalValues = null, result = null) => {

        /**
         * Get checklist data
         */
        let checklistData = [];
        for (const element of document.getElementsByClassName('checklistInput')) {
            checklistData.push({id: element.getAttribute('checklistid'), type: element.type, name: element.getAttribute('name'), value: element.value})
        }

        /**
         * Gather data for storage
         * @type {{data: {data: {summary: any, amounts: number[], rawResult: ({}|*), misses: number, templateData: {}, calculationData: *[], length: string, width: string, checklist: *[], totals: number}, company: unknown, Name: string}}}
         */
        let templateValues = {};
        let optionalMachines = {};
        for (const [machineKey, machine] of Object.entries(rawTemplateData?.data?.data ?? {})) {
            if (machine.optional) {
                optionalMachines[machineKey] = document.getElementById(machineKey).querySelector('.machineOptional').value
            }
            templateValues[machineKey] = [];
            if (machine.widgets !== undefined) {

                for (const [widgetKey, widget] of Object.entries(machine.widgets)) {
                    let value = null;
                    if (widget.data.type === 'MaterialChoice') {
                        value = document.getElementById(`${widget.prefix}${widget.id}`).getAttribute('widgetdata');
                    } else {
                        if (document.getElementById(`${widget.prefix}${widget.id}`).querySelector('input') !== null) {
                            value = document.getElementById(`${widget.prefix}${widget.id}`).querySelector('input').value
                        } else if (document.getElementById(`${widget.prefix}${widget.id}`).querySelector('select') !== null) {
                            value = document.getElementById(`${widget.prefix}${widget.id}`).querySelector('select').value
                        }
                    }

                    templateValues[machineKey].push({
                        id: widget.id,
                        prefix: widget.prefix,
                        value: value
                    })
                }
            }
        }

        /**
         * Gather third party work
         */
        let thirdPartyWorkStorage = [];
        for (const thirdPartyWork of document.getElementsByClassName('werk-derden-entry')) {

            const entryData = JSON.parse(thirdPartyWork.dataset?.json ?? '{}');

            thirdPartyWorkStorage.push(entryData);
        }

        /**
         * Gather manual labor storage
         * @type {{}}
         */
        let manualLaborStorage = [];
        for (const handwerk of document.getElementsByClassName('handwerk-entry')) {

            const entryData = JSON.parse(handwerk.dataset?.json ?? '{}');

            manualLaborStorage.push(entryData);
        }

        let summary = {}
        // let chosenCalculation = false;
        // for (const calculation of calculationData) {
        //     if (calculation.id === chosenCalculationDataId) {
        //         chosenCalculation = calculation
        //     }
        // }

        summary[chosenAmount] = calculation.data.summary[chosenAmount]

        let amountKey = null;
        for (const key of calculation.data.amounts.keys()) {
            if (calculation.data.amounts[key] === chosenAmount) {
                amountKey = key;
                break;
            }
        }

        let price = calculation?.data?.summary[chosenAmount]?.quote;
        let json = {
            data: {
                Name: calculation.name,
                company: calculation.company.id,
                language: calculation.language,
                data: {
                    templateData: rawTemplateData,
                    calculationData: calculationResult,
                    checklist: calculation.data.checklist,
                    values: optionalValues,
                    amounts: [chosenAmount],
                    length: calculation.data.length,
                    misses: calculation.data.misses,
                    width: calculation.data.width,
                    totalResultJson: result,
                    manualLabor: manualLaborStorage,
                    thirdParty: thirdPartyWorkStorage,
                    optionalMachines: optionalMachines,
                    summary: summary,
                    totalConfirmationPrice: price,
                    templateResults: calculation.data.templateResults
                }
            },
        }

        setJson(json);
    }

    return (
        <>
            <Block name={'calculation-tab'} contentStyle={{paddingTop: '10px', height: 'calc(100vh - 200px)'}}>
                <div className={"tabcontent"} id={`calculation-${calculation.id}`}>
                    {/*<select*/}
                    {/*    value={chosenCalculation}*/}
                    {/*    onChange={(e, id) => {*/}
                    {/*        setChosenCalculation(e.target.value)*/}
                    {/*    }}>*/}
                    {/*    {calculations.map((calculation, id) =>*/}
                    {/*        <option key={calculation.value} value={calculation.value}>{calculation.label}</option>*/}
                    {/*    )}*/}
                    {/*</select>*/}

                    <select
                        value={(chosenAmount ?? '')}
                        onChange={(e, id) => {
                            setChosenAmount(e.target.value)
                            setChosenAmountArray([[e.target.value, 0]])
                            setExternalChosenAmount(e.target.value)
                        }}>
                        {amounts.map((amount, id) =>
                            <option key={amount} value={amount}>{amount}</option>
                        )}
                    </select>

                    {/*{amountSelect}*/}

                    <IF condition={chosenAmount !== null}>
                        <Grid
                            columns={{
                                700: 1,
                                default: 2
                            }}
                            customColTemplate={{
                                700: '1fr',
                                900: '300px 1fr',
                                default: '400px 1fr'
                            }}
                            style={{marginTop: '20px'}}
                        >
                            <div className={'calculation__tab-buttons'}>
                                <b>Verplichte machines:</b>
                                {calculationTabButtons.filter(machine => !machine.optional).map((button, index) =>
                                    <button key={index} className="calculation__tab-button" id={`calculation__tab-link--${button.prefix}-${button.machineId}`} onClick={() => openCalculationTab(`${button.prefix}-${button.machineId}`)}>{button.name}</button>
                                )}
                                <b>Optionele machines:</b>
                                {calculationTabButtons.filter(machine => machine.optional).map((button, index) =>
                                    <button key={index} className="calculation__tab-button" id={`calculation__tab-link--${button.prefix}-${button.machineId}`} onClick={() => openCalculationTab(`${button.prefix}-${button.machineId}`)}>{button.name}</button>
                                )}
                                <b>Overig:</b>
                                <IF condition={chosenAmount !== null}>
                                    <button className="calculation__tab-button" id={`calculation__tab-link--handwerk`} onClick={() => openCalculationTab(`handwerk`)}>{`Handwerk`}</button>
                                    <button className="calculation__tab-button" id={`calculation__tab-link--werk-derden`} onClick={() => openCalculationTab(`werk-derden`)}>{`Werkderden`}</button>
                                </IF>
                            </div>

                            <div>
                                {calculationTabs}
                                <IF condition={chosenAmount !== null}>
                                    <HandwerkTab id={"handwerk"} quantities={chosenAmountArray} data={manualLabor}/>
                                    <WerkderdenPurchaseTab
                                        id={"werk-derden"}
                                        werkDerdenData={werkderden}
                                        setWerkDerdenData={() => {}}
                                        quantities={chosenAmountArray}
                                        thirdParty={thirdParty}
                                        activeTab={activeStep}
                                        calculationId={chosenCalculation}
                                        checklistData={chosenCalculation?.data?.checklist}
                                        base64={true}
                                    />
                                </IF>
                            </div>
                        </Grid>
                    </IF>
                </div>
            </Block>
        </>
    )
}
