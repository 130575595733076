import React, {useContext, useEffect, useState} from "react";
import {FetchContext} from "App/Strapi/FetchContext";
import {useNavigate} from "react-router-dom";
import {usePagination} from "UI/App/Components/Pagination/Pagination";
import {toast} from "react-toastify";
import {stringify} from "qs";
import Table from "UI/App/Components/Table/Table";
import Icon from "UI/App/Components/Icon/Icon";
import Popup, {closePopup, openPopup} from "UI/App/Components/Popup/Popup";
import CreateWareHouse from "UI/App/Partials/Content/Warehouse/CreateWareHouse";
import HasRole from "UI/App/Components/Auth/HasRole";
import {BooleanControlled} from "UI/App/Components/Form/Boolean";
import { exportObject, getExportMaterials } from "App/Util/exportObject";
import { formatCurrency } from "App/Util/format";
import { AuthContext } from 'App/Strapi/AuthProvider';
import Button from "UI/App/Components/Button/Button";
import Grid from "UI/App/Components/Grid/Grid";
import Select from "react-select";
import {split} from "lodash";

export default function WareHouses() {
    const {authAxios} = useContext(FetchContext);
    const authData = useContext(AuthContext);
    const navigate = useNavigate();

    const [filterWarehouses, setFilterWareHouses] = useState(true);
    const [wareHouseTableData, setWareHouseTableData] = useState([]);

    const [months, setMonths] = useState([]);
    const [selectedMonth, setSelectedMonth] = useState(null);

    const {
        paging,
        filtering,
        setTotalPages,
        setTotalResults,
        filterQuery
    } = usePagination({
        storageKey: 'warehouse',
        searchSettings: {
            enabled: true,
            strapiFields: ['name', 'location']
        },
        htmlElements: [
            <HasRole roles={['admin', 'sales', 'productie manager', 'management']}>
                <span className='pagination__filter addItem' key='link--add'>
                    <span style={{ cursor: "pointer" }} onClick={(e) => {
                        openPopup("export");
                    }} className='btn btn--add btn--black btn--icon-right'>Exporteer voorraad <Icon name='arrow-down' /></span>
                </span>
            </HasRole>,
            <HasRole roles={['admin']}>
                <span className='pagination__filter addItem' key='link--add'>
                <span style={{cursor: "pointer"}} onClick={(e) => {
                    openPopup("addWarehouse")
                }} className='btn btn--add btn--icon-right'>Toevoegen <Icon name='plus'/></span>
            </span>
            </HasRole>,
        ],
        resultsPerPageBreakpoint: 1960,
        paginationBreakpoint: 1270,
        resultCountBreakpoint: 1850
    });

    const query = stringify({
        sort: ['id:desc']
    }, { encodeValuesOnly: true});

    useEffect(() => {
        if(filterWarehouses){
            getWareHouses();
        }else{
            getStorages();
        }
    }, [filterQuery,filterWarehouses]);

    function getMonths(startDate, endDate) {
        let start      = startDate.split('-');
        let end        = endDate.split('-');
        let startYear  = parseInt(start[0]);
        let endYear    = parseInt(end[0]);
        let dates      = [];

        for(let i = startYear; i <= endYear; i++) {
            let endMonth = i != endYear ? 11 : parseInt(end[1]) - 1;
            let startMon = i === startYear ? parseInt(start[1])-1 : 0;
            for(let j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j+1) {
                let month = j+1;
                let displayMonth = month < 10 ? '0'+month : month;
                dates.push([i, displayMonth].join('-'));
            }
        }
        return dates;
    }

    async function loadMonthExport(){
        let monthOptions = [];

        for(const month of getMonths('2022-01',`${new Date().getFullYear()}-${new Date().getMonth()}`).reverse()){
            monthOptions.push({
                label: `${new Date(month).getFullYear()}-${(new Date(month)).toLocaleString("nl-NL", { month: 'long' })}`,
                value: `${new Date(month).getFullYear()}-${(new Date(month).getMonth())}`
            })
        }

        setMonths(monthOptions);
    }

    function getStorages() {
        authAxios
            .get(`/storages`)
            .then(({data}) => {
                const tableData = [];

                // loop through all the records
                for (const [index, wareHouse] of Object.entries(data.data)) {

                    // add a row for the warehouse
                    tableData.push({
                        attributes: {
                            onClick: (e) => {

                                navigate(`/warehouses/${wareHouse.id}/storage`);
                            }
                        },
                        data: [
                            wareHouse.name,
                            wareHouse.location,
                        ]
                    });
                }

                setWareHouseTableData(tableData);
                setTotalPages(data.meta.pagination.pageCount);
                setTotalResults(data.meta.pagination.total);
            })
            .catch((exception) => {
                console.error(exception);
                toast.error('Kon de magazijnen niet ophalen.');
            });
    }

    function getWareHouses(){
        authAxios
            .get(`/logistics/warehouses?${query}&${filterQuery}`)
            .then(({data}) => {
                const tableData = [];

                // loop through all the records
                for (const [index, wareHouse] of Object.entries(data.data)) {

                    // add a row for the warehouse
                    tableData.push({
                        attributes: {
                            onClick: (e) => {

                                navigate(`/warehouses/${wareHouse.id}`);
                            }
                        },
                        data: [
                            wareHouse.name,
                            wareHouse.location,
                        ]
                    });
                }

                setWareHouseTableData(tableData);
                setTotalPages(data.meta.pagination.pageCount);
                setTotalResults(data.meta.pagination.total);
            })
            .catch((exception) => {
                console.error(exception);
                toast.error('Kon de magazijnen niet ophalen.');
            });
    }

    function getExportMaterials() {
        const query = stringify({
            populate: {
                stock: true
            }
        }, { encodeValuesOnly: true });

        authAxios
            .get(`/logistics/materials/all?${query}`)
            .then(({ data }) => {
                // create list of all materials to export
                let exportList = []

                for (let material of data) {
                    let totalAmount = 0;
                    // calculate pcs.
                    let pricePerPiece = material.purchasePrice / material.countPerUnit;

                    // // check all know stocks for total material count
                    for (let stock of material.stock) {
                        totalAmount += stock.total
                    }

                    // push formated findings to an array
                    exportList.push({
                        "Categorie": material.category,
                        "Artikelnummer klant": material?.supplierSKU ?? "",
                        "Interne artikelnummer": material?.internalSKU ?? "",
                        "Offerte naam": material?.quotationNameNL ?? "",
                        "Aantal": totalAmount,
                        "Eenheid": material.unit ?? "onbekend",
                        "Stuk prijs": `${formatCurrency(pricePerPiece)}`,
                        "Totale waarde voorraad": ` ${formatCurrency(pricePerPiece * totalAmount)}`,
                        "Coated": (material.coated ? 'ja' : 'nee'),
                        "Actief": (material.active ? 'ja' : 'nee'),
                    })

                }

                // export the formated list
                exportObject(exportList, {
                    filename: `actuele_voorraad-${new Date().toLocaleDateString("nl-NL", { year: "numeric", month: "2-digit", day: "2-digit" })}`, // date in DD-MM-YYYY
                    fieldSeparator: ';',
                    decimalSeparator: 'locale',
                    useKeysAsHeaders: true,
                    showColumnHeaders: true,
                    useBom: true,
                }, authAxios, authData, "export.warehouses");

                closePopup('export');

                toast.success('Materiaal export geslaagd.');
            })
    }

    const doneCreating = () => {
        closePopup('addWarehouse');
        getWareHouses();
    }

    async function exportMonth(){
        let exportList = [];
        await authAxios
            .get(`/logistics/materials/monthexport?year=${selectedMonth.value.split('-')[0]}&month=${selectedMonth.value.split('-')[1]}`)
            .then(({ data }) => {

                // Loop materials
                for (const [materialKey, material] of Object.entries(data.materials)) {
                    const materialForExport = material.material;

                    // Add default keys
                    materialForExport.checkOut = 0;
                    materialForExport.checkIn = 0;

                    // Check default keys
                    for (const [historyKey, historyItems] of Object.entries(material.history)) {
                        if(historyKey === 'afboeken'){
                            for(const historyItem of historyItems){
                                materialForExport.checkOut += historyItem.quantity;
                            }
                        }

                        if(historyKey === 'inboeken'){
                            for(const historyItem of historyItems){
                                materialForExport.checkIn += historyItem.quantity;
                            }
                        }
                    }

                    let totalAmount = 0;
                    // calculate pcs.
                    let pricePerPiece = materialForExport.purchasePrice / materialForExport.countPerUnit;

                    // check all know stocks for total material count
                    for (let stock of materialForExport.stock) {
                        totalAmount += stock.total
                    }

                    exportList.push({
                        "Categorie": materialForExport.category,
                        "Artikelnummer klant": materialForExport?.supplierSKU ?? "",
                        "Interne artikelnummer": materialForExport?.internalSKU ?? "",
                        "Offerte naam": materialForExport?.quotationNameNL ?? "",
                        "Aantal": totalAmount,
                        "Eenheid": materialForExport.unit ?? "onbekend",
                        "Stuk prijs": `${formatCurrency(pricePerPiece)}`,
                        "Totale waarde voorraad": ` ${formatCurrency(pricePerPiece * totalAmount)}`,
                        "Coated": (materialForExport.coated ? 'ja' : 'nee'),
                        "Actief": (materialForExport.active ? 'ja' : 'nee'),
                        })
                }
            })

        if(exportList.length === 0){
            toast.warn(`Geen in of uitboek record gevonden.`);
            return;
        }

        // export the formated list
        exportObject(exportList, {
            filename: `maand_export-${parseInt(split(selectedMonth.value,'-')[1])+1}`, // date in DD-MM-YYYY
            fieldSeparator: ';',
            decimalSeparator: 'locale',
            useKeysAsHeaders: true,
            showColumnHeaders: true,
            useBom: true,
        }, authAxios, authData, "export.warehouses.monthexport");
        closePopup('export');
        closePopup('monthExport');

        toast.success('Maand export geslaagd.')
    }

    return (
        <>
            <Popup
                popupID='addWarehouse'
                title={'Magazijn toevoegen'}
                overflow={true}
            >
                <CreateWareHouse closePopup={doneCreating}/>
            </Popup>
            <Popup
                popupID='export'
                title={'Exporteren'}
                overflow={true}
            >
                <Grid columns={2}>
                    <Button onClick={() => getExportMaterials()}>
                        Actuele export
                    </Button>
                    <Button onClick={() => {
                            loadMonthExport();
                            closePopup('export');
                            openPopup('monthExport')
                        }}>
                        Maand export
                    </Button>
                </Grid>
            </Popup>
            <Popup
                popupID='monthExport'
                title='Maand export'
            >
                <Grid columns={1}>
                    <span>
                        Kies een maand.
                        <Select
                            options={months}
                            onChange={(e) => setSelectedMonth(e)}
                            value={selectedMonth}
                        >
                        </Select>
                    </span>
                    <Button onClick={() => exportMonth()}>
                        Exporteren
                    </Button>
                </Grid>
            </Popup>
            {filtering}
            <Table
                headers={['Naam', 'Locatie']}
                data={wareHouseTableData}
                padding={'s'}
            />
            {paging}
        </>
    );
}
