import React, {useContext, useEffect, useState} from "react";
import {FetchContext} from "App/Strapi/FetchContext";
import {Link, useNavigate} from "react-router-dom";
import Table from "../../../../Components/Table/Table";
import Icon from "../../../../Components/Icon/Icon";
import {toast} from "react-toastify";
import Container from "UI/App/Components/Container/Container";
import Select from "react-select";
import Grid from "UI/App/Components/Grid/Grid";
import {BooleanControlled} from "UI/App/Components/Form/Boolean";
import HasRole from "UI/App/Components/Auth/HasRole";
import Button from "UI/App/Components/Button/Button";
import User from "App/Strapi/User";
import {SpinnerOverlay} from "UI/App/Components/Spinner";
import {usePagination} from 'UI/App/Components/Pagination/Pagination';
import objRead from 'lodash/get';
import objWrite from 'lodash/set';


const _ACTIVE_FILTER_VALUES = [
    {value: false, label: 'Actief'},
    {value: true, label: 'Inactief'},
    {value: 'beide', label: 'Beide'}
];

export default function TemplateIndex() {
    const [tableData, setTableData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const {authAxios} = useContext(FetchContext);
    const [searchString, setSearchString] = useState('');

    // States for machine select
    const [allMachines, setAllMachines] = useState([]);
    const [chosenMachine, setChosenMachine] = useState(false);
    const [showInactive, setShowInactive] = useState(false);

    const navigate = useNavigate();

    const columnCount = User.hasRole(['admin', 'management']) ? User.hasRole(['admin']) ? 3 : 2 : 1;
    const columnTemplate = User.hasRole(['admin', 'management']) ? User.hasRole(['admin']) ? '120px 1fr 120px' : '1fr 120px' : '1fr';

    const {
        filtering,
        setTotalResults,
        setTotalPages,
        filterQuery,
        paginationStateLoaded
    } = usePagination({
        storageKey: "calculationTemplates",
        searchSettings: {
            enabled: true,
            strapiFields: [
                'name',
            ]
        },

        htmlElements: [
            <span className='pagination__filter addItem' key='link--add'>
                <Select
                    name='templatesIncludingMachine'
                    id='templatesIncludingMachine'
                    placeholder='Machine'
                    options={allMachines}
                    value={chosenMachine}
                    onChange={(machines) => {
                        setChosenMachine(machines);
                    }}
                    styles={{
                        container: (provided) => ({
                            ...provided,
                            margin: '0 !important'
                        }),
                    }}
                />
            </span>,
            <Select
                name='templatesIncludingMachine'
                id='templatesIncludingMachine'
                placeholder='Actief'
                options={_ACTIVE_FILTER_VALUES}
                value={_ACTIVE_FILTER_VALUES.find((value) => value.value === showInactive) ?? _ACTIVE_FILTER_VALUES[0]}
                onChange={(value) => {
                    setShowInactive((value?.['value']));
                }}
                styles={{
                    container: (provided) => ({
                        ...provided,
                        margin: '0 !important'
                    }),
                }}
            />,
            <Grid columns={columnCount} customColTemplate={columnTemplate}>
                <HasRole roles={['admin']}>
                    <Button type={'button'} onClick={() => updateConstants(true)} className={'btn btn--black btn--icon-right'}>
                        UUID reset <Icon name={'pencil'}/>
                    </Button>
                </HasRole>
                <HasRole roles={['admin', 'management']}>
                    <Button type={'button'} onClick={() => updateConstants(false)} className={'btn btn--black btn--icon-right'}>
                        Machines updaten <Icon name={'pencil'}/>
                    </Button>
                </HasRole>
                <Link className={'btn btn--icon-right'} to={'/calculations/templates/create'}>Toevoegen <Icon name={'plus'}/></Link>
            </Grid>
        ],
        buttonCollapseBreakpoint: 1375,
        resultsPerPageBreakpoint: 1960
        // paginationBreakpoint: 1850,
        // resultCountBreakpoint: 1850
    });


    async function updateConstants(resetUUID = false) {
        const pendingUpdate = toast.loading("Templates updaten...");

        void updateTemplates(pendingUpdate, resetUUID)
    }

    async function updateTemplates(pendingUpdate, resetUUID) {

        // Get initial templates
        let templates = await getTemplates(resetUUID);

        // Debug boolean used for debugging without storing or updating
        let debug = false;
        console.time(`updateTemplates debug: ${debug}`)

        // Get fresh machines and order as array by key
        const FreshMachines = keyIFy(await authAxios.get(`/calculations/resources/machines/all`,).then((data) => {
            return data.data;
        }).catch((exception) => {
            // toast.error(`Er is een probleem bij het ophalen van resource template: ${widgetFlowId}.`)
            console.error(exception);
        }), 'id');

        let currentTemplate = 1;
        // Used for storing gathered machine data
        const freshMachineData = {};
        const machineTemplateData = {};
        let checkedWidgetFlows = {};

        let updates = {
            constants: 0,
            widgets: 0,
            newWidgets: 0,
            removedWidgets: 0,
            uuidsAdded: 0,
            checklistsTemplatesAdded: 0,
            checklistsTemplatesUpdated: 0
        };

        // Validate uuid for old templates
        updates = await validateUUIDForWidgets(templates, pendingUpdate, updates, resetUUID);

        // Get all machines templates
        const FreshTemplates = keyIFy(await authAxios.get(`/calculations/resources/templates/all?populate=*`,).then((data) => {
            return data.data;
        }).catch((exception) => {
            // toast.error(`Er is een probleem bij het ophalen van resource template: ${widgetFlowId}.`)
            console.error(exception);
        }), 'id');

        // Get new templates if uuid's changed
        if (updates.uuidsAdded) {
            templates = await getTemplates(resetUUID);
        }

        // Loop templates and check for differences between templates
        for (const templateKey in templates) {
            const template = templates[templateKey];

            // Update toast
            toast.update(pendingUpdate, {
                render: `Templates controleren voor constants (${currentTemplate}/${templates.length}).`,
                type: "info",
                isLoading: true
            });

            updates = await validateAndUpdateTemplateConstants(template, FreshMachines, updates, pendingUpdate);

            currentTemplate++;
        }

        // Fetch updated templates
        templates = await getTemplates(resetUUID);

        // Reset current template for widgets
        currentTemplate = 1;
        for (const templateKey in templates) {
            const template = templates[templateKey];

            // Update toast
            toast.update(pendingUpdate, {
                render: `Templates controleren voor widgets (${currentTemplate}/${templates.length}).`,
                type: "info",
                isLoading: true
            });

            updates = await validateAndUpdateTemplateWidgets(template, FreshTemplates, updates, pendingUpdate, resetUUID);

            currentTemplate++;
        }

        // Reset current template for widgets
        currentTemplate = 1;
        for (const templateKey in templates) {
            const template = templates[templateKey];

            // Update toast
            toast.update(pendingUpdate, {
                render: `Templates controleren voor planning checklists (${currentTemplate}/${templates.length}).`,
                type: "info",
                isLoading: true
            });

            updates = await validateAndUpdateTemplatePlanningChecklist(template, FreshTemplates, updates, pendingUpdate);

            currentTemplate++;
        }

        const updateMessages = [
            updates.constants !== 0 ? `${updates.constants} constants geüpdate` : '',
            updates.widgets !== 0 ? `${updates.widgets} widgets geüpdate` : '',
            updates.newWidgets !== 0 ? `${updates.newWidgets} nieuwe widgets toegevoegd` : '',
            updates.removedWidgets !== 0 ? `${updates.removedWidgets} widgets verwijderd` : '',
            updates.uuidsAdded !== 0 ? `${updates.uuidsAdded} UUID's toegevoegd` : '',
            updates.checklistsTemplatesAdded !== 0 ? `${updates.checklistsTemplatesAdded} checklist templates toegevoegd` : '',
            updates.checklistsTemplatesUpdated !== 0 ? `${updates.checklistsTemplatesUpdated} checklist templates bijgewerkt` : ''
        ].filter(m => m !== '');

        toast.update(pendingUpdate, {
            render: `Klaar met updaten: ${updateMessages.join(', ')}!`,
            type: "success",
            isLoading: false,
            closeButton: true,
            closeOnClick: true
        });
    }

    async function validateAndUpdateTemplatePlanningChecklist(template, FreshTemplates, updates) {

        // Loop machines
        let updateTemplate = false;
        for (const [machineKey, machine] of Object.entries(template.data)) {

            // Skip widgetless machineKeys
            if (machineKey === 'result' || machineKey === 'template_result' || machineKey === 'calculation_result' || machineKey === 'amounts' || machineKey === 'isWebshopTemplate') continue;

            // Get machine Id from constant
            const widgetFlowId = machineKey.split('-')[1];
            const tabPrefix = machineKey.split('-')[0];

            const freshTemplate = FreshTemplates[widgetFlowId]

            if (freshTemplate === undefined) continue;

            if (template.data[machineKey].planningChecklist === undefined && freshTemplate.widgetData.planningChecklist !== undefined) {
                updates.checklistsTemplatesAdded++;
                updateTemplate = true;

                template.data[machineKey].planningChecklist = [...freshTemplate.widgetData.planningChecklist]
            }

            if (template.data[machineKey].planningChecklist !== undefined && freshTemplate.widgetData.planningChecklist !== undefined) {
                // First check if there is a difference between fresh template else overwrite whole checklist with new
                if (template.data[machineKey].planningChecklist.toString().replaceAll(`${tabPrefix}-`, '') !== freshTemplate.widgetData.planningChecklist.toString().replaceAll(`${tabPrefix}-`, '')) {
                    template.data[machineKey].planningChecklist = [...freshTemplate.widgetData.planningChecklist]

                    updateTemplate = true;

                }
            }

            // Loop template and add prefixes if needed
            for (const checklistItemKey in template.data[machineKey].planningChecklist) {
                const checklistItem = template.data[machineKey].planningChecklist[checklistItemKey]

                // Match all wigets without prefix
                for (const regexMatch of checklistItem.matchAll(/(?:\d+-)+?(?:\d+-widget-\d+(\.[A-z]+)?)/g)) {
                    // Skip if already prefixed
                    if (!/^\d+-\d+-widget-\d+/i.test(regexMatch[0])) continue;

                    template.data[machineKey].planningChecklist[checklistItemKey] = template.data[machineKey].planningChecklist[checklistItemKey].replaceAll(regexMatch[0], `${tabPrefix}-${regexMatch[0]}`)
                    updateTemplate = true;

                }
            }

            if (updateTemplate) {
                updates.checklistsTemplatesUpdated++;
            }

        }

        if (updateTemplate) {
            await authAxios.put(`/calculations/templates/${template.id}`, {
                data: {
                    data: template.data
                }
            }).catch((exception) => {
                console.error(exception);
                toast.error(`Er ging iets mis met het opslaan van widget-templates!`);
            });
        }

        return updates
    }

    async function validateAndUpdateTemplateWidgets(template, FreshTemplates, updates, pendingUpdate,resetUUID) {

        // First check for new widgets

        let updateTemplate = false;

        // return updates;
        updateTemplate = false;
        for (const [machineKey, machine] of Object.entries(template.data)) {

            // Skip widgetless machineKeys
            if (machineKey === 'result' || machineKey === 'template_result' || machineKey === 'calculation_result' || machineKey === 'amounts' || machineKey === 'isWebshopTemplate') continue;

            // Update constants
            if (machine.widgets !== undefined) {
                let machineId = null

                // Get machine Id from constant
                const widgetFlowId = machineKey.split('-')[1];
                const prefix = machineKey.split('-')[0];


                // Skip machine if no ID is set that means template has no machine attached
                if (widgetFlowId === undefined || FreshTemplates[widgetFlowId] === undefined) continue

                // Get fresh template data to check widgets
                let freshWidgetData = keyIFy(FreshTemplates[widgetFlowId].widgetData.widgets, 'uuid')
                let currentWidgets = keyIFy(machine.widgets, 'uuid')

                let updateResourceTemplate = false;
                // Check for added widgets
                for (const [key, {...newWidget}] of Object.entries(freshWidgetData)) {
                    if (resetUUID) continue; // When resetting dont add or remove widgets

                    // New widget add widget
                    if (currentWidgets[key] === undefined) {

                        // Give new widget uuid
                        if (newWidget.uuid === null || newWidget.uuid === undefined || newWidget.uuid === '') {
                            updateResourceTemplate = true
                            // newWidget.uuid = crypto.randomUUID();
                        }

                        if(newWidget.prefix === undefined){
                            newWidget.prefix = `${machineKey.split('-')[0]}-`;
                            newWidget.parentId = `${prefix}-${widgetFlowId}`;
                        }

                        template.data[machineKey].widgets.push(newWidget);

                        updates.newWidgets++;
                        updateTemplate = true
                    }
                }

                if (updateResourceTemplate) {

                    const updatedResourceTemplate = await authAxios.get(`/calculations/resources/templates/${widgetFlowId}?populate=*`,).then(({data: {data}}) => {

                        const oldWidgetsById = keyIFy(template.data[machineKey].widgets, 'id');
                        // First check widgets
                        for (const widgetKey in data.widgetData.widgets) {
                            const widget = data.widgetData.widgets[widgetKey]
                            // const widget = newWidgetsById[widgetId]
                            // Add crypto to new widgets
                            if (widget.uuid === null || widget.uuid === undefined || widget.uuid === '') {
                                widget.uuid = oldWidgetsById[widget.id].uuid
                                data.widgetData.widgets[data.widgetData.widgets] = widget;
                            }

                        }

                        return data;
                    }).catch((exception) => {
                        // toast.error(`Er is een probleem bij het ophalen van resource template: ${widgetFlowId}.`)
                        console.error(exception);
                    });


                    const updatedWidgetTemplate = await authAxios.put(`/calculations/resources/templates/${widgetFlowId}?populate=*`, {data: updatedResourceTemplate})
                        .then((data) => {
                            return data.data.data
                        })
                        .catch((exception) => {
                            toast.error(`Er is een probleem bij het bewerken van resource template:. ${widgetFlowId}`)
                            console.error(exception);
                            return false;
                        });

                    FreshTemplates[updatedResourceTemplate.id] = updatedWidgetTemplate
                }

                // Add added widgets

                freshWidgetData = keyIFy(FreshTemplates[widgetFlowId].widgetData.widgets, 'uuid')
                currentWidgets = keyIFy(machine.widgets, 'uuid')

                // Check fo rremoved widgets
                for (const [key, removedWidget] of Object.entries(currentWidgets)) {
                    // When resetting dont remove widgets
                    if (resetUUID) continue;

                    if (removedWidget.uuid === '') continue

                    // New widget add widget
                    if (freshWidgetData[key] === undefined) {

                        // Remove widget
                        // Loop widgets and unset matching key
                        for (const widgetKey in template.data[machineKey].widgets) {
                            const potentialRemoval = template.data[machineKey].widgets[widgetKey]

                            if (potentialRemoval !== null) {

                                if (potentialRemoval.uuid === removedWidget.uuid) {
                                    updates.removedWidgets++;
                                    updateTemplate = true
                                    template.data[machineKey].widgets.splice(widgetKey, 1)
                                }
                            }
                        }
                    }
                }

                // Loop widgets and check for update
                for (const widgetKey in machine.widgets) {
                    const widget = machine.widgets[widgetKey];

                    // Widget is removed
                    if (widget === null) continue;
                    // Check if widget exists
                    if (freshWidgetData[widget.uuid] !== undefined) {

                        // Handle widget
                        const potentialUpdate = handleWidget(widget, freshWidgetData[widget.uuid],prefix,machineKey)

                        if (potentialUpdate !== false) {
                            updates.widgets++;
                            updateTemplate = true

                            // update widget
                            template.data[machineKey].widgets[widgetKey] = potentialUpdate;
                        }
                    } else {
                        // Try to update widget uuid
                        const freshWidgetsById = keyIFy(FreshTemplates[widgetFlowId].widgetData.widgets, 'id')
                        if (freshWidgetsById[widget.id] !== undefined && freshWidgetsById[widget.id] !== null) {
                            widget.uuid = freshWidgetsById[widget.id].uuid;
                            updateTemplate = true;
                            updates.widgets++;
                        }
                    }
                }
            }
        }

        if (updateTemplate) {

            template = await authAxios.put(`/calculations/templates/${template.id}`, {
                data: {
                    data: template.data
                }
            }).then((data) => {
                return data.data
            }).catch((exception) => {
                console.error(exception);
                toast.error(`Er ging iets mis met het opslaan van widget-templates!`);
            });
        }

        return updates
    }

    function objectCompare(obj1, obj2, keys = []) {
        const obj1Keys = Object.keys(obj1);
        const obj2Keys = Object.keys(obj2);
        const keysToSkip = [
            'hidden',
            'defaultValue',
            'target',
            'selectedValue',
            'result'
        ]

        let diff = [];

        for (const key of obj1Keys) {
            if (keysToSkip.includes(key)) continue

            if (keys.at(-1) === 'widgets') {
                const existingWidget = obj1[key];
                const extractedWidget = obj2.find(widget => widget.id === existingWidget.id);

                if (typeof existingWidget === 'object' && existingWidget !== null && extractedWidget !== null && extractedWidget !== undefined) {
                    diff = diff.concat(objectCompare(existingWidget, extractedWidget, keys.concat(key)));
                    continue;
                }

                if (existingWidget !== extractedWidget) {
                    diff.push({
                        key: [...keys, key].join('.'),
                        existing: existingWidget,
                        extracted: extractedWidget
                    });
                }

                continue;
            }

            // Skip uuid's as they are not relevant
            // Skip constants as they are not relevant
            if (key === 'uuid' || key === 'constants') continue;

            if (typeof obj1[key] === 'object' && obj1[key] !== null && obj2[key] !== null && obj2[key] !== undefined) {
                diff = diff.concat(objectCompare(obj1[key], obj2[key], keys.concat(key)));
                continue;
            }

            if (obj1[key] !== obj2[key]) {
                diff.push({
                    key: [...keys, key].join('.'),
                    existing: obj1[key],
                    extracted: obj2[key]
                });
            }
        }

        for (const key of obj2Keys) {

            if (keysToSkip.includes(key)) continue

            if (keys.at(-1) === 'widgets') {
                const extractedWidget = obj2[key];
                const existingWidget = obj1.find(widget => widget.id === extractedWidget.id);

                if (extractedWidget && existingWidget === undefined) {
                    diff.push({
                        key: [...keys, key].join('.'),
                        existing: existingWidget,
                        extracted: extractedWidget
                    });
                }

                continue;
            }

            // Skip uuid's as they are not relevant
            // Skip constants as they are not relevant
            if (key === 'uuid' || key === 'constants') continue;

            if (obj2[key] && obj1[key] === undefined) {
                diff.push({
                    key: [...keys, key].join('.'),
                    existing: obj1[key],
                    extracted: obj2[key]
                });
            }
        }

        return diff;
    }

    function handleWidget(currentWidget, potentialUpdatedWidget,prefix,parentId) {
        let widgetUpdated = false;
        // Boolean for error handling and futre proofing
        let handled = false;
        const basicTypes = [
            'Addition',
            'Input',
            'Times',
            'Int',
            'Division',
            'Subtraction',
            'CeilWidget',
            'FloorWidget'
        ]

        if(currentWidget.prefix === undefined){
            currentWidget.prefix = `${prefix}-`;
            currentWidget.parentId = parentId;
            widgetUpdated = true;
        }

        // Handle basic diff or handle specific widgets
        if (basicTypes.includes(currentWidget.data.type)) {
            handled = true;
            let diffArray = objectCompare(currentWidget.data, potentialUpdatedWidget.data)

            for (const diff of diffArray) {
                if (diff.extracted !== undefined) {

                    /**
                     * Get the current value in the widget data using lodash get for nested keys
                     */
                    const currentValue = objRead(currentWidget.data, diff.key);

                    /**
                     * The next if statement is a temporary fix for nested keys that are found literally.
                     * There was an issue with the update script which caused differing keys to be added literally instead of nested.
                     * e.g. "value.val1" was added as "valueval1" instead of "value: { val1: 0 }"
                     * This if statement will remove the value if it is found literally.
                     */
                    // First check if key is nested
                    if (diff.key.split('.').length > 0) {
                        // Array to store found keys
                        const foundLiteralKeys = [];

                        // Check if key is found literally with periods
                        if (currentWidget.data[diff.key] !== undefined) {
                            foundLiteralKeys.push(diff.key);
                        }

                        // Check if key is found literally without periods
                        if (currentWidget.data[diff.key.replaceAll('.', '')] !== undefined) {
                            foundLiteralKeys.push(diff.key.replaceAll('.', ''));
                        }

                        if (foundLiteralKeys.length > 0) {
                            // Remove found keys
                            for (const foundLiteralKey of foundLiteralKeys) {
                                console.warn(`Nested key "${foundLiteralKey}" found literally. Removing value:`, currentWidget.data[foundLiteralKey], currentWidget.data)
                                delete currentWidget.data[foundLiteralKey];
                            }

                            // Log some information for debugging which values are updated
                            console.info("Current nested value:", currentValue);
                            console.info("Updating to value:", diff.extracted);
                            console.log("=====================================");
                        }
                    }

                    // Double check value
                    if (currentValue !== diff.extracted) {
                        widgetUpdated = true;
                        // Update the value using lodash set for nested keys
                        currentWidget.data = objWrite(currentWidget.data, diff.key, diff.extracted);
                    }
                }
            }
        } else {
            // Handle specific widgets
            if (currentWidget.data.type === 'ReturnWidget') {
                handled = true;

                if (currentWidget.data.title !== potentialUpdatedWidget.data.title) {
                    currentWidget.data.title = potentialUpdatedWidget.data.title;
                    widgetUpdated = true;
                }

                if (currentWidget.data.unit !== potentialUpdatedWidget.data.unit) {
                    currentWidget.data.unit = potentialUpdatedWidget.data.unit;
                    widgetUpdated = true;
                }

                if (currentWidget.data?.position !== potentialUpdatedWidget.data?.position) {
                    currentWidget.data.position = potentialUpdatedWidget.data?.position;
                    widgetUpdated = true;
                }

                // Handle target or value
                if (currentWidget?.data?.value?.target !== undefined) {
                    if (currentWidget.data.value.target.substring(currentWidget.data.value.target.indexOf("-") + 1) !== potentialUpdatedWidget.data.value) {
                        if (currentWidget.data.value.target !== potentialUpdatedWidget.data.value) {
                            currentWidget.data.value.target = `${currentWidget.prefix}${potentialUpdatedWidget.data.value}`;
                            widgetUpdated = true;
                        }
                    }
                } else {
                    if (currentWidget.data.value !== potentialUpdatedWidget.data.value) {
                        currentWidget.data.value = potentialUpdatedWidget.data.value;
                        widgetUpdated = true;
                    }
                }
            }

            if (currentWidget.data.type === 'IfWidget') {
                handled = true;

                if (currentWidget.data.title !== potentialUpdatedWidget.data.title) {
                    currentWidget.data.title = potentialUpdatedWidget.data.title;
                    widgetUpdated = true;
                }

                if (currentWidget.data.unit !== potentialUpdatedWidget.data.unit) {
                    currentWidget.data.unit = potentialUpdatedWidget.data.unit;
                    widgetUpdated = true;
                }

                if (currentWidget.data?.position !== potentialUpdatedWidget.data?.position) {
                    currentWidget.data.position = potentialUpdatedWidget.data?.position;
                    widgetUpdated = true;
                }

                let diffArray = objectCompare(currentWidget.data.value, potentialUpdatedWidget.data.value)

                for (const diff of diffArray) {

                    widgetUpdated = true;
                    currentWidget.data.value[diff.key] = diff.extracted;
                }
            }

            if (currentWidget.data.type === 'SelectWidget') {
                handled = true;

                if (currentWidget.data.title !== potentialUpdatedWidget.data.title) {
                    currentWidget.data.title = potentialUpdatedWidget.data.title;
                    widgetUpdated = true;
                }

                if (currentWidget.data.unit !== potentialUpdatedWidget.data.unit) {
                    currentWidget.data.unit = potentialUpdatedWidget.data.unit;
                    widgetUpdated = true;
                }

                if (currentWidget.data?.position !== potentialUpdatedWidget.data?.position) {
                    currentWidget.data.position = potentialUpdatedWidget.data?.position;
                    widgetUpdated = true;
                }

                if (JSON.stringify(currentWidget.data.options) !== JSON.stringify(potentialUpdatedWidget.data.options)) {
                    currentWidget.data.options = potentialUpdatedWidget.data.options;
                    widgetUpdated = true;
                }
            }

            if (currentWidget.data.type === 'MaterialChoice') {
                handled = true;

                if (currentWidget.data.title !== potentialUpdatedWidget.data.title) {
                    currentWidget.data.title = potentialUpdatedWidget.data.title;
                    widgetUpdated = true;
                }

                if (currentWidget.data.unit !== potentialUpdatedWidget.data.unit) {
                    currentWidget.data.unit = potentialUpdatedWidget.data.unit;
                    widgetUpdated = true;
                }

                if (currentWidget.data?.position !== potentialUpdatedWidget.data?.position) {
                    currentWidget.data.position = potentialUpdatedWidget.data?.position;
                    widgetUpdated = true;
                }

                if (currentWidget.data.value.label !== potentialUpdatedWidget.data.value.label) {
                    currentWidget.data.value.label = potentialUpdatedWidget.data.value.label;
                    widgetUpdated = true;
                }

                if (JSON.stringify(currentWidget.data.value.filters) !== JSON.stringify(potentialUpdatedWidget.data.value.filters)) {
                    currentWidget.data.value.filters = potentialUpdatedWidget.data.value.filters;
                    widgetUpdated = true;
                }

                if (JSON.stringify(currentWidget.data.value.returnFields) !== JSON.stringify(potentialUpdatedWidget.data.value.returnFields)) {
                    currentWidget.data.value.returnFields = potentialUpdatedWidget.data.value.returnFields;
                    widgetUpdated = true;
                }
            }
        }
        if (!handled) {
            console.warn(`${currentWidget.data.type} not handled`)
        }

        if (widgetUpdated) return currentWidget;

        return false
    }

    // Updates template if needed
    async function validateAndUpdateTemplateConstants(template, FreshMachines, updates) {

        let updateTemplate = false;
        for (const [machineKey, machine] of Object.entries(template.data)) {

            // Skip widgetless machineKeys
            if (machineKey === 'result' || machineKey === 'template_result' || machineKey === 'calculation_result' || machineKey === 'amounts' || machineKey === 'isWebshopTemplate') continue;

            // Update constants
            if (machine.constants !== undefined) {
                let machineId = null

                // Get machine Id from constant
                for (const constant of machine.constants) {
                    if (constant.id.includes('const-id')) {
                        machineId = constant.value
                    }
                }

                if (machineId === null) continue;

                const freshConstants = FreshMachines[machineId]

                for (const [constKey, constant] of Object.entries(machine.constants)) {

                    // Get constant values
                    const constantName = constant.id.split('-')[2];
                    const currentConstValue = constant.value;
                    const constValueFromApi = freshConstants[constantName]

                    // If value does not match update
                    if (currentConstValue != constValueFromApi) {
                        updateTemplate = true;

                        // For update toast
                        updates.constants++;

                        // Add new value to template
                        template.data[machineKey].constants[constKey].value = constValueFromApi;
                    }
                }
            }
        }

        if (updateTemplate) {
            await authAxios.put(`/calculations/templates/${template.id}`, {
                data: {
                    data: template.data
                }
            }).catch((exception) => {
                console.error(exception);
                toast.error(`Er ging iets mis met het opslaan van widget-templates!`);
            });
        }

        return updates
    }

    async function getTemplates(resetUUID) {
        return await authAxios.get(`/calculations/templates/all?filters[active][$eq]=true&populate=*`).then((data) => {

            // Hard reset all UUID
            if (resetUUID) {
                for (const dataKey in data.data) {
                    const template = data.data[dataKey];

                    for (const [key, machine] of Object.entries(template.data)) {

                        if (key === 'isWebshopTemplate' || key === 'result' || key === 'template_result' || key === 'calculation_result' || key === 'amounts') {
                            continue;
                        }

                        for (const widgetKey in machine.widgets) {
                            if (data.data[dataKey].data[key].widgets[widgetKey] !== null) {
                                data.data[dataKey].data[key].widgets[widgetKey].uuid = '';
                            }
                        }
                    }
                }
            }

            return data.data
        }).catch((exception) => {
            console.error(exception)
            toast.error(`Er ging iets mis met het ophalen van de date van templates!`);
        });
    }

    async function validateUUIDForWidgets(templates, pendingUpdate, updates) {

        // Validated templates
        let resourceTemplates = await authAxios.get(`/calculations/resources/templates/all`,).then((data) => {
            return data.data;
        }).catch((exception) => {
            // toast.error(`Er is een probleem bij het ophalen van resource template: ${widgetFlowId}.`)
            console.error(exception);
        });

        let currentTemplate = 1;

        for (const templateKey in resourceTemplates) {
            const template = resourceTemplates[templateKey];

            toast.update(pendingUpdate, {
                render: `Templates controleren voor UUID (${currentTemplate}/${resourceTemplates.length}).`,
                type: "info",
                isLoading: true
            });

            if (template === undefined) continue

            // Check widgets for uuid
            let updateTemplate = false;
            for (const widget of template.widgetData.widgets) {

                // widget is removed
                if (widget === null) continue;

                // Add universal unique ID if needed
                if (widget.uuid === undefined || widget.uuid === null || widget.uuid === '') {
                    updateTemplate = true;
                    break;
                }
            }

            // Update if needed
            if (updateTemplate) {
                const updatedResourceTemplate = await authAxios.get(`/calculations/resources/templates/${template.id}?populate=*`,).then(({data: {data}}) => {

                    // First check widgets
                    for (const widget of data.widgetData.widgets) {
                        // Add universal unique ID if needed
                        if (widget.uuid === undefined || widget.uuid === null || widget.uuid === '') {
                            widget.uuid = crypto.randomUUID();
                            updates.uuidsAdded++;
                        }
                    }

                    return data;
                }).catch((exception) => {
                    // toast.error(`Er is een probleem bij het ophalen van resource template: ${widgetFlowId}.`)
                    console.error(exception);
                });

                await authAxios.put(`/calculations/resources/templates/${template.id}?populate=*`, {data: updatedResourceTemplate}).catch((exception) => {
                    toast.error(`Er is een probleem bij het bewerken van resource template:. ${template.id}`)
                    console.error(exception);
                    return false;
                });
            }

            currentTemplate++;
        }

        return updates
    }

    async function updateTemplateActiveState(templateId, boolean) {
        const updateToast = toast.loading('Waarde aanpassen.')

        // update template
        authAxios.put(`/calculations/templates/${templateId}`, {
            data: {
                active: boolean
            }
        }).then(({data}) => {
            loadTemplates(updateToast);
        }).catch((exception) => {
            console.error(exception);
            toast.error(`Er ging iets mis met het opslaan van widget-templates!`);
        });
    }

    function keyIFy(array, key) {
        let filteredArray = [];

        for (const item of array) {
            // widget is removed

            if (item === null || item[key] === undefined) continue

            filteredArray[item[key]] = item
        }

        return filteredArray;
    }


    // Run in dom update
    useEffect(() => {

        if (!paginationStateLoaded) return;
        void loadTemplates();

    }, [chosenMachine, showInactive, authAxios, paginationStateLoaded, filterQuery])

    async function loadTemplates(updateToast) {
        authAxios.get(`/calculations/templates/all?${filterQuery}`).then(({data}) => {
            let options = [];

            data = data
                .filter((template) => template.active !== showInactive)
                .sort((a, b) => a.name.localeCompare(b.name));

            for (const template of data) {
                if (searchString !== false || searchString === '') {
                    if (!template.name.toLowerCase().includes(searchString.toLowerCase())) {
                        continue;
                    }
                }

                if (chosenMachine !== false) {
                    let templateIncludingMachine = false;

                    // Loop template data
                    for (const dataKey in template.data) {
                        if (dataKey.split('-')[1] === undefined) continue;

                        if (templateIncludingMachine) break;

                        // Check if first part of string is equal to machine id
                        if (parseInt(dataKey.split('-')[1]) === chosenMachine.value) {

                            // Add template based on ID to ensure templates are not added double
                            templateIncludingMachine = true;
                        }
                    }

                    if (!templateIncludingMachine) {
                        continue;
                    }
                }

                options.push({
                    attributes: {
                        id: template.id,
                        key: template.id,
                        onClick: () => {
                            navigate(`/calculations/templates/${template.id}/edit/`);
                        }
                    },
                    data: [
                        template.name,
                        <BooleanControlled
                            leftLabel='Inactief'
                            rightLabel='Actief'
                            leftIsRed={true}
                            value={template?.active}
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                            setValue={(value) => {
                                if (template.active !== value) {
                                    updateTemplateActiveState(template.id, value);
                                }
                            }}
                        />
                    ]
                })
            }

            setTableData(options);

            if (updateToast !== undefined) {
                toast.update(updateToast, {
                    render: `Machine status geüpdatet.`,
                    type: "success",
                    isLoading: false,
                    autoClose: 1000
                });
            }

            setTotalResults(options.length);
            setTotalPages(1);
        }).catch((exception) => {
            console.error(exception)
            toast.error(`Er ging iets mis met het ophalen van de date van templates!`);
        }).finally(() => {
            setIsLoading(false);
        });
    }

    // useEffect to load defaults
    useEffect(() => {
        authAxios.get(`/calculations/resources/templates/all`).then(({data}) => {
            let options = [];

            for (const machine of data) {
                options.push({
                    value: machine.id,
                    label: machine.name
                })
            }

            setAllMachines(options)
        }).catch((exception) => {
            console.error(exception)
            toast.error(`Er ging iets mis met het ophalen van de date van templates!`);
        });
    }, [])

    return (
        <>
            <SpinnerOverlay visible={isLoading} message={"Templates ophalen..."}>
                {filtering}

                <Container>
                    <Table
                        headers={['Naam', '']}
                        structure={{
                            900: ['100%', '160px'],
                            default: ['100%', '200px']
                        }}
                        data={tableData}
                    />
                </Container>

                {/* {paging} */}
            </SpinnerOverlay>
        </>
    )
}
