import Table from "UI/App/Components/Table/Table";
import { useLoaderData } from "react-router-dom";
import ActualCostingCalculator from "UI/App/Partials/Content/CRM/Orders/ActualCosting/ActualCostingCalculator";

export default function MiscellaneousCostTable() {
    const { order } = useLoaderData();

    const calculator = new ActualCostingCalculator(order);

    const tableData = calculator.getMiscellaneousCost().tableData;

    if (tableData.length === 0) {
        return null;
    }

    return (
        <Table
            className='miscellaneousCostTable'
            headers={[
                <strong>Diversen</strong>,
                "Berekend (calculatie)",
                "Daadwerkelijk (gerealiseerd)",
                ""
            ]}
            data={tableData}
        />
    )
}