import {useContext, useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {FetchContext} from "App/Strapi/FetchContext";
import Table from "UI/App/Components/Table/Table";
import {toast} from "react-toastify";
import Icon from "../../../../Components/Icon/Icon";
import qs from "qs";
import {usePagination} from "UI/App/Components/Pagination/Pagination";
import Button from "../../../../Components/Button/Button";
import Popup, {closePopup, openPopup} from "../../../../Components/Popup/Popup";

export default function OrderConfirmations({ archived = false }) {
    const { authAxios } = useContext(FetchContext);

    // create a state for the table data
    const [tableData, setTableData] = useState([]);
    const [selectedConfirmation, setSelectedConfirmation] = useState(null);
    const {
        paging,
        filtering,
        currentPage,
        resultsPerPage,
        setTotalPages,
        setTotalResults,
        filterQuery
    } = usePagination({
        storageKey: 'confirmation',
        searchSettings: {
            enabled: true,
            strapiFields: [
                'number',
                '[quote][company][name]',
                '[quote][number]',
                '[quote][description]',
            ]
        },
        filters: [
            {
                name: "status",
                type: "select",
                options: [
                    {value: "*", name: "Alle"},
                    {value: "Open ", name: "Open"},
                    {value: "Bevestigd", name: "Bevestigd"},
                ],
            }
        ]
    });

    const navigate = useNavigate();

    // get the table data using the useEffect hook
    useEffect(() => {
        const statusses = [
            'Open',
            'Bevestigd',
        ]

        const params = qs.stringify({
            populate: {
                chosenAmounts:true,
                quote: {
                    populate: {
                        calculations:{
                            fields:[
                                'owner'
                            ]
                        },
                        company: {
                            fields: [
                                'name',
                                'id'
                            ]
                        }
                    },
                    fields: [
                        'number',
                        'id',
                        'description'
                    ]
                },
                calculations: {
                    populate: {
                        calculationTemplate: {
                            fields: ['name']
                        }
                    },
                    fields: ['customerReference']
                }
            },
            sort: {
                id: 'desc'
            },
            fields: [
                'number',
                'status',
                'description'
            ]
        });

        // get the companies using the built params
        authAxios.get(`/quotes/confirmations?${params}&${filterQuery}`)
            .then(({ data }) => {
                // // Set data
                const tableData = [];

                // loop through all the records
                for (const orderConfirmation of data.data) {
                    let options = [];
                    for(const status of statusses){
                            options.push(<option key={status}>{status}</option>)
                    }

                    let amountString = '';
                    if(orderConfirmation.chosenAmounts.length !== 0){
                        let i = 0;
                        for(const amount of orderConfirmation.chosenAmounts){
                            console.log(orderConfirmation.id,amount);
                            amountString += `${amount.amount} ${orderConfirmation.chosenAmounts.length > 1 ? i !== 0 ? `` : ',' : ''}`;

                            i++;
                        }
                    }

                    tableData.push({
                        attributes: {
                            onClick: () => { navigate(`/orders/confirmation/${orderConfirmation.id}`) },
                            id: orderConfirmation.id
                        },
                        data: [
                            <div className={'owner-indicator'}>
                                {orderConfirmation.quote?.calculations[0].owner !== null ? orderConfirmation.quote?.calculations[0].owner.toLowerCase() === 'hjmg' ? <span className={'circle circle-hjmg'}></span> : <span className={'circle circle-pretore'}></span> : <></>}
                            </div>,
                            orderConfirmation.number,
                            <Link onClick={(e) => { e.stopPropagation() }} to={`/quotes/${orderConfirmation?.quote?.id}`}>{orderConfirmation?.quote?.number}</Link>,
                            <Link onClick={(e) => { e.stopPropagation() }} to={`/crm/companies/${orderConfirmation?.quote?.company.id}`}>{orderConfirmation?.quote?.company.name}</Link>,
                            orderConfirmation.quote?.description ? orderConfirmation.quote.description : <span style={{ color: 'gray', fontStyle: 'italic' }}>Leeg</span>,
                            amountString,
                            <select defaultValue={orderConfirmation?.status ?? undefined} onClick={(e) => { e.stopPropagation() }} onChange={(e) => { updateStatus(e, orderConfirmation.id) }}>{options}</select>,
                            <div className={'table__action-buttons'}>
                                {/* <Link data-title={'Bekijken'}  onClick={(e) => {e.stopPropagation()}} className={'btn btn--transparent'} to={`/orders/${orderConfirmation.id}`}><Icon name={'eye'} /></Link> */}
                                {/*<Link data-title={'Kopieren'}  onClick={(e) => {e.stopPropagation()}} className={'btn btn--transparent'} to={`/orders/confirmations/${orderConfirmation.id}/copy`}><Icon name={'copy'} /></Link>*/}
                                <Button data-title={'Calculatie kopiëren'} className={'btn btn--transparent'} onClick={(e) => {
                                    e.stopPropagation();
                                    openPopup("CopyOrRevision")
                                    setSelectedConfirmation(orderConfirmation.id)
                                }}><Icon name={'copy'}/></Button>
                            </div>
                        ]
                    })
                }
                setTableData(tableData);

                // set the total pages
                setTotalPages(data.meta.pagination.pageCount);
                setTotalResults(data.meta.pagination.total);
            }).catch((exception) => {
            toast.error(`Er is iets fout gegaan ${exception?.response?.status ? `(${exception.response?.status})` : ''}`);
            console.error(exception);
        })
    }, [authAxios, currentPage, resultsPerPage, filterQuery]);

    const updateStatus = (e, confirmationId) => {
        authAxios.put(`/quotes/confirmations/${confirmationId}`, {
            "data": {
                "status": e.target.value
            }
        }).then(({data}) => {
            toast.success(`Offerte: ${data.data.number} status omgezet naar ${e.target.value}`);
        }).catch((exception) => {
            console.error(exception);
            toast.error(`Er ging iets mis met het bewerken van offerte.`);
        });
    }

    return (
        <div>
            <Popup popupID='CopyOrRevision' title={'Kopie of revisie'}>
                <Button onClick={() => {
                    closePopup('CopyOrRevision')
                    navigate(`/orders/confirmations/${selectedConfirmation}/copy`)
                }}>
                    Kopie
                </Button>
                <Button onClick={() => {
                    closePopup('CopyOrRevision')
                    navigate(`/orders/confirmations/${selectedConfirmation}/revision`)
                }}>
                    Revisie
                </Button>
            </Popup>
            {filtering}
            <Table
                headers={['','Nummer', 'Offerte', 'Klant', 'Omschrijving', 'Aantal','Status', '']}
                structure={{
                    500: ['40px', '100%', 0, 0, 0, 0, '150px', 0],
                    700: ['40px', '120px', '100%', 0, 0, '100px', '150px', 0],
                    900: ['40px', '120px', '150px', '100%', 0, '100px', '150px', 0],
                    1000: ['40px', '120px', '150px', '100%', 0, '100px', '150px', '70px'],
                    default: ['40px', '120px', '150px', '200px', '100%', '100px', '150px', '70px']
                }}
                data={tableData}
                hover
                border='row'
                padding='m'
                paddingBreakpoint={1500}
                columnOverflowExceptions={[6]}
            />
            {paging}
        </div>
    )
}
