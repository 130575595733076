import {Controller, useForm} from "react-hook-form";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import {FetchContext} from "App/Strapi/FetchContext";
import {toast} from "react-toastify";
import spinner from "UI/Assets/Spinners/spinner-primary.svg";
import Boolean from "../../../../Components/Form/Boolean";
import CreatableSelect from "react-select/creatable";
import strapiToast from "App/Util/errorHandle";
import Container from "UI/App/Components/Container/Container";
import Block from "UI/App/Components/Block/Block";
import Icon from "UI/App/Components/Icon/Icon";
import {formatDate} from "App/Util/format";
import Select from "react-select";
import Button from "UI/App/Components/Button/Button";

export default function MaterialCreateUpdate({type}) {
    const {register, handleSubmit, control, formState: {errors}, setValue, setError} = useForm();
    const [materialData, setMaterialData] = useState(false);
    const [categories, setCategories] = useState([]);
    const [categoriesIsLoading, setCategoriesIsLoading] = useState(true);

    const [supplierOptions, setSupplierOptions] = useState([]);
    const [chosenSupplier, setChosenSupplier] = useState(null);
    const [usingEnglish, setUsingEnglish] = useState(false);

    const [customerOptions, setCustomerOptions] = useState([]);
    const [chosenCustomer, setChosenCustomer] = useState(null);

    const [isCustomerSpecific, setIsCustomerSpecific] = useState(false);

    const msgRequired = 'Dit veld is verplicht!';

    // get the params from the url
    const params = useParams();

    // get the machine id from the params
    const materialId = params?.materialId;

    // navigation hook for navigating after creating a new machine
    const navigate = useNavigate();

    // get the axios instance form the fetch context
    const {authAxios} = useContext(FetchContext);

    // update the state for customerSpecific based on the material data
    useEffect(() => {
        setIsCustomerSpecific(materialData?.customerSpecific ?? false);
    }, [materialData]);

    useEffect(() => {
        load();
    }, [type, authAxios, materialId]);

    async function load() {

        // Get companies
        await authAxios.get(`/crm/companies/all`).then(({data}) => {
            let customerData = [];

            for (const customer of data) {

                customerData.push({
                    label: customer.name,
                    value: customer.id
                });
            }

            setCustomerOptions(customerData)

        }).catch((e) => {
            toast.error(`Er is iets fout gegaan bij het ophalen van de klanten!`);
        });

        // Get suppliers
        await authAxios.get(`/crm/suppliers/all`).then(({data}) => {
            let supplierData = [];

            for (const supplier of data) {

                supplierData.push({
                    label: supplier.name,
                    value: supplier.id
                });
            }

            setSupplierOptions(supplierData)

        }).catch((e) => {
            toast.error(`Er is iets fout gegaan bij het ophalen van het leveranciers!`);
        });

        // Get categories
        await authAxios.get(`/logistics/materials/categories`).then(({data}) => {
            setCategories(data.categories.map((category) => ({
                label: category,
                value: category
            })));
            setCategoriesIsLoading(false);
        }).catch((e) => {
            if (process.env.NODE_ENV === 'development') console.error(e);
            toast.error(`Er is iets fout gegaan bij het ophalen van het categorieen! ${e?.response?.status && `(${e.response.status})`}`);
            setCategoriesIsLoading(false);
        });

        if (type === 'update') {
            // Get material data
            await authAxios.get(`/logistics/materials/${materialId}?populate=*`).then(({ data }) => {
                if (data.company !== null && data.company !== undefined) {
                    setChosenCustomer({
                        label: data.company.name,
                        value: data.company.id
                    })
                }

                if (data.supplier !== null && data.supplier !== undefined) {
                    setChosenSupplier({
                        label: data.supplier.name,
                        value: data.supplier.id
                    })
                }

                data.category = { label: data.category, value: data.category }
                // set the machine data
                setMaterialData(data);
            }).catch((e) => {
                if (process.env.NODE_ENV === 'development') console.error(e);
                toast.error(`Er is iets fout gegaan bij het ophalen van het materiaal! ${e?.response?.status && `(${e.response.status})`}`);
            })
        } else if (type === 'copy') {
            // Get material data
            await authAxios.get(`/logistics/materials/${materialId}?populate=*`).then(({ data }) => {
                if (data.supplier !== null && data.supplier !== undefined) {
                    setChosenSupplier({
                        label: data.supplier.name,
                        value: data.supplier.id
                    })
                }
                data.category = { label: data.category, value: data.category }
                console.log(data)
                data.quotationNameEN = `Copy of ${data.quotationNameEN}`
                data.quotationNameNL = `Kopie van ${data.quotationNameNL}`
                data.calculationDescription = `Kopie van ${data.calculationDescription}`
                //data.
                // set the machine data
                setMaterialData(data);
            }).catch((e) => {
                if (process.env.NODE_ENV === 'development') console.error(e);
                toast.error(`Er is iets fout gegaan bij het ophalen van het materiaal! ${e?.response?.status && `(${e.response.status})`}`);
            })
        } else {
            setMaterialData(true);
        }
    }

    /**
     * Shows a span with an error message if it exists
     * @param name
     * @returns {undefined|JSX.Element}
     */
    function showErrorMsg(name) {
        const err = errors?.[name];
        const msg = err?.message;
        return typeof err !== 'undefined' &&
            <span className={'form--error'}>{msg === '' ? "Controleer dit veld" : msg}</span>
    }

    /**
     * Transforms the data after submitting to fix some potential issues
     * @param data
     */
    function transformData(data) {
        // unset the supplier SKU if it is an empty string
        if (data.supplierSKU === "") {
            data.supplierSKU = null;
        }

        if (typeof data.category === "object") {
            data.category = data.category.value;
        }

        // clear empty fields
        for (const field in data) {
            if (data[field] === '') {
                data[field] = null;
            }
        }

        return data;
    }

    /**
     * Function that will run once the
     * @param data
     */
    function onSubmit(data) {
        data = transformData(data);

        if(chosenCustomer !== null){
            data.company = chosenCustomer.value;
        }

        if(chosenSupplier !== null){
            data.supplier = chosenSupplier.value;
        }

        // send the update or create request
        const updateOrCreatePromise = () => {
            if (type === 'update') {
                return authAxios.put(`/logistics/materials/${materialId}`, {data});
            } else {
                return authAxios.post(`/logistics/materials`, {data}).then(({data}) => {
                    navigate(`../${data?.data?.id}/update`);
                })
            }
        }

        // create a toast
        toast.promise(
            updateOrCreatePromise,
            {
                pending: `Materiaal ${type === 'update' ? 'Bijwerken' : 'Aanmaken'}...`,
                success: `Materiaal ${type === 'update' ? 'Bijgewerkt' : 'Aangemaakt'}.`,
                error: {
                    render({data}) {

                        data?.response?.data?.error?.details?.errors?.forEach((error) => {
                            setError(error.path.join('.'), {type: 'unique', message: error.message});
                        })
                        return strapiToast(data, true)
                        //return `Er is iets fout gegaan bij het ${type === 'update' ? 'bijwerken' : 'aanmaken'} van het materiaal ${(data?.response?.status !== undefined && `(${data.response.status})`)}`;
                    }
                }
            }
        )
    }

    /**
     * Parses the given values to float (if the result isn't NaN)
     * @param {object} values
     * @returns {object}
     */
    function parseFormValuesToNumber(values) {
        for (const key in values) {
            if (!isNaN(parseFloat(values[key]))) {
                values[key] = parseFloat(values[key]);
            }
        }
        return values;
    }

    return (
        <Container>
            <Block name={'Material'} title={<>
                <Link className={'btn btn--transparent'} to={`/calculations/catalog/materials/${materialId}`}><Icon name={'arrow-left'}/></Link>
                Materiaal {type === 'update' ? 'aanpassen' : 'toevoegen'}
            </>} headerRightSideChildren={
                <>
                    <Button className={'btn--transparent'} style={{ padding: "0", backgroundColor: 'transparent' }} onClick={() => { setUsingEnglish(!usingEnglish) }}>
                        <Icon style={{ width: "30px", lineHeight: '14px' }} className={"fa-2xl"} name={"language"} />
                    </Button>

                    {materialData?.updatedAt && <span>
					<b>Laatst bijgewerkt:</b>
					<span style={{marginLeft: '10px'}}>{formatDate(materialData?.updatedAt)}</span>
                    </span>}
                </>
            }>
                {/* Show the spinner while the machine data is not yet loaded */}
                {!materialData && <img className="spinner" src={spinner} alt='spinner' width="50" height="50"/>}
                {/* Show the form as soon as the machine data has been set */}
                {materialData && <form className={'form'} onSubmit={handleSubmit(onSubmit)}>
                    {/* customerSpecific */}
                    <div className={'input-group'}>
                        <label>{usingEnglish ? "Customer specific" : "Klantspecifiek"}</label>
                        {showErrorMsg('customerSpecific')}
                        <Controller
                            name={'customerSpecific'}
                            control={control}
                            defaultValue={materialData?.customerSpecific ?? ''}
                            render={(field) =>
                                <Boolean 
                                    {...field} 
                                    value={field?.field?.value} 
                                    displayFalse={usingEnglish ? "No" : "Nee"}
                                    displayTrue={usingEnglish ? "Yes" : "Ja"} 
                                    nullable={false}
                                    setValue={(name, value) => {
                                        setIsCustomerSpecific(value);
                                        setValue(name, value);
                                    }} 
                                    />}
                            />
                    </div>

                    {isCustomerSpecific ? (
                        /* customer */
                        <div className={'input-group'}>
                            <label>{usingEnglish ? "Customer" : "Klant"}</label>
                            {showErrorMsg('Customer')}
                            <Select
                                options={customerOptions}
                                value={chosenCustomer}
                                onChange={(e) => {setChosenCustomer(e)}}
                            />
                        </div>
                    ) : (
                        /* supplier */
                        <div className={'input-group'}>
                            <label>{usingEnglish ? "Supplier" : "Leverancier"}</label>
                            {showErrorMsg('Supplier')}
                            <Select
                                options={supplierOptions}
                                value={chosenSupplier}
                                onChange={(e) => {setChosenSupplier(e)}}
                            />
                        </div>
                    )}
                    
                    {/* category */}
                    <div className={'input-group'}>
                        <label className={'required'}>{usingEnglish ? "Category" : "Categorie"}</label>
                        {showErrorMsg('category')}
                        <Controller
                            name={'category'}
                            control={control}
                            defaultValue={materialData?.category ?? ''}
                            rules={{
                                required: msgRequired,
                                minLength: 3
                            }}
                            render={({field}) => <CreatableSelect {...field}
                                                                  value={field?.value ?? ''} isMulti={false}
                                                                  isClearable
                                                                  options={categories}
                                                                  isLoading={categoriesIsLoading}
                            />
                            }
                        />
                    </div>

                    {/* internalSKU */}
                    <div className='input-group'>
                        <label className={'required'}>{usingEnglish ? "Internal SKU" : "Intern SKU"}</label>
                        {showErrorMsg('internalSKU')}
                        <input type={'text'} {...register('internalSKU', {
                            required: msgRequired,
                            minLength: 3,
                            value: materialData?.internalSKU ?? ''
                        })} />
                    </div>

                    {/* supplierSKU */}
                    <div className='input-group'>
                        <label>{usingEnglish ? "Supplier SKU" : "Leverancier SKU"}</label>
                        {showErrorMsg('supplierSKU')}
                        <input type={'text'} {...register('supplierSKU', {
                            minLength: 3,
                            value: materialData?.supplierSKU ?? ''
                        })} />
                    </div>

                    {/* calculationDescription */}
                    <div className='input-group'>
                        <label className={'required'}>{usingEnglish ? "Calculation description" : "Calculatie omschrijving"}</label>
                        {showErrorMsg('calculationDescription')}
                        <input type={'text'} {...register('calculationDescription', {
                            required: msgRequired,
                            minLength: 3,
                            value: materialData?.calculationDescription ?? ''
                        })} />
                    </div>

                    {/* quotationName NL */}
                    <div className='input-group'>
                        <label className={'required'}>{usingEnglish ? "Quotation name (NL)" : "Offerte naam (NL)"}</label>
                        {showErrorMsg('quotationNameNL')}
                        <input type={'text'} {...register('quotationNameNL', {
                            required: msgRequired,
                            minLength: 3,
                            value: materialData?.quotationNameNL ?? ''
                        })} />
                    </div>

                    {/* quotationName EN */}
                    <div className='input-group'>
                        <label className={'required'}>{usingEnglish ? "Quotation name (EN)" : "Offerte naam (EN)"}</label>
                        {showErrorMsg('quotationNameEN')}
                        <input type={'text'} {...register('quotationNameEN', {
                            required: msgRequired,
                            minLength: 3,
                            value: materialData?.quotationNameEN ?? ''
                        })} />
                    </div>

                    {/* lengthInMeters */}
                    <div className='input-group'>
                        <label>{usingEnglish ? "Length In Meters" : "Lengte In Meters"}</label>
                        {showErrorMsg('lengthInMeters')}
                        <input type={'number'} step={0.001} {...register('lengthInMeters', {
                            value: materialData?.lengthInMeters ?? ''
                        })} />
                    </div>

                    {/* widthInMeters */}
                    <div className='input-group'>
                        <label>{usingEnglish ? "Width In Meters" : "Breedte In Meters"}</label>
                        {showErrorMsg('widthInMeters')}
                        <input type={'number'} step={0.001} {...register('widthInMeters', {
                            value: materialData?.widthInMeters ?? ''
                        })} />
                    </div>

                    {/* heightInMeters */}
                    <div className='input-group'>
                        <label>{usingEnglish ? "Height In Meters" : "Hoogte In Meters"}</label>
                        {showErrorMsg('heightInMeters')}
                        <input type={'number'} step={0.001} {...register('heightInMeters', {
                            value: materialData?.heightInMeters ?? ''
                        })} />
                    </div>

                    {/* weightInGram */}
                    <div className='input-group'>
                        <label>{usingEnglish ? "Weight In Gram" : "Gewicht In Gram"}</label>
                        {showErrorMsg('weightInGram')}
                        <input type={'number'} step={1} {...register('weightInGram', {
                            value: materialData?.weightInGram ?? ''
                        })} />
                    </div>

                    {/* thicknessInMM */}
                    <div className='input-group'>
                        <label>{usingEnglish ? "Thickness In MM" : "Dikte In MM"}</label>
                        {showErrorMsg('thicknessInMM')}
                        <input type={'number'} step={0.001} {...register('thicknessInMM', {
                            value: materialData?.thicknessInMM ?? ''
                        })} />
                    </div>

                    {/* unitsPerOrder */}
                    <div className='input-group'>
                        <label>{usingEnglish ? "Units Per Order" : "Aantal per Order"}</label>
                        {showErrorMsg('unitsPerOrder')}
                        <input type={'number'} step={1} {...register('unitsPerOrder', {
                            value: materialData?.unitsPerOrder ?? ''
                        })} />
                    </div>

                    {/* unitsPerOrderName */}
                    <div className='input-group'>
                        <label>{usingEnglish ? "Order Unit" : "Order eenheid"}</label>
                        {showErrorMsg('unitsPerOrderName')}
                        <select {...register('unitsPerOrderName', {
                            value: materialData?.unitsPerOrderName ?? ''
                        })}>
                            <option value='rol'>rol</option>
                            <option value='pallet'>pallet</option>
                            <option value='doos'>doos</option>
                            <option value='dozen'>dozen</option>
                            <option value='meter'>meter</option>
                        </select>
                    </div>

                    {/* countPerUnit */}
                    <div className='input-group'>
                        <label>{usingEnglish ? "Count Per Unit" : "Aantal per eenheid"}</label>
                        {showErrorMsg('countPerUnit')}
                        <input type={'number'} step={1} {...register('countPerUnit', {
                            value: materialData?.countPerUnit ?? ''
                        })} />
                    </div>

                    {/* unit */}
                    <div className='input-group'>
                        <label>{usingEnglish ? "Unit" : "eenheid"}</label>
                        {showErrorMsg('unit')}
                        <select {...register('unit', {
                            value: materialData?.unit ?? ''
                        })}>
                            <option value='kilogram'>kilogram</option>
                            <option value='meter'>meter</option>
                            <option value='strekkende meter'>strekkende meter</option>
                            <option value='vierkante meter'>vierkante meter</option>
                            <option value='enveloppen'>enveloppen</option>
                            <option value='vel'>vel</option>
                            <option value='stuks'>stuks</option>
                            <option value='dozen'>dozen</option>
                        </select>
                    </div>

                    {/* runningDirection */}
                    <div className='input-group'>
                        <label>{usingEnglish ? "Running Direction" : "Looprichting"}</label>
                        {showErrorMsg('runningDirection')}
                        <select {...register('runningDirection', {
                            value: materialData?.runningDirection ?? ''
                        })}>
                            <option value={"LL"}>LL</option>
                            <option value={"BL"}>BL</option>
                        </select>
                    </div>

                    {/* purchasePrice */}
                    <div className='input-group'>
                        <label>{usingEnglish ? "Purchase Price" : "Aankoopprijs"}</label>
                        {showErrorMsg('purchasePrice')}
                        <input type={'number'} step={0.0000001} {...register('purchasePrice', {
                            value: materialData?.purchasePrice ?? ''
                        })} />
                    </div>

                    {/* regularPrice */}
                    <div className='input-group'>
                        <label>{usingEnglish ? "Regular Price" : "Normale prijs"}</label>
                        {showErrorMsg('regularPrice')}
                        <input type={'number'} step={0.0000001} {...register('regularPrice', {
                            value: materialData?.regularPrice ?? ''
                        })} />
                    </div>

                    {/* salePrice */}
                    <div className='input-group'>
                        <label>{usingEnglish ? "Sale Price" : "Verkoopprijs"}</label>
                        {showErrorMsg('salePrice')}
                        <input type={'number'} step={0.0000001} {...register('salePrice', {
                            value: materialData?.salePrice ?? ''
                        })} />
                    </div>

                    {/* thresholdPrice */}
                    <div className='input-group'>
                        <label>{usingEnglish ? "Threshold Amount" : "Drempelwaarde aantal"}</label>
                        {showErrorMsg('thresholdAmount')}
                        <input type={'number'} {...register('thresholdAmount', {
                            value: materialData?.thresholdAmount ?? ''
                        })} />
                    </div>

                    {/* coated */}
                    <div className='input-group'>
                        <label>{usingEnglish ? "Coated" : "Gecoat"}</label>
                        {showErrorMsg('coated')}
                        <Controller
                            name={'coated'}
                            control={control}
                            defaultValue={materialData?.coated ?? ''}
                            render={(field) =>
                                <Boolean {...field} value={field?.field?.value} displayFalse={usingEnglish ? "Uncoated" : "Ongecoat"}
                                    displayTrue={usingEnglish ? "Coated" : "Gecoat"} nullable={true} setValue={setValue} />}
                        />
                    </div>

                    {/* lastPriceUpdate */}
                    <div className='input-group'>
                        <label>{usingEnglish ? "Last Price Update" : "Laaste Prijs aanpassing"}</label>
                        {showErrorMsg('lastPriceUpdate')}
                        <input type={'date'} {...register('lastPriceUpdate', {
                            value: materialData?.lastPriceUpdate ?? ''
                        })} />
                    </div>

                    <button className={'btn'} type={'submit'}>Opslaan <Icon name={'save'}/></button>
                </form>}
            </Block>
        </Container>
    )
}
