import {Link, useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import React, {useContext, useEffect, useState} from "react";
import {FetchContext} from "App/Strapi/FetchContext";
import Table from "../../../../Components/Table/Table";
import Popup, {closePopup, openPopup} from "../../../../Components/Popup/Popup";
import {usePagination} from "../../../../Components/Pagination/Pagination";
import {stringify} from "qs";
import Icon from "../../../../Components/Icon/Icon";
import Container from "UI/App/Components/Container/Container";
import Boolean from "UI/App/Components/Form/Boolean";

export default function Widgets() {
    const { authAxios } = useContext(FetchContext);
    const [tableData, setTableData] = useState([]);
    const navigate = useNavigate();

    // Enable pagination
    const {
        paging,
        filtering,
        currentPage,
        resultsPerPage,
        setTotalPages,
        setTotalResults,
        filterQuery,
        paginationStateLoaded
    } = usePagination({
        storageKey: 'resource-templates',
        searchSettings: {
            enabled: true,
            strapiFields: [
                'name',
            ]
        },
        filters: [
            {
                name: 'active',
                type: 'select',
                options: [
                    { value: 'true', name: 'Actief' },
                    { value: 'false', name: 'Inactief' },
                    { value: '*', name: 'Beide' },
                ],
                strapiFilterFields: [
                    'active'
                ]
            }
        ],
        htmlElements: [
            <Link to={`./create`} className="btn btn--icon-right">Toevoegen <Icon name='plus' /></Link>,
        ]
    });

    useEffect(() => {

        if (!paginationStateLoaded) return;

        const params = stringify({
            populate: 'machine',
            sort: ['name:asc'],
            filters: {
                $or: [
                    {
                        "machine": {
                            "archived": {
                                $null: true
                            }
                        }
                    },
                    {
                        "machine": {
                            "archived": {
                                $eq: false
                            }
                        }
                    }
                ]
            },
            pagination: {
                pageSize: resultsPerPage,
                page: currentPage
            }
        });

        // get the machines from the api
        authAxios.get(`/calculations/resources/templates?${params}&${filterQuery}`).then(({ data }) => {
            setTotalPages(data.meta.pagination.pageCount)
            setTotalResults(data.meta.pagination.total)

            // check for the data
            if (data) {
                const tableData = [];

                // loop through all the records
                for (const widgetFlow of (data.data)) {

                    // add a row for the company
                    tableData.push({
                        attributes: {
                            id: widgetFlow.id,
                            onClick: (e) => {
                                const url = `/calculations/widgets/${widgetFlow.id}/edit/`;
                                if (e?.ctrlKey || e?.metaKey) {
                                    window.open(url, '_blank');
                                } else if (e.shiftKey) {
                                    window.open(url);
                                } else {
                                    navigate(url);
                                }
                            }
                        },
                        data: [
                            widgetFlow.name,
                            widgetFlow.machine?.name ?? <i style={{ color: 'gray' }}>Geen</i>,
                            <Boolean
                                displayFalse='Inactief'
                                displayTrue='Actief'
                                falseIsRed={true}
                                value={widgetFlow.active}
                                field={{ name: 'active' }}
                                setValue={(name, value) => {
                                    updateWidgetTemplateActive(widgetFlow.id, value);
                                }}
                            />
                        ],
                    })
                }
                setTableData(tableData);
            }
        }).catch((e) => {
            if (process.env.NODE_ENV === 'development') console.error(e);
            toast.error(`Er is iets fout gegaan bij het ophalen van de widgets! ${e?.response?.status && `(${e.response.status})`}`);
        });
    }, [authAxios, currentPage, resultsPerPage, setTotalPages, setTotalResults, filterQuery, paginationStateLoaded]);


    function updateWidgetTemplateActive(id, active) {

        const pendingUpdate = toast.loading("De template wordt aangepast...");

        authAxios.put(`/calculations/resources/templates/${id}`, {
            data: {
                active
            }
        }).then(() => {
            toast.update(pendingUpdate, {
                render: "Template aangepast.",
                type: "success",
                isLoading: false,
                autoClose: 1500
            });
        }).catch((e) => {
            console.error(e);

            toast.update(pendingUpdate, {
                render: `Er is iets fout gegaan bij het aanpassen van de widget! ${e?.response?.status && `(${e.response.status})`}`,
                type: "error",
                isLoading: false,
                autoClose: 4000
            });
        });
    }

    function handleCreateButtonClick() {
        openPopup('createWidgetFlow');
    }

    function createFlow(e) {
        e.preventDefault();

        // get the flow name
        const flowName = e.target.elements?.['widgetFlowName']?.value;

        // send the axios request to create the flow
        authAxios.post('/calculations/resources/templates', {
            data: {
                name: flowName,
                widgetData: {},
                templateType: 'WidgetCollectionFlow'
            }
        }).then(({ data }) => {
            closePopup();
            navigate(`/calculations/widgets/${data.data.id}/edit`);
        }).catch((reason) => {
            toast.error(`Er is iets mis gegaan bij het aanmaken van de widget flow${reason.response.status && ` (${reason.response.status})`}`);
            if (process.env.NODE_ENV === 'development') {
                console.error(reason);
            }
        });

        console.log(flowName);
    }

    return (
        <div>
            <Popup popupID='createWidgetFlow' title={"Widgetflow aanmaken"}>
                <form style={{
                    display: 'flex',
                    flexWrap: 'wrap'
                }} onSubmit={createFlow}>
                    <input id={'widgetFlowName'} type={'text'} placeholder={'Flow benaming'} name={'widgetFlowName'} />
                    <button className={'btn btn--center'} type={'submit'}>Aanmaken</button>
                </form>
            </Popup>
            {filtering}
            <Container>
                <Table
                    headers={['Naam', 'Machine', '']}
                    structure={{
                        550: ['100%', 0, '160px'],
                        900: ['60%', '40%', '160px'],
                        default: ['60%', '40%', '210px']}
                    }
                    data={tableData}
                />
            </Container>
            {paging}
        </div>
    )
}
