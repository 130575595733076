import React, {useContext, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import {FetchContext} from 'App/Strapi/FetchContext';
import Table from 'UI/App/Components/Table/Table';
import Popup, {closePopup, openPopup} from "UI/App/Components/Popup/Popup";
import Icon from "UI/App/Components/Icon/Icon";
import HasRole from "UI/App/Components/Auth/HasRole";
import PageHeader from "UI/App/Components/PageHeader/PageHeader";
import Select from "react-select";
import Grid from "UI/App/Components/Grid/Grid";
import CheckInStorage from "UI/App/Partials/Content/Warehouse/CheckInStorage";
import CheckOutStorage from "UI/App/Partials/Content/Warehouse/CheckOutStorage";
import User from "App/Strapi/User";
import useWindowDimensions from "App/Util/useWindowDimensions";

export default function Storage({title = ''}) {
    const {width} = useWindowDimensions();
    const {authAxios} = useContext(FetchContext);
    const params = useParams();
    const [editingStorageSpot, setEditingStorageSpot] = useState(null);

    const [chosenStorageSpot, setChosenStorageSpot] = useState(null);

    const [name, setName] = useState('');
    const sizeOptions = [
        {
            label: 'Europallet (80x120cm.)',
            value: 'Europallet (80x120cm.)'
        },
        {
            label: 'Pallet (80x60cm.)',
            value: 'Pallet (80x60cm.)'
        },
        {
            label: 'Mini pallet (40x60cm.)',
            value: 'Mini pallet (40x60cm.)'
        },
        {
            label: 'A4-doos (30x22cm.)',
            value: 'A4-doos (30x22cm.)'
        },
        {
            label: 'Anders',
            value: 'Anders'
        }
    ]
    const [chosenSize, setChosenSize] = useState(null);

    const categoryOptions = [
        {
            label: 'Normale voorraad',
            value: 'Normale voorraad'
        },
        {
            label: 'Snelle voorraad',
            value: 'Snelle voorraad'
        },
        {
            label: 'Bulk voorraad',
            value: 'Bulk voorraad'
        }
    ]
    const [chosenCategory, setChosenCategory] = useState(null);


    const [warehouse, setWarehouse] = useState({name: null, location: null});
    const [stockTableData, setStockTableData] = useState([]);
    const [isTableLoading, setIsTableLoading] = useState(true);

    useEffect(() => {
        void loadData();
    }, [warehouse])

    useEffect(() => {
        if (params.id !== undefined) {
            authAxios
                .get(`logistics/warehouses/${params.id}?populate=storage`)
                .then(({data}) => {
                    setWarehouse(data.data);
                })
                .catch((exception) => {
                    console.error(exception);
                    toast.error('Kon de opslag posities niet ophalen.');
                });
        }
    }, [params.id]);

    function setDefaults() {
        setName('');
        setChosenSize(null);
        setChosenCategory({
            label: 'Normale voorraad',
            value: 'Normale voorraad'
        })
    }

    async function loadData() {

        await authAxios
            .get(`logistics/warehouses/${params.id}?populate=storage.stock.material`)
            .then(({data}) => {
                const tableData = [];

                if (data.data?.storage !== undefined) {


                    // loop through all the records
                    for (const [_, storageSpot] of Object.entries(data.data?.storage)) {

                        // add a row for the warehouse
                        tableData.push({
                            attributes: {
                                onClick: () => {
                                    setChosenStorageSpot(storageSpot)
                                },
                            },
                            data: [
                                storageSpot.name,
                                storageSpot.category,
                                storageSpot.size,
                                storageSpot?.stock?.material !== null && storageSpot?.stock?.material !== undefined ? storageSpot?.stock?.material?.internalSKU : '-',
                                <button className={'btn--transparent'} style={{ color: 'black' }} onClick={() => {
                                    setName(storageSpot.name)
                                    setChosenSize({
                                        label: storageSpot.size,
                                        value: storageSpot.size
                                    })
                                    setChosenCategory({
                                        label: storageSpot.category,
                                        value: storageSpot.category
                                    })
                                    setEditingStorageSpot(storageSpot);

                                    openPopup('addUpdateStorageSpot')
                                }}>
                                    <Icon name={'wrench'} />
                                </button>
                            ]
                        });
                    }

                    setStockTableData(tableData);
                }
                setIsTableLoading(false);
            })
            .catch((exception) => {
                console.error(exception);
                toast.error('Kon de magazijnen niet ophalen.');
            });

        setDefaults();
    }

    async function deleteStorageSpot() {
        await authAxios.delete(`/storage-spot/${editingStorageSpot.id}`,).then(({data}) => {
            toast.warn(`Opslag positie verwijderd!`);
            loadData();
            closePopup();
        }).catch((exception) => {
            console.error(exception);
            toast.error(`Er ging iets mis met het verwijderen van opslag positie!`);
        });
    }

    async function createUpdateStorageSpot() {
        const data = {
            name: name,
            size: chosenSize.value,
            category: chosenCategory.value,
            warehouse: params.id
        };

        if (editingStorageSpot === null) {
            // Add new storage spot
            await authAxios.post(`/storage-spot`, {
                data: data
            }).then(({data}) => {
                toast.success(`Opslag positie aangemaakt!`);
            }).catch((exception) => {
                console.error(exception);
                toast.error(`Er ging iets mis met het opslaan van de opslag positie!`);
            });
        } else {
            // Update storage spot
            await authAxios.put(`/storage-spot/${editingStorageSpot.id}`, {
                data: data
            }).then(({data}) => {
                toast.success(`Opslag positie bewerkt!`);
            }).catch((exception) => {
                console.error(exception);
                toast.error(`Er ging iets mis met het bewerken van de opslag positie`);
            });
            setEditingStorageSpot(null);
        }

        // Load new data and close popup
        await loadData();
        closePopup();
    }

    return (
        <>
            <PageHeader sticky={true} title={title}>
                <HasRole roles={['admin', 'management', 'productie manager']}>
                    <button data-title={'Opslag positie toevoegen'} onClick={() => {
                        setName(null);
                        setDefaults()
                        setEditingStorageSpot(null);
                        openPopup('addUpdateStorageSpot')
                    }} className={'btn btn--black'}>{width > 550 ? 'Positie toevoegen ' : ''}<Icon name={'plus'}/></button>
                </HasRole>
            </PageHeader>
            <Table
                headers={[`Positie`, 'Type', 'Maat', 'Product (internal SKU)', '']}
                data={stockTableData}
                padding='s'
                isDataLoading={isTableLoading}
                structure={{
                    700: ['150px', '50%', '50%', 0, '65px'],
                    1000: ['200px', '25%', '25%', '25%', '65px'],
                    default: ['200px', '200px', '250px', '100%', '65px']
                }}
            />
            <Popup
                popupID='checkIn'
                title={'Inboeken'}
                overflow={true}
                contentStyle={{
                    minHeight: '20px'
                }}
            >
                <CheckInStorage setChosenStorageSpot={setChosenStorageSpot} chosenStorageSpot={chosenStorageSpot} warehouse={warehouse} setWarehouse={setWarehouse}/>
            </Popup>
            <Popup
                popupID='checkOut'
                title={'Uitboeken'}
                overflow={true}
                contentStyle={{
                    minHeight: '20px'
                }}
            >
                <CheckOutStorage setChosenStorageSpot={setChosenStorageSpot} chosenStorageSpot={chosenStorageSpot} setWarehouse={setWarehouse}/>
            </Popup>
            <Popup
                popupID='addUpdateStorageSpot'
                title={editingStorageSpot === null ? 'Opslag positie toevoegen' : 'Opslag positie bewerken'}
                overflow={true}
                contentStyle={{
                    minHeight: '20px'
                }}
            >
                <label>
                    Naam
                </label>
                <input
                    type="text"
                    value={name}
                    onChange={(e) => {
                        setName(e.target.value)
                    }}
                />
                <label>
                    Maat
                </label>
                <Select
                    options={sizeOptions}
                    value={chosenSize}
                    onChange={(e) => {
                        setChosenSize(e)
                    }}
                />
                <label>
                    Categorie
                </label>
                <Select
                    options={categoryOptions}
                    value={chosenCategory}
                    onChange={(e) => {
                        setChosenCategory(e)
                    }}
                />
                <Grid columns={editingStorageSpot !== null && User.hasRole(['admin']) ? 2 : 1}>
                    {editingStorageSpot !== null ? <HasRole roles={['admin']}>
                        <button
                            className={'btn--error'}
                            onClick={deleteStorageSpot}
                        >
                            Verwijderen <Icon name={'trash-can'}/>
                        </button>
                    </HasRole> : <></>}
                    <button
                        onClick={createUpdateStorageSpot}
                        disabled={name === '' || chosenSize === null}
                    >
                        Opslaan
                    </button>
                </Grid>
            </Popup>
        </>
    );
}
