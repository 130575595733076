import {useContext, useEffect, useState} from "react";
import {toast} from "react-toastify";
import {stringify} from "qs";
import {FetchContext} from "App/Strapi/FetchContext";
import Select from "react-select";
import {useNavigate} from "react-router-dom";
import {closePopup} from "UI/App/Components/Popup/Popup";
import ProjectRepository from "../../../Projects/ProjectRepository";

export default function FromQuote({FSCStatus}) {
    const {authAxios} = useContext(FetchContext);
    const navigate = useNavigate();

    const [chosenQuote, setChosenQuote] = useState(null);
    const [quotes, setQuotes] = useState([]);
    const [quote, setQuote] = useState(null)

    const [calculationData, setCalculationData] = useState(null);

    const [amountsForStorage, setAmountsForStorage] = useState([]);
    const [reference, setReference] = useState(null)

    // Use effect for loading data
    useEffect(() => {
        load();
    }, []);

    useEffect(() => {
        if (quote === null) return;

        // Get calculations from quote
        const quoteParam = stringify({
            populate: {
                calculations: true,
                company: true,
                contactPerson: true
            }
        })

        authAxios.get(`/quotes/${quote.value}?${quoteParam}`)
            .then(({data}) => {

                setChosenQuote(data.data)
                setCalculationData(data.data.calculations)
                setReference(data?.data?.calculations[0]?.data?.checklist?.find(item => ["customerReference", "Your reference"].includes(item.name))?.value)

            }).catch((exception) => {
            toast.error(`Er is iets fout gegaan ${exception?.response?.status ? `(${exception.response?.status})` : ''}`);
            console.error(exception);
        })
    }, [quote]);

    async function updateAmounts(amount,calculationId){
        let storedAmount= {...amountsForStorage}
        storedAmount[calculationId] = amount;

        setAmountsForStorage(storedAmount);
    }

    async function load() {
        const quoteParam = stringify({
            populate: {
                company: true,
                description: true
            },
            fields:[
                'number'
            ]
        })

        // Load all calculations
        authAxios.get(`/quotes/all?${quoteParam}&sort=number:desc`)
            .then(({data}) => {
                let tableData = [];

                for (const quote of data) {
                    tableData.push({
                        label: `${quote?.number} - ${quote?.company?.name ?? ""} ${quote?.description ? `- ${quote?.description}` : ""}`,
                        value: quote.id
                    })
                }

                setQuotes(tableData)
            }).catch((exception) => {
            toast.error(`Er is iets fout gegaan ${exception?.response?.status ? `(${exception.response?.status})` : ''}`);
            console.error(exception);
        })
    }

    async function createOrders() {

        // Create project
        let project = await new ProjectRepository(null, authAxios).createProject(null)
        let orderIds = [];
        let calculationIds = [];

        // Add quote to project
        await new ProjectRepository(project, authAxios).addQuoteToProject([chosenQuote.id])

        // Loop calculation data to create order
        for(const calculation of  calculationData){
            // Gather calculations ids to add to project
            calculationIds.push(calculation.id);

            let json = {
                data: {
                    description: chosenQuote.description,
                    customerReference: reference,
                    //     shipping: getShippingData(data),
                    status: 'binnengekomen',
                    chosenAmount: amountsForStorage[calculation.id],
                    calculation: calculation.id,
                    company: chosenQuote.company,
                    contactPerson: chosenQuote.contactPerson,
                    quote: chosenQuote.id,
                    FSC: FSCStatus
                }
            }

            await authAxios.post(`/orders`, json).then(({ data }) => {

                setQuoteStatus()
                orderIds.push(data.data.id);
                toast.success(`Order ${data.data.number} aangemaakt!`);
            }).catch((exception) => {
                console.error(exception);
                toast.error(`Er ging iets mis met het opslaan van een order!`);
            });

        }

        // Add projects
        new ProjectRepository(project, authAxios).addCalculationsToProject(calculationIds);

        // Add orders to project
        new ProjectRepository(project, authAxios).addOrdersToProject(orderIds);

        // Navigate to order
        closePopup('addOrder');
        navigate(`/orders/${orderIds[0]}`);
    }

    async function setQuoteStatus(quote, status = "order") {


        await authAxios.put(`quotes/${chosenQuote?.id}`, {
            data: {
                status: status
            }
        }).catch((reason) => {
            console.error(reason);
            toast.error(`Er is iets mis gegaan bij het updaten van de status. ${reason?.response?.status && `(${reason.response.status})`}`)
        });

        // format all the id of the calculation
        let calculationsToUpdate = chosenQuote?.calculations.map((ell) => { return { id: { $eq: ell.id } } }) ?? {}

        // set all the linked calculations to be the same, 'order' by default
        await authAxios.post(`calculations/update-many-statuses`, {
            data: {
                status: status
            },
            where: {
                $or: [...calculationsToUpdate]
            }


        }).catch((reason) => {
            console.error(reason);
            toast.error(`Er is iets mis gegaan bij het updaten van de status. ${reason?.response?.status && `(${reason.response.status})`}`)
        });
    }
    return (
        <>
            Offerte:
            <Select
                name='quote'
                id='quote'
                isClearable={false}
                options={quotes}
                isLoading={quotes.length === 0 || !quotes}
                value={quote}
                onChange={(quote) => {
                    setQuote(quote);
                }}
                required
            />
            {calculationData !== null ? calculationData.map(function (calculation, i) {
                    let amounts = [];

                if (calculation?.data?.amounts?.length > 0) {
                    for (const amount of calculation?.data?.amounts) {
                        amounts.push({
                            label: amount,
                            value: amount,
                        })
                    }
                }

                    return <>
                        {calculation.number} :
                        <Select
                            name='amount'
                            id='amount'
                            isClearable={false}
                            options={amounts}
                            onChange={(amount) => {
                                updateAmounts(amount.value,calculation.id)
                            }}
                            required
                        />
                    </>
                })
                : <></>}
            Klant referentie:
            <input
                name='reference'
                type='text'
                value={reference}
                onChange={(reference) => {
                    setReference(reference.target.value)

                }}
            />

            <br />
            <button onClick={() => createOrders()} disabled={!(calculationData !== null && amountsForStorage !== null ? Object.keys(amountsForStorage).length === Object.keys(calculationData).length : false)}>
                Opslaan
            </button>
        </>
    )
}
