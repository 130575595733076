import { formatCurrency, formatNumberValue, formatSeconds, formatSmallCurrency } from "App/Util/format";
import React from "react";
import { getMaterialsForOrder } from "UI/App/Partials/Content/CRM/Orders/orderUtil";
import { getAuthAxios } from "App/Util/fetch";

export default class ActualCostingCalculator {

    order;

    constructor(order) {
        this.order = order;
    }

    getCostPrice() {
        const summary = this.#getCalculationSummary();

        if (summary) {
            return summary.total;
        }

        return 0;
    }

    getCalculationCost() {
        const summary = this.#getCalculationSummary();

        if (summary) {
            return summary.subTotal;
        }

        return 0;
    }

    getQuoteCost() {
        const summary = this.#getCalculationSummary();

        if (summary) {
            return summary.quote;
        }

        return null;
    }

    getMachineCostData() {
        const returnValue = {
            tableData: [],
            cost: 0,
            realisedCost: 0,
            calculationCost: 0
        }

        const machineTabResults = Object.entries(this.order.calculation.data.rawResult);

        for (const [machineTabId, machineTabValues] of machineTabResults) {
            if (this.#isDisabledOptionalTab(machineTabId) || machineTabValues['Machine planning.Opstart uren'] === undefined) {
                continue;
            }

            const name = this.#getTabName(machineTabId);
            const startupHours = machineTabValues['Machine planning.Opstart uren']?.[this.order.chosenAmount];
            const productionHours = machineTabValues['Machine planning.Productie uren']?.[this.order.chosenAmount];
            const hourlyRate = Number(this.#getTabConstant(machineTabId, 'hourlyRate'));
            const employeeWage = Number(this.#getTabConstant(machineTabId, 'employeeWage'));
            const productionCostPerHour = Number(this.#getTabConstant(machineTabId, 'productionCostPerHour'));
            const totalCost = machineTabValues['Totaal.Totale machine kosten inclusief arbeid']?.[this.order.chosenAmount];

            const calculationSum = `(${formatNumberValue(startupHours, 'tijd')} × ${formatCurrency(hourlyRate + employeeWage)}) + (${formatNumberValue(productionHours, 'tijd')} × ${formatCurrency(productionCostPerHour)}) = ${formatCurrency(totalCost)}`;

            const matchingTimers = this.order.timers.filter(timer => timer.timerFor === name);

            const totalRegisteredTimeInSeconds = matchingTimers.reduce((acc, timer) => {
                const start = new Date(timer.start);
                const end = new Date(timer.end ?? new Date());

                const totalDiff = end - start;

                const breaks = timer.breaks.reduce((acc, current) => {
                    const start = new Date(current.start);
                    const end = new Date(current.end ?? new Date());

                    return acc + (end - start);
                }, 0);

                return acc + (totalDiff - breaks) / 1000;
            }, 1);

            const calculatedTotal = (totalRegisteredTimeInSeconds / 60 / 60) * productionCostPerHour;
            const realisedSum = `${formatSeconds(totalRegisteredTimeInSeconds, false)} × ${formatCurrency(productionCostPerHour)} = ${formatCurrency(calculatedTotal)}`;

            const priceDiff = (calculatedTotal - totalCost) / totalCost * 100;

            returnValue.tableData.push([
                name,
                calculationSum,
                matchingTimers.length > 0 ? realisedSum : <span style={{ color: '#0089B4' }}>{calculationSum}</span>,
                matchingTimers.length > 0 ? <span style={{ color: priceDiff > 0 ? '#D60E14' : '#76B83A' }}>{formatCurrency(calculatedTotal - totalCost)} ({priceDiff > 0 ? '+' : ''}{formatNumberValue(priceDiff, 'procent')})</span> :
                    <span style={{ color: '#0089B4' }}>{formatCurrency(0)} (0%)</span>
            ]);

            const total = matchingTimers.length > 0 ? calculatedTotal : totalCost;
            returnValue.cost += isNaN(total) ? 0 : total;

            returnValue.calculationCost += totalCost;
            returnValue.realisedCost += total;
        }

        const totalDiff = returnValue.realisedCost - returnValue.calculationCost;
        const totalDiffPercentage = totalDiff / returnValue.calculationCost * 100;

        returnValue.tableData.push({
            attributes: {
                style: {
                    fontWeight: 'bold',
                }
            },
            data: [
                'Totaal',
                formatCurrency(returnValue.calculationCost),
                formatCurrency(returnValue.realisedCost),
                <span style={{ color: totalDiff > 0 ? '#D60E14' : '#76B83A' }}>{formatCurrency(totalDiff)} ({totalDiff > 0 ? '+' : ''}{formatNumberValue(totalDiffPercentage, 'procent')})</span>
            ]
        });

        return returnValue;
    }

    async getMaterialCostData() {
        const returnValue = {
            tableData: [],
            cost: 0,
            realisedCost: 0,
            calculationCost: 0
        }

        const materials = await getMaterialsForOrder(this.order, this.order.calculation, getAuthAxios());

        if (!materials) {
            return returnValue;
        }

        for (const { material, stock } of materials) {

            const hasStock = stock?.raw?.writtenOff !== undefined;

            const calculationCost = material.priceQuantity * material.salePrice;
            const calculationSum = `${material.formattedValue} × ${formatSmallCurrency(material.salePrice)} = ${formatCurrency(calculationCost)}`;

            const realisedCost = hasStock ? stock?.raw?.writtenOff * material.salePrice : calculationCost;
            const realisedSum = `${formatNumberValue(stock?.raw?.writtenOff, stock?.unit)} × ${formatSmallCurrency(material.salePrice)} = ${formatCurrency(realisedCost)}`;

            const priceDiff = (realisedCost - calculationCost) / calculationCost * 100;

            returnValue.cost += isNaN(realisedCost) ? 0 : realisedCost;
            returnValue.calculationCost += calculationCost;
            returnValue.realisedCost += isNaN(realisedCost) ? calculationCost : realisedCost;

            returnValue.tableData.push([
                material.name,
                calculationSum,
                hasStock ? realisedSum : <span style={{ color: '#0089B4' }}>{calculationSum}</span>,
                hasStock ? <span style={{ color: priceDiff > 0 ? '#D60E14' : '#76B83A' }}>{priceDiff > 0 ? '+' : ''}{formatNumberValue(priceDiff, 'procent')}</span> : <span style={{ color: '#0089B4' }}>0%</span>
            ])
        }

        const totalDiff = returnValue.realisedCost - returnValue.calculationCost;
        const totalDiffPercentage = totalDiff / returnValue.calculationCost * 100;

        returnValue.tableData.push({
            attributes: {
                style: {
                    fontWeight: 'bold',
                }
            },
            data: [
                'Totaal',
                formatCurrency(returnValue.calculationCost),
                formatCurrency(returnValue.realisedCost),
                <span style={{ color: totalDiff > 0 ? '#D60E14' : '#76B83A' }}>{formatCurrency(totalDiff)} ({totalDiff > 0 ? '+' : ''}{formatNumberValue(totalDiffPercentage, 'procent')})</span>
            ]
        });

        return returnValue;
    }

    getMiscellaneousCost() {
        const returnValue = {
            tableData: [],
            cost: 0,
            realisedCost: 0,
            calculationCost: 0
        }

        const machineTabResults = Object.entries(this.order.calculation.data.rawResult);

        for (const [machineTabId, machineTabValues] of machineTabResults) {
            if (this.#isDisabledOptionalTab(machineTabId) || machineTabValues['Machine planning.Opstart uren'] !== undefined || machineTabValues['Totaal.Subtotaal'] === undefined) {
                continue;
            }

            const cost = Number(machineTabValues['Totaal.Subtotaal']?.[this.order.chosenAmount]);

            if (!cost || isNaN(cost))
                continue;

            returnValue.tableData.push([
                this.#getTabName(machineTabId),
                formatCurrency(cost),
                <span style={{ color: '#0089B4' }}>{formatCurrency(cost)}</span>,
                <span style={{ color: '#0089B4' }}>{formatCurrency()} (0%)</span>,
            ]);

            returnValue.cost += cost;
            returnValue.calculationCost += cost;
            returnValue.realisedCost += cost;
        }

        const totalDiff = returnValue.realisedCost - returnValue.calculationCost;
        const totalDiffPercentage = totalDiff / returnValue.calculationCost * 100;

        if (returnValue.cost > 0) {
            returnValue.tableData.push({
                attributes: {
                    style: {
                        fontWeight: 'bold',
                    }
                },
                data: [
                    'Totaal',
                    formatCurrency(returnValue.calculationCost),
                    formatCurrency(returnValue.realisedCost),
                    <span style={{ color: totalDiff > 0 ? '#D60E14' : '#76B83A' }}>{formatCurrency(totalDiff)} ({totalDiff > 0 ? '+' : ''}{formatNumberValue(totalDiffPercentage, 'procent')})</span>
                ]
            });
        }

        return returnValue;
    }

    getThirdPartyData() {
        const returnValue = {
            tableData: [],
            cost: 0,
            realisedCost: 0,
            calculationCost: 0
        }

        const thirdPartyEntries = this.order.calculation.data.thirdParty;

        if (!thirdPartyEntries || thirdPartyEntries?.length === 0) {
            return returnValue;
        }

        for (const thirdPartyEntry of thirdPartyEntries) {
            returnValue.tableData.push([
                thirdPartyEntry.description,
                formatCurrency(thirdPartyEntry.quantities[this.order.chosenAmount].cost),
                <span style={{ color: '#0089B4' }}>{formatCurrency(thirdPartyEntry.quantities[this.order.chosenAmount].cost)}</span>,
                <span style={{ color: '#0089B4' }}>0%</span>
            ]);

            returnValue.cost += thirdPartyEntry.quantities[this.order.chosenAmount].cost;
            returnValue.realisedCost += thirdPartyEntry.quantities[this.order.chosenAmount].cost;
            returnValue.calculationCost += thirdPartyEntry.quantities[this.order.chosenAmount].cost;
        }

        const totalDiff = returnValue.realisedCost - returnValue.calculationCost;
        const totalDiffPercentage = totalDiff / returnValue.calculationCost * 100;

        returnValue.tableData.push({
            attributes: {
                style: {
                    fontWeight: 'bold',
                }
            },
            data: [
                'Totaal',
                formatCurrency(returnValue.calculationCost),
                formatCurrency(returnValue.realisedCost),
                <span style={{ color: totalDiff > 0 ? '#D60E14' : '#76B83A' }}>{formatCurrency(totalDiff)} ({totalDiff > 0 ? '+' : ''}{formatNumberValue(totalDiffPercentage, 'procent')})</span>
            ]
        });

        return returnValue;
    }

    getManualLaborData() {
        const returnValue = {
            tableData: [],
            cost: 0,
            realisedCost: 0,
            calculationCost: 0
        }

        const manualLaborEntries = this.order.calculation.data.manualLabor;

        if (!manualLaborEntries || manualLaborEntries?.length === 0) {
            return returnValue;
        }

        for (const manualLaborEntry of manualLaborEntries) {
            const entryCost = manualLaborEntry.quantities[this.order.chosenAmount].minutes / 60 * manualLaborEntry.hourlyWage;

            returnValue.tableData.push([
                manualLaborEntry.description,
                formatCurrency(entryCost),
                <span style={{ color: '#0089B4' }}>{formatCurrency(entryCost)}</span>,
                <span style={{ color: '#0089B4' }}>0%</span>
            ]);

            returnValue.cost += entryCost;
            returnValue.realisedCost += entryCost;
            returnValue.calculationCost += entryCost;
        }

        const totalDiff = returnValue.realisedCost - returnValue.calculationCost;
        const totalDiffPercentage = totalDiff / returnValue.calculationCost * 100;

        returnValue.tableData.push({
            attributes: {
                style: {
                    fontWeight: 'bold',
                }
            },
            data: [
                'Totaal',
                formatCurrency(returnValue.calculationCost),
                formatCurrency(returnValue.realisedCost),
                <span style={{ color: totalDiff > 0 ? '#D60E14' : '#76B83A' }}>{formatCurrency(totalDiff)} ({totalDiff > 0 ? '+' : ''}{formatNumberValue(totalDiffPercentage, 'procent')})</span>
            ]
        });

        return returnValue;
    }

    #getCalculationSummary() {
        if (this.order?.calculation !== null) {
            // Check if chosen amount in order is not null
            if (this.order?.chosenAmount !== null) {
                if (this.order?.calculation.data?.summary[this.order?.chosenAmount] !== undefined) {
                    return this.order?.calculation.data?.summary[this.order?.chosenAmount];
                }
            }
        }

        return null;
    }

    async getRealisedCost() {
        const machineCost = this.getMachineCostData().cost;
        const materialCost = (await this.getMaterialCostData()).cost;
        const thirdPartyCost = this.getThirdPartyData().cost;
        const manualLaborCost = this.getManualLaborData().cost;
        const miscellaneousCost = this.getMiscellaneousCost().cost;

        return machineCost + materialCost + thirdPartyCost + manualLaborCost + miscellaneousCost;
    }

    #getTabConstant(tabId, name) {
        const constPrefix = tabId.split('-')[0];
        return this.order.calculation.data.templateData.data?.[tabId]?.constants?.find(constant => constant.id === `${constPrefix}-const-${name}`)?.value;
    }

    #getTabName(tabId) {
        return this.order.calculation.data.templateData.data?.[tabId]?.name?.trim();
    }

    #isDisabledOptionalTab(tabId) {
        return this.order.calculation.data.optionalMachines?.[tabId] === "false"
    }
}